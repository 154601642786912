import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { groupBy, sortBy, uniq } from "lodash";

import { Card, Classes, H4, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box } from "@rebass/grid";
import AppViewTemplate from "../../../templates/AppView";
import { AutoGrid, MaxBound } from "../../../atoms/Layout";
import NewEntryCard from "../../../organisms/NewEntryCard";
import CreateModelModal from "../../../modals/CreateModel";
import Loading from "../../../molecules/Loading";

import { getClient } from "../../../../store/modules/client";
import { getModels, ModelMap } from "../../../../store/modules/model";
import { RootState } from "../../../../store/reducer";
import { Model, Profile } from "../../../../types/models";
import { ThunkActionDispatch } from "../../../../types/redux";
import ModelCard from "./ModelCard";

interface IProps extends RouteComponentProps<any> {
  getClient: ThunkActionDispatch<typeof getClient>;
  getModels: ThunkActionDispatch<typeof getModels>;
  models?: ModelMap;
  profile: Profile;
}

interface IState {
  isLoading: boolean;
  showCreateModelModal: boolean;
  modelToEdit: Model | undefined;
}

class ClientView extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    showCreateModelModal: false,
    modelToEdit: undefined,
  };

  public async componentWillMount() {
    const clientID = this.props.match.params.client;
    try {
      await this.props.getClient(clientID);
      await this.props.getModels(clientID);

      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      // TODO: Error handling
      console.log(error);
    }
  }

  public render() {
    const { match, models, profile } = this.props;
    const { isLoading, showCreateModelModal, modelToEdit } = this.state;

    const myModelIds =
      profile !== undefined
        ? uniq(profile.ModelMemberships.map((m) => m.ModelID))
        : [];

    const { myModels = [], otherModels = [] } = groupBy(
      sortBy(models, "Name"),
      (model) =>
        myModelIds.indexOf(model.id) !== -1 ? "myModels" : "otherModels"
    );

    return (
      <AppViewTemplate title="Models">
        <MaxBound mt={3}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <H4>My Models</H4>
              {myModels.length === 0 && (
                <NonIdealState
                  icon={IconNames.INFO_SIGN}
                  title="No Models"
                  description="You are not currently assigned to any models in this client"
                />
              )}
              <AutoGrid columns={4}>
                {myModels.map((model) => (
                  <ModelCard
                    key={model.id}
                    model={model}
                    clientId={match.params.client}
                    toggleModal={this.toggleModal}
                    profile={profile}
                  />
                ))}
              </AutoGrid>
              <Box mb={3} />
              <H4>Other Models</H4>
              <AutoGrid columns={4}>
                {otherModels.map((model, i) => (
                  <Link
                    key={i}
                    to={`/clients/${match.params.client}/models/${model.id}/current`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Card interactive={true} className={Classes.TEXT_LARGE}>
                      {model.Name}
                    </Card>
                  </Link>
                ))}
                {
                  <NewEntryCard
                    title="Add New Model"
                    onClick={this.toggleModal}
                  />
                }
              </AutoGrid>
            </>
          )}
          {showCreateModelModal && (
            <CreateModelModal
              model={modelToEdit}
              isOpen={showCreateModelModal}
              onClose={this.toggleModal}
            />
          )}
        </MaxBound>
      </AppViewTemplate>
    );
  }

  private toggleModal = (modelToEdit?: Model) =>
    this.setState({
      showCreateModelModal: !this.state.showCreateModelModal,
      modelToEdit,
    });
}

const mapStateToProps = (state: RootState) => ({
  profile: state.profile,
  models: state.models,
});

export default withRouter(
  connect(mapStateToProps, { getClient, getModels })(ClientView)
);
