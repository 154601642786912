import * as React from "react";
import { Alert, Button, Intent } from "@blueprintjs/core";

import {
  ModelInstance,
  ModelInstanceState,
  ModelInstanceRevision,
  Profile,
} from "../../../types/models";
import { isModelMember } from "../../../models/profile";
import api from "../../../services/api";

import ExportModelDialog from "../../modals/ExportModelDialog";

interface Props {
  modelInstance: ModelInstance;
  profile: Profile;
}

interface IdleState {
  id: "idle";
}

interface ConfirmState {
  id: "confirm";
}

interface BusyState {
  id: "busy";
}

interface ProcessingState {
  id: "processing";
  revision: ModelInstanceRevision;
}

type State = IdleState | ConfirmState | BusyState | ProcessingState;

const ExportModelButton = ({ modelInstance, profile }: Props) => {
  const [state, setState] = React.useState<State>({ id: "idle" });

  const canExport =
    modelInstance !== undefined &&
    isModelMember(profile, modelInstance.ModelID) &&
    [ModelInstanceState.Dirty, ModelInstanceState.Synced].includes(
      modelInstance.State
    ) &&
    modelInstance.Archive_Flag === 0;

  const generate = async () => {
    setState({ id: "busy" });

    try {
      const revision = await api.post(
        `/instances/${modelInstance.id}/revisions`
      );

      setState({ id: "processing", revision });
    } catch (error) {
      setState({ id: "idle" });
    }
  };

  return (
    <React.Fragment>
      <Button
        intent={Intent.SUCCESS}
        text="Generate Model"
        large={true}
        disabled={state.id === "busy" || !canExport}
        loading={state.id === "busy"}
        onClick={() => setState({ id: "confirm" })}
      />
      <Alert
        isOpen={state.id === "confirm"}
        cancelButtonText="Cancel"
        confirmButtonText="Generate"
        intent={Intent.PRIMARY}
        onCancel={() => setState({ id: "idle" })}
        onConfirm={generate}
      >
        This will generate a new excel model.
      </Alert>
      <ExportModelDialog
        isOpen={state.id === "processing"}
        generatedFile={
          state.id === "processing" ? state.revision.GeneratedFile : undefined
        }
        onClose={() => setState({ id: "idle" })}
      />
    </React.Fragment>
  );
};

export default ExportModelButton;
