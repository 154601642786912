import * as React from "react";
import { includes } from "lodash";
import Space from "styled-space";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Switch,
  Intent,
  Callout,
  Checkbox,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MultiSelect2, ItemRenderer } from "@blueprintjs/select";

import { ThunkDispatch } from "../../types/redux";
import { saveInput } from "../../store/modules/inputs";

// TODO: Move these MultiSelector helpers into a common directory, probably
// all multiselector things can be refactored to be shared
import { highlightText } from "../organisms/ChildSelector/helpers";

import {
  CalculationBlock,
  StandardCalculationBlock,
  Dimension,
  Input,
  InputCategory,
  InputCurveType,
  InputCrossType,
  TabNameType,
} from "../../types/models";
import { TabNames } from "../../constants/tabnames";
import { CrossTypes } from "../../constants/cross_types";
import { selectInputRow } from "../../store/modules/selected_input_row";
import { MenuItem2 } from "@blueprintjs/popover2";

const singleValueAssumptionsTabname = TabNames[0];
const uptakeAndEventAssumptionsTabname = TabNames[1];

interface IProps {
  onClose: () => void;
  dispatch: ThunkDispatch;
  modelInstanceId: number;
  dimensions: Dimension[];
  calculationBlocks: StandardCalculationBlock[];
  inputCategories: InputCategory[];
  validate(input: Input, blocks: CalculationBlock[]): object;
}

interface IState {
  createInputStep: number;
  errorMessageName: string;
  errorMessageCalculationBlocks: string;
  errorMessageCategoryId: string;
  inputName: string;
  inputCategoryId: string;
  inputCurveType: string;
  inputCrossType: string;
  isCrossInput: boolean;
  inputTabName: string;
  selectedBlocks: StandardCalculationBlock[];
  selectedDimensions: Dimension[];
  HasActiveNoteInput: boolean;
  DisableInputEdit: boolean;
}

const curveTypes = ["Event", "Uptake"];

const eventCurveType = curveTypes[0];
const cousinCrossType = CrossTypes[0];

class CreateNewInputModal extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      createInputStep: 1,
      errorMessageName: "",
      errorMessageCalculationBlocks: "",
      errorMessageCategoryId: "",
      inputName: "",
      inputCategoryId: "",
      inputTabName: singleValueAssumptionsTabname,
      inputCurveType: "",
      isCrossInput: false,
      inputCrossType: "",
      selectedBlocks: [],
      selectedDimensions: [],
      HasActiveNoteInput: false,
      DisableInputEdit: false,
    };
  }

  public render() {
    const { calculationBlocks, dimensions } = this.props;
    const {
      createInputStep,
      errorMessageName,
      errorMessageCalculationBlocks,
      errorMessageCategoryId,
      inputName,
      inputCategoryId,
      inputTabName,
      inputCurveType,
      isCrossInput,
      inputCrossType,
    } = this.state;

    return (
      <Dialog
        className="p-input-creator"
        title="New Input"
        isOpen={true}
        onClose={this.handleClose}
      >
        <div className={Classes.DIALOG_BODY}>
          {createInputStep === 1 ? (
            <React.Fragment>
              <FormGroup
                label="Input Name"
                labelInfo="(required)"
                helperText={errorMessageName}
                intent={errorMessageName ? Intent.DANGER : Intent.NONE}
              >
                <InputGroup
                  defaultValue={inputName}
                  onChange={this.handleInputNameChange}
                  placeholder="Input Name"
                  autoFocus={true}
                />
              </FormGroup>

              <FormGroup
                label="Input Category"
                labelInfo="(required)"
                helperText={errorMessageCategoryId}
                intent={errorMessageCategoryId ? Intent.DANGER : Intent.NONE}
              >
                <HTMLSelect
                  defaultValue={inputCategoryId}
                  onChange={this.handleInputCategoryChange}
                  options={this.categoryOptions()}
                  fill={true}
                />
              </FormGroup>

              <FormGroup
                label="Tab Name"
                labelInfo="(required)"
                helperText="This cannot be changed after input is created"
              >
                <HTMLSelect
                  defaultValue={inputTabName}
                  onChange={this.handleTabNameChange}
                  options={TabNames}
                  fill={true}
                />
              </FormGroup>

              <FormGroup
                label="Curve Type"
                helperText="This cannot be changed after input is created"
              >
                <HTMLSelect
                  value={inputCurveType}
                  onChange={this.handleCurveTypeChange}
                  options={curveTypes}
                  disabled={!this.isCurveTypeInput()}
                  fill={true}
                />
              </FormGroup>

              <Switch
                checked={isCrossInput}
                label="Cross Input"
                onChange={this.handleCrossFlag}
              />

              <FormGroup label="Cross Type">
                <HTMLSelect
                  value={inputCrossType}
                  onChange={this.handleCrossTypeChange}
                  options={CrossTypes}
                  disabled={!isCrossInput}
                  fill={true}
                />
              </FormGroup>
              <Checkbox
                checked={this.state.HasActiveNoteInput}
                label="Add Input Note"
                onChange={this.handleInputNoteChange}
              />
              <Checkbox
                checked={this.state.DisableInputEdit}
                label="Do not display in Web Calculation Editor"
                onChange={this.handleInputWebCalculationEditorDisplayChange}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {isCrossInput && (
                <FormGroup
                  label="Calculation Block Mapping"
                  labelInfo="(required)"
                  helperText={
                    errorMessageName || errorMessageCategoryId
                      ? "Error on the previous window"
                      : errorMessageCalculationBlocks
                  }
                  intent={Intent.DANGER}
                >
                  <MultiSelect2<StandardCalculationBlock>
                    items={calculationBlocks}
                    itemRenderer={this.renderCalculationBlockNode}
                    itemPredicate={this.filterCalculationBlockNode}
                    noResults={<MenuItem2 text="No Results" disabled={true} />}
                    onItemSelect={this.handleCalculationBlockSelect}
                    popoverProps={{ minimal: true }}
                    tagRenderer={this.renderCalculationBlockTag}
                    tagInputProps={{
                      tagProps: { intent: Intent.NONE, minimal: true },
                      onRemove: this.handleCalculationBlockRemove,
                      rightElement: undefined,
                    }}
                    selectedItems={this.state.selectedBlocks}
                    placeholder="Select Calculation blocks..."
                  />
                </FormGroup>
              )}

              {!isCrossInput && (
                <React.Fragment>
                  <Space mb={2}>
                    <Callout intent={Intent.PRIMARY}>
                      Inputs that haven’t been explicitly mapped to dimensions
                      will be mapped to the root node. Dimension Mapping cannot
                      be changed after an Input is created.
                    </Callout>
                  </Space>

                  <FormGroup
                    label="Dimension Mapping"
                    helperText={
                      errorMessageName || errorMessageCategoryId
                        ? "Error on the previous window"
                        : ""
                    }
                    intent={Intent.DANGER}
                  >
                    <MultiSelect2<Dimension>
                      items={dimensions}
                      itemRenderer={this.renderDimensionNode}
                      itemPredicate={this.filterDimensionNode}
                      noResults={
                        <MenuItem2 text="No Results" disabled={true} />
                      }
                      onItemSelect={this.handleDimensionSelect}
                      popoverProps={{ minimal: true }}
                      tagRenderer={this.renderDimensionTag}
                      tagInputProps={{
                        tagProps: { intent: Intent.NONE, minimal: true },
                        onRemove: this.handleDimensionRemove,
                        rightElement: undefined,
                      }}
                      selectedItems={this.state.selectedDimensions}
                      placeholder="Select Dimensions..."
                    />
                  </FormGroup>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {createInputStep === 1 ? (
              <React.Fragment>
                <Button text="Cancel" onClick={this.handleClose} />
                <Button
                  text="Next"
                  onClick={this.handleNext}
                  intent={Intent.PRIMARY}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button text="Back" onClick={this.handleBack} />
                <Button
                  text={"Create Input"}
                  onClick={this.handleSubmit}
                  intent={Intent.PRIMARY}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </Dialog>
    );
  }

  private handleInputNoteChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    this.setState({
      HasActiveNoteInput: e.currentTarget.checked,
    });
  };

  private handleInputWebCalculationEditorDisplayChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    this.setState({
      DisableInputEdit: e.currentTarget.checked,
    });
  };

  private categoryOptions = () => {
    const { inputCategories } = this.props;

    const defaultOption = {
      label: "Select category",
      value: "",
    };

    const categoryOptions = inputCategories.map((category) => ({
      label: category.Name,
      value: `${category.id}`,
    }));

    return [defaultOption, ...categoryOptions];
  };

  private isCurveTypeInput = (): boolean => {
    const uptakeAndEventAssumptions = TabNames[1];
    const { inputTabName } = this.state;

    return inputTabName === uptakeAndEventAssumptions;
  };

  private handleInputNameChange = (event: any): void =>
    this.setState({ inputName: event.target.value });

  private handleInputCategoryChange = (event: any): void =>
    this.setState({ inputCategoryId: event.target.value });

  private handleTabNameChange = (event: any): void => {
    const tabname = event.target.value;
    const newState = { inputTabName: tabname };

    if (tabname === uptakeAndEventAssumptionsTabname) {
      this.setState({
        ...newState,
        inputCurveType: eventCurveType,
      });
    } else {
      this.setState({
        ...newState,
        inputCurveType: "",
      });
    }
  };

  private handleCurveTypeChange = (event: any): void =>
    this.setState({ inputCurveType: event.target.value });

  private handleCrossFlag = (): void => {
    const newState = { isCrossInput: !this.state.isCrossInput };

    if (newState.isCrossInput) {
      this.setState({
        ...newState,
        inputCrossType: cousinCrossType,
      });
    } else {
      this.setState({
        ...newState,
        inputCrossType: "",
      });
    }
  };

  private handleCrossTypeChange = (event: any): void =>
    this.setState({ inputCrossType: event.target.value });

  private handleSubmit = () => {
    const { dispatch, onClose, modelInstanceId, validate } = this.props;
    const {
      inputName,
      inputCategoryId,
      inputTabName,
      inputCurveType,
      isCrossInput,
      inputCrossType,
      selectedBlocks,
      selectedDimensions,
      HasActiveNoteInput,
      DisableInputEdit,
    } = this.state;

    const newInput: Input = {
      ModelInstanceID: modelInstanceId,
      Name: inputName,
      CategoryID: parseInt(inputCategoryId, 10),
      Tab_Name: inputTabName as TabNameType,
      Cross_Flag: isCrossInput ? 1 : 0,
      blockMapping: [],
      dimensionMapping: [],
      HasActiveNoteInput,
      DisableInputEdit,
    };

    if ("" !== inputCurveType) {
      newInput.Curve_Type = inputCurveType as InputCurveType;
    }

    if (isCrossInput) {
      newInput.Cross_Type = inputCrossType as InputCrossType;
    }

    const validation = validate(newInput, selectedBlocks);

    if (Object.keys(validation).length === 0) {
      const blockIds = selectedBlocks.map(
        (block: CalculationBlock) => block.id as number
      );
      const dimensionIds = selectedDimensions.map(
        (dimension: Dimension) => dimension.id as number
      );

      dispatch(saveInput(newInput, blockIds, dimensionIds))
        .then((r) =>
          this.props
            .dispatch(selectInputRow(r.payload.input.id))
            .then(() => onClose())
        )
        .catch((error) => {
          console.error(error);
        });
    } else {
      const resetState = {
        errorMessageName: "",
        errorMessageCalculationBlocks: "",
        errorMessageCategoryId: "",
      };

      this.setState({
        ...resetState,
        ...validation,
      });
    }
  };

  private handleClose = () => this.props.onClose();

  private handleNext = () => this.setState({ createInputStep: 2 });

  private handleBack = () => this.setState({ createInputStep: 1 });

  private renderDimensionNode: ItemRenderer<Dimension> = (
    dimension: Dimension,
    { modifiers, handleClick, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const { selectedDimensions } = this.state;

    return (
      <MenuItem2
        active={modifiers.active}
        icon={
          selectedDimensions.indexOf(dimension) !== -1
            ? IconNames.TICK
            : IconNames.BLANK
        }
        key={dimension.id}
        onClick={handleClick}
        text={highlightText(dimension.Name as string, query)}
        shouldDismissPopover={false}
      />
    );
  };

  private renderDimensionTag = (dimension: Dimension) => dimension.Name;

  private renderCalculationBlockNode: ItemRenderer<StandardCalculationBlock> = (
    block: StandardCalculationBlock,
    { modifiers, handleClick, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const { selectedBlocks } = this.state;

    return (
      <MenuItem2
        active={modifiers.active}
        icon={
          selectedBlocks.indexOf(block) !== -1
            ? IconNames.TICK
            : IconNames.BLANK
        }
        key={block.id}
        onClick={handleClick}
        text={highlightText(block.Name as string, query)}
        shouldDismissPopover={false}
      />
    );
  };

  private renderCalculationBlockTag = (block: CalculationBlock) => block.Name;

  private filterDimensionNode = (
    query: string,
    dimension: Dimension
  ): boolean => {
    if (!dimension.Name) {
      return false;
    }

    return dimension.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  private filterCalculationBlockNode = (
    query: string,
    block: StandardCalculationBlock
  ): boolean => {
    if (!block.Name) {
      return false;
    }

    return block.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  private handleDimensionSelect = (dimension: Dimension) => {
    const { selectedDimensions } = this.state;

    if (includes(selectedDimensions, dimension)) {
      this.deselectDimension(dimension);
    } else {
      this.selectDimension(dimension);
    }
  };

  private handleDimensionRemove = (tag: React.ReactNode, index: number): void =>
    this.deselectDimension(this.state.selectedDimensions[index]);

  private selectDimension = (dimension: Dimension) =>
    this.setState({
      selectedDimensions: [...this.state.selectedDimensions, dimension],
    });

  private deselectDimension = (dimension: Dimension) =>
    this.setState({
      selectedDimensions: this.state.selectedDimensions.filter(
        (d) => d.id !== dimension.id
      ),
    });

  private handleCalculationBlockSelect = (block: StandardCalculationBlock) => {
    const { selectedBlocks } = this.state;

    if (includes(selectedBlocks, block)) {
      this.deselectCalculationBlock(block);
    } else {
      this.selectCalculationBlock(block);
    }
  };

  private handleCalculationBlockRemove = (
    tag: React.ReactNode,
    index: number
  ): void => this.deselectCalculationBlock(this.state.selectedBlocks[index]);

  private selectCalculationBlock = (block: StandardCalculationBlock): void =>
    this.setState({
      selectedBlocks: [...this.state.selectedBlocks, block],
    });

  private deselectCalculationBlock = (block: StandardCalculationBlock): void =>
    this.setState({
      selectedBlocks: this.state.selectedBlocks.filter(
        (b) => b.id !== block.id
      ),
    });
}

export default CreateNewInputModal;
