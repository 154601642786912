import * as React from "react";
import "styled-components/macro";
import {
  AnchorButton,
  Callout,
  Classes,
  Dialog,
  Intent,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box, Flex } from "@rebass/grid";

import { GeneratedFile, GeneratedFileStatus } from "../../types/models";
import api from "../../services/api";
import { includes } from "lodash";

interface Props {
  isOpen: boolean;
  generatedFile?: GeneratedFile;
  onClose(): void;
}

const DEFAULT_MESSAGE = {
  [GeneratedFileStatus.Queued]: "Waiting",
  [GeneratedFileStatus.Started]: "Processing",
};

const ExportModelDialog: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
  ...props
}) => {
  const [generatedFile, setGeneratedFile] = React.useState<
    GeneratedFile | undefined
  >();

  const timeout = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    const tick = () => {
      timeout.current = props.generatedFile
        ? setTimeout(async () => {
            timeout.current = undefined;

            const file = await api.get(
              `/generated_files/${
                props.generatedFile && props.generatedFile.id
              }`
            );
            setGeneratedFile(file);

            if (
              file.Status === GeneratedFileStatus.Queued ||
              file.Status === GeneratedFileStatus.Started
            ) {
              tick();
            }
          }, 2000)
        : undefined;
    };
    tick();
  }, [props.generatedFile]);

  let contents = null;
  if (generatedFile) {
    switch (generatedFile.Status) {
      case GeneratedFileStatus.Queued:
      case GeneratedFileStatus.Started:
        contents = (
          <Flex>
            <Spinner size={SpinnerSize.SMALL} intent={Intent.PRIMARY} />
            <Box ml={2}>
              <strong>{DEFAULT_MESSAGE[generatedFile.Status]}</strong>
              {generatedFile.Status_Message &&
                `: ${generatedFile.Status_Message}`}
            </Box>
          </Flex>
        );
        break;
      case GeneratedFileStatus.Finished:
        contents = (
          <span>
            <strong>Finished</strong>: Your model is ready to download.
          </span>
        );
        break;
      case GeneratedFileStatus.Failed:
        contents = (
          <Callout intent={Intent.DANGER} title="Model Export Failed">
            {generatedFile.Status_Message ||
              "Something unexpected occurred. Please contact Trinity support."}
          </Callout>
        );
        break;
      case GeneratedFileStatus.Failed_With_Return_File:
        contents = (
          <Callout intent={Intent.DANGER} title="Model Export Failed">
            {generatedFile.Status_Message ||
              "Something unexpected occurred. Please contact Trinity support."}
          </Callout>
        );
        break;
      case GeneratedFileStatus.Failed_WebCalculation_FileGeneration:
        contents = (
          <Callout intent={Intent.WARNING} title="Model Generation Issues">
            {generatedFile.Status_Message ||
              "Something unexpected occurred. Please contact Trinity support."}
          </Callout>
        );
    }
  }

  return (
    <Dialog
      title="Generate Model"
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={props.generatedFile !== undefined}
      onClose={onClose}
      onOpening={() => setGeneratedFile(props.generatedFile)}
      onClosed={() => setGeneratedFile(undefined)}
    >
      <Box className={Classes.DIALOG_BODY} css={{ minHeight: "20px" }}>
        {contents}
      </Box>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton
            href={generatedFile && generatedFile.BlobURL}
            onClick={onClose}
            disabled={
              generatedFile
                ? !includes(
                    [
                      GeneratedFileStatus.Finished,
                      GeneratedFileStatus.Failed_With_Return_File,
                      GeneratedFileStatus.Failed_WebCalculation_FileGeneration,
                    ],
                    generatedFile.Status
                  )
                : true
            }
            intent={Intent.PRIMARY}
            icon={IconNames.DOCUMENT}
          >
            Download
          </AnchorButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ExportModelDialog;
