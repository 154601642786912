import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { InputCategory } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type InputCategoryState = InputCategory[];

const initialState: InputCategoryState = [];

const sortInputCategories = (a: InputCategory, b: InputCategory) => {
  const aName = a.Name.toLowerCase();
  const bName = b.Name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setInputCategories):
      return action.payload.sort(sortInputCategories);
    case getType(actions.addInputCategory):
      return [...state, action.payload];
    case getType(actions.cancelAddInputCategory):
      return state.filter((ic: InputCategory) => ic.id);
    case getType(actions.createInputCategory):
      return state
        .filter((ic: InputCategory) => ic.id)
        .concat([action.payload])
        .sort(sortInputCategories);
    case getType(actions.updateInputCategory):
      return state
        .map((ic: InputCategory) => {
          return ic.id === action.payload.id ? action.payload : ic;
        })
        .sort(sortInputCategories);
    case getType(actions.deleteInputCategory):
      return state.filter((ic: InputCategory) => action.payload.id !== ic.id);
    default:
      return state;
  }
}

export const actions = {
  setInputCategories: createAction("@categories/setInputCategories")<
    InputCategory[]
  >(),
  addInputCategory: createAction(
    "@categories/addInputCategory"
  )<InputCategory>(),
  createInputCategory: createAction(
    "@categories/createInputCategory"
  )<InputCategory>(),
  updateInputCategory: createAction(
    "@categories/updateInputCategory"
  )<InputCategory>(),
  cancelAddInputCategory: createAction("@categories/cancelAddInputCategory")(),
  deleteInputCategory: createAction(
    "@categories/deleteCalculationBlock"
  )<InputCategory>(),
};

export const getInputCategories =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/input_categories`)
      .then((categories) => dispatch(actions.setInputCategories(categories)));
  };

export const saveInputCategory =
  (inputCategory: InputCategory): ThunkResult<Promise<any>> =>
  (dispatch) => {
    // Existing input category - update
    if (inputCategory.id) {
      return api
        .post(
          `/instances/${inputCategory.ModelInstanceID}/input_categories/${inputCategory.id}`,
          inputCategory
        )
        .then((updatedInputCategory) =>
          dispatch(actions.updateInputCategory(updatedInputCategory))
        );
    } else {
      // New input category - create
      return api
        .post(
          `/instances/${inputCategory.ModelInstanceID}/input_categories`,
          inputCategory
        )
        .then((createdInputCategory) =>
          dispatch(actions.createInputCategory(createdInputCategory))
        );
    }
  };

export const deleteInputCategory =
  (inputCategory: InputCategory): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (!inputCategory.id) {
      return Promise.resolve();
    }
    return api
      .delete(
        `/instances/${inputCategory.ModelInstanceID}/input_categories/${inputCategory.id}`
      )
      .then(() => dispatch(actions.deleteInputCategory(inputCategory)));
  };
