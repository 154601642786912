import React, { FunctionComponent, useState } from "react";
import { omit, trim } from "lodash";

import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";

import { ModelInstance } from "../../types/models";
import CloneModelInstanceForm, {
  Form,
  Fields,
  Errors,
} from "../organisms/CloneModelInstanceForm";

interface Props {
  instance: ModelInstance;
  instances: ModelInstance[];
  isOpen: boolean;
  onClose(): void;
  onSave(fields: Fields): void;
}

function normalize(fields: Fields) {
  return {
    ...fields,
    Name: trim(fields.Name),
  };
}

function validate(values: Fields, instances: ModelInstance[]) {
  const errors: Errors = {};

  if (typeof values.Name !== "string" || values.Name === "") {
    errors.Name = "Please enter a name";
  }

  if (instances.some((instance) => instance.Name === values.Name)) {
    errors.Name = "That name is already taken";
  }

  return errors;
}

const CloneModelInstanceDialog: FunctionComponent<Props> = ({
  instance,
  instances,
  isOpen,
  onClose,
  onSave,
}) => {
  const getInitialForm = () => ({
    fields: { Name: `${instance.Name} Clone` },
    errors: {},
    disabled: false,
  });

  const [form, setForm] = useState<Form>(getInitialForm);

  const handleChange = (changes: Partial<Fields>) =>
    setForm({
      ...form,
      fields: { ...form.fields, ...changes },
      errors: omit(form.errors, Object.keys(changes)),
    });

  const handleSave = async () => {
    const values = normalize(form.fields);
    const errors = validate(values, instances);

    if (Object.keys(errors).length > 0) {
      setForm({ ...form, errors });
      return;
    }

    try {
      try {
        setForm({ ...form, disabled: true });
        await onSave(form.fields);
      } finally {
        setForm({ ...form, disabled: false });
      }

      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      title="Clone Model Instance"
      isOpen={isOpen}
      onOpening={() => setForm(getInitialForm())}
      onClosed={() => setForm(getInitialForm())}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <CloneModelInstanceForm
          {...form}
          instance={instance}
          onChange={handleChange}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={onClose} />
          <Button
            text="Clone Instance"
            intent={Intent.PRIMARY}
            disabled={form.disabled}
            loading={form.disabled}
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CloneModelInstanceDialog;
