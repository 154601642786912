import * as React from "react";
import { Flex } from "@rebass/grid";
import {
  Button,
  Classes,
  ControlGroup,
  HTMLSelect,
  ButtonGroup,
  Text,
} from "@blueprintjs/core";

interface Props {
  value: string;
  options: Array<{ value: string; label: string }>;
  placeholder: string;
  onChange: (v: string) => Promise<any>;
  onCancel?: () => void;
  startEditing: boolean;
}

interface EditingState {
  state: "editing";
  value: string;
  failed: boolean;
}

interface SavingState {
  state: "saving";
  value: string;
}

interface NotEditingState {
  state: "not-editing";
}

type State = EditingState | NotEditingState | SavingState;

export default class EditableSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (props.startEditing) {
      this.state = {
        state: "editing",
        value: props.value,
        failed: false,
      };
    } else {
      this.state = {
        state: "not-editing",
      };
    }
  }

  public render() {
    const { value, options, placeholder } = this.props;
    const { state } = this;

    if (this.isEditing(state) || this.isSaving(state)) {
      return (
        <form
          onSubmit={this.finishEdit}
          className={Classes.FILL}
          style={{ width: "100%" }}
        >
          <ControlGroup fill={true}>
            <HTMLSelect fill={true} onChange={this.onEdit} value={state.value}>
              <option value="">{placeholder}</option>
              {options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </HTMLSelect>
            <ButtonGroup minimal={true}>
              <Button
                className={Classes.FIXED}
                loading={this.isSaving(state)}
                icon="tick"
                type="submit"
                text="Save"
              />
              <Button
                className={Classes.FIXED}
                disabled={this.isSaving(state)}
                icon="cross"
                onClick={this.onCancel}
              />
            </ButtonGroup>
          </ControlGroup>
        </form>
      );
    }
    const selected = options.find((o) => o.value === value);
    return (
      <Flex alignItems="center" justifyContent={"space-between"} width={1}>
        <Text ellipsize={true}>{selected ? selected.label : placeholder}</Text>
        <Button
          minimal={true}
          icon="edit"
          onClick={() => this.setState({ state: "editing", value })}
          text="Edit"
        />
      </Flex>
    );
  }

  private finishEdit = (e: React.SyntheticEvent) => {
    const { state } = this;
    if (this.isEditing(state)) {
      this.setState({ state: "saving", value: state.value }, () => {
        this.props
          .onChange(state.value)
          .then(() => {
            this.setState({ state: "not-editing" });
          })
          .catch(() => {
            this.setState({
              state: "editing",
              value: state.value,
              failed: true,
            });
          });
      });
      e.preventDefault();
      e.stopPropagation();
    }
  };

  private onEdit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ state: "editing", value: e.currentTarget.value });
  };

  private isEditing(state: State): state is EditingState {
    return this.state.state === "editing";
  }

  private isSaving(state: State): state is SavingState {
    return this.state.state === "saving";
  }

  private onCancel = () => {
    this.setState({ state: "not-editing" });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
}
