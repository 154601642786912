import { createAction, getType } from "typesafe-actions";
import { sortBy } from "lodash";

import api from "../../services/api";

import { XRefFunction } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type XRefFunctionState = XRefFunction[];

const initialState: XRefFunctionState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setXRefFunctions):
      return sortBy(action.payload, "Function");
    default:
      return state;
  }
}

export const actions = {
  setXRefFunctions: createAction("@xref/setFunctions")<XRefFunction[]>(),
};

export const getXRefFunctions = (): ThunkResult<Promise<any>> => (dispatch) => {
  return api
    .get("/xref/function")
    .then((functions) => dispatch(actions.setXRefFunctions(functions)));
};
