import "styled-components/macro";
import React, {
  Fragment,
  FunctionComponent,
  MouseEvent,
  useState,
} from "react";
import { connect } from "react-redux";
import cx from "classnames";

import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Classes,
  Intent,
  Menu,
  Position,
  Tag,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box, Flex } from "@rebass/grid";
import { saveAs } from "file-saver";
import {
  archiveModelInstance,
  cloneModelInstance,
  publishModelInstance,
  updateModelInstance,
  transferModelInstance,
} from "../../../store/modules/model_instances";

import {
  ModelInstance,
  ModelInstanceFilter,
  ModelInstanceState,
  XRefDate,
} from "../../../types/models";
import { ThunkDispatch } from "../../../types/redux";

import CloneModelInstanceDialog from "../../modals/CloneModelInstanceDialog";
import TransferModelInstanceDialog from "../../modals/TransferModelInstanceDialog";
import PublishModelInstanceDialog from "../../modals/PublishModelInstanceDialog";
import EditModelInstanceDialog from "../../modals/EditModelInstanceDialog";
import LastUpdated from "../../organisms/LastUpdated";
import ArchiveModelInstanceDialog from "../../modals/ArchiveModelInstanceDialog";
import UnarchiveModelInstanceDialog from "../../modals/UnarchiveModelInstanceDialog";
import { MenuItem2, Popover2, Tooltip2 } from "@blueprintjs/popover2";

interface OwnProps {
  clientId: number;
  modelId: number;
  instance: ModelInstance;
  instances: ModelInstance[];
  xrefDates: XRefDate[];
  isMember: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  modelInstanceFilter: ModelInstanceFilter;
}

interface StoreProps {
  dispatch: ThunkDispatch;
}

type Props = OwnProps & StoreProps;

interface IdleState {
  id: "idle";
}

interface EditState {
  id: "edit";
}

interface CloneState {
  id: "clone";
}

interface TransferState {
  id: "transfer";
}

interface PublishState {
  id: "publish";
}

interface BusyState {
  id: "busy";
}

interface ArchiveState {
  id: "archive";
}

interface UnArchiveState {
  id: "unarchive";
}

type State =
  | IdleState
  | EditState
  | CloneState
  | TransferState
  | PublishState
  | BusyState
  | ArchiveState
  | UnArchiveState;

const STATE_TAGS = {
  [ModelInstanceState.Draft]: (
    <Tooltip2
      content="This model instance has not yet been finalized."
      position={Position.BOTTOM_LEFT}
    >
      <Tag minimal={true} intent={Intent.DANGER}>
        Draft
      </Tag>
    </Tooltip2>
  ),
  [ModelInstanceState.Dirty]: (
    <Tooltip2
      content="This model instance has been changed since the excel model generator was last run."
      position={Position.BOTTOM_LEFT}
    >
      <Tag minimal={true} intent={Intent.WARNING}>
        Dirty
      </Tag>
    </Tooltip2>
  ),
  [ModelInstanceState.Synced]: null,
  [ModelInstanceState.Published]: (
    <Tooltip2
      content="This model instance has been published and can no longer be modified."
      position={Position.BOTTOM_LEFT}
    >
      <Tag minimal={true} intent={Intent.PRIMARY}>
        Published
      </Tag>
    </Tooltip2>
  ),
};

const ModelInstanceCard: FunctionComponent<Props> = ({
  dispatch,
  clientId,
  modelId,
  instance,
  isMember,
  isOwner,
  isAdmin,
  xrefDates,
  instances,
  modelInstanceFilter,
}) => {
  const [state, setState] = useState<State>({ id: "idle" });

  const save = async (changes: Partial<ModelInstance>) => {
    if (instance.id === undefined) {
      return;
    }

    return dispatch(updateModelInstance(instance.id, changes));
  };

  const clone = async (fields: Partial<ModelInstance>) => {
    if (instance.id === undefined) {
      return;
    }

    return dispatch(cloneModelInstance(instance.id, fields));
  };

  const transfer = async (fields: any) => {
    if (instance.id === undefined) {
      return;
    }

    return dispatch(
      transferModelInstance(instance.id, fields.Name, fields.Model.id)
    );
  };

  const publish = async () => {
    if (instance.id === undefined) {
      return;
    }

    return dispatch(publishModelInstance(instance.id));
  };

  const archive = async () => {
    if (instance.id === undefined) {
      return;
    }

    return dispatch(archiveModelInstance(instance.id, 1));
  };

  const unarchive = async () => {
    if (instance.id === undefined) {
      return;
    }

    return dispatch(archiveModelInstance(instance.id, 0));
  };

  return (
    <Fragment>
      <Link
        to={`/clients/${clientId}/models/${modelId}/${modelInstanceFilter}/instances/${
          instance.id
        }${instance.Draft ? "" : "/nodes"}`}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <Card interactive={true}>
          <Flex>
            <Box flex={1} alignSelf="center" className={Classes.TEXT_LARGE}>
              {instance.Name}{" "}
              <Box
                as="span"
                css={{ display: "inline-block", verticalAlign: "text-bottom" }}
              >
                {STATE_TAGS[instance.State]}
              </Box>
            </Box>
            <div onClick={(e: MouseEvent) => e.preventDefault()}>
              <Popover2
                position={Position.BOTTOM_LEFT}
                disabled={state.id === "busy"}
                content={
                  <Menu>
                    {modelInstanceFilter === "current" ? (
                      <>
                        <MenuItem2
                          icon={IconNames.EDIT}
                          text="Edit Instance Metadata"
                          disabled={!isMember}
                          onClick={() => setState({ id: "edit" })}
                        />
                        <MenuItem2
                          icon={IconNames.DUPLICATE}
                          text="Clone Instance"
                          disabled={
                            !isMember ||
                            instance.State === ModelInstanceState.Draft
                          }
                          onClick={() => setState({ id: "clone" })}
                        />
                        <MenuItem2
                          icon={IconNames.FLOW_BRANCH}
                          text="Transfer Instance"
                          disabled={
                            !isMember ||
                            !isAdmin ||
                            instance.State === ModelInstanceState.Draft
                          }
                          onClick={() => setState({ id: "transfer" })}
                        />
                        <MenuItem2
                          icon={IconNames.TICK_CIRCLE}
                          text="Publish Instance"
                          disabled={
                            !isOwner ||
                            instance.State !== ModelInstanceState.Synced
                          }
                          onClick={() => setState({ id: "publish" })}
                        />
                        <MenuItem2
                          icon={IconNames.DOCUMENT_SHARE}
                          text="Archive Instance"
                          disabled={!isOwner}
                          onClick={() => setState({ id: "archive" })}
                        />
                        <MenuItem2
                          icon={IconNames.DOWNLOAD}
                          text="Download Tab Transfer Tool"
                          onClick={() =>
                            saveAs(
                              instance.Tab_Transfer_Download_BlobURL,
                              instance.Tab_Transfer_Download_FileName
                            )
                          }
                        />
                      </>
                    ) : (
                      <MenuItem2
                        icon={IconNames.DOCUMENT_SHARE}
                        text="Unarchive Instance"
                        disabled={!isOwner}
                        onClick={() => setState({ id: "unarchive" })}
                      />
                    )}
                  </Menu>
                }
              >
                <Button
                  icon={IconNames.MORE}
                  minimal={true}
                  small={true}
                  loading={state.id === "busy"}
                  disabled={state.id === "busy"}
                  onClick={(e: MouseEvent) => e.preventDefault()}
                />
              </Popover2>
            </div>
          </Flex>
          <span className={cx(Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>
            <LastUpdated object={instance} />
          </span>
        </Card>
      </Link>
      <EditModelInstanceDialog
        instance={instance}
        instances={instances}
        xrefDates={xrefDates}
        isOpen={state.id === "edit"}
        onClose={() => setState({ id: "idle" })}
        onSave={save}
      />
      <CloneModelInstanceDialog
        instance={instance}
        instances={instances}
        isOpen={state.id === "clone"}
        onClose={() => setState({ id: "idle" })}
        onSave={clone}
      />
      <TransferModelInstanceDialog
        instance={instance}
        isOpen={state.id === "transfer"}
        onClose={() => setState({ id: "idle" })}
        onSave={transfer}
      />
      <PublishModelInstanceDialog
        isOpen={state.id === "publish"}
        onClose={() => setState({ id: "idle" })}
        onSave={publish}
      />
      <ArchiveModelInstanceDialog
        isOpen={state.id === "archive"}
        onClose={() => setState({ id: "idle" })}
        onSave={archive}
      />
      <UnarchiveModelInstanceDialog
        isOpen={state.id === "unarchive"}
        onClose={() => setState({ id: "idle" })}
        onSave={unarchive}
      />
    </Fragment>
  );
};

export default connect()(ModelInstanceCard);
