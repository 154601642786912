import * as React from "react";
import "styled-components/macro";
import { Box } from "@rebass/grid";

export const Alignment = (props: any) => (
  <Box
    css={{
      display: "grid",
      width: "340px",
      minHeight: "40px",
      alignItems: "center",
      gridTemplateColumns: `[start] 50px
                            [name-col] 120px
                            [type-col] 88px
                            [control-col] 1fr
                            [end]`,
    }}
    {...props}
  />
);
