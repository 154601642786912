/* eslint-disable  react-hooks/exhaustive-deps */
import React, {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
} from "react";
import { connect } from "react-redux";
import { Alignment, Button } from "@blueprintjs/core";
import { Select2, ItemRenderer } from "@blueprintjs/select";
import { Client } from "../../../types/models";
import { RootState } from "../../../store/reducer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { isEmpty, values, isEqual, sortBy } from "lodash";
import { MenuItem2 } from "@blueprintjs/popover2";

interface ISelectClientProps
  extends RouteComponentProps<{
    client: string;
  }> {
  clients: { [id: number]: Client };
  onChange: (client: Client | undefined) => void;
  selectedClient: Client | undefined;
  disabled: boolean;
}

const ClientSelect: FunctionComponent<ISelectClientProps> = (
  props: ISelectClientProps
) => {
  const [selectClient, setSelectedClient] = useState<Client | undefined>(
    undefined
  );

  const params = useCallback(() => {
    const { client: clientIdString } = props.match.params;
    return {
      clientId: parseInt(clientIdString, 10),
    };
  }, [props.match]);

  useEffect(() => {
    if (!isEmpty(props.clients)) {
      const preSelectedClient = values(props.clients).find(
        (client: Client) => client.id === params().clientId
      );
      setSelectedClient(preSelectedClient);
      props.onChange(preSelectedClient);
    }
  }, [params, props.clients]);

  useEffect(() => {
    if (!isEqual(props.selectedClient, selectClient)) {
      setSelectedClient(props.selectedClient);
    }
  }, [props.selectedClient]);

  const renderClient: ItemRenderer<Client> = (
    client: { id: any; Name: any },
    { handleClick, modifiers }: any
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem2
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={client.id}
        onClick={handleClick}
        text={client.Name}
      />
    );
  };

  const handleClientChange = (client: Client) => {
    props.onChange(client);
  };

  return (
    <Select2<Client>
      noResults={<MenuItem2 disabled={true} text="No Clients" />}
      onItemSelect={handleClientChange}
      items={sortBy(values(props.clients), ["Name"])}
      itemRenderer={renderClient}
      filterable={false}
      activeItem={selectClient}
      className="fullwidth"
      popoverProps={{ minimal: true, portalClassName: "fullwidth" }}
      disabled={props.disabled}
    >
      <Button
        alignText={Alignment.LEFT}
        fill={true}
        disabled={props.disabled}
        rightIcon="caret-down"
        text={selectClient ? `${selectClient.Name}` : "(No selection)"}
      />
    </Select2>
  );
};

const mapStateToProps = (state: RootState) => ({ clients: state.clients });

export default connect(mapStateToProps)(withRouter(ClientSelect));
