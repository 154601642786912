import * as React from "react";
import { Box, Flex } from "@rebass/grid";
import {
  Classes,
  Text,
  HTMLTable,
  Button,
  InputGroup,
} from "@blueprintjs/core";
import classnames from "classnames";
import { map } from "lodash";

import OutputRow from "./OutputRow";

import { Output } from "../../../types/models";
import { ThunkDispatch } from "../../../types/redux";
import { getXRefFunctions } from "../../../store/modules/xref_functions";
import { IconNames } from "@blueprintjs/icons";

interface IProps {
  clientId: number;
  modelId: number;
  modelInstanceId: number;
  outputs: Output[];
  dispatch: ThunkDispatch;
  outputFilter: string;
  handleOutputFilterChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onOutputEdit(output: Output): void;
}

interface State {
  isDimensionAggregationEditable?: Output["id"];
  isTimescaleAggregationEditable?: Output["id"];
  isFilterOpen: boolean;
}

const textClasses = classnames(Classes.TEXT_MUTED, Classes.TEXT_LARGE);

class OutputsTable extends React.Component<IProps> {
  public state: State = {
    isDimensionAggregationEditable: undefined,
    isTimescaleAggregationEditable: undefined,
    isFilterOpen: false,
  };

  public componentDidMount() {
    this.props.dispatch(getXRefFunctions());
  }

  public render() {
    const {
      outputs,
      onOutputEdit,
      clientId,
      modelId,
      modelInstanceId,
      outputFilter,
      handleOutputFilterChange,
    } = this.props;

    const { isDimensionAggregationEditable, isTimescaleAggregationEditable } =
      this.state;

    return (
      <HTMLTable style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th>
              <div style={{ width: 160 }}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text className={textClasses}>Output Name</Text>
                  <Button
                    minimal={true}
                    icon={
                      this.state.isFilterOpen
                        ? IconNames.FILTER_OPEN
                        : IconNames.FILTER
                    }
                    onClick={this.handleFilterVisibility}
                    small={true}
                  />
                </Flex>
                {this.state.isFilterOpen && (
                  <Box mt={1}>
                    <InputGroup
                      small={true}
                      type="search"
                      leftIcon={IconNames.SEARCH}
                      placeholder="Filter Outputs"
                      value={outputFilter}
                      onChange={handleOutputFilterChange}
                      fill
                    />
                  </Box>
                )}
              </div>
            </th>
            <th>
              <Box>
                <Text className={textClasses}>Dimension Aggregation Block</Text>
              </Box>
            </th>
            <th>
              <Box>
                <Text className={textClasses}>Timescale Aggregation Block</Text>
              </Box>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {map(outputs, (output, idx) => (
            <OutputRow
              key={`${output.id}-${idx}`}
              output={output}
              onEdit={onOutputEdit}
              clientId={clientId}
              modelId={modelId}
              modelInstanceId={modelInstanceId}
              handleDimensionAggregateEditableState={
                this.handleDimensionAggregateEditableState
              }
              handleTimescaleAggregateEditableState={
                this.handleTimescaleAggregateEditableState
              }
              isDimensionAggregationEditable={isDimensionAggregationEditable}
              isTimescaleAggregationEditable={isTimescaleAggregationEditable}
            />
          ))}
        </tbody>
      </HTMLTable>
    );
  }

  private handleFilterVisibility = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  private handleDimensionAggregateEditableState = (id?: Output["id"]): void => {
    this.setState({ isDimensionAggregationEditable: id });
  };

  private handleTimescaleAggregateEditableState = (id?: Output["id"]): void => {
    this.setState({ isTimescaleAggregationEditable: id });
  };
}

export default OutputsTable;
