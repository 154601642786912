import * as React from "react";
import { Flex, Box } from "@rebass/grid";

interface IProps {
  error: string;
}

const Error500: React.FC<IProps> = ({ error }) => (
  <Flex>
    <Box>{error}</Box>
  </Flex>
);

export default Error500;
