/* eslint-disable  react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useReducer } from "react";
import { Button, Intent, Alignment, Text } from "@blueprintjs/core";
import { Row, Column } from "../../../atoms/Layout";
import { IconNames } from "@blueprintjs/icons";
import { Box } from "@rebass/grid";
import { range, head, last } from "lodash";
import { Popover2 } from "@blueprintjs/popover2";

const numberPerPage = 16;

interface IYearSelectorProps
  extends Pick<IYearRangePickerPickerProps, "minYear" | "maxYear"> {
  year?: number;
  onSelectYear: (year: number) => void;
}

interface IYearSelectorState {
  yearState?: number;
  currentPage: number;
  begin: number;
  end: number;
}

const YearSelector: FunctionComponent<IYearSelectorProps> = ({
  year,
  onSelectYear,
  minYear,
  maxYear,
}: IYearSelectorProps) => {
  const [state, setState] = useReducer(
    (state: IYearSelectorState, newState: Partial<IYearSelectorState>) => ({
      ...state,
      ...newState,
    }),
    {
      yearState: year,
      currentPage: 1,
      begin: 0,
      end: numberPerPage,
    }
  );

  const styles: { [key: string]: React.CSSProperties } = {
    yearContainer: {
      display: "inline-block",
      padding: "1px",
      boxSizing: "border-box",
    },
    yearButton: {
      lineHeight: "3em",
      textAlign: "center",
      width: "100%",
      minWidth: "4em",
    },
  };

  const yearsRange: number[] = range(minYear, maxYear + 1);

  const getNumberOfPages = (): number => {
    return Math.ceil(yearsRange.length / numberPerPage);
  };

  const onSelect = (year: number): void => {
    setState({ yearState: year });
    onSelectYear(year);
  };

  const renderYear = (year: number): JSX.Element => {
    const selected: boolean = year === state.yearState;

    return (
      <Box className="year-container" style={styles.yearContainer} key={year}>
        <Button
          intent={selected ? Intent.PRIMARY : Intent.NONE}
          style={styles.yearButton}
          onClick={() => onSelect(year)}
        >
          {year}
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    setState({
      begin: (state.currentPage - 1) * numberPerPage,
      end: (state.currentPage - 1) * numberPerPage + numberPerPage,
    });
  }, [state.currentPage]);

  useEffect(() => {
    if (state.yearState) {
      const yearIndex = yearsRange.findIndex(
        (item) => item === state.yearState
      );
      setState({
        begin: yearIndex - (yearIndex % numberPerPage),
        end: yearIndex - (yearIndex % numberPerPage) + numberPerPage,
        currentPage: Math.floor(yearIndex / numberPerPage) + 1,
      });
    }
  }, [state.yearState]);

  const years: JSX.Element[] = [];
  for (let i = 0; i < yearsRange.length; i++) {
    years.push(renderYear(yearsRange[i]));
  }

  const onPrevYear = (): void => {
    setState({ currentPage: state.currentPage - 1 });
  };

  const onNextYear = (): void => {
    setState({ currentPage: state.currentPage + 1 });
  };

  const slicedYears: JSX.Element[] = years.slice(state.begin, state.end);

  return (
    <Box className="pt-3">
      <Box
        className="d-flex justify-content-center align-items-center"
        style={styles.yearNav}
      >
        <Button
          small={true}
          onClick={onPrevYear}
          icon={IconNames.CHEVRON_LEFT}
          minimal={true}
          disabled={state.currentPage === 1}
        />
        <Text>{`${(head(slicedYears) as any).key} - ${
          (last(slicedYears) as any).key
        }`}</Text>
        <Button
          small={true}
          onClick={onNextYear}
          icon={IconNames.CHEVRON_RIGHT}
          minimal={true}
          disabled={state.currentPage === getNumberOfPages()}
        />
      </Box>
      <Box className="years-grid">{slicedYears}</Box>
    </Box>
  );
};

interface IYearRangePickerPickerProps {
  minYear: number;
  maxYear: number;
  onChange: (selectedYear: number | undefined) => void;
  large?: boolean;
  value?: string;
}

interface IYearRangePickerPickerState {
  selectedYear?: number;
  isPopOverOpen: boolean;
}

const YearRangePicker: FunctionComponent<IYearRangePickerPickerProps> = ({
  minYear,
  maxYear,
  onChange,
  value,
  large = true,
}: IYearRangePickerPickerProps) => {
  const [state, setState] = useReducer(
    (
      state: IYearRangePickerPickerState,
      newState: Partial<IYearRangePickerPickerState>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      selectedYear: value ? Number(value) : undefined,
      isPopOverOpen: false,
    }
  );

  useEffect(() => {
    if (value) {
      setState({ selectedYear: Number(value) });
    }
  }, [value]);

  const onSelectYear = (year: number): void => {
    setState({
      selectedYear: year,
      isPopOverOpen: false,
    });
  };

  const handleClose = (): void => {
    onChange(state.selectedYear);
  };

  return (
    <Popover2
      hasBackdrop={true}
      placement="bottom-start"
      fill={true}
      onClose={handleClose}
      isOpen={state.isPopOverOpen}
      content={
        <Box className="container-fluid">
          <Row>
            <Column className="form-group">
              <YearSelector
                year={state.selectedYear}
                onSelectYear={onSelectYear}
                minYear={minYear}
                maxYear={maxYear}
              />
            </Column>
          </Row>
        </Box>
      }
    >
      <Button
        text={value === undefined ? "Select a year" : state.selectedYear}
        rightIcon={IconNames.CALENDAR}
        alignText={Alignment.LEFT}
        fill={true}
        large={large}
        onClick={() => setState({ isPopOverOpen: true })}
      />
    </Popover2>
  );
};

export default YearRangePicker;
