import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { BlockNodeMapping } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export interface BlockNodeMappingState {
  [nodeId: number]: BlockNodeMapping[];
}

interface BlockNodeMappingCollectionResponseObject {
  nodeId: number;
  mappings: BlockNodeMapping[];
}

type NodeID = number;

const initialState: BlockNodeMappingState = {};

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.resetBlockNodeMappings):
      return action.payload.reduce(
        (
          acc: BlockNodeMappingState,
          r: BlockNodeMappingCollectionResponseObject
        ) => {
          acc[r.nodeId] = r.mappings;
          return acc;
        },
        {}
      );
    case getType(actions.setBlockNodeMappings):
      return {
        ...state,
        [action.payload.nodeId]: action.payload.mappings,
      };
    case getType(actions.deleteBlockNodeMappings):
      const newState = state;
      action.payload.forEach((nodeId) => {
        delete newState[nodeId];
      });

      return newState;
    default:
      return state;
  }
}

export const actions = {
  setBlockNodeMappings: createAction("@block_nodes/setBlockNodeMappings")<{
    nodeId: NodeID;
    mappings: BlockNodeMapping[];
  }>(),
  resetBlockNodeMappings: createAction("@block_nodes/resetBlockNodeMappings")<
    BlockNodeMappingCollectionResponseObject[]
  >(),
  deleteBlockNodeMappings: createAction("@block_nodes/deleteBlockNodeMappings")<
    NodeID[]
  >(),
};

export const getAllBlockNodeMappings =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/block_nodes`)
      .then((resp: BlockNodeMappingCollectionResponseObject[]) =>
        dispatch(actions.resetBlockNodeMappings(resp))
      );
  };

export const getBlockNodeMappings =
  (modelInstanceId: number, nodeId: NodeID): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/nodes/${nodeId}/block_nodes`)
      .then((result) =>
        dispatch(actions.setBlockNodeMappings({ nodeId, mappings: result }))
      );
  };

export const saveBlockNodeMapping =
  (
    blockNodeMappings: BlockNodeMapping[],
    modelInstanceId: number,
    nodeId: NodeID
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post(
        `/instances/${modelInstanceId}/nodes/${nodeId}/block_nodes`,
        blockNodeMappings
      )
      .then((result) =>
        dispatch(actions.setBlockNodeMappings({ nodeId, mappings: result }))
      );
  };

export const deleteBlockNodeMappings =
  (
    modelInstanceId: number,
    deletedNodeIds: NodeID[]
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .delete(`/instances/${modelInstanceId}/block_nodes`, {
        nodes: deletedNodeIds,
      })
      .then(() => dispatch(actions.deleteBlockNodeMappings(deletedNodeIds)));
  };
