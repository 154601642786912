import * as React from "react";
import "styled-components/macro";
import { Flex } from "@rebass/grid";

import { INavigationBarProps } from "../organisms/NavigationBar";

import GlobalStyle from "./GlobalStyle";
import NavigationBar from "../organisms/NavigationBar";
import { Colors } from "@blueprintjs/core";

interface IProps extends INavigationBarProps {
  children: React.ReactNode;
  controls?: React.ReactNode;
  tabs?: React.ReactNode;
  title: string;
}

const AppView: React.FC<IProps> = ({ children, controls, tabs, title }) => (
  <Flex
    as={"main"}
    bg={Colors.LIGHT_GRAY5}
    flexDirection={"column"}
    css={{
      minHeight: "100vh",
    }}
  >
    <GlobalStyle />
    <NavigationBar title={title} tabs={tabs} controls={controls} />
    {children}
  </Flex>
);

export default AppView;
