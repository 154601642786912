import React, { FunctionComponent } from "react";
import { IconNames } from "@blueprintjs/icons";
import { eachDayOfInterval, format } from "date-fns";
import {
  filter,
  find,
  includes,
  isEmpty,
  map,
  maxBy,
  minBy,
  split,
} from "lodash";

import { Timescale, XRefDate } from "../../../../types/models";

import MonthPicker from "./MonthPicker";
import QuarterPicker from "./QuarterPicker";
import YearPicker from "./YearPicker";
import { Alignment, Button } from "@blueprintjs/core";
import { DateInput2, DateFormatProps } from "@blueprintjs/datetime2";

const jsDateFormatter: DateFormatProps = {
  // note that the native implementation of Date functions differs between browsers
  formatDate: (date) => date.toLocaleDateString(),
  parseDate: (str) => new Date(str),
  placeholder: "M/D/YYYY",
};

interface ISelectTimescaleValueProps {
  timescale?: Timescale;
  large?: boolean;
  onChange: (timescaleValue: XRefDate["DateID"]) => void;
  value?: string | XRefDate["DateID"];
  dates: XRefDate[];
}

const SelectTimescaleValue: FunctionComponent<ISelectTimescaleValueProps> = ({
  timescale,
  large = true,
  onChange,
  value,
  dates,
}) => {
  if (timescale === Timescale.Weekly && dates.length) {
    const dateRanges = map(
      eachDayOfInterval({
        start: new Date((minBy(dates, "DateID") as any).Date),
        end: new Date((maxBy(dates, "DateID") as any).Date),
      }),
      (date) => format(date, "M/d/yyyy")
    );

    const datesOnly = map(dates, "Date");

    const disabledDates = map(
      filter(dateRanges, (i) => !includes(datesOnly, i)),
      (i) => new Date(i)
    );

    return (
      <DateInput2
        {...jsDateFormatter}
        fill={true}
        canClearSelection={false}
        minDate={
          dates && dates.length
            ? new Date((minBy(dates, "DateID") as any).Date)
            : undefined
        }
        maxDate={
          dates && dates.length
            ? new Date((maxBy(dates, "DateID") as any).Date)
            : undefined
        }
        dayPickerProps={{
          disabledDays: disabledDates,
        }}
        disabled={timescale === undefined}
        popoverProps={{ usePortal: false }}
        onChange={(selectedDate: any) => {
          const filteredDate = find(dates, {
            Date: format(selectedDate, "M/d/yyyy"),
          }) as XRefDate;
          filteredDate && dates.length && onChange(filteredDate.DateID);
        }}
        value={
          value
            ? (
                find(dates, {
                  DateID: value,
                }) as XRefDate
              ).Date
            : null
        }
      />
    );
  }
  if (timescale === Timescale.Monthly) {
    return (
      <MonthPicker
        large={large}
        minYear={Number(
          format(new Date((minBy(dates, "DateID") as any).Date), "yyyy")
        )}
        maxYear={Number(
          format(new Date((maxBy(dates, "DateID") as any).Date), "yyyy")
        )}
        onChange={(month, year) =>
          month &&
          year &&
          onChange(
            (find(dates, { Date: `${month}/1/${year}` }) as XRefDate).DateID
          )
        }
        value={
          value ? (find(dates, { DateID: value }) as XRefDate).Date : undefined
        }
        dates={dates}
      />
    );
  } else if (timescale === Timescale.Quarterly) {
    return (
      <QuarterPicker
        large={large}
        minYear={Number(split((minBy(dates, "DateID") as any).Date, " ")[0])}
        maxYear={Number(split((maxBy(dates, "DateID") as any).Date, " ")[0])}
        onChange={(quarter, year) =>
          quarter &&
          year &&
          onChange(
            (find(dates, { Date: `${year} Q${quarter}` }) as XRefDate).DateID
          )
        }
        value={
          value ? (find(dates, { DateID: value }) as XRefDate).Date : undefined
        }
        dates={dates}
      />
    );
  } else if (timescale === Timescale.Yearly) {
    return (
      <YearPicker
        large={large}
        onChange={(year) =>
          year &&
          onChange((find(dates, { Date: `${year}` }) as XRefDate).DateID)
        }
        value={
          value ? (find(dates, { DateID: value }) as XRefDate).Date : undefined
        }
        minYear={Number(
          format(new Date((minBy(dates, "DateID") as any).Date), "yyyy")
        )}
        maxYear={Number(
          format(new Date((maxBy(dates, "DateID") as any).Date), "yyyy")
        )}
      />
    );
  } else if (timescale === undefined || isEmpty(dates)) {
    return (
      <Button
        disabled={true}
        text="Select a timescale value"
        fill={true}
        rightIcon={IconNames.CARET_DOWN}
        alignText={Alignment.LEFT}
        large={large}
      />
    );
  }

  return null;
};

export default SelectTimescaleValue;
