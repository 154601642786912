import * as React from "react";
import { Flex } from "@rebass/grid";
import { H2, Classes } from "@blueprintjs/core";

import { MaxBound } from "../../atoms/Layout";
import { INavigationBarProps } from "../../organisms/NavigationBar";

const ActionNav: React.FC<INavigationBarProps> = (props) => {
  return (
    <Flex className={Classes.DARK} justifyContent="center">
      <MaxBound>
        <Flex justifyContent="space-between">
          <Flex flex={1}>
            <H2>{props.title}</H2>
          </Flex>
          <Flex flex={1} justifyContent="center" alignItems="flex-end">
            {props.tabs}
          </Flex>
          <Flex flex={1} justifyContent="flex-end" alignItems="center">
            {props.controls}
          </Flex>
        </Flex>
      </MaxBound>
    </Flex>
  );
};

export default ActionNav;
