import * as React from "react";
import { connect } from "react-redux";
import { Flex } from "@rebass/grid";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Space from "styled-space";
import { Button, Intent, Callout, Alert } from "@blueprintjs/core";

import AppViewTemplate from "../../../templates/AppView";
import InitialDimensionsEditor from "../../../organisms/Dimensions/InitialDimensionsEditor";
import FinalizeDimensionsModal from "../../../modals/FinalizeDimensions";
import { MaxBound } from "../../../atoms/Layout";

import { RootState } from "../../../../store/reducer";
import { getModelInstance } from "../../../../store/modules/model_instances";
import { getDimensions } from "../../../../store/modules/dimensions";
import { ThunkDispatch } from "../../../../types/redux";
import {
  Dimension,
  ModelInstance,
  DimensionInstance,
} from "../../../../types/models";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  instances: ModelInstance[];
  dimensions: Dimension[];
  match: any;
}

interface IState {
  saving: boolean;
  showFinalizeDimensionsModal: boolean;
  modelInstanceId: number;
  unsavedDataAlertOpen: boolean;
  selectedInstance?: DimensionInstance;
}

class DimensionEditorView extends React.Component<IProps, IState> {
  public state: IState = {
    saving: false,
    showFinalizeDimensionsModal: false,
    modelInstanceId: this.props.match.params.instance,
    unsavedDataAlertOpen: false,
    selectedInstance: undefined,
  };

  public async componentDidMount() {
    const { modelInstanceId } = this.state;
    const { instances, dispatch, history, location } = this.props;
    const instance = instances[modelInstanceId];

    await dispatch(getDimensions(modelInstanceId));

    // Check if instance was already finalized and redirect to node-editor if so
    if (instance && !instance.Draft) {
      return history.push(`${location.pathname}/nodes`);
    }

    // We didnt have the instance in state yet, so we need to go get it
    try {
      await dispatch(getModelInstance(modelInstanceId));
    } catch (error) {
      // TODO: Error handling
      console.log(error);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { instances, history, location } = this.props;
    const { modelInstanceId } = this.state;
    const selectedInstance = instances.find(
      (instance) => Number(modelInstanceId) === instance.id
    );

    if (
      selectedInstance &&
      prevProps.instances[Number(modelInstanceId)] !== selectedInstance
    ) {
      if (!selectedInstance.Draft) {
        return history.push(`${location.pathname}/nodes`);
      }
    }
  }

  public render() {
    const {
      unsavedDataAlertOpen,
      showFinalizeDimensionsModal,
      modelInstanceId,
    } = this.state;
    return (
      <AppViewTemplate
        title="Define Model Dimensions"
        controls={
          <Space ml={3}>
            <Button
              intent={Intent.PRIMARY}
              text="Finalize Dimensions"
              onClick={this.toggleFinalizeModal}
            />
          </Space>
        }
      >
        <MaxBound mt={3}>
          <Flex flexDirection="column">
            <Alert
              isOpen={unsavedDataAlertOpen}
              onClose={this.handleAlertClose}
            >
              <h4>Unsaved Data</h4>
              <p>
                Please finish naming any dimensions or dimension instances
                before saving.
              </p>
            </Alert>
            <Callout
              intent={Intent.WARNING}
              title={"Dimension order is important"}
            >
              Once the dimension order is finalized, you will not be able to
              reorder dimensions in the future. Dimension order is directly tied
              to model functionality.
            </Callout>
            <InitialDimensionsEditor
              isEditingView={showFinalizeDimensionsModal ? true : false}
            />
            <FinalizeDimensionsModal
              modelInstanceId={modelInstanceId}
              isOpen={showFinalizeDimensionsModal}
              onClose={this.toggleFinalizeModal}
            />
          </Flex>
        </MaxBound>
      </AppViewTemplate>
    );
  }

  private handleAlertClose = () => {
    const { unsavedDataAlertOpen } = this.state;
    if (unsavedDataAlertOpen) {
      this.setState({ unsavedDataAlertOpen: false });
    }
  };

  private toggleFinalizeModal = () => {
    const { showFinalizeDimensionsModal } = this.state;
    this.setState({
      showFinalizeDimensionsModal: !showFinalizeDimensionsModal,
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  dimensions: state.dimensions,
  instances: state.instances,
});

export default withRouter(connect(mapStateToProps)(DimensionEditorView));
