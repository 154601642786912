import * as React from "react";
import "styled-components/macro";
import { Flex, Box } from "@rebass/grid";
import {
  Icon,
  Text,
  Button,
  Intent,
  Classes,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { StandardCalculationBlock } from "../../../types/models";
import "styled-components/macro";
import styled from "styled-components";
import { Popover2 } from "@blueprintjs/popover2";

interface IProps {
  calculationBlock: StandardCalculationBlock;
  onRemove(calculationBlock: StandardCalculationBlock): void;
}

class CalcBlockOrderCard extends React.Component<IProps> {
  public render() {
    const { calculationBlock } = this.props;
    return (
      <Flex
        alignItems="center"
        p={1}
        flex={1}
        className={Classes.ELEVATION_1}
        css={{ borderRadius: "3px", cursor: "grab", margin: "5px 0" }}
      >
        <Icon
          icon={IconNames.DRAG_HANDLE_VERTICAL}
          className={Classes.TEXT_MUTED}
        />
        <Popover2
          content={
            <StyledRepresentation>{calculationBlock.Name}</StyledRepresentation>
          }
          interactionKind={PopoverInteractionKind.HOVER}
          targetTagName="div"
        >
          <Box>
            <Text className={Classes.TEXT_MUTED}>{calculationBlock.Name}</Text>
          </Box>
        </Popover2>

        <Box ml="auto">
          <Button
            text="Remove"
            intent={Intent.DANGER}
            minimal={true}
            onClick={this.handleRemove}
          />
        </Box>
      </Flex>
    );
  }

  private handleRemove = () => {
    this.props.onRemove(this.props.calculationBlock);
  };
}

export default CalcBlockOrderCard;

const StyledRepresentation = styled.div`
  padding: 20px;
`;
