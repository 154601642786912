import * as React from "react";
import { Divider } from "@blueprintjs/core";

interface Props<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  getId: (item: T) => number | string;
}

const DividedList = <T extends any>({ items, renderItem, getId }: Props<T>) => (
  <React.Fragment>
    {items.map((item: T, index: number) => (
      <React.Fragment key={getId(item)}>
        {index !== 0 && <Divider />}
        {renderItem(item)}
      </React.Fragment>
    ))}
  </React.Fragment>
);

export default DividedList;
