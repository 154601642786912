import { Profile, ModelMembershipRole, UserRole } from "../types/models";

export const isAdmin = (profile?: Profile) =>
  profile !== undefined && profile.User.Role === UserRole.Trinity_Admin_User;

export const isModelOwner = (profile: Profile, modelId: number) =>
  profile.ModelMemberships.some(
    (membership) =>
      membership.ModelID === modelId &&
      membership.Role === ModelMembershipRole.Owner
  );

export const isModelMember = (profile: Profile, modelId: number) =>
  profile.ModelMemberships.some((membership) => membership.ModelID === modelId);
