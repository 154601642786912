import * as React from "react";
import "styled-components/macro";
import { Box, Flex } from "@rebass/grid";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Colors, Icon, Text } from "@blueprintjs/core";

const NewRow: React.FunctionComponent<any> = ({ onClick }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    css={{
      backgroundColor: "#EBF1F5",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <Box py={3} px={2}>
      <Text className={Classes.TEXT_MUTED}>Add Calculation Row</Text>
    </Box>
    <Icon color={Colors.GRAY1} icon={IconNames.PLUS} size={20} />
  </Flex>
);

export default NewRow;
