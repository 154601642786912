import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button, Intent } from "@blueprintjs/core";

import { ThunkDispatch } from "../../../../types/redux";
import { RootState } from "../../../../store/reducer";
import { getInputs } from "../../../../store/modules/inputs";
import { getDimensions } from "../../../../store/modules/dimensions";
import { getCalculationBlocks } from "../../../../store/modules/calculation_blocks";

import { MaxBound } from "../../../atoms/Layout";
import AppViewTemplate from "../../../templates/AppView";
import Loading from "../../../molecules/Loading";
import InputOutputEditorTabs from "../../../organisms/InputOutputEditorTabs";
import TabBar from "../../../organisms/TabBar";
import CreateInputCategoryModal from "../../../modals/CreateInputCategory";
import CreateNewInputModal from "../../../modals/CreateNewInput";
import InputsTable from "../../../organisms/InputsTable";
import { getFormatParameters } from "../../../../store/modules/format_parameter";

import {
  Dimension,
  CalculationBlock,
  InputCategory,
  Input,
  BlockSource,
  StandardCalculationBlock,
} from "../../../../types/models";
import { getBlockSources } from "../../../../store/modules/block_sources";
import { clearSelectInputRow } from "../../../../store/modules/selected_input_row";
import FloatingButton from "../../../atoms/FloatingButton";
import { filter, toLower, includes } from "lodash";

interface IProps extends RouteComponentProps<any> {
  calculationBlocks: StandardCalculationBlock[];
  dispatch: ThunkDispatch;
  dimensions: Dimension[];
  inputCategories: InputCategory[];
  inputs: Input[];
  blockSources: BlockSource[];
}

interface IState {
  isLoading: boolean;
  modelInstanceId: number;
  showNewInputModal: boolean;
  showCreateInputCategoryModal: boolean;
  inputFilter: string;
  inputsList: Input[];
}

class InputsEditorView extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    modelInstanceId: this.props.match.params.instance,
    showCreateInputCategoryModal: false,
    showNewInputModal: false,
    inputFilter: "",
    inputsList: [],
  };

  public componentDidMount() {
    const { dispatch } = this.props;
    const { modelInstanceId } = this.state;

    Promise.all([
      dispatch(getFormatParameters(modelInstanceId)),
      dispatch(getInputs(modelInstanceId)),
      dispatch(getDimensions(modelInstanceId)),
      dispatch(getCalculationBlocks(modelInstanceId)),
      dispatch(getBlockSources(modelInstanceId)),
      dispatch(clearSelectInputRow()),
    ]).then(() =>
      this.setState({ isLoading: false, inputsList: this.props.inputs })
    );
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ) {
    if (prevState.inputFilter !== this.state.inputFilter) {
      this.setState({
        inputsList: filter(this.props.inputs, (input) =>
          includes(toLower(input.Name), toLower(this.state.inputFilter))
        ),
      });
    }
    if (prevProps.inputs !== this.props.inputs) {
      this.setState({
        inputsList: filter(this.props.inputs, (output) =>
          includes(toLower(output.Name), toLower(this.state.inputFilter))
        ),
      });
    }
  }

  public render() {
    const {
      inputCategories,
      inputs,
      calculationBlocks,
      dimensions,
      dispatch,
      blockSources,
    } = this.props;

    const {
      isLoading,
      modelInstanceId,
      showCreateInputCategoryModal,
      showNewInputModal,
      inputsList,
    } = this.state;

    const controls = (
      <Button
        intent={Intent.PRIMARY}
        text="Input Categories"
        onClick={this.toggleCategoryModal}
      />
    );
    return (
      <React.Fragment>
        <AppViewTemplate
          title="Inputs & Outputs"
          tabs={
            <InputOutputEditorTabs selectedTab="inputs" dispatch={dispatch} />
          }
          controls={controls}
        >
          <MaxBound mt={3} mb={6}>
            {isLoading ? (
              <Loading />
            ) : (
              <React.Fragment>
                <InputsTable
                  dispatch={dispatch}
                  calculationBlocks={calculationBlocks}
                  dimensions={dimensions}
                  inputs={inputsList}
                  inputCategories={inputCategories}
                  validateInput={this.validateInput}
                  blockSources={blockSources}
                  inputFilter={this.state.inputFilter}
                  handleInputFilterChange={this.handleInputFilterChange}
                />
                <FloatingButton
                  text="Define New Input"
                  onClick={this.toggleNewInputModal}
                />
                <CreateInputCategoryModal
                  isOpen={showCreateInputCategoryModal}
                  onClose={this.toggleCategoryModal}
                  inputs={inputs}
                />
                {showNewInputModal && (
                  <CreateNewInputModal
                    onClose={this.toggleNewInputModal}
                    modelInstanceId={modelInstanceId}
                    inputCategories={inputCategories}
                    calculationBlocks={calculationBlocks}
                    dimensions={dimensions}
                    dispatch={dispatch}
                    validate={this.validateInput}
                  />
                )}
              </React.Fragment>
            )}
          </MaxBound>
        </AppViewTemplate>
        <TabBar />
      </React.Fragment>
    );
  }

  private toggleCategoryModal = (): void =>
    this.setState({
      showCreateInputCategoryModal: !this.state.showCreateInputCategoryModal,
    });

  private handleInputFilterChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({
      inputFilter: event.currentTarget.value,
    });
  };

  private toggleNewInputModal = (): void =>
    this.setState({ showNewInputModal: !this.state.showNewInputModal });

  private validateInput = (
    input: Input,
    blocks: CalculationBlock[]
  ): object => {
    const validation: Record<string, string> = {};
    const matchingNameResult = this.props.inputs.find(
      (individualInput) =>
        input.Name.toLowerCase() === individualInput.Name.toLowerCase()
    );
    // Check that Name is not empty
    if ("" === input.Name) {
      validation["errorMessageName"] = "Please enter a name";
    }

    if (matchingNameResult !== undefined) {
      // Check that it is a new input and its Name does not already exist
      if (input.id === undefined) {
        validation["errorMessageName"] = "That name is already taken";
        // Check that it is an edited input and its Name does not already exist
      } else if (matchingNameResult.id !== input.id) {
        validation["errorMessageName"] = "That name is already taken";
      }
    }

    if (!input.CategoryID) {
      validation["errorMessageCategoryId"] = "Please select a category";
    }

    if (input.Cross_Flag && blocks.length === 0) {
      validation["errorMessageCalculationBlocks"] =
        "Please select a calculation block";
    }

    return validation;
  };
}

const mapStateToProps = (state: RootState) => ({
  blockSources: state.blockSources,
  dimensions: state.dimensions,
  inputCategories: state.inputCategories,
  inputs: state.inputs,
  calculationBlocks: state.calculationBlocks,
});

export default withRouter(connect(mapStateToProps)(InputsEditorView));
