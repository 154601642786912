import * as React from "react";
import { connect } from "react-redux";
import "styled-components/macro";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import classnames from "classnames";
import { Flex, Box } from "@rebass/grid";
import {
  Button,
  Classes,
  Text,
  Intent,
  InputGroup,
  HTMLSelect,
  Colors,
  Menu,
  Dialog,
  FormGroup,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  sortBy,
  find,
  head,
  isEmpty,
  trimStart,
  includes,
  map,
  trim,
} from "lodash";
import { ThunkDispatch } from "../../../../types/redux";

import {
  CalculationBlock,
  Dimension,
  ModelInstanceFilter,
  StandardCalculationBlock,
} from "../../../../types/models";
import { RootState } from "../../../../store/reducer";
import { selectCalculationBlockRow } from "../../../../store/modules/selected_calculation_block_rows";
import { clearSelectCalculationRow } from "../../../../store/modules/selected_calculation_row";
import { FormEvent } from "react";
import { cloneCalculationBlock } from "../../../../store/modules/calculation_blocks";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";

interface IProps extends RouteComponentProps<any> {
  calculationBlock: CalculationBlock;
  dimensions: Dimension[];
  modelInstancesFilter: ModelInstanceFilter;
  dispatch: ThunkDispatch;
  isCalculationBlockLoading: boolean;
  selectedCalculationBlockRow: Array<StandardCalculationBlock["id"]>;
  handleCalculationBlockEditing: (id?: CalculationBlock["id"]) => void;
  calculationBlocks: StandardCalculationBlock[];
  onCancel(block: CalculationBlock): void;
  onSave(block: CalculationBlock): void;
  onDelete(block: CalculationBlock): void;
  onValidate(block: CalculationBlock): boolean;
}

interface IState {
  editedCalculationBlock: CalculationBlock;
  errorMsg: string;
  isCloneBlockOpen: boolean;
  cloneCalculationBlockName: string;
  isLoading: boolean;
}

export const bodyRowCSS = {
  borderBottom: "1px solid #E1E8ED",
  width: "100%",
  height: "70px",
};

export const tableCellCSS = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
};

class CalculationBlockRow extends React.Component<IProps, IState> {
  public state: IState = {
    errorMsg: "",
    editedCalculationBlock: this.props.calculationBlock,
    isCloneBlockOpen: false,
    cloneCalculationBlockName: "",
    isLoading: false,
  };
  private cloneCalculationBlockInputRef: HTMLInputElement | undefined;

  public render() {
    const {
      calculationBlock,
      selectedCalculationBlockRow,
      isCalculationBlockLoading,
      calculationBlocks,
    } = this.props;

    return (
      <React.Fragment>
        <Dialog
          onOpening={() =>
            this.cloneCalculationBlockInputRef &&
            this.cloneCalculationBlockInputRef.focus()
          }
          isOpen={this.state.isCloneBlockOpen}
          onClose={() => {
            this.setState({
              isCloneBlockOpen: false,
              cloneCalculationBlockName: "",
            });
            this.cloneCalculationBlockInputRef &&
              this.cloneCalculationBlockInputRef.blur();
          }}
          title="Clone Calculation Block"
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Calculation Block Name"
              labelFor="cloneCalculationBlockInput"
              helperText={`${
                256 - this.state.cloneCalculationBlockName.length
              } characters left`}
            >
              <InputGroup
                value={this.state.cloneCalculationBlockName}
                onChange={this.handleChangeCloneCalculationBlock}
                inputRef={(ref) =>
                  (this.cloneCalculationBlockInputRef = ref as HTMLInputElement)
                }
                id="cloneCalculationBlockInput"
              />
              <p
                className={Classes.FORM_HELPER_TEXT}
                style={{ color: Colors.RED3 }}
              >
                {includes(
                  map(calculationBlocks, "Name"),
                  trim(this.state.cloneCalculationBlockName)
                )
                  ? "That name is already taken"
                  : ""}
              </p>
            </FormGroup>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  disabled={this.state.isLoading}
                  text="Close"
                  minimal={true}
                  onClick={() =>
                    this.setState({
                      isCloneBlockOpen: false,
                      cloneCalculationBlockName: "",
                    })
                  }
                />
                <Button
                  loading={this.state.isLoading}
                  text="Clone"
                  intent={Intent.PRIMARY}
                  disabled={
                    !this.state.cloneCalculationBlockName ||
                    includes(
                      map(calculationBlocks, "Name"),
                      trim(this.state.cloneCalculationBlockName)
                    )
                  }
                  onClick={() => {
                    this.handleCloneCalculationBlock(
                      find(calculationBlocks, {
                        id: head(selectedCalculationBlockRow),
                      }) as StandardCalculationBlock,
                      this.state.cloneCalculationBlockName
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
        <tr
          id={`block-${calculationBlock.id}`}
          style={{
            ...bodyRowCSS,
            backgroundColor:
              head(selectedCalculationBlockRow) === calculationBlock.id ||
              calculationBlock.DimensionID === -1
                ? `rgba(19,124,189,.15)`
                : Colors.WHITE,
            ...(isCalculationBlockLoading && {
              pointerEvents: "none",
            }),
          }}
        >
          <td style={{ width: "35vw" }}>
            <Box pr={4} css={{ width: "100%", ...tableCellCSS }}>
              {this.isEditing() ? (
                <InputGroup
                  className={Classes.FILL}
                  defaultValue={calculationBlock.Name}
                  onChange={this.handleNameChange}
                  autoFocus={true}
                  disabled={isCalculationBlockLoading}
                />
              ) : (
                <Text>{calculationBlock.Name}</Text>
              )}
              {this.state.errorMsg && (
                <span>
                  <Text
                    className={classnames(
                      Classes.TEXT_SMALL,
                      Classes.INTENT_DANGER
                    )}
                  >
                    {this.state.errorMsg}
                  </Text>
                </span>
              )}
            </Box>
          </td>
          <td>
            <Box css={tableCellCSS}>
              {this.isCreating() ? (
                <HTMLSelect
                  options={this.dimensionOptions()}
                  onChange={this.handleDimensionChange}
                  value={this.dimensionId()}
                />
              ) : (
                <Text>{this.dimensionName()}</Text>
              )}
            </Box>
          </td>
          <td>
            <Flex
              justifyContent={"flex-end"}
              css={{ right: 0, ...tableCellCSS }}
            >
              {this.isEditing() ? (
                <React.Fragment>
                  {this.isCreating() ? (
                    <Box px={2}>
                      <Button
                        disabled={isCalculationBlockLoading}
                        minimal={true}
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box px={2}>
                      <Button
                        disabled={isCalculationBlockLoading}
                        icon={IconNames.TRASH}
                        intent={Intent.DANGER}
                        minimal={true}
                        onClick={this.handleDelete}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                  <Box px={2}>
                    <Button
                      disabled={isCalculationBlockLoading}
                      icon={IconNames.TICK}
                      minimal={true}
                      onClick={this.handleEditClose}
                    >
                      Confirm
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box px={2}>
                    <Popover2
                      disabled={isCalculationBlockLoading}
                      content={
                        <Menu>
                          <MenuItem2
                            icon={IconNames.EDIT}
                            onClick={() =>
                              this.handleEditOpen(calculationBlock.id)
                            }
                            text="Edit"
                          />
                          <MenuItem2
                            icon={IconNames.DUPLICATE}
                            onClick={() =>
                              this.handleCloneBlock(calculationBlock.id)
                            }
                            text="Clone"
                          />
                        </Menu>
                      }
                    >
                      <Button
                        disabled={isCalculationBlockLoading}
                        icon={IconNames.MORE}
                        minimal={true}
                      />
                    </Popover2>
                  </Box>
                  <Box px={2}>
                    <Link
                      to={this.editRoute()}
                      onClick={() => this.editRouteClick(calculationBlock.id)}
                    >
                      <Button
                        disabled={isCalculationBlockLoading}
                        icon={IconNames.CHEVRON_RIGHT}
                        minimal={true}
                      />
                    </Link>
                  </Box>
                </React.Fragment>
              )}
            </Flex>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.selectedCalculationBlockRow !==
      this.props.selectedCalculationBlockRow
    ) {
      this.setState({
        cloneCalculationBlockName: !isEmpty(
          find(
            this.props.calculationBlocks,
            (item) => item.id === head(this.props.selectedCalculationBlockRow)
          )
        )
          ? `${
              (
                find(
                  this.props.calculationBlocks,
                  (item) =>
                    item.id === head(this.props.selectedCalculationBlockRow)
                ) as CalculationBlock
              ).Name
            } Clone`
          : "",
      });
    }
  }

  private handleChangeCloneCalculationBlock = (
    e: FormEvent<HTMLInputElement>
  ) => {
    this.setState({
      cloneCalculationBlockName: trimStart(
        e.currentTarget.value.substring(0, 256)
      ),
    });
  };

  private editRoute = () => {
    const {
      calculationBlock,
      modelInstancesFilter,
      match: { params },
    } = this.props;

    if (!calculationBlock.id) {
      return "/clients";
    }

    const { client, model, instance } = params;
    return `/clients/${client}/models/${model}/${modelInstancesFilter}/instances/${instance}/calculations/${calculationBlock.id}/edit`;
  };

  private editRouteClick = (id: CalculationBlock["id"]) => {
    this.props
      .dispatch(selectCalculationBlockRow(id))
      .then(() => this.props.dispatch(clearSelectCalculationRow()));
  };

  private isEditing = () =>
    this.props.calculationBlock.isEditing || this.isCreating();

  private isCreating = () => !this.props.calculationBlock.id;

  private handleEditOpen = (id: CalculationBlock["id"]) => {
    this.props
      .dispatch(selectCalculationBlockRow(id))
      .then(() => this.props.handleCalculationBlockEditing(id));
  };

  private handleCloneBlock = (id: CalculationBlock["id"]) => {
    this.props
      .dispatch(selectCalculationBlockRow(id))
      .then(() => this.setState({ isCloneBlockOpen: true }));
  };

  private handleEditClose = () => {
    if (this.props.onValidate(this.state.editedCalculationBlock)) {
      this.setState({
        errorMsg: "",
      });
      this.props.handleCalculationBlockEditing();
      this.props.onSave(this.state.editedCalculationBlock);
    } else {
      this.setState({
        errorMsg: "Please provide both a unique Name and a valid Dimension.",
      });
    }
  };

  private handleDelete = () => {
    const { calculationBlock, onDelete } = this.props;
    onDelete(calculationBlock);
  };

  private handleCancel = () => {
    const { calculationBlock, onCancel } = this.props;
    onCancel(calculationBlock);
  };

  private handleNameChange = (evt: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      editedCalculationBlock: {
        ...this.state.editedCalculationBlock,
        Name: evt.currentTarget.value,
      },
    });
  };

  private handleDimensionChange = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({
      editedCalculationBlock: {
        ...this.state.editedCalculationBlock,
        DimensionID: parseInt(evt.currentTarget.value, 10),
      },
    });
  };

  private handleCloneCalculationBlock = (
    block: StandardCalculationBlock,
    Name: string
  ) => {
    this.setState({ isLoading: true }, () => {
      this.props.dispatch(cloneCalculationBlock(block, Name)).then((block) =>
        this.props
          .dispatch(selectCalculationBlockRow(block.payload.id))
          .then(() =>
            this.setState(
              {
                isLoading: false,
                isCloneBlockOpen: false,
                cloneCalculationBlockName: "",
              },
              () => {
                const calcBlock = document.getElementById(
                  `block-${block.payload.id}`
                );
                calcBlock && calcBlock.scrollIntoView();
              }
            )
          )
      );
    });
  };

  private dimensionName = (): string => {
    const dimension = find(this.props.dimensions, [
      "id",
      this.props.calculationBlock.DimensionID,
    ]);
    return dimension ? dimension.Name : "";
  };

  private dimensionId = (): number => {
    const block = (
      this.isCreating()
        ? this.state.editedCalculationBlock
        : this.props.calculationBlock
    ) as StandardCalculationBlock;
    return block.DimensionID;
  };

  private dimensionOptions = () => {
    const { dimensions } = this.props;
    const defaultOption = {
      label: "Select Dimension",
      value: "-1",
    };
    const options = sortBy(
      dimensions.map((dim) => ({
        label: dim.Name,
        value: `${dim.id}`,
      })),
      "label"
    );

    return [defaultOption, ...options];
  };
}

const mapStateToProps = (state: RootState) => {
  return {
    modelInstancesFilter: state.instanceFilter,
    selectedCalculationBlockRow: state.selectedCalculationBlockRow,
    calculationBlocks: state.calculationBlocks,
  };
};

export default withRouter(connect(mapStateToProps)(CalculationBlockRow));
