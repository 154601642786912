import * as React from "react";

import { Flex, Box } from "@rebass/grid";
import { Button, Classes, Text } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import DeleteButton from "../atoms/DeleteButton";

export interface EditableProps<ValueType> {
  value?: ValueType;
  handleUpdate: (v: ValueType) => Promise<any>;
  handleCancel: () => void;
}

interface Props<ValueType> {
  value?: ValueType;
  valueRender?: (v: ValueType) => React.ReactNode;
  editor: React.ComponentType<EditableProps<ValueType>>;
  creationPrompt: string;
  onCreate: (v: ValueType) => Promise<any>;
  onUpdate: (v: ValueType) => Promise<any>;
  onDelete: () => Promise<any>;
}

interface State {
  isEditing: boolean;
}

class CRUDable<ValueType> extends React.Component<Props<ValueType>, State> {
  constructor(props: Props<ValueType>) {
    super(props);

    this.state = {
      isEditing: false,
    };
  }

  public render() {
    const { isEditing } = this.state;
    const { value, valueRender, creationPrompt } = this.props;
    const Editor = this.props.editor;

    return (
      <React.Fragment>
        {isEditing && (
          <Box flex="auto" className={Classes.CONTROL_GROUP}>
            <Editor
              value={value}
              handleUpdate={value ? this.handleSave : this.handleCreate}
              handleCancel={() => this.setState({ isEditing: false })}
            />
            <DeleteButton onDelete={this.handleDelete} />
          </Box>
        )}

        {value && !isEditing && (
          <Flex alignItems="center" justifyContent={"space-between"} width={1}>
            <Text className="bp4-text-muted bp4-text-large">
              {valueRender
                ? (valueRender(value) as React.ReactNode)
                : (value as React.ReactNode)}
            </Text>
            <Button
              minimal={true}
              icon={IconNames.EDIT}
              onClick={() =>
                this.setState({
                  isEditing: true,
                })
              }
            />
          </Flex>
        )}

        {!value && !isEditing && (
          <Button
            text={creationPrompt}
            icon={IconNames.PLUS}
            fill={true}
            className={Classes.TEXT_SMALL}
            onClick={() =>
              this.setState({
                isEditing: true,
              })
            }
          />
        )}
      </React.Fragment>
    );
  }

  private handleCreate = (value: ValueType) => {
    const { onCreate } = this.props;

    return onCreate(value).then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };

  private handleDelete = () => {
    const { onDelete } = this.props;

    return onDelete().then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };

  private handleSave = (value: ValueType) => {
    const { onUpdate } = this.props;

    return onUpdate(value).then(() => {
      this.setState({
        isEditing: false,
      });
    });
  };
}

export class CRUDableString extends CRUDable<string> {}
export class CRUDableNumber extends CRUDable<number> {}

export default CRUDable;
