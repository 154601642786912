import * as React from "react";
import "styled-components/macro";
import api from "../../../../services/api";
import classnames from "classnames";
import { Box, Flex } from "@rebass/grid";
import { Button, Classes, Divider, Intent, Text } from "@blueprintjs/core";

import { ThunkDispatch } from "../../../../types/redux";

import { Alignment } from "../ui";

import { BlockSource, XRefCurveSubType } from "../../../../types/models";
import { deleteBlockSource } from "../../../../store/modules/block_sources";

interface IProps {
  blockSource: BlockSource;
  dispatch: ThunkDispatch;
  xrefCurveSubTypes: XRefCurveSubType[];
  disableEditMode: boolean;
  modelInstanceId: string;
  isEditingArr: Array<number | undefined>;
  addRemoveToEdit: any;
}

interface IState {
  isEditing: boolean;
}

const subtextClasses = classnames(Classes.TEXT_MUTED, "font-subtext");

class InputSource extends React.Component<IProps, IState> {
  public state: IState = {
    isEditing: false,
  };

  public render() {
    const { blockSource, disableEditMode } = this.props;

    return (
      <React.Fragment>
        <Alignment
          pb={2}
          className={Classes.TEXT_SMALL}
          onClick={this.handleCancel}
        >
          <Box css={{ gridColumn: "start / name-col" }}>
            <Text>{blockSource.sourceCode}</Text>
          </Box>

          <Box css={{ gridColumn: "name-col / type-col" }}>
            <Flex flexDirection="column">
              <Text>{blockSource.sourceName}</Text>

              {this.hasSourceSubType() && (
                <Text className={subtextClasses}>
                  {this.blockSourceSubType()}
                </Text>
              )}
            </Flex>
          </Box>

          <Box css={{ gridColumn: "type-col / control-col" }}>
            <Text>{blockSource.Type}</Text>
          </Box>

          {!disableEditMode && this.controlsBox()}
        </Alignment>
        <Divider />
      </React.Fragment>
    );
  }

  private controlsBox = (): JSX.Element => {
    const { isEditing } = this.state;
    const { isEditingArr, blockSource } = this.props;
    return (
      <Box css={{ gridColumn: "control-col / end" }}>
        {isEditing || isEditingArr.includes(blockSource.id) ? (
          <Button
            text="Remove"
            minimal={true}
            className={Classes.TEXT_SMALL}
            intent={Intent.DANGER}
            onClick={this.handleDelete}
          />
        ) : (
          <Button
            text="Edit"
            minimal={true}
            className={Classes.TEXT_SMALL}
            onClick={this.handleEdit}
          />
        )}
      </Box>
    );
  };

  private handleEdit = (): void => {
    const { blockSource, addRemoveToEdit } = this.props;

    this.setState({ isEditing: true });
    addRemoveToEdit(blockSource.id);
  };

  private handleCancel = (): void => {
    const { blockSource, addRemoveToEdit } = this.props;

    if (this.state.isEditing) {
      this.setState({ isEditing: false });
      addRemoveToEdit(blockSource.id);
    }
  };

  private handleDelete = (): void => {
    const { dispatch, blockSource, addRemoveToEdit } = this.props;

    api
      .get(
        `/instances/${this.props.modelInstanceId}/block_source_function_parameters/${blockSource.id}`
      )
      .then((result) => {
        let confirmedDelete = true;
        if (result.length > 0) {
          confirmedDelete = window.confirm(
            "This Block Source is used in one or more Function Parameters. \nAre you sure you want to delete this Block Source?"
          );
        }
        if (confirmedDelete) {
          dispatch(deleteBlockSource(blockSource));
          this.setState({ isEditing: false });
          addRemoveToEdit(blockSource.id);
        }
      });
  };

  private hasSourceSubType = (): boolean => {
    const { blockSource } = this.props;

    return (
      blockSource.curveSubTypeMapping &&
      blockSource.curveSubTypeMapping.length > 0
    );
  };

  private blockSourceSubType = (): string => {
    const { blockSource, xrefCurveSubTypes } = this.props;

    const sourceCurveSubtypeIds = blockSource.curveSubTypeMapping.map(
      (mapping) => mapping.Curve_Sub_TypeID
    );

    if (sourceCurveSubtypeIds.length > 0) {
      const curveSubTypeId = sourceCurveSubtypeIds[0];
      const curveSubType = xrefCurveSubTypes.find(
        (type) => type.Curve_Sub_TypeID === curveSubTypeId
      ) as XRefCurveSubType;

      return curveSubType.Curve_Sub_Type;
    } else {
      return "";
    }
  };
}

export default InputSource;
