import { createAction, getType } from "typesafe-actions";

import _ from "lodash";

import api from "../../services/api";

import { FirstDimensionGroup } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type FirstDimensionGroupState = FirstDimensionGroup[];
const initialState: FirstDimensionGroupState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.hydrateFirstDimensionGroups):
      return action.payload;
    case getType(actions.addFirstDimensionGroup):
      // Sort because the API returns them sorted from hydrate and adding a
      // group should be consistent with that.
      return _.sortBy(state.concat(action.payload), (a) =>
        a.Name.toLowerCase()
      );
    case getType(actions.updateFirstDimensionGroup):
      return _.sortBy(
        state.map((g) => {
          if (g.id === action.payload.id) {
            return action.payload;
          }
          return g;
        }),
        (a) => a.Name.toLowerCase()
      );
    case getType(actions.removeFirstDimensionGroup):
      return _.filter(state, (g) => g.id !== action.payload);
    default:
      return state;
  }
}

// Not exported because they are low-level actions not intended for exposure
const actions = {
  hydrateFirstDimensionGroups: createAction("@firstDimensionGroups/hydrate")<
    FirstDimensionGroup[]
  >(),
  addFirstDimensionGroup: createAction(
    "@firstDimensionGroups/add"
  )<FirstDimensionGroup>(),
  updateFirstDimensionGroup: createAction(
    "@firstDimensionGroups/update"
  )<FirstDimensionGroup>(),
  removeFirstDimensionGroup: createAction(
    "@firstDimensionGroups/remove"
  )<number>(),
};

export type Actions = typeof actions;

// public actions
export const viewFirstDimensionGroups =
  (
    modelInstanceId: number
  ): ThunkResult<Promise<ReturnType<Actions["hydrateFirstDimensionGroups"]>>> =>
  (dispatch) =>
    api
      .get(`/instances/${modelInstanceId}/first_dimension_groups`)
      .then((groups) => dispatch(actions.hydrateFirstDimensionGroups(groups)));

export const createFirstDimensionGroup =
  (
    modelInstanceId: number,
    name: string
  ): ThunkResult<Promise<ReturnType<Actions["addFirstDimensionGroup"]>>> =>
  (dispatch) =>
    api
      .post(`/instances/${modelInstanceId}/first_dimension_groups`, {
        Name: name,
      })
      .then((group) => dispatch(actions.addFirstDimensionGroup(group)));

export const updateFirstDimensionGroup =
  (
    modelInstanceId: number,
    groupId: number,
    name: string
  ): ThunkResult<Promise<ReturnType<Actions["updateFirstDimensionGroup"]>>> =>
  (dispatch) =>
    api
      .post(`/instances/${modelInstanceId}/first_dimension_groups/${groupId}`, {
        Name: name,
      })
      .then((group) => dispatch(actions.updateFirstDimensionGroup(group)));

export const deleteFirstDimensionGroup =
  (
    modelInstanceId: number,
    groupId: number
  ): ThunkResult<Promise<ReturnType<Actions["removeFirstDimensionGroup"]>>> =>
  (dispatch) =>
    api
      .delete(`/instances/${modelInstanceId}/first_dimension_groups/${groupId}`)
      .then(() => dispatch(actions.removeFirstDimensionGroup(groupId)));
