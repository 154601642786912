import * as React from "react";
import "styled-components/macro";
import {
  Button,
  Classes,
  Colors,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  OptionProps,
  PopoverInteractionKind,
  Text,
} from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";
import styled from "styled-components";
import classnames from "classnames";
import Toaster from "../../molecules/Toaster";
import { includes } from "lodash";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Space from "styled-space";
import {
  BlockSource,
  CalculationBlockRow,
  FunctionParameter,
  Input,
  Output,
  SchemaFunctionParameter,
  XRefFunction,
} from "../../../types/models";
import {
  CalculationBlockRowTreeState,
  setCalculationBlockRowTree,
} from "../../../store/modules/calculation_block_row_tree";
import { ThunkDispatch } from "../../../types/redux";
import { Popover2 } from "@blueprintjs/popover2";

type MemoizableFunction = (n: any, ...args: any[]) => any;
const memoize = (fn: MemoizableFunction) => {
  const cache: Record<string, any> = {};
  return (n: any, cacheBust?: boolean, ...args: any[]) => {
    const shouldBustCache = cacheBust || false;
    if (!shouldBustCache) {
      if (n in cache) {
        return cache[n];
      }
    }

    const result = fn(n, ...args);
    cache[n] = result;
    return result;
  };
};

interface IProps extends RouteComponentProps<any> {
  functionSchemaParameter: SchemaFunctionParameter;
  functionParameter: FunctionParameter;
  canBeDeleted: boolean;
  blockSources: BlockSource[];
  blockRow: CalculationBlockRow;
  blockRows: CalculationBlockRow[];
  inputs: Input[];
  outputs: Output[];
  xrefFunctions: XRefFunction[];
  endOfRepeatedParamGroup: boolean;
  dispatch: ThunkDispatch;
  calculationBlockRowTree: CalculationBlockRowTreeState;
  onSaveFunctionParameter: (fp: FunctionParameter) => Promise<any>;
  onClearFunctionParameter: (fp: FunctionParameter) => Promise<any>;
}

interface IState {
  isEditable: boolean;
  type: string;
  typeOptions: OptionProps[];
  value: string | number;
  valueOptions: OptionProps[];
  representation: string;
  isSaving: boolean;
  hasEdits: boolean;
}

const colCSS = {
  "& .bp4-input, & .bp4-input-group": {
    width: "100%",
  },
  "& .bp4-popover-wrapper": {
    flex: 1,
  },
};

const flexWrapperDefaultCSS = {
  width: "100%",
  height: "70px",
};

const NESTED_FUNCTION = "Nested Function";
const SCALAR = "Scalar";
const SOURCE = "Source";

class ParameterRow extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    const { functionParameter: fp } = props;

    const typeOptions = this.getTypeOptions();
    const initialType = fp.Type ? fp.Type : typeOptions[0].value;
    const valueOptions = this.memoizedGetValueOptions(initialType);
    const initialValue = this.getInitialFunctionParameterValue(
      initialType,
      valueOptions
    );

    let representation = fp.Representation || "";
    if (
      fp.Type === "Scalar" &&
      valueOptions.every((val: OptionProps) => {
        return includes(["TRUE", "FALSE"], val.label);
      })
    ) {
      representation = initialValue === "0" ? "FALSE" : "TRUE";
    }

    this.state = {
      isEditable: props.functionParameter.Type !== NESTED_FUNCTION,
      type: initialType,
      value: initialValue || "",
      typeOptions,
      valueOptions,
      representation,
      isSaving: false,
      hasEdits: false,
    };
  }

  public componentDidMount() {
    const { type, valueOptions } = this.state;
    const initialValue = this.initialValueGuard(type, valueOptions);

    if (initialValue !== undefined) {
      this.setState({ value: initialValue });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { functionParameter: fp } = this.props;
    if (fp.id !== prevProps.functionParameter.id) {
      const typeOptions = this.getTypeOptions();
      const newType = fp.Type ? fp.Type : typeOptions[0].value;
      const valueOptions = this.memoizedGetValueOptions(newType);
      const initialValue = this.getInitialFunctionParameterValue(
        newType,
        valueOptions
      );

      let representation = "";

      if (initialValue !== undefined) {
        representation = initialValue.toString();
        const valueOption = valueOptions.find(
          (vo: OptionProps) => initialValue.toString() === vo.value.toString()
        );
        if (valueOption) {
          representation = valueOption.label!;
        }
      }

      this.setState({
        type: newType,
        value: initialValue || "",
        typeOptions,
        valueOptions,
        representation,
        isSaving: false,
        hasEdits: false,
        isEditable: newType !== NESTED_FUNCTION,
      });
    }

    if (this.props.blockSources.length !== prevProps.blockSources.length) {
      const valueOptions = this.memoizedGetValueOptions(this.state.type, true);
      this.setState({ valueOptions });
    }
  }

  public render() {
    const {
      functionSchemaParameter: schemaParam,
      functionParameter,
      canBeDeleted,
      calculationBlockRowTree,
    } = this.props;

    const { typeOptions, valueOptions, representation } = this.state;

    const showSaveButton =
      this.state.hasEdits || !this.props.functionParameter.id;
    const showNestedFunctionControls =
      this.props.functionParameter.Type && NESTED_FUNCTION === this.state.type;
    const nestedFormulaPath =
      showNestedFunctionControls &&
      this.props.location.pathname.replace(
        /\d+\/formula$/,
        `${functionParameter.Nested_BlockRowID}/formula`
      );
    const isScalar = SCALAR === this.state.type;

    const paramGroupEndCSS = this.props.endOfRepeatedParamGroup
      ? { borderBottom: `1px solid ${Colors.LIGHT_GRAY3}` }
      : {};
    const flexWrapperCSS = { ...flexWrapperDefaultCSS, ...paramGroupEndCSS };

    const popoverContent = representation ? (
      <StyledRepresentation>{representation}</StyledRepresentation>
    ) : undefined;

    return (
      <Flex alignItems="center" css={flexWrapperCSS}>
        <Space mr={2}>
          <Flex flex={1} css={colCSS}>
            <Text
              className={classnames(Classes.TEXT_LARGE, Classes.TEXT_MUTED)}
            >
              {schemaParam.Name}
              {schemaParam.Optional && (
                <span
                  className={classnames(Classes.TEXT_SMALL, Classes.TEXT_MUTED)}
                >
                  &nbsp;(optional)
                </span>
              )}
            </Text>
          </Flex>

          <Flex flex={1}>
            <HTMLSelect
              fill={true}
              options={typeOptions}
              value={this.state.type}
              disabled={!this.state.isEditable || 1 === typeOptions.length}
              onChange={this.handleTypeChange}
            />
          </Flex>

          <Flex flex={1}>
            {valueOptions.length > 0 ? (
              <HTMLSelect
                fill={true}
                disabled={
                  !this.state.isEditable ||
                  (isScalar && 1 === valueOptions.length)
                }
                options={valueOptions}
                value={this.state.value}
                onChange={this.handleValueChange}
              />
            ) : (
              <HTMLSelect
                disabled={true}
                fill={true}
                options={[{ label: "any", value: "any" }]}
                value="any"
              />
            )}
          </Flex>

          <Box flex={3} css={colCSS}>
            {0 === valueOptions.length ||
            (1 === valueOptions.length && isScalar) ? (
              <InputGroup
                value={`${this.state.value}`}
                onChange={this.handleScalarTextValueChange}
                fill
              />
            ) : (
              <Popover2
                content={popoverContent}
                interactionKind={PopoverInteractionKind.HOVER}
                hoverOpenDelay={300}
                fill
              >
                <InputGroup value={representation} readOnly={true} fill />
              </Popover2>
            )}
          </Box>

          <Flex flex={1}>
            <Space mr={2}>
              {canBeDeleted && (
                <Button
                  icon="trash"
                  loading={this.state.isSaving}
                  onClick={this.handleOnClear}
                />
              )}
              {showSaveButton && (
                <Button
                  text="Save"
                  loading={this.state.isSaving}
                  onClick={this.handleOnSave}
                />
              )}
              {!showSaveButton && showNestedFunctionControls && (
                <Space mr={2}>
                  <Button
                    text="Clear"
                    loading={this.state.isSaving}
                    onClick={this.handleOnClear}
                  />
                  <Link
                    to={{
                      pathname: nestedFormulaPath || "#",
                      state: {
                        functionID: functionParameter.Nested_FunctionID,
                      },
                    }}
                    onClick={() => {
                      if (calculationBlockRowTree.length > 1) {
                        this.handleSetCalculationBlockRowTree(
                          this.xrefFunctionName(this.state.value as number),
                          functionParameter.Nested_BlockRowID as number,
                          nestedFormulaPath || "#"
                        );
                      }
                    }}
                  >
                    <Button
                      text="Load"
                      intent={Intent.PRIMARY}
                      loading={this.state.isSaving}
                    />
                  </Link>
                </Space>
              )}
            </Space>
          </Flex>
          <Flex flex={1}>{this.statusIcon()}</Flex>
        </Space>
      </Flex>
    );
  }

  private xrefFunctionName = (functionID: number) => {
    const func = this.props.xrefFunctions.find((xrf) => {
      return functionID === xrf.FunctionID;
    });

    return func ? func.Function : "N/A";
  };

  private handleSetCalculationBlockRowTree = (
    name: string,
    id: number,
    href: string
  ) => {
    this.props.dispatch(
      setCalculationBlockRowTree({
        text: name,
        id,
        href,
      })
    );
  };

  private statusIcon = () => {
    if (this.state.hasEdits || !this.props.functionParameter.id) {
      return (
        <Icon
          icon="ban-circle"
          size={16}
          intent={Intent.NONE}
          className={Classes.TEXT_MUTED}
        />
      );
    }

    if (this.props.functionParameter.IsValidParameter) {
      return (
        <Icon
          icon="tick-circle"
          size={16}
          intent={Intent.SUCCESS}
          className={Classes.TEXT_MUTED}
        />
      );
    }

    return (
      <Icon
        icon="error"
        size={16}
        intent={Intent.DANGER}
        className={Classes.TEXT_MUTED}
      />
    );
  };

  private initialValueGuard = (
    initialType: string,
    valueOptions: OptionProps[]
  ) => {
    const initialValue = this.getInitialFunctionParameterValue(
      initialType,
      valueOptions
    );

    if (initialValue === undefined) {
      // Exit safely.  We could not set an initial value because we have no valid
      // value options defined based on the parameter validation requirements of
      // this parameter row
      Toaster.show({
        message:
          "The required BlockSources or Functions do not yet exist to properly define the Function Parameters for this Block Row based on the validation requirements.",
        intent: Intent.WARNING,
        timeout: 0,
      });
      this.props.history.goBack();
    }

    return initialValue;
  };

  private blockSourceLabel = (bs: BlockSource): string => {
    const labelPrefix =
      "Intermediate" === bs.Type
        ? `R${bs.MDA_Source_Code}`
        : `S${bs.MDA_Source_Code}`;

    if (bs.InputID) {
      const input = this.props.inputs.find((i) => i.id === bs.InputID);
      return input ? `${labelPrefix} - ${input.Name}` : labelPrefix;
    }

    if (bs.OutputID) {
      const output = this.props.outputs.find((o) => o.id === bs.OutputID);
      return output ? `${labelPrefix} - ${output.Name}` : labelPrefix;
    }

    if (bs.BlockRowID) {
      const row = this.props.blockRows.find((br) => br.id === bs.BlockRowID);
      if (row) {
        if ("Intermediate" === row.Type) {
          return `${labelPrefix} - ${row.Name!}`;
        }

        const output = this.props.outputs.find((o) => o.id === row.OutputID);
        return output ? `${labelPrefix} - ${output.Name}` : labelPrefix;
      }

      return labelPrefix;
    }

    return labelPrefix;
  };

  private getTypeOptions = () => {
    const { functionSchemaParameter: param } = this.props;
    return param["Parameter Type Validation"].map((value) => ({
      label: value.Type,
      value: "Function" === value.Type ? NESTED_FUNCTION : value.Type,
    }));
  };

  private getValueOptions = (type: string): OptionProps[] => {
    const { functionSchemaParameter: param } = this.props;
    const options: OptionProps[] = param.Optional
      ? [{ label: " ", value: "" }]
      : [];
    const ptv = param["Parameter Type Validation"].find(
      (p) => type === ("Function" === p.Type ? NESTED_FUNCTION : p.Type)
    );

    if (!ptv) {
      return options;
    }

    if (Array.isArray(ptv.Validation)) {
      switch (type) {
        case SOURCE:
          return this.props.blockSources.reduce(
            (acc: OptionProps[], bs: BlockSource) => {
              if (!ptv.Validation.includes(bs.Type)) {
                return acc;
              }

              acc.push({
                label: this.blockSourceLabel(bs),
                value: bs.id!,
              });
              return acc;
            },
            options
          );
        case SCALAR:
          return options.concat(
            ptv.Validation.map((v) => {
              if (typeof v === "boolean") {
                return { label: `${v}`.toUpperCase(), value: v ? 1 : 0 };
              }
              return { label: `${v}`, value: v };
            })
          );
        case NESTED_FUNCTION:
          return options.concat(
            this.props.xrefFunctions
              .filter((xrf) => {
                return ptv.Validation.includes(xrf.FunctionID);
              })
              .map((xf) => ({
                label: xf.Function,
                value: xf.FunctionID,
              }))
          );
      }
    }

    // No Validation rules for this Type
    if (null === ptv.Validation) {
      switch (type) {
        case SOURCE:
          return options.concat(
            this.props.blockSources.map((bs) => ({
              label: this.blockSourceLabel(bs),
              value: bs.id!,
            }))
          );
        case SCALAR:
          return [];
        case NESTED_FUNCTION:
          return options.concat(
            this.props.xrefFunctions.map((xf) => ({
              label: xf.Function,
              value: xf.FunctionID,
            }))
          );
        default:
          return options;
      }
    }

    // Otherwise the ptv.Validation is a string ("integer" or "positive integer" so we display that
    // single "string" validation as a disabled option in the drop down
    return [{ label: ptv.Validation, value: "" }];
  };

  private memoizedGetValueOptions = memoize(this.getValueOptions);

  private getInitialFunctionParameterValue = (
    type: string,
    valueOptions: OptionProps[]
  ): string | number | undefined => {
    const { functionParameter: fp } = this.props;

    if (SCALAR === type) {
      return fp.Scalar || (valueOptions[0] ? valueOptions[0].value : "");
    }

    if (0 === valueOptions.length) {
      // We don't have any valid options.  Either no BlockSource records exist that fit the
      // parameter validation requirements, or we don't have any XREF_Functions that match the
      // parameter validation requirements. Need to return an undefined value so that
      // the component can exit and alert/warn the user
      return undefined;
    }

    switch (type) {
      case SOURCE:
        return fp.BlockSourceID || valueOptions[0].value;
      case NESTED_FUNCTION:
        return fp.Nested_FunctionID || valueOptions[0].value;
      default:
        return "";
    }
  };

  private handleScalarTextValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({
      value: e.target.value,
      representation: e.target.value,
      hasEdits: true,
    });
  };

  private handleTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const newType = e.target.value;
    const valueOptions = this.memoizedGetValueOptions(newType);
    const defaultValueOption = valueOptions[0];
    this.setState({
      type: newType,
      valueOptions,
      value: defaultValueOption ? defaultValueOption.value! : "",
      representation: defaultValueOption ? defaultValueOption.label! : "",
      hasEdits: true,
    });
  };

  private handleValueChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({
      value: e.target.value,
      representation: e.target.selectedOptions[0].label,
      hasEdits: true,
    });
  };

  private handleOnClear = (): void => {
    this.setState({ isSaving: true });
    this.props
      .onClearFunctionParameter(this.props.functionParameter)
      .then(() => {
        this.setState({ isSaving: false });
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  private handleOnSave = (): void => {
    this.setState({ isSaving: true });

    const { type, value } = this.state;

    // Build updated FP here
    const fp = { ...this.props.functionParameter };

    switch (type) {
      case SCALAR:
        fp.BlockSourceID = undefined;
        fp.Nested_FunctionID = undefined;
        fp.Nested_BlockRowID = undefined;
        fp.Scalar = value ? `${value}` : undefined;
        break;
      case SOURCE:
        fp.Nested_FunctionID = undefined;
        fp.Nested_BlockRowID = undefined;
        fp.Scalar = undefined;
        fp.BlockSourceID = value ? parseInt(`${value}`, 10) : undefined;
        break;
      case NESTED_FUNCTION:
        fp.BlockSourceID = undefined;
        fp.Scalar = undefined;
        fp.Nested_FunctionID = value ? parseInt(`${value}`, 10) : undefined;
        break;
      default:
        break;
    }

    fp.Type = type;

    this.props
      .onSaveFunctionParameter(fp)
      .then(() => this.setState({ isSaving: false, hasEdits: false }))
      .catch(() => this.setState({ isSaving: false }));
  };
}

export default withRouter(ParameterRow);

export const StyledRepresentation = styled.div`
  padding: 20px;
`;
