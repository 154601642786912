import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import * as R from "ramda";
import { Classes, Dialog } from "@blueprintjs/core";

import { Dimension } from "../../../types/models";
import { ThunkDispatch } from "../../../types/redux";
import { RootState } from "../../../store/reducer";
import { actions, reorderDimension } from "../../../store/modules/dimensions";

import DimensionCreator from "./DimensionCreator";
import DimensionColumn, { DEFAULT_DIMENSION_NAME } from "./DimensionColumn";
import DimensionColumns from "./DimensionColumns";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  dimensions: Array<Partial<Dimension>>;
  isEditingView: boolean;
}

class InitialDimensionsEditor extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.isValidDimensionName = this.isValidDimensionName.bind(this);

    this.state = { isReordering: false };
  }

  public render() {
    const isAddingDimension = this.props.dimensions.some(
      (d) => DEFAULT_DIMENSION_NAME === d.Name
    );
    const sortedDimensions = R.sortBy(R.prop("DimNumber"))(
      this.props.dimensions
    );

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppable) => (
            <div ref={droppable.innerRef} {...droppable.droppableProps}>
              <DimensionColumns>
                {sortedDimensions.map((dimension: any, index: number) => (
                  <Draggable
                    key={index}
                    draggableId={`${index}`}
                    index={index}
                    isDragDisabled={this.props.isEditingView}
                  >
                    {(draggable: any) => (
                      <div
                        ref={draggable.innerRef}
                        {...draggable.draggableProps}
                        {...draggable.dragHandleProps}
                      >
                        <DimensionColumn
                          key={`${dimension.id}-${dimension.Name}`}
                          dimension={dimension}
                          validateName={this.isValidDimensionName}
                          isEditingView={this.props.isEditingView}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppable.placeholder}
                {this.props.dimensions.length < 8 &&
                  !this.props.isEditingView && (
                    <DimensionCreator
                      onClick={this.createDimension}
                      disabled={isAddingDimension}
                    />
                  )}
              </DimensionColumns>
            </div>
          )}
        </Droppable>
        <Dialog
          isOpen={this.state.isReordering}
          isCloseButtonShown={false}
          canOutsideClickClose={false}
          canEscapeKeyClose={false}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          title="Re-ordering Dimensions"
        >
          <div className={Classes.DIALOG_BODY}>Please wait...</div>
        </Dialog>
      </DragDropContext>
    );
  }
  private onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    this.setState({ isReordering: true });
    this.props
      .dispatch(
        reorderDimension(this.props.match.params.instance, {
          from: result.source.index,
          to: result.destination.index,
        })
      )
      .then(() => this.setState({ isReordering: false }))
      .catch(() => this.setState({ isReordering: false }));
  };
  private isValidDimensionName = (
    newName: string,
    dimNumber: number
  ): boolean => {
    return R.not(
      R.any(
        (dim) => {
          return (
            dim.DimNumber !== dimNumber &&
            R.toLower(dim.Name || DEFAULT_DIMENSION_NAME) === R.toLower(newName)
          );
        },
        [...this.props.dimensions]
      )
    );
  };
  private createDimension = () => {
    const { instance } = this.props.match.params;
    this.props.dispatch(
      actions.addDimension({
        Name: DEFAULT_DIMENSION_NAME,
        DimNumber: this.props.dimensions.length,
        ModelInstance: Number(instance),
        dimension_instances: [],
      })
    );
  };
}
const mapStateToProps = (state: RootState) => ({
  dimensions: state.dimensions,
});
export default withRouter(connect(mapStateToProps)(InitialDimensionsEditor));
