import * as React from "react";
import { find, get, map, trim } from "lodash";
import classnames from "classnames";
import { Box, Flex } from "@rebass/grid";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  HTMLTable,
  Text,
  Icon,
  Position,
  Checkbox,
} from "@blueprintjs/core";

import DimensionRow from "./DimensionRow";

import {
  Dimension,
  OutputDimensionMapping,
  Output,
} from "../../../types/models";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import SuppressReportingDimensionRow from "./SuppressReportingDimensionRow";

interface IProps {
  isOpen: boolean;
  output?: Output;
  modelInstanceId: number;
  dimensions: Dimension[];
  isValid(output: Output): boolean;
  onSave(output: Output): void;
  onCancel(): void;
  onDelete(output: Output): void;
}

interface IState {
  errorMsg: string;
  editedOutput: Output;
  areDimensionsColumnsVisible: boolean;
}

const tableHeaderClasses = classnames(Classes.TEXT_MUTED);
const errorMsgClasses = classnames(Classes.TEXT_SMALL, Classes.INTENT_DANGER);

const customDialogCSS = {
  minWidth: "600px",
};

class EditOutputModal extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    const { output, dimensions, modelInstanceId } = this.props;

    this.state = {
      errorMsg: "",
      editedOutput: output || this.newOutput(modelInstanceId, dimensions),
      areDimensionsColumnsVisible: false,
    };
    this.handleDimensionsColumnVisibility =
      this.handleDimensionsColumnVisibility.bind(this);
  }

  public render() {
    const { isOpen, output, dimensions } = this.props;
    const { editedOutput, errorMsg, areDimensionsColumnsVisible } = this.state;

    return (
      <Dialog
        isOpen={isOpen}
        title={output ? "Edit Output" : "New Output"}
        onClose={this.handleCancel}
        style={customDialogCSS}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Output Name"
            labelFor="output-name-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="output-name-input"
              defaultValue={editedOutput.Name}
              onChange={this.handleNameChange}
              placeholder="Output Name"
              required={true}
            />
            {errorMsg && (
              <Box py={1}>
                <Text className={errorMsgClasses}>{errorMsg}</Text>
              </Box>
            )}
          </FormGroup>
          <Checkbox
            checked={editedOutput.DisableOutputAggregation}
            label="Disable aggregation in Forecast Manager"
            onChange={this.handleChangeDisableAggregation}
          />
          <Flex>
            <Box width="95%">
              <HTMLTable className="edit-output-dimensions-table">
                <thead>
                  <tr>
                    <th>
                      <Box>
                        <Text className={tableHeaderClasses}>Dimension</Text>
                      </Box>
                    </th>
                    <th>
                      <Box>
                        <Text className={tableHeaderClasses}>Report</Text>
                      </Box>
                    </th>
                    <th>
                      <Box>
                        <Text className={tableHeaderClasses}>Aggregate</Text>
                      </Box>
                    </th>
                    {areDimensionsColumnsVisible && (
                      <>
                        <th>
                          <Box>
                            <Text className={tableHeaderClasses}>
                              Dimension Total 1
                            </Text>
                          </Box>
                        </th>
                        <th>
                          <Box>
                            <Text className={tableHeaderClasses}>
                              Dimension Total 2
                            </Text>
                          </Box>
                        </th>
                        <th>
                          <Box>
                            <Text className={tableHeaderClasses}>
                              Dimension Total 3
                            </Text>
                          </Box>
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {map(dimensions, (dimension, idx) => {
                    const dimensionMapping = find(
                      editedOutput.dimensionMapping,
                      (mapping) => mapping.DimensionID === dimension.id
                    );
                    return (
                      dimensionMapping && (
                        <DimensionRow
                          key={`${dimension.id}-${idx}`}
                          dimension={dimension}
                          dimensionMapping={dimensionMapping}
                          onChange={this.handleDimensionMappingChange}
                          isLowestDimension={idx === dimensions.length - 1}
                          areDimensionsColumnsVisible={
                            areDimensionsColumnsVisible
                          }
                        />
                      )
                    );
                  })}
                  {areDimensionsColumnsVisible && (
                    <SuppressReportingDimensionRow
                      suppressReportingDimensionTotal1={
                        this.state.editedOutput.SuppressReportingDimensionTotal1
                      }
                      suppressReportingDimensionTotal2={
                        this.state.editedOutput.SuppressReportingDimensionTotal2
                      }
                      suppressReportingDimensionTotal3={
                        this.state.editedOutput.SuppressReportingDimensionTotal3
                      }
                      handleSuppressReportingDimensionMappingChange={
                        this.handleSuppressReportingDimensionMappingChange
                      }
                    />
                  )}
                </tbody>
              </HTMLTable>
            </Box>
            <Box width="5%" alignSelf="center">
              <Popover2 position={Position.RIGHT}>
                <Tooltip2
                  content="Dimension Total Aggregation"
                  position={Position.RIGHT}
                >
                  <Button onClick={this.handleDimensionsColumnVisibility}>
                    <Icon
                      icon={
                        areDimensionsColumnsVisible
                          ? "double-chevron-left"
                          : "double-chevron-right"
                      }
                    />
                  </Button>
                </Tooltip2>
              </Popover2>
            </Box>
          </Flex>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Flex justifyContent="space-between">
            <Box>
              {output && (
                <Button intent={Intent.DANGER} onClick={this.handleDelete}>
                  Delete
                </Button>
              )}
            </Box>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleCancel}>Cancel</Button>
              <Button intent={Intent.PRIMARY} onClick={this.handleSave}>
                {output ? "Update Output" : "Create Output"}
              </Button>
            </div>
          </Flex>
        </div>
      </Dialog>
    );
  }

  private handleDimensionsColumnVisibility() {
    this.setState({
      areDimensionsColumnsVisible: !this.state.areDimensionsColumnsVisible,
    });
  }

  private handleNameChange = (e: React.FormEvent<HTMLInputElement>): void =>
    this.setState({
      editedOutput: {
        ...this.state.editedOutput,
        Name: trim(e.currentTarget.value),
      },
    });

  private handleChangeDisableAggregation = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    this.setState({
      editedOutput: {
        ...this.state.editedOutput,
        DisableOutputAggregation: e.currentTarget.checked,
      },
    });
  };

  private handleDimensionMappingChange = (
    dimensionMapping: OutputDimensionMapping
  ): void => {
    this.setState({
      editedOutput: {
        ...this.state.editedOutput,
        dimensionMapping: this.state.editedOutput.dimensionMapping
          .filter(
            (mapping: OutputDimensionMapping) =>
              mapping.DimensionID !== dimensionMapping.DimensionID
          )
          .concat(dimensionMapping),
      },
    });
  };

  private handleSuppressReportingDimensionMappingChange = (
    SuppressReportingDimensionTotal1: boolean,
    SuppressReportingDimensionTotal2: boolean,
    SuppressReportingDimensionTotal3: boolean
  ): void => {
    this.setState({
      editedOutput: {
        ...this.state.editedOutput,
        SuppressReportingDimensionTotal1,
        SuppressReportingDimensionTotal2,
        SuppressReportingDimensionTotal3,
      },
    });
  };

  private handleCancel = (): void => this.props.onCancel();

  private handleSave = (): void => {
    const { isValid, onSave } = this.props;
    const { editedOutput } = this.state;

    if (isValid(editedOutput)) {
      onSave(editedOutput);
    } else {
      this.setState({
        errorMsg: "Please provide a unique Name",
      });
    }
  };

  private handleDelete = (): void =>
    this.props.onDelete(this.props.output as Output);

  private newOutput = (
    modelInstanceId: number,
    dimensions: Dimension[]
  ): Output => ({
    ModelInstanceID: modelInstanceId,
    Name: "",
    dimensionMapping: this.newDimensionMappings(dimensions),
    Dimension_Aggregation_FunctionID: null,
    Timescale_Aggregation_FunctionID: null,
    SuppressReportingDimensionTotal1: false,
    SuppressReportingDimensionTotal2: false,
    SuppressReportingDimensionTotal3: false,
    DisableOutputAggregation: false,
  });

  private newDimensionMappings = (
    dimensions: Dimension[]
  ): OutputDimensionMapping[] =>
    map(dimensions, (dimension: Dimension) => ({
      DimensionID: get(dimension, "id", 0) as number,
      Reporting_Flag: 0,
      Aggregation_Flag: 0,
      Dimension_Total_1: 0,
      Dimension_Total_2: 0,
      Dimension_Total_3: 0,
    }));
}

export default EditOutputModal;
