import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import {
  BlockSource,
  BlockSourceBlockMapping,
  BlockSourceDimensionMapping,
  BlockSourceCurveSubTypeMapping,
} from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

interface BlockSourceResponseObject {
  block_source: BlockSource;
  blockMapping: BlockSourceBlockMapping[];
  dimensionMapping: BlockSourceDimensionMapping[];
  curveSubTypeMapping: BlockSourceCurveSubTypeMapping[];
}

export type BlockSourceState = BlockSource[];

const initialState: BlockSourceState = [];

const buildBlockSourceState = (
  payload: BlockSourceResponseObject[]
): BlockSourceState => payload.map(buildBlockSource);

const buildBlockSource = (data: BlockSourceResponseObject): BlockSource => {
  return {
    ...data.block_source,
    blockMapping: data.blockMapping,
    dimensionMapping: data.dimensionMapping,
    curveSubTypeMapping: data.curveSubTypeMapping,
  };
};

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setBlockSources):
      return buildBlockSourceState(action.payload);
    case getType(actions.createBlockSource):
      return [...state, buildBlockSource(action.payload)];
    case getType(actions.updateBlockSource):
      const updatedBlockSource = buildBlockSource(action.payload);
      return state.map((source: BlockSource) =>
        source.id === updatedBlockSource.id ? updatedBlockSource : source
      );
    case getType(actions.deleteBlockSource):
      return state.filter(
        (source: BlockSource) => action.payload.id !== source.id
      );
    default:
      return state;
  }
}

export const actions = {
  setBlockSources: createAction("@blockSources/setBlockSources")<
    BlockSourceResponseObject[]
  >(),
  createBlockSource: createAction(
    "@blockSources/createBlockSource"
  )<BlockSourceResponseObject>(),
  updateBlockSource: createAction(
    "@blockSources/updateBlockSource"
  )<BlockSourceResponseObject>(),
  deleteBlockSource: createAction(
    "@blockSources/deleteBlockSource"
  )<BlockSource>(),
};

export const getBlockSources =
  (modelInstanceID: number | string): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceID}/block_sources`)
      .then((blockSources) => dispatch(actions.setBlockSources(blockSources)));
  };

export const createBlockSource =
  (newBlockSource: BlockSource): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post(
        `/instances/${newBlockSource.ModelInstanceID}/block_sources`,
        newBlockSource
      )
      .then((blockSource) => dispatch(actions.createBlockSource(blockSource)));
  };

export const updateBlockSource =
  (blockSource: BlockSource): ThunkResult<Promise<any>> =>
  (dispatch) => {
    const { blockMapping, dimensionMapping, curveSubTypeMapping } = blockSource;
    const payload = {
      block_source: blockSource,
      blockMapping,
      dimensionMapping,
      curveSubTypeMapping,
    };

    return api
      .post(
        `/instances/${blockSource.ModelInstanceID}/block_sources/${blockSource.id}`,
        payload
      )
      .then((response) => dispatch(actions.updateBlockSource(response)));
  };

export const deleteBlockSource =
  (blockSource: BlockSource): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .delete(
        `/instances/${blockSource.ModelInstanceID}/block_sources/${blockSource.id}`
      )
      .then(() => dispatch(actions.deleteBlockSource(blockSource)));
  };
