import * as React from "react";
import { Intent } from "@blueprintjs/core";
import { MultiSelect2, ItemRenderer } from "@blueprintjs/select";

import { DimensionInstance, Node } from "../../../types/models";

// TODO: Move these MultiSelector helpers into a common directory, probably all multiselector things can be refactored to be shared
import {
  highlightText,
  filterNode,
  sortByNameCaseInsensitive,
} from "./helpers";
import { DESELECT_ALL, SELECT_ALL, SELECT_CHILDREN } from "./index";
import { MenuItem2 } from "@blueprintjs/popover2";

interface IProps {
  instances: DimensionInstance[];
  preSelectedInstanceIds: number[];
  onNodeSelect: (nodes: DimensionInstance[]) => void;
  dimensionName: string;
  parentNode?: Node;
  selectAll: SELECT_CHILDREN;
  isChanged: boolean;
}

export interface IState {
  selectedInstances: DimensionInstance[];
}

class MultiSelector extends React.Component<IProps, IState> {
  public state: IState = {
    selectedInstances: [],
  };

  public componentDidMount() {
    this.setState({ selectedInstances: this.computedSelectedFromPreSelect() });
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.parentNode !== this.props.parentNode ||
      prevProps.preSelectedInstanceIds !== this.props.preSelectedInstanceIds
    ) {
      this.setState({
        selectedInstances: this.computedSelectedFromPreSelect(),
      });
    }
    if (prevProps.selectAll !== this.props.selectAll) {
      if (this.props.selectAll === SELECT_ALL) {
        this.setState(
          {
            selectedInstances: this.props.instances,
          },
          () => this.props.onNodeSelect(this.state.selectedInstances)
        );
      } else if (this.props.selectAll === DESELECT_ALL) {
        this.setState(
          {
            selectedInstances: [],
          },
          () => this.props.onNodeSelect(this.state.selectedInstances)
        );
      }
    }
  }

  public render() {
    const { selectedInstances } = this.state;

    return (
      <MultiSelect2<DimensionInstance>
        items={this.props.instances}
        itemRenderer={this.renderNode}
        itemListPredicate={this.itemListPredicate}
        noResults={<MenuItem2 text="No Results" disabled={true} />}
        onItemSelect={this.handleNodeSelect}
        popoverProps={{
          minimal: true,
        }}
        tagRenderer={this.renderTag}
        tagInputProps={{
          tagProps: { intent: Intent.NONE, minimal: true },
          onRemove: this.handleTagRemove,
          rightElement: undefined,
        }}
        selectedItems={selectedInstances}
        placeholder={"Select Instances..."}
      />
    );
  }

  private itemListPredicate = (
    query: string,
    items: DimensionInstance[]
  ): DimensionInstance[] => {
    return sortByNameCaseInsensitive(items).filter((item, index) =>
      filterNode(query, item, index)
    );
  };

  private computedSelectedFromPreSelect = (): DimensionInstance[] => {
    return this.props.instances.filter(
      (di) => this.props.preSelectedInstanceIds.indexOf(di.id || -1) > -1
    );
  };

  private renderTag = (node: DimensionInstance) => node.Name;

  private renderNode: ItemRenderer<DimensionInstance> = (
    node,
    { modifiers, handleClick, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${node.Name}`;
    return (
      <MenuItem2
        active={modifiers.active}
        icon={
          this.state.selectedInstances.indexOf(node) !== -1 ? "tick" : "blank"
        }
        key={node.id}
        label={this.props.dimensionName}
        onClick={handleClick}
        text={highlightText(text, query)}
        shouldDismissPopover={false}
      />
    );
  };

  private handleTagRemove = (TAG: React.ReactNode, index: number) => {
    return this.deselectNode(index);
  };

  private getSelectedInstanceIndex = (node: DimensionInstance) => {
    return this.state.selectedInstances.indexOf(node);
  };

  private isNodeSelected = (node: DimensionInstance) => {
    return this.getSelectedInstanceIndex(node) !== -1;
  };

  private selectNode = (node: DimensionInstance) => {
    this.setState(
      { selectedInstances: [...this.state.selectedInstances, node] },
      () => this.props.onNodeSelect(this.state.selectedInstances)
    );
  };

  private deselectNode(index: number) {
    this.setState(
      {
        selectedInstances: this.state.selectedInstances.filter(
          (NODE, i) => i !== index
        ),
      },
      () => this.props.onNodeSelect(this.state.selectedInstances)
    );
  }

  private handleNodeSelect = (node: DimensionInstance) => {
    if (!this.isNodeSelected(node)) {
      this.selectNode(node);
    } else {
      this.deselectNode(this.getSelectedInstanceIndex(node));
    }
  };
}

export default MultiSelector;
