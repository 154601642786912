import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { XRefCurveSubType } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type XRefCurveSubTypeState = XRefCurveSubType[];

const initialState: XRefCurveSubTypeState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setXRefCurveSubTypes):
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  setXRefCurveSubTypes: createAction("@xref/setCurveSubTypes")<
    XRefCurveSubType[]
  >(),
};

export const getXRefCurveSubTypes =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return api
      .get("/xref/curve_sub_type")
      .then((data) => dispatch(actions.setXRefCurveSubTypes(data)));
  };
