import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { CalculationBlockRow } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";
import { sortBy } from "lodash";

export type CalculationBlockRowsState = CalculationBlockRow[];

export enum CalculationBlockRowTypes {
  Intermediate = "Intermediate",
  Nested = "Nested",
  Output = "Output",
}

const initialState: CalculationBlockRowsState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setCalculationBlockRows): {
      const calculationBlockRows = action.payload.map(
        (cb: CalculationBlockRow) => {
          cb.isEditing = false;
          return cb;
        }
      );
      return sortBy(calculationBlockRows, "BlockRow_Number");
    }
    case getType(actions.addCalculationBlockRow): {
      const calculationBlockRows = state.map((cb: CalculationBlockRow) => {
        cb.isEditing = false;
        return cb;
      });
      return [...calculationBlockRows, action.payload];
    }
    case getType(actions.editCalculationBlockRow): {
      return state
        .map((cb: CalculationBlockRow) => {
          cb.isEditing = action.payload ? cb.id === action.payload : false;
          return cb;
        })
        .filter((cb: CalculationBlockRow) => cb.id);
    }
    case getType(actions.createCalculationBlockRow): {
      return sortBy(
        state.filter((cbr) => cbr.id).concat([action.payload]),
        "BlockRow_Number"
      );
    }
    case getType(actions.updateCalculationBlockRow): {
      return sortBy(
        state.map((cbr) =>
          cbr.id === action.payload.id ? action.payload : cbr
        ),
        "BlockRow_Number"
      );
    }
    case getType(actions.cancelAddCalculationBlockRow): {
      return state.filter((cbr) => cbr.id);
    }
    case getType(actions.deleteCalculationBlockRow): {
      return state.filter((cbr) => cbr.id !== action.payload.id);
    }
    default:
      return state;
  }
}

export const actions = {
  setCalculationBlockRows: createAction("@blockRows/setCalculationBlockRows")<
    CalculationBlockRow[]
  >(),
  addCalculationBlockRow: createAction(
    "@blockRows/addCalculationBlockRow"
  )<CalculationBlockRow>(),
  editCalculationBlockRow: createAction("@blockRows/editCalculationBlockRow")<
    CalculationBlockRow["id"]
  >(),
  createCalculationBlockRow: createAction(
    "@blockRows/createCalculationBlockRow"
  )<CalculationBlockRow>(),
  updateCalculationBlockRow: createAction(
    "@blockRows/updateCalculationBlockRow"
  )<CalculationBlockRow>(),
  cancelAddCalculationBlockRow: createAction(
    "@blockRows/cancelAddCalculationBlockRow"
  )(),
  deleteCalculationBlockRow: createAction(
    "@blockRows/deleteCalculationBlockRow"
  )<CalculationBlockRow>(),
  getCalculationBlockRow: createAction(
    "@blockRows/getCalculationBlockRow"
  )<CalculationBlockRow>(),
};

export const getCalculationBlockRows =
  (modelInstanceId: number, blockId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/blocks/${blockId}/rows`)
      .then((blockRows) =>
        dispatch(actions.setCalculationBlockRows(blockRows))
      );
  };

export const getCalculationBlockRow =
  (
    modelInstanceId: number,
    blockId: number,
    blockRowId: number
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/blocks/${blockId}/rows/${blockRowId}`)
      .then((blockRow) => dispatch(actions.getCalculationBlockRow(blockRow)));
  };

export const editCalculationBlockRow =
  (id: CalculationBlockRow["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.editCalculationBlockRow(id))
    );
  };

export const saveCalculationBlockRow =
  (row: CalculationBlockRow): ThunkResult<Promise<any>> =>
  (dispatch) => {
    // Existing row - save
    if (row.id) {
      return api
        .post(
          `/instances/${row.ModelInstanceID}/blocks/${row.BlockID}/rows/${row.id}`,
          row
        )
        .then((updatedRow) =>
          dispatch(actions.updateCalculationBlockRow(updatedRow))
        );
    }

    // New row - create
    return api
      .post(`/instances/${row.ModelInstanceID}/blocks/${row.BlockID}/rows`, row)
      .then((savedRow) =>
        dispatch(actions.createCalculationBlockRow(savedRow))
      );
  };

export const deleteCalculationBlockRow =
  (
    row: CalculationBlockRow,
    modelInstanceID: string
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .delete(
        `/instances/${modelInstanceID}/blocks/${row.BlockID}/rows/${row.id}`
      )
      .then(() => dispatch(actions.deleteCalculationBlockRow(row)));
  };

interface IBlockRowReorderParams {
  from: number;
  to: number;
}

export const reorderBlockRows =
  (
    modelInstanceID: string,
    { from, to }: IBlockRowReorderParams
  ): ThunkResult<Promise<any>> =>
  (dispatch, getState) => {
    const newState = Array.from(getState().calculationBlockRows);
    newState.splice(to, 0, newState.splice(from, 1)[0]);

    // Any unsaved block rows we ignore
    const orderedBlockRowIds = newState.reduce(
      (acc: number[], br: CalculationBlockRow) => {
        if (br.id) {
          if ("Nested" !== br.Type) {
            return acc.concat(br.id);
          }
        }

        return acc;
      },
      []
    );

    const blockID = newState[0].BlockID;

    return api
      .post(`/instances/${modelInstanceID}/blocks/${blockID}/rows/reorder`, {
        orderedBlockRowIds,
      })
      .then((blockRows: CalculationBlockRow[]) =>
        dispatch(actions.setCalculationBlockRows(blockRows))
      );
  };

export const cloneCalculationBlockRow =
  (
    row: CalculationBlockRow,
    modelInstanceID: string,
    blockId: number,
    Name: string
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (!blockId) {
      return Promise.resolve();
    }
    return api
      .post(
        `/instances/${modelInstanceID}/blocks/${blockId}/rows/${row.id}/clone`,
        {
          Name,
        }
      )
      .then((blockRow) => dispatch(actions.addCalculationBlockRow(blockRow)));
  };
