import * as React from "react";
import { get, map, sortBy, trim } from "lodash";
import { Flex, Box } from "@rebass/grid";
import classnames from "classnames";

import { Classes, Text, HTMLSelect, HTMLTable } from "@blueprintjs/core";

import CalculationBlockRow from "./CalculationBlockRow";

import {
  CalculationBlock,
  Dimension,
  StandardCalculationBlock,
} from "../../../types/models";
import {
  CalculationBlockTypes,
  editCalculationBlock,
} from "../../../store/modules/calculation_blocks";
import { ThunkDispatch } from "../../../types/redux";

interface IProps {
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  isAdding: boolean;
  isCalculationBlockLoading: boolean;
  dispatch: ThunkDispatch;
  onBlockCancelAdd(): void;
  handleIsAddingWhileEditing(): void;
  onBlockRemove(block: CalculationBlock): void;
  onBlockSave(block: CalculationBlock): void;
}

interface IState {
  dimensionFilter: string;
  isLoading: boolean;
}

const textClasses = classnames(Classes.TEXT_MUTED, Classes.TEXT_LARGE);

class CalculationBlocksTable extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      dimensionFilter: "",
      isLoading: false,
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.isAdding && prevProps.isAdding !== this.props.isAdding) {
      this.setState({ dimensionFilter: "" });
    }
  }

  public render() {
    const {
      dimensions,
      onBlockCancelAdd,
      onBlockSave,
      onBlockRemove,
      isCalculationBlockLoading,
    } = this.props;
    const { dimensionFilter } = this.state;

    const filteredBlocks = this.filteredBlocks();

    return (
      <HTMLTable style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th>
              <Box>
                <Text className={textClasses}>Block Name</Text>
              </Box>
            </th>
            <th>
              <Box>
                <Text className={textClasses}>Block Dimensions</Text>
              </Box>
            </th>
            <th>
              <Flex justifyContent="flex-end" flex={1}>
                <Box px={2}>
                  <HTMLSelect
                    options={this.dimensionOptions()}
                    onChange={this.handleFilterChange}
                    value={dimensionFilter}
                  />
                </Box>
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>
          {map(filteredBlocks, (block, idx) => (
            <CalculationBlockRow
              key={`${block.id}-${idx}`}
              calculationBlock={block}
              dimensions={dimensions}
              onCancel={onBlockCancelAdd}
              onSave={onBlockSave}
              onDelete={onBlockRemove}
              onValidate={(b: CalculationBlock) => this.isValidBlock(b, idx)}
              isCalculationBlockLoading={isCalculationBlockLoading}
              handleCalculationBlockEditing={this.handleCalculationBlockEditing}
            />
          ))}
        </tbody>
      </HTMLTable>
    );
  }

  private handleCalculationBlockEditing = (id?: CalculationBlock["id"]) => {
    this.props
      .dispatch(editCalculationBlock(id))
      .then(() => this.props.handleIsAddingWhileEditing());
  };

  private dimensionOptions = () => {
    const { dimensions } = this.props;
    const defaultOption = {
      label: "All Dimensions",
      value: "",
    };
    const options = sortBy(
      map(dimensions, (dim) => ({
        label: dim.Name,
        value: `${dim.id}`,
      })),
      "label"
    );
    return [defaultOption, ...options];
  };

  private isValidBlock = (block: CalculationBlock, idx: number): boolean => {
    // Only Standard block types will have a Name field and be editable via the Calc Block Editor screen
    if (CalculationBlockTypes.Standard !== block.Type) {
      return false;
    }

    const standardBlock = block as StandardCalculationBlock;

    if (-1 === block.DimensionID) {
      return false;
    }

    const blockName = trim(standardBlock.Name.toLowerCase());
    if ("" === blockName) {
      return false;
    }

    const duplicateNameBlock = this.props.calculationBlocks.find(
      (cb) =>
        CalculationBlockTypes.Standard === cb.Type &&
        blockName === trim((cb as StandardCalculationBlock).Name.toLowerCase())
    );
    return !(duplicateNameBlock && duplicateNameBlock.id !== block.id);
  };

  private filteredBlocks = (): CalculationBlock[] => {
    const { calculationBlocks } = this.props;
    const { dimensionFilter } = this.state;

    if ("" === dimensionFilter) {
      return calculationBlocks.filter(
        (cb: CalculationBlock) => CalculationBlockTypes.Standard === cb.Type
      );
    }

    return calculationBlocks.filter(
      (cb: CalculationBlock) =>
        CalculationBlockTypes.Standard === cb.Type &&
        cb.DimensionID === parseInt(dimensionFilter, 10)
    );
  };

  private handleFilterChange = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const newDimensionFilter = get(evt.currentTarget, "value", undefined);

    if (newDimensionFilter) {
      this.setState({ dimensionFilter: newDimensionFilter });
    } else {
      this.setState({ dimensionFilter: "" });
    }
  };
}

export default CalculationBlocksTable;
