import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { groupBy, sortBy, uniq } from "lodash";

import AppViewTemplate from "../../templates/AppView";
import { AutoGrid, MaxBound } from "../../atoms/Layout";
import { Card, Classes, H4, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box } from "@rebass/grid";

import NewEntryCard from "../../organisms/NewEntryCard";
import CreateClientModal from "../../modals/CreateClient";

import { RootState } from "../../../store/reducer";
import { isAdmin } from "../../../models/profile";
import { Profile } from "../../../types/models";
import { ClientMap } from "../../../store/modules/client";

interface IProps {
  clients?: ClientMap;
  profile?: Profile;
}

interface IState {
  showCreateClientModal: boolean;
}

class ClientsView extends React.Component<IProps, IState> {
  public state: IState = {
    showCreateClientModal: false,
  };

  public render() {
    const { profile, clients } = this.props;

    const myClientIds =
      profile !== undefined
        ? uniq(profile.ModelMemberships.map((m) => m.ClientID))
        : [];

    const { myClients = [], otherClients = [] } = groupBy(
      sortBy(clients, "Name"),
      (client) =>
        myClientIds.indexOf(client.id) !== -1 ? "myClients" : "otherClients"
    );

    return (
      <AppViewTemplate title="Clients">
        <MaxBound mt={3}>
          <H4>My Clients</H4>
          {myClients.length === 0 && (
            <NonIdealState
              title="No Clients"
              icon={IconNames.INFO_SIGN}
              description="You are not currently assigned to any clients"
            />
          )}
          <AutoGrid columns={4}>
            {myClients.map((client, i) => (
              <Link
                key={i}
                to={`/clients/${client.id}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Card interactive={true} className={Classes.TEXT_LARGE}>
                  {client.Name}
                </Card>
              </Link>
            ))}
          </AutoGrid>

          <Box mb={3} />

          <H4>Other Clients</H4>
          <AutoGrid columns={4}>
            {otherClients.map((client, i) => (
              <Link
                key={i}
                to={`/clients/${client.id}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Card interactive={true} className={Classes.TEXT_LARGE}>
                  {client.Name}
                </Card>
              </Link>
            ))}
            {isAdmin(profile) && (
              <NewEntryCard title="Add New Client" onClick={this.toggleModal} />
            )}
          </AutoGrid>
        </MaxBound>
        <CreateClientModal
          isOpen={this.state.showCreateClientModal}
          onClose={this.toggleModal}
        />
      </AppViewTemplate>
    );
  }

  private toggleModal = () =>
    this.setState({ showCreateClientModal: !this.state.showCreateClientModal });
}

const mapStateToProps = (state: RootState) => ({
  profile: state.profile,
  clients: state.clients,
});

export default connect(mapStateToProps)(ClientsView);
