import * as React from "react";

import { FormGroup, ControlGroup, Intent } from "@blueprintjs/core";

import EditableText from "../../../molecules/EditableText";

import { InputCategory } from "../../../../types/models";
import DeleteButton from "../../../atoms/DeleteButton";

interface IProps {
  isValid: (name: string, id: number | undefined) => string;
  inputCategory: InputCategory;
  canDelete: boolean;
  onCancel: () => void;
  onSave(inputCategory: InputCategory): Promise<any>;
  onDelete(inputCategory: InputCategory): Promise<any>;
}

interface IState {
  errorMsg: string;
}

class InputCategoryRow extends React.Component<IProps, IState> {
  public state: IState = {
    errorMsg: "",
  };

  public render() {
    const { inputCategory } = this.props;
    return (
      <tr style={{ borderBottom: "1px solid #E1E8ED" }}>
        <td>
          <FormGroup
            className="input-category-row"
            helperText={this.state.errorMsg}
            intent={this.state.errorMsg ? Intent.DANGER : Intent.NONE}
          >
            <ControlGroup fill={true}>
              <EditableText
                placeholder="Input Category name"
                onChange={this.handleEditClose}
                onCancel={this.props.onCancel}
                value={inputCategory.Name}
                startEditing={this.isCreating()}
              />
              {!this.isCreating() && (
                <DeleteButton onDelete={this.handleDelete} />
              )}
            </ControlGroup>
          </FormGroup>
        </td>
      </tr>
    );
  }

  private isCreating = () => !this.props.inputCategory.id;

  private handleEditClose = (newName: string) => {
    const inputCategory = { ...this.props.inputCategory, Name: newName.trim() };
    const validationResult = this.props.isValid(
      inputCategory.Name,
      inputCategory.id
    );

    if (validationResult === "") {
      this.setState({
        errorMsg: validationResult,
      });
      return this.props.onSave(inputCategory);
    } else {
      this.setState({ errorMsg: validationResult });
      return Promise.reject();
    }
  };

  private handleDelete = () => {
    const { inputCategory, onDelete, canDelete } = this.props;
    if (canDelete) {
      return onDelete(inputCategory);
    } else {
      this.setState({
        errorMsg:
          "Input categories can only be deleted once all inputs associated with a category have been removed.",
      });
      return Promise.reject();
    }
  };
}

export default InputCategoryRow;
