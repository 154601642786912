import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*, *:before, *:after {
  position: relative;
}
html {
  background: #F5f8fa;
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  background: #EBF1F5;
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar:disabled {
  background: #F5F8FA;
}

::-webkit-scrollbar-thumb {
  background: #CED9E0;
}

::-webkit-scrollbar-thumb:hover {
  background: #BFCCD6;
}

.p-node-editor .bp4-tag-input .bp4-input-ghost,
.p-node-editor .bp4-popover-target,
.p-input-creator .bp4-tag-input .bp4-input-ghost,
.p-input-creator .bp4-popover-target {
  min-width: 100%;
}

.font-subtext {
  font-size: 10px;
}

.margin-zero {
  margin: 0!important;
}
table.bp4-html-table.calculation-row-editor {
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;

  tbody {
    tr {
      background: #FFFFFF;
      border-radius: 3px;
    }

    tr:first-child td {
      box-shadow: none;
    }

    td {
      border-top: 1px solid #EBF1F5;
      border-bottom: 1px solid #EBF1F5;
    }

    td.start {
      border-left: 1px solid #EBF1F5;
      border-radius: 3px 0 0 3px;
    }
    td.end {
      border-right: 1px solid #EBF1F5;
      border-radius: 0 3px 3px 0;
    }
  }
}

table.bp4-html-table.input-category-row-editor {
  border-collapse: collapse;
  width: 100%;

  tbody {
    tr:first-child td {
      box-shadow:none;
    }

    td {
      border-bottom: 1px solid #EBF1F5;
    }
  }
}

.bp4-form-group.input-category-row {
  margin-bottom: 0px;
}

table.bp4-html-table.edit-output-dimensions-table {
  border-collapse: collapse;
  width: 100%;

  tbody {
    tr:first-child td {
      box-shadow:none;
    }

    td {
      width: 16%;
      border-bottom: 1px solid #E1E8ED;
    }
  }
}
`;
