import * as React from "react";
import { Button, Classes, Intent } from "@blueprintjs/core";

interface Props {
  onDelete: () => Promise<any>;
}

interface State {
  deleting: boolean;
}

export default class DeleteButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { deleting: false };
  }

  public render() {
    return (
      <Button
        icon="trash"
        intent={Intent.DANGER}
        minimal={true}
        fill={false}
        className={Classes.FIXED}
        loading={this.state.deleting}
        onClick={this.onClick}
      />
    );
  }

  private onClick = () => {
    this.setState({ deleting: true }, () => {
      this.props
        .onDelete()
        .then(() => {
          this.setState({ deleting: false });
        })
        .catch(() => {
          this.setState({ deleting: false });
        });
    });
  };
}
