import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import AppViewTemplate from "../../../templates/AppView";
import CalculationRowEditor from "../../../organisms/CalculationRowEditor";
import TabBar from "../../../organisms/TabBar";

interface IProps extends RouteComponentProps<any> {}

class RowEditor extends React.Component<IProps> {
  public render() {
    return (
      <React.Fragment>
        <AppViewTemplate title="Calculation Editor">
          <CalculationRowEditor
            calculationBlockId={this.props.match.params.blockId}
            modelInstanceId={this.props.match.params.instance}
            clientId={this.props.match.params.client}
            modelId={this.props.match.params.model}
          />
        </AppViewTemplate>
        <TabBar />
      </React.Fragment>
    );
  }
}

export default withRouter(RowEditor);
