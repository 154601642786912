import * as React from "react";
import "styled-components/macro";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Intent } from "@blueprintjs/core";
import { Flex } from "@rebass/grid";

import {
  Dimension,
  Node,
  StandardCalculationBlock,
  FirstDimensionGroup,
} from "../../../../types/models";
import { ThunkDispatch } from "../../../../types/redux";

import {
  getDimensions,
  saveDimensions,
  actions as dimActions,
} from "../../../../store/modules/dimensions";
import { getCalculationBlocks } from "../../../../store/modules/calculation_blocks";
import { getNodes, actions } from "../../../../store/modules/nodes";
import {
  viewFirstDimensionGroups,
  createFirstDimensionGroup,
  updateFirstDimensionGroup,
  deleteFirstDimensionGroup,
} from "../../../../store/modules/first_dimension_groups";
import { RootState } from "../../../../store/reducer";

import { Size } from "../../../atoms/Layout";
import AppViewTemplate from "../../../templates/AppView";
import TabBar from "../../../organisms/TabBar";
import Loading from "../../../molecules/Loading";
import FinalizedDimensionEditor from "../../../organisms/Dimensions/FinalizedDimensionsEditor";
import Toaster from "../../../molecules/Toaster";
import NodeEditorView from "./NodeEditor";
import FirstDimensionGroupsModal from "./FirstDimensionGroupsModal";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  dimensions: Dimension[];
  nodes: Node[];
  calcBlocks: StandardCalculationBlock[];
  groups: FirstDimensionGroup[];
}

interface IState {
  isSaving: boolean;
  isLoading: boolean;
  isEditingView: boolean;
  isEditingFirstGroupDimensions: boolean;
  modelInstanceId: number;
}

const containerCSS = {
  paddingBottom: "50px",
};

class NodeDimensionEditorView extends React.Component<IProps, IState> {
  public state: IState = {
    isSaving: false,
    isLoading: true,
    isEditingView: false,
    isEditingFirstGroupDimensions: false,
    modelInstanceId: this.props.match.params.instance,
  };

  public componentDidMount() {
    const { dispatch } = this.props;
    const modelInstanceId = this.state.modelInstanceId;

    Promise.all([
      dispatch(getDimensions(modelInstanceId)),
      dispatch(getNodes(modelInstanceId)),
      dispatch(getCalculationBlocks(modelInstanceId)),
      dispatch(viewFirstDimensionGroups(modelInstanceId)),
    ]).then(() => this.setState({ isLoading: false }));
  }

  public componentWillUnmount() {
    this.props.dispatch(actions.resetNodes());
    this.props.dispatch(dimActions.resetDimensions());
    Toaster.clear();
  }

  public render() {
    const {
      isLoading,
      isSaving,
      isEditingView,
      isEditingFirstGroupDimensions,
    } = this.state;

    const controls = (
      <React.Fragment>
        {isEditingView ? (
          <React.Fragment>
            <Flex mr={2}>
              <Button
                intent={Intent.PRIMARY}
                text="First Dimension Groups"
                onClick={() => {
                  this.setState({ isEditingFirstGroupDimensions: true });
                }}
              />
            </Flex>
            <Button
              intent={Intent.PRIMARY}
              text="Edit Nodes"
              loading={isSaving}
              onClick={this.handleSaveEdits}
            />
          </React.Fragment>
        ) : (
          <Button
            intent={Intent.PRIMARY}
            text="Edit Instances"
            onClick={() => {
              Toaster.clear();
              this.setState({
                isEditingView: !isEditingView,
              });
            }}
          />
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <AppViewTemplate
          title={isEditingView ? "Instance Editor" : "Node Editor"}
          controls={controls}
        >
          <Flex flex={1} className="p-node-editor" css={containerCSS}>
            <Flex
              flex={3}
              pl={`calc((100vw - ${Size.MAX_WIDTH}) / 2 + 32px)`}
              css={{ overflow: "scroll" }}
            >
              {isLoading ? (
                <Loading />
              ) : isEditingView ? (
                <FinalizedDimensionEditor />
              ) : (
                <NodeEditorView />
              )}
            </Flex>
          </Flex>
        </AppViewTemplate>
        <TabBar />
        <FirstDimensionGroupsModal
          groups={this.props.groups}
          isOpen={isEditingFirstGroupDimensions}
          onClose={() => {
            this.setState({ isEditingFirstGroupDimensions: false });
          }}
          onAddGroup={(s) => {
            return this.props.dispatch(
              createFirstDimensionGroup(this.state.modelInstanceId, s)
            );
          }}
          onUpdateGroup={(g, s) => {
            if (g.id) {
              return this.props.dispatch(
                updateFirstDimensionGroup(this.state.modelInstanceId, g.id, s)
              );
            } else {
              throw new Error(
                `Tried to update a group without a group id! ${JSON.stringify(
                  g
                )}`
              );
            }
          }}
          onDeleteGroup={(g) => {
            if (g.id) {
              return this.props.dispatch(
                deleteFirstDimensionGroup(this.state.modelInstanceId, g.id)
              );
            } else {
              throw new Error(
                `Tried to delete a group without a group id! ${JSON.stringify(
                  g
                )}`
              );
            }
          }}
        />
      </React.Fragment>
    );
  }

  private handleSaveEdits = () => {
    this.setState({
      isSaving: true,
    });

    this.props
      .dispatch(saveDimensions(this.state.modelInstanceId))
      .then(() =>
        this.setState({
          isSaving: false,
          isEditingView: false,
        })
      )
      .catch((error) => {
        Toaster.show({ message: error.message, intent: "danger" });
        this.setState({ isSaving: false });
      });
  };
}

const mapStateToProps = (state: RootState) => ({
  dimensions: state.dimensions,
  nodes: state.nodes,
  calcBlocks: state.calculationBlocks,
  groups: state.firstDimensionGroups,
});

export default connect(mapStateToProps)(NodeDimensionEditorView);
