import * as React from "react";
import { Box } from "@rebass/grid";
import { Checkbox, Text } from "@blueprintjs/core";

interface IProps {
  suppressReportingDimensionTotal1: boolean;
  suppressReportingDimensionTotal2: boolean;
  suppressReportingDimensionTotal3: boolean;
  handleSuppressReportingDimensionMappingChange(
    SuppressReportingDimensionTotal1: boolean,
    SuppressReportingDimensionTotal2: boolean,
    SuppressReportingDimensionTotal3: boolean
  ): void;
}

class SuppressReportingDimensionRow extends React.Component<IProps> {
  public render() {
    const {
      suppressReportingDimensionTotal1,
      suppressReportingDimensionTotal2,
      suppressReportingDimensionTotal3,
    } = this.props;

    return (
      <tr>
        <td>
          <Box>
            <Text>Suppress Reporting</Text>
          </Box>
        </td>
        <td />
        <td />
        <td>
          <Box>
            <Checkbox
              defaultChecked={suppressReportingDimensionTotal1}
              onChange={this.handleSuppressReportingDimensionTotalChangeOne}
            />
          </Box>
        </td>
        <td>
          <Box>
            <Checkbox
              defaultChecked={suppressReportingDimensionTotal2}
              onChange={this.handleSuppressReportingDimensionTotalChangeTwo}
            />
          </Box>
        </td>
        <td>
          <Box>
            <Checkbox
              defaultChecked={suppressReportingDimensionTotal3}
              onChange={this.handleSuppressReportingDimensionTotalChangeThree}
            />
          </Box>
        </td>
      </tr>
    );
  }

  private handleSuppressReportingDimensionTotalChangeOne = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.handleSuppressReportingDimensionMappingChange(
      e.currentTarget.checked,
      this.props.suppressReportingDimensionTotal2,
      this.props.suppressReportingDimensionTotal3
    );

  private handleSuppressReportingDimensionTotalChangeTwo = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.handleSuppressReportingDimensionMappingChange(
      this.props.suppressReportingDimensionTotal1,
      e.currentTarget.checked,
      this.props.suppressReportingDimensionTotal3
    );

  private handleSuppressReportingDimensionTotalChangeThree = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.handleSuppressReportingDimensionMappingChange(
      this.props.suppressReportingDimensionTotal1,
      this.props.suppressReportingDimensionTotal2,
      e.currentTarget.checked
    );
}

export default SuppressReportingDimensionRow;
