import * as React from "react";
import { Flex } from "@rebass/grid";
import {
  Button,
  Classes,
  ControlGroup,
  Intent,
  InputGroup,
  ButtonGroup,
  Text,
} from "@blueprintjs/core";

interface Props {
  value: string;
  placeholder: string;
  onChange: (v: string) => Promise<any>;
  onCancel?: () => void;
  startEditing: boolean;
}

interface EditingState {
  state: "editing";
  text: string;
  failed: boolean;
}

interface SavingState {
  state: "saving";
  text: string;
}

interface NotEditingState {
  state: "not-editing";
}

type State = EditingState | NotEditingState | SavingState;

export default class EditableText extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (props.startEditing) {
      this.state = {
        state: "editing",
        text: props.value,
        failed: false,
      };
    } else {
      this.state = {
        state: "not-editing",
      };
    }
  }

  public render() {
    const { value, placeholder } = this.props;
    const { state } = this;

    if (this.isEditing(state) || this.isSaving(state)) {
      return (
        <form onSubmit={this.finishEdit}>
          <ControlGroup fill={true}>
            <InputGroup
              type="text"
              onChange={this.onEdit}
              placeholder={placeholder}
              className={Classes.FILL}
              required={true}
              autoFocus={true}
              value={state.text}
              intent={
                this.isEditing(state) && state.failed
                  ? Intent.DANGER
                  : Intent.PRIMARY
              }
            />
            <ButtonGroup minimal={true}>
              <Button
                className={Classes.FIXED}
                loading={this.isSaving(state)}
                icon="tick"
                type="submit"
                text="Save"
              />
              <Button
                className={Classes.FIXED}
                disabled={this.isSaving(state)}
                icon="cross"
                onClick={this.onCancel}
              />
            </ButtonGroup>
          </ControlGroup>
        </form>
      );
    }
    return (
      <Flex alignItems="center" justifyContent={"space-between"} width={1}>
        <Text ellipsize={true}>{value}</Text>
        <Button
          minimal={true}
          icon="edit"
          onClick={() => this.setState({ state: "editing", text: value })}
          text="Edit"
        />
      </Flex>
    );
  }

  private finishEdit = (e: React.SyntheticEvent) => {
    const { state } = this;
    if (this.isEditing(state)) {
      this.setState({ state: "saving", text: state.text }, () => {
        this.props
          .onChange(state.text)
          .then(() => {
            this.setState({ state: "not-editing" });
          })
          .catch(() => {
            this.setState({ state: "editing", text: state.text, failed: true });
          });
      });
      e.preventDefault();
      e.stopPropagation();
    }
  };

  private onEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ state: "editing", text: e.currentTarget.value });
  };

  private isEditing(state: State): state is EditingState {
    return this.state.state === "editing";
  }

  private isSaving(state: State): state is SavingState {
    return this.state.state === "saving";
  }

  private onCancel = () => {
    this.setState({ state: "not-editing" });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
}
