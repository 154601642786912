import * as React from "react";
import { connect } from "react-redux";
import "styled-components/macro";
import { matchPath, withRouter, RouteComponentProps } from "react-router-dom";
import { Flex } from "@rebass/grid";
import { Colors } from "@blueprintjs/core";
import Space from "styled-space";

import TabBarButton from "./TabBarButton";
import { MaxBound } from "../../atoms/Layout";
import { IconNames } from "@blueprintjs/icons";
import { RootState } from "../../../store/reducer";
import { selectors as modelInstanceSelectors } from "../../../store/modules/model_instances";

import {
  ModelInstance,
  ModelInstanceFilter,
  Profile,
} from "../../../types/models";
import ExportModelButton from "./ExportModelButton";
import { PropsWithChildren } from "react";

export const tabBarHeight = "345px";

const TabBarWrapper: React.FunctionComponent<PropsWithChildren> = (props) => {
  return (
    <Flex
      bg={Colors.LIGHT_GRAY4}
      alignItems="center"
      justifyContent="center"
      width={1}
      py={10}
      css={{
        bottom: "0",
        position: "fixed",
      }}
      {...props}
    />
  );
};

type RouteProps = RouteComponentProps<{
  client: string;
  model: string;
  instance: string;
}>;

interface StateProps {
  modelInstance: ModelInstance;
  profile: Profile;
  modelInstancesFilter: ModelInstanceFilter;
}

type Props = RouteProps & StateProps;

class TabBar extends React.Component<Props> {
  public render() {
    const {
      match: { params },
      modelInstance,
      profile,
      modelInstancesFilter,
    } = this.props;
    const { client, model, instance } = params;

    return (
      <TabBarWrapper>
        <MaxBound>
          <Flex justifyContent={"flex-end"}>
            <Flex mx={"auto"}>
              <Space mx={2}>
                <TabBarButton
                  to={`/clients/${client}/models/${model}/${modelInstancesFilter}/instances/${instance}/nodes`}
                  icon={IconNames.GRAPH}
                  label="Node Editor"
                />
                <TabBarButton
                  to={`/clients/${client}/models/${model}/${modelInstancesFilter}/instances/${instance}/calculations`}
                  icon={IconNames.FUNCTION}
                  label="Function Editor"
                />
                <TabBarButton
                  to={`/clients/${client}/models/${model}/${modelInstancesFilter}/instances/${instance}/inputs`}
                  icon={IconNames.VARIABLE}
                  label="Inputs & Outputs"
                  isActive={(match, location) =>
                    !!(
                      match ||
                      matchPath(location.pathname, {
                        path: `/clients/${client}/models/${model}/${modelInstancesFilter}/instances/${instance}/outputs`,
                      })
                    )
                  }
                />
              </Space>
            </Flex>
            <ExportModelButton
              modelInstance={modelInstance}
              profile={profile}
            />
          </Flex>
        </MaxBound>
      </TabBarWrapper>
    );
  }
}

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const instanceId = parseInt(props.match.params.instance, 10);
  return {
    modelInstance: modelInstanceSelectors.findById(state, instanceId),
    profile: state.profile,
    modelInstancesFilter: state.instanceFilter,
  };
};

export default withRouter(connect(mapStateToProps)(TabBar));
