import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {
  Dialog,
  FormGroup,
  InputGroup,
  Button,
  Classes,
} from "@blueprintjs/core";

import { createClient } from "../../store/modules/client";
import { ThunkDispatch } from "../../types/redux";
import { trimStart } from "lodash";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  isOpen: boolean;
  onClose: () => void;
}

interface IState {
  name: string;
  isLoading: boolean;
  error: string;
}

class CreateClientModal extends React.Component<IProps, IState> {
  public state: IState = {
    name: "",
    isLoading: false,
    error: "",
  };

  public render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Add New Client"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            helperText={
              this.state.error ||
              `${40 - this.state.name.length} characters left`
            }
            intent={this.state.error ? "danger" : "none"}
            label="New Client"
            labelFor="client-name-input"
            labelInfo="(required)"
          >
            <InputGroup
              value={this.state.name}
              id="client-name-input"
              placeholder="Client Name"
              required={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({
                  name: trimStart(e.target.value).substring(0, 40),
                })
              }
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.handleOnClose}
              disabled={this.state.isLoading}
            >
              Cancel
            </Button>
            <Button
              intent="primary"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
            >
              Create Client
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  private handleOnClose = () => {
    this.setState({ error: "" });
    this.props.onClose();
  };

  private handleSubmit = async () => {
    this.setState({ isLoading: true });

    this.props
      .dispatch(createClient(this.state.name))
      .then((client) => {
        this.props.history.push(`/clients/${client.payload.id}`);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: "Error creating client. Please try again.",
        });
        console.log(error);
      });
  };
}

export default withRouter(connect()(CreateClientModal));
