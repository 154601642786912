/**
 * Dimensions state
 */
import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import {
  Dimension,
  DimensionInstance,
  FirstDimensionGroup,
} from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";
import * as R from "ramda";

export type DimensionsState = Dimension[];

const initialState: DimensionsState = [];

// Reducer
export default function reducer(state = initialState, action: RootAction) {
  let dimension: Dimension;
  let dimensionInstance: DimensionInstance;

  switch (action.type) {
    case getType(actions.setDimensions):
      return action.payload;
    case getType(actions.resetDimensions):
      return initialState;
    case getType(actions.addDimension):
      return [...state, action.payload];
    case getType(actions.updateDimension):
      const updatedDim = action.payload;

      return state.map((d: Dimension) => {
        return d.id !== updatedDim.id ? d : updatedDim;
      });
    case getType(actions.deleteDimension):
      return state.filter((d: Dimension) => {
        return d.id !== action.payload.id;
      });
    case getType(actions.deleteUnsavedDimension):
      // An unsaved dimension was deleted, so just remove it from the list
      // The payload is the DimNumber (index) to remove
      return R.remove(action.payload, 1, state);
    case getType(actions.createDimensionSuccess):
      // New dimension(s) added via the `addDimension` instance was successfully persisted to
      // the server - update them with the new server data at the appropriate DimNumber
      return state.map((d: Dimension) => {
        if (d.DimNumber !== action.payload.DimNumber) {
          return d;
        }

        return { ...action.payload };
      });
    case getType(actions.deleteUnsavedDimensionInstance):
      dimension = action.payload.dimension;
      dimensionInstance = action.payload.dimensionInstance;

      const newInstances = dimension.dimension_instances.filter(
        (di: DimensionInstance) => di.Name !== dimensionInstance.Name
      );

      return state.map((dim: Dimension) => {
        if (dim.id !== dimension.id) {
          return dim;
        }

        return { ...dimension, dimension_instances: newInstances };
      });
    case getType(actions.deleteDimensionInstance):
      const deletedDimensionInstance = action.payload.dimensionInstance;
      dimension = action.payload.dimension;
      return state.map((d: Dimension) => {
        if (d.id !== dimension.id) {
          return d;
        }

        return {
          ...d,
          dimension_instances: d.dimension_instances.filter(
            (di: DimensionInstance) => di.id !== deletedDimensionInstance.id
          ),
        };
      });
    case getType(actions.addDimensionInstance):
      return state.map((dim: Dimension) => {
        if (dim.id !== action.payload.dimension.id) {
          return dim;
        }

        return {
          ...dim,
          dimension_instances: [
            action.payload.dimensionInstance,
            ...dim.dimension_instances,
          ],
        };
      });
    case getType(actions.createDimensionInstanceSuccess):
      const { newDimensionInstance } = action.payload;
      dimension = action.payload.dimension;
      return state.map((d: Dimension) => {
        if (d.id !== dimension.id) {
          return d;
        }

        const newDimensionInstances = dimension.dimension_instances.map(
          (di: DimensionInstance, idx: number) => {
            return di.id ? di : newDimensionInstance;
          }
        );

        return { ...dimension, dimension_instances: newDimensionInstances };
      });
    case getType(actions.updateDimensionInstance):
      dimension = action.payload.dimension;
      dimensionInstance = action.payload.dimensionInstance;

      return state.map((d: Dimension) => {
        if (d.id !== dimension.id) {
          return d;
        }

        const updatedDimensionInstances = dimension.dimension_instances.map(
          (di: DimensionInstance) => {
            return di.id === dimensionInstance.id ? dimensionInstance : di;
          }
        );

        return { ...dimension, dimension_instances: updatedDimensionInstances };
      });

    default:
      return state;
  }
}

// Actions
export const actions = {
  setDimensions: createAction("@dimensions/setDimensions")<Dimension[]>(),
  updateDimension: createAction("@dimensions/updateDimension")<Dimension>(),
  resetDimensions: createAction("@dimensions/resetDimensions")(),
  createDimensionSuccess: createAction(
    "@dimensions/createDimensionSuccess"
  )<Dimension>(),
  addDimension: createAction("@dimensions/addDimension")<Dimension>(),
  deleteDimension: createAction("@dimensions/deleteDimension")<Dimension>(),
  deleteDimensionInstance: createAction("@dimensions/deleteDimensionInstance")<{
    dimensionInstance: Partial<DimensionInstance>;
    dimension: Dimension;
  }>(),
  deleteUnsavedDimension: createAction(
    "@dimension/deleteUnsavedDimension"
  )<number>(),
  reorderDimension: createAction("@dimensions/reorderDimension")<{
    from: number;
    to: number;
  }>(),
  addDimensionInstance: createAction("@dimensions/addDimensionInstance")<{
    dimensionInstance: DimensionInstance;
    dimension: Dimension;
  }>(),
  deleteUnsavedDimensionInstance: createAction(
    "@dimensions/deleteUnsavedDimensionInstance"
  )<{ dimensionInstance: DimensionInstance; dimension: Dimension }>(),
  createDimensionInstanceSuccess: createAction(
    "@dimensions/createDimensionInstanceSuccess"
  )<{
    newDimensionInstance: DimensionInstance;
    dimension: Dimension;
  }>(),
  updateDimensionInstance: createAction("@dimensions/updateDimensionInstance")<{
    dimensionInstance: DimensionInstance;
    dimension: Dimension;
  }>(),
};

// Action Creators
export const getDimensions =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api.get(`/instances/${modelInstanceId}/dimensions`).then((data) => {
      dispatch(actions.setDimensions(data));
    });
  };

export const saveDimension =
  (dim: Dimension): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (dim.id) {
      return api
        .post(`/instances/${dim.ModelInstance}/dimensions/${dim.id}`, dim)
        .then((updatedDim) => {
          dispatch(actions.updateDimension(updatedDim));
        });
    }

    return api
      .post(`/instances/${dim.ModelInstance}/dimensions`, [dim])
      .then((newDim) => {
        dispatch(actions.createDimensionSuccess(newDim[0]));
      });
  };

export const deleteDimension =
  (dim: Dimension): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (dim.id) {
      return api
        .delete(`/instances/${dim.ModelInstance}/dimensions/${dim.id}`)
        .then((dimensions: Dimension[]) =>
          dispatch(actions.setDimensions(dimensions))
        );
    }

    return Promise.resolve(
      dispatch(actions.deleteUnsavedDimension(dim.DimNumber))
    );
  };

export const saveDimensions =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() => dispatch(actions.resetDimensions));
  };

export const saveDimensionInstance =
  (dimInstance: DimensionInstance, dim: Dimension): ThunkResult<Promise<any>> =>
  (dispatch) => {
    // Existing DimensionInstance - Update it
    if (dimInstance.id) {
      return api
        .post(
          `/instances/${dim.ModelInstance}/dimensions/${dim.id}/dimension_instances/${dimInstance.id}`,
          {
            Name: dimInstance.Name,
            Tab_Name: dimInstance.Tab_Name,
          }
        )
        .then((updatedDimInstance) =>
          dispatch(
            actions.updateDimensionInstance({
              dimensionInstance: updatedDimInstance,
              dimension: dim,
            })
          )
        );
    }

    // New DimensionInstance - Create it
    return api
      .post(
        `/instances/${dim.ModelInstance}/dimensions/${dim.id}/dimension_instances`,
        { Name: dimInstance.Name }
      )
      .then((newDimInstance: DimensionInstance) =>
        dispatch(
          actions.createDimensionInstanceSuccess({
            newDimensionInstance: newDimInstance,
            dimension: dim,
          })
        )
      );
  };

export const updateFirstDimensionGroup =
  (
    dimInstance: DimensionInstance,
    dim: Dimension,
    group: FirstDimensionGroup | null
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    // Existing DimensionInstance - Update it
    if (dimInstance.id) {
      return api
        .post(
          `/instances/${dim.ModelInstance}/dimensions/${dim.id}/dimension_instances/${dimInstance.id}`,
          {
            FirstDimensionGroupID: group && group.id,
          }
        )
        .then((updatedDimInstance) =>
          dispatch(
            actions.updateDimensionInstance({
              dimensionInstance: updatedDimInstance,
              dimension: dim,
            })
          )
        );
    } else {
      throw new Error(
        "Cannot update first dimension group of a dimension instance that hasn't been created"
      );
    }
  };

export const deleteDimensionInstance =
  (dimInstance: DimensionInstance, dim: Dimension): ThunkResult<Promise<any>> =>
  (dispatch) => {
    // Existing DimensionInstance - delete from server
    if (dimInstance.id) {
      return api
        .delete(
          `/instances/${dim.ModelInstance}/dimensions/${dim.id}/dimension_instances/${dimInstance.id}`
        )
        .then(() =>
          dispatch(
            actions.deleteDimensionInstance({
              dimensionInstance: dimInstance,
              dimension: dim,
            })
          )
        );
    }

    // Un-persisted DimensionInstance, just remove from redux state
    return Promise.resolve(
      dispatch(
        actions.deleteUnsavedDimensionInstance({
          dimensionInstance: dimInstance,
          dimension: dim,
        })
      )
    );
  };

interface IDimensionReorderParams {
  from: number;
  to: number;
}
export const reorderDimension =
  (
    modelInstanceId: number,
    { from, to }: IDimensionReorderParams
  ): ThunkResult<Promise<any>> =>
  (dispatch, getState) => {
    const newState = Array.from(getState().dimensions);
    newState.splice(to, 0, newState.splice(from, 1)[0]);

    // Any unsaved dimensions we skip here and will just put onto the end
    // end of the state array in the reducer
    const orderedDimensionIds = newState.reduce(
      (acc: number[], d: Dimension) => {
        return d.id ? acc.concat(d.id) : acc;
      },
      []
    );

    return api
      .post(`/instances/${modelInstanceId}/dimensions/reorder`, {
        orderedDimensionIds,
      })
      .then((dimensions: Dimension[]) =>
        dispatch(actions.setDimensions(dimensions))
      );
  };
