/* eslint-disable  react-hooks/exhaustive-deps */
import React, {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
} from "react";
import { Alignment, Button } from "@blueprintjs/core";
import { Select2, ItemRenderer } from "@blueprintjs/select";
import { Model, Client } from "../../../types/models";
import { isEqual, sortBy } from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import api from "../../../services/api";
import { MenuItem2 } from "@blueprintjs/popover2";

interface ISelectModelProps
  extends RouteComponentProps<{
    model: string;
  }> {
  onChange: (model: Model | undefined) => void;
  selectedModel: Model | undefined;
  selectedClientId: Client["id"];
  getDisabledDropdown: (disabledStatus: boolean) => void;
}

const ModelSelect: FunctionComponent<ISelectModelProps> = (
  props: ISelectModelProps
) => {
  const [selectModel, setSelectedModel] = useState<Model | undefined>(
    undefined
  );
  const [dropDownDisable, setDropDownDisable] = useState<boolean>(false);
  const [modelDropdownValues, setModelDropdownValues] = useState<Model[]>([]);

  const params = useCallback(() => {
    const { model: modelIdString } = props.match.params;
    return {
      modelId: parseInt(modelIdString, 10),
    };
  }, [props.match]);

  useEffect(() => {
    if (!isEqual(props.selectedModel, selectModel)) {
      setSelectedModel(props.selectedModel);
    }
  }, [props.selectedModel]);

  useEffect(() => {
    props.getDisabledDropdown(dropDownDisable);
  }, [dropDownDisable]);

  useEffect(() => {
    if (props.selectedClientId) {
      setDropDownDisable(true);
      (async () => {
        try {
          const models = await api.get(
            `/clients/${props.selectedClientId}/models`
          );
          setModelDropdownValues(models);
          setDropDownDisable(false);
        } catch (error) {
          console.log(error);
        }
      })();
      setSelectedModel(undefined);
    }
  }, [props.selectedClientId]);

  const renderModel: ItemRenderer<Model> = (
    model,
    { handleClick, modifiers }: any
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem2
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={model.id}
        onClick={handleClick}
        text={model.Name}
      />
    );
  };

  const handleModelChange = (model: Model) => {
    props.onChange(model);
  };

  return (
    <Select2<Model>
      noResults={<MenuItem2 disabled={true} text="No Models" />}
      onItemSelect={handleModelChange}
      items={sortBy(
        modelDropdownValues.filter((model) => model.id !== params().modelId),
        ["Name"]
      )}
      itemRenderer={renderModel}
      filterable={false}
      activeItem={selectModel}
      className="fullwidth"
      popoverProps={{ minimal: true, portalClassName: "fullwidth" }}
    >
      <Button
        alignText={Alignment.LEFT}
        fill={true}
        rightIcon="caret-down"
        loading={dropDownDisable}
        text={selectModel ? `${selectModel.Name}` : "No model selected"}
      />
    </Select2>
  );
};

export default withRouter(ModelSelect);
