import * as React from "react";
import "styled-components/macro";
import classnames from "classnames";
import Space from "styled-space";
import { Flex, Box } from "@rebass/grid";
import {
  Classes,
  HTMLTable,
  Text,
  HTMLSelect,
  Button,
  InputGroup,
} from "@blueprintjs/core";

import InputsTableRow from "./InputsTableRow";

import {
  CalculationBlock,
  Dimension,
  InputCategory,
  Input,
  BlockSource,
} from "../../../types/models";
import { TabNames } from "../../../constants/tabnames";
import { ThunkDispatch } from "../../../types/redux";
import { clearSelectInputRow } from "../../../store/modules/selected_input_row";
import { IconNames } from "@blueprintjs/icons";

const thClasses = classnames(Classes.TEXT_MUTED);

interface IProps {
  blockSources: BlockSource[];
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  inputCategories: InputCategory[];
  inputs: Input[];
  dispatch: ThunkDispatch;
  inputFilter: string;
  handleInputFilterChange: (event: React.FormEvent<HTMLInputElement>) => void;
  validateInput(input: Input, blocks: CalculationBlock[]): object;
}

interface IState {
  tabnameFilter: string;
  categoryIdFilter: string;
  isFilterOpen: boolean;
}

class InputsTable extends React.Component<IProps, IState> {
  public state: IState = {
    tabnameFilter: "",
    categoryIdFilter: "",
    isFilterOpen: false,
  };

  public componentDidMount() {
    this.props.dispatch(clearSelectInputRow());
  }

  public render() {
    const {
      blockSources,
      calculationBlocks,
      dimensions,
      inputCategories,
      validateInput,
      inputFilter,
      handleInputFilterChange,
    } = this.props;

    return (
      <HTMLTable style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th>
              <Box css={{ width: "160px" }}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text className={thClasses}>Input Name</Text>
                  <Button
                    minimal={true}
                    icon={
                      this.state.isFilterOpen
                        ? IconNames.FILTER_OPEN
                        : IconNames.FILTER
                    }
                    onClick={this.handleFilterVisibility}
                    small={true}
                  />
                </Flex>
                {this.state.isFilterOpen && (
                  <Box mt={1}>
                    <InputGroup
                      small={true}
                      type="search"
                      leftIcon={IconNames.SEARCH}
                      placeholder="Filter Inputs"
                      value={inputFilter}
                      onChange={handleInputFilterChange}
                    />
                  </Box>
                )}
              </Box>
            </th>
            <th>
              <Box css={{ width: "160px" }}>
                <Text className={thClasses}>Input Category</Text>
              </Box>
            </th>
            <th>
              <Box>
                <Text className={thClasses}>Tab Name</Text>
              </Box>
            </th>
            <th>
              <Box>
                <Text className={thClasses}>Curve Type</Text>
              </Box>
            </th>
            <th>
              <Box>
                <Text className={thClasses}>Cross Type</Text>
              </Box>
            </th>
            <th>
              <Flex justifyContent="flex-end" flex={1}>
                <Space mx={1}>
                  <HTMLSelect
                    options={this.tabNameOptions()}
                    onChange={this.handleTabnameFilterChange}
                  />
                  <HTMLSelect
                    options={this.inputCategooryFilterOptions()}
                    onChange={this.handleCategoryFilterChange}
                  />
                </Space>
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.filteredInputs().map((input) => (
            <InputsTableRow
              key={input.id}
              calculationBlocks={calculationBlocks}
              dimensions={dimensions}
              input={input}
              inputCategories={inputCategories}
              validate={validateInput}
              blockSources={blockSources}
            />
          ))}
        </tbody>
      </HTMLTable>
    );
  }

  private handleFilterVisibility = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  private filteredInputs = (): Input[] => {
    const { inputs } = this.props;
    const { tabnameFilter, categoryIdFilter } = this.state;

    let filteredInputs = inputs;

    if ("" !== tabnameFilter) {
      filteredInputs = filteredInputs.filter(
        (input: Input) => tabnameFilter === input.Tab_Name
      );
    }

    if ("" !== categoryIdFilter) {
      filteredInputs = filteredInputs.filter(
        (input: Input) => categoryIdFilter === `${input.CategoryID}`
      );
    }

    return filteredInputs;
  };

  private tabNameOptions = () => {
    const defaultOption = {
      label: "All Tab Names",
      value: "",
    };

    const tabnameOptions = TabNames.map((tabname) => ({
      label: tabname,
      value: tabname,
    }));

    return [defaultOption, ...tabnameOptions];
  };

  private handleTabnameFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => this.setState({ tabnameFilter: e.currentTarget.value });

  private inputCategooryFilterOptions = () => {
    const { inputCategories } = this.props;

    const defaultOption = {
      label: "All Categories",
      value: "",
    };

    const categoryOptions = inputCategories.map((category) => ({
      label: category.Name,
      value: `${category.id}`,
    }));

    return [defaultOption, ...categoryOptions];
  };

  private handleCategoryFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => this.setState({ categoryIdFilter: e.currentTarget.value });
}

export default InputsTable;
