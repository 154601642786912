import * as React from "react";
import "styled-components/macro";
import classnames from "classnames";

import { tabBarHeight } from "../../organisms/TabBar";
import {
  Text,
  Classes,
  InputGroup,
  Button,
  Collapse,
  FormGroup,
} from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";

import { Alignment } from "./ui";
import BlockSourceRow from "./BlockSourceRow";

import {
  BlockSource,
  CalculationBlock,
  Dimension,
  XRefCurveSubType,
} from "../../../types/models";

interface IProps {
  blockSources: BlockSource[];
  calculationBlock: CalculationBlock;
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  xrefCurveSubTypes: XRefCurveSubType[];
  disableEditMode?: boolean;
  modelInstanceId: string;
}

interface IState {
  search: string;
  searchCollapsed: boolean;
  isEditingArr: Array<number | undefined>;
}

const INITIAL_VALUE: IState = {
  search: "",
  searchCollapsed: true,
  isEditingArr: [],
};

const rowHeadClasses = classnames(Classes.TEXT_MUTED);
const searchStyle: React.CSSProperties = {
  marginTop: "10px",
};

class BlockSourcesTable extends React.Component<IProps, IState> {
  public state: IState = INITIAL_VALUE;

  public render() {
    const {
      blockSources,
      calculationBlock,
      calculationBlocks,
      dimensions,
      xrefCurveSubTypes,
      disableEditMode,
      modelInstanceId,
    } = this.props;

    const filteredBlockSources = blockSources.filter(
      (blockSource: BlockSource) =>
        this.compareValues(blockSource.sourceCode) ||
        this.compareValues(blockSource.sourceName) ||
        this.compareValues(blockSource.Type)
    );

    return (
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Text className={classnames(Classes.TEXT_LARGE, Classes.TEXT_MUTED)}>
            Block Sources
          </Text>
          <Button
            active={!this.state.searchCollapsed}
            minimal={true}
            icon="search"
            onClick={() =>
              this.setState((prevState) => ({
                searchCollapsed: !prevState.searchCollapsed,
              }))
            }
          />
        </Flex>
        <Collapse isOpen={!this.state.searchCollapsed}>
          <FormGroup
            className={Classes.TEXT_MUTED}
            inline={true}
            label="Find block sources:"
            style={searchStyle}
          >
            <InputGroup
              type="search"
              round={true}
              value={this.state.search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value.trim().length === 0
                  ? this.setState({ search: "" })
                  : this.setState({ search: e.target.value })
              }
            />
          </FormGroup>
        </Collapse>
        <Alignment>
          <Box css={{ gridColumn: "start / name-col" }}>
            <Text className={rowHeadClasses}>ID</Text>
          </Box>
          <Box css={{ gridColumn: "name-col / type-col" }}>
            <Text className={rowHeadClasses}>Name</Text>
          </Box>
          <Box css={{ gridColumn: "type-col / end" }}>
            <Text className={rowHeadClasses}>Type</Text>
          </Box>
        </Alignment>
        <Flex
          flexDirection="column"
          css={{
            overflowY: "scroll",
            height: "100%",
            maxHeight: `calc(100vh - ${
              this.state.searchCollapsed ? tabBarHeight : "400px"
            })`,
          }}
        >
          {filteredBlockSources.length ? (
            blockSources
              .filter(
                (blockSource: BlockSource) =>
                  this.compareValues(blockSource.sourceCode) ||
                  this.compareValues(blockSource.sourceName) ||
                  this.compareValues(blockSource.Type)
              )
              .map((blockSource: BlockSource) => (
                <BlockSourceRow
                  key={blockSource.id}
                  blockSource={blockSource}
                  calculationBlock={calculationBlock}
                  calculationBlocks={calculationBlocks}
                  dimensions={dimensions}
                  xrefCurveSubTypes={xrefCurveSubTypes}
                  disableEditMode={disableEditMode || false}
                  modelInstanceId={modelInstanceId}
                  addRemoveToEdit={this.addRemoveToEdit}
                  isEditingArr={this.state.isEditingArr}
                />
              ))
          ) : (
            <Flex py={30} justifyContent="center">
              No Result Found
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }

  private addRemoveToEdit = (value: number | undefined) =>
    this.setState((prev) => {
      if (!this.state.isEditingArr.includes(value)) {
        this.setState((prevState) => ({
          isEditingArr: [...prevState.isEditingArr, value],
        }));
      } else {
        this.setState((prevState) => ({
          isEditingArr: [
            ...prevState.isEditingArr.filter((val) => val !== value),
          ],
        }));
      }
    });

  private compareValues = (value: string | number | undefined) =>
    String(value).toLocaleLowerCase().includes(this.state.search.toLowerCase());
}

export default BlockSourcesTable;
