import React, { Fragment, FunctionComponent, FormEvent, useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Intent, InputGroup } from "@blueprintjs/core";
import { ModelInstance } from "../../../types/models";
import ClientSelect from "../../molecules/DropDown/ClientSelect";
import ModelSelect from "../../molecules/DropDown/ModelSelect";
import { Client } from "../../../types/models";
import { Model } from "../../../types/models";
import { RootState } from "../../../store/reducer";

export interface Fields {
  Model?: Model;
  Name: string;
}
export type Errors = { [P in keyof Fields]?: string };

export interface Form {
  fields: Fields;
  errors: Errors;
  disabled: boolean;
}

interface Props extends Form {
  instance: ModelInstance;
  onChange(changes: Partial<Fields>): void;
}

const TransferModelInstanceForm: FunctionComponent<Props> = ({
  instance,
  fields,
  errors,
  disabled,
  onChange,
}) => {
  const [client, getSelectedClient] = useState<Client | undefined>(undefined);
  const [model, getSelectedModel] = useState<Model | undefined>(undefined);
  const [disableClient, setDisableClient] = useState<boolean>(false);

  const selectClient = (client: Client | undefined) => {
    getSelectedClient(client);
    onChange({ Model: undefined });
  };

  const selectModel = (model: Model | undefined) => {
    getSelectedModel(model);
    onChange({ Model: model });
  };

  const getDisabledDropdown = (disabledStatus: boolean) => {
    setDisableClient(disabledStatus);
  };

  return (
    <Fragment>
      <FormGroup disabled={disabled} label="Client">
        <ClientSelect
          onChange={selectClient}
          selectedClient={client}
          disabled={disableClient}
        />
      </FormGroup>

      {client && (
        <FormGroup disabled={disabled} label="Model">
          <ModelSelect
            onChange={selectModel}
            selectedModel={model}
            selectedClientId={client.id}
            getDisabledDropdown={getDisabledDropdown}
          />
        </FormGroup>
      )}

      <FormGroup disabled={disabled} label="Base Model Instance Name">
        <InputGroup disabled={true} value={instance.Name} />
      </FormGroup>

      <FormGroup
        disabled={disabled}
        intent={errors.Name ? Intent.DANGER : Intent.NONE}
        helperText={errors.Name}
        label="Transfer Model Instance Name"
        labelInfo="(required)"
      >
        <InputGroup
          required={true}
          disabled={disabled}
          value={fields.Name}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            onChange({ Name: e.currentTarget.value })
          }
        />
      </FormGroup>
    </Fragment>
  );
};
const mapStateToProps = (state: RootState) => ({ models: state.models });

export default connect(mapStateToProps)(TransferModelInstanceForm);
