import React, { FunctionComponent, useState } from "react";

import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

interface Props {
  isOpen: boolean;
  onClose(): void;
  onSave(): void;
}

const UnarchiveModelInstanceDialog: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [busy, setBusy] = useState<boolean>(false);

  const handleSave = async () => {
    setBusy(true);
    try {
      await onSave();
      onClose();
    } finally {
      setBusy(false);
    }
  };

  return (
    <Dialog
      title="Unarchive Model Instance"
      isOpen={isOpen}
      onClose={onClose}
      icon={IconNames.WARNING_SIGN}
    >
      <div className={Classes.DIALOG_BODY}>
        Are you sure you want to unarchive this model instance? You can find it
        later in the "Current" section of model instances.
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={onClose} />
          <Button
            text="Unarchive Model Instance"
            intent={Intent.PRIMARY}
            disabled={busy}
            loading={busy}
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UnarchiveModelInstanceDialog;
