import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

import rootReducer, { RootState } from "./reducer";
import { RootAction } from "./actions";
import thunkMiddleware, { ThunkMiddleware } from "redux-thunk";

const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(
    applyMiddleware(thunkMiddleware as ThunkMiddleware<RootState, RootAction>)
  )
);

export default store;
