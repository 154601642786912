export const TabNames: string[] = [
  "Single Value Assumptions",
  "Uptake & Event Assumptions",
  "Other Assumptions",
  "Weekly Assumptions",
  "Monthly Assumptions",
  "Quarterly Assumptions",
  "Yearly Assumptions",
  "Weekly Data",
  "Monthly Data",
  "Quarterly Data",
  "Yearly Data",
];
