import * as React from "react";
import "styled-components/macro";
import { Flex, Box } from "@rebass/grid";
import { PropsWithChildren } from "react";

export enum Size {
  MAX_WIDTH = "1800px",
}

export const MaxBound: React.FC<any> = (props) => {
  return (
    <Box
      px={4}
      mx={"auto"}
      css={{
        width: "100%",
        maxWidth: `${Size.MAX_WIDTH}`,
      }}
      {...props}
    />
  );
};

export const AppWrapper: React.FC = () => <Flex />;

/**
 * For unidirectional auto layout
 */
export const Row: React.FC<any> = (props) => <Flex {...props} />;

export const Column: React.FC<any> = (props) => (
  <Box {...props} px={2} flex="1 1 auto" />
);

/**
 * For automatically reflowing 2 axis grids layouts
 */

export const AutoGrid: React.FC<PropsWithChildren & { columns: number }> = (
  props
) => (
  <Box
    css={{
      display: "grid",
      width: "100%",
      gridGap: "16px",
      gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
      gridAutoRows: "min-content",
    }}
    {...props}
  />
);
