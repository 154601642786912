import * as React from "react";
import { includes, trim } from "lodash";
import classnames from "classnames";
import { Box, Flex } from "@rebass/grid";
import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Text,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MultiSelect2, ItemRenderer } from "@blueprintjs/select";

import { highlightText } from "../../organisms/ChildSelector/helpers";

import { ThunkDispatch } from "../../../types/redux";
import { deleteInput, saveInput } from "../../../store/modules/inputs";
import { CrossTypes } from "../../../constants/cross_types";

import {
  CalculationBlock,
  Dimension,
  Input,
  InputCategory,
  InputDimensionMapping,
} from "../../../types/models";
import { MenuItem2 } from "@blueprintjs/popover2";

interface IProps {
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  dispatch: ThunkDispatch;
  input: Input;
  inputCategories: InputCategory[];
  blockSourceInputCheck: boolean;
  onClose(): void;
  validate(input: Input, blocks: CalculationBlock[]): object;
}

interface IState {
  editedInput: Input;
  errorMessageCalculationBlocks: string;
  errorMessageCategoryId: string;
  errorMessageName: string;
  selectedBlocks: CalculationBlock[];
  HasActiveNoteInput: boolean;
}

const listStyles = classnames(Classes.LIST, Classes.LIST_UNSTYLED);
const itemStyles = Classes.TEXT_MUTED;

class EditInputModal extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    const { calculationBlocks, input } = this.props;
    const blockIds = input.blockMapping.map((mapping) => mapping.BlockID);
    const selectedBlocks = calculationBlocks.filter((block) =>
      includes(blockIds, block.id)
    );

    this.state = {
      editedInput: input,
      errorMessageCalculationBlocks: "",
      errorMessageCategoryId: "",
      errorMessageName: "",
      selectedBlocks,
      HasActiveNoteInput: input.HasActiveNoteInput,
    };
  }

  public render() {
    const { calculationBlocks, input } = this.props;
    const {
      errorMessageCalculationBlocks,
      errorMessageCategoryId,
      errorMessageName,
      selectedBlocks,
    } = this.state;

    return (
      <Dialog
        className="p-input-creator"
        isOpen={true}
        title="Edit Input"
        onClose={this.handleClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Input Name"
            labelInfo="(required)"
            helperText={errorMessageName}
            intent={errorMessageName ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              defaultValue={input.Name}
              onChange={this.handleNameChange}
              placeholder="Input Name"
              required={true}
            />
          </FormGroup>

          <FormGroup
            label="Input Category"
            labelInfo="(required)"
            helperText={errorMessageCategoryId}
            intent={errorMessageCategoryId ? Intent.DANGER : Intent.NONE}
          >
            <HTMLSelect
              defaultValue={`${input.CategoryID}`}
              options={this.inputCategoryOptions()}
              onChange={this.handleInputCategoryChange}
              fill={true}
              required={true}
            />
          </FormGroup>

          <Checkbox
            checked={this.state.HasActiveNoteInput}
            label="Add Input Note"
            onChange={this.handleInputNoteChange}
          />
          <Checkbox
            checked={this.state.editedInput.DisableInputEdit}
            label="Do not display in Web Calculation Editor"
            onChange={this.handleInputWebCalculationEditorDisplayChange}
          />

          {input.Cross_Type ? (
            <React.Fragment>
              <FormGroup label="Cross Type" labelInfo="(required)">
                <HTMLSelect
                  defaultValue={input.Cross_Type}
                  options={CrossTypes}
                  onChange={this.handleCrossTypeChange}
                  fill={true}
                />
              </FormGroup>

              <FormGroup
                label="Calculation Block Mapping"
                labelInfo="(required)"
                helperText={errorMessageCalculationBlocks}
                intent={
                  errorMessageCalculationBlocks ? Intent.DANGER : Intent.NONE
                }
              >
                <MultiSelect2<CalculationBlock>
                  items={calculationBlocks}
                  itemRenderer={this.renderCalculationBlockItem}
                  itemPredicate={this.filterCalculationBlockItem}
                  onItemSelect={this.handleCalculationBlockSelect}
                  placeholder="Select Calculation blocks..."
                  popoverProps={{ minimal: true }}
                  noResults={<MenuItem2 text="No Results" disabled={true} />}
                  tagRenderer={this.renderCalculationBlockName}
                  tagInputProps={{
                    tagProps: { intent: Intent.NONE, minimal: true },
                    onRemove: this.handleCalculationBlockRemove,
                    rightElement: undefined,
                  }}
                  selectedItems={selectedBlocks}
                />
              </FormGroup>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.inputDimensions().length > 0 && (
                <>
                  <Box mb={2}>
                    <Text>Dimensions</Text>
                  </Box>
                  <ul className={listStyles}>
                    {this.inputDimensions().map((dimension: Dimension) => (
                      <li key={dimension.id} className={itemStyles}>
                        {dimension.Name}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </React.Fragment>
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <Flex justifyContent="space-between">
            <Box>
              <Button intent={Intent.DANGER} onClick={this.handleDelete}>
                Delete
              </Button>
            </Box>

            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Cancel" onClick={this.handleClose} />
              <Button
                text={"Update Input"}
                intent={Intent.PRIMARY}
                onClick={this.handleSave}
              />
            </div>
          </Flex>
        </div>
      </Dialog>
    );
  }

  private inputCategoryOptions = () => {
    const { inputCategories } = this.props;

    const defaultOption = {
      label: "Select category",
      value: "",
    };

    const categoryOptions = inputCategories.map((category) => ({
      label: category.Name,
      value: `${category.id}`,
    }));

    return [defaultOption, ...categoryOptions];
  };

  private inputDimensions = (): Dimension[] => {
    const { dimensions, input } = this.props;

    const dimensionIds = input.dimensionMapping.map(
      (mapping: InputDimensionMapping) => mapping.DimensionID
    );

    return dimensions.filter((dimension: Dimension) =>
      includes(dimensionIds, dimension.id)
    );
  };

  private handleInputNoteChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    this.setState({
      HasActiveNoteInput: e.currentTarget.checked,
    });
  };

  private handleInputWebCalculationEditorDisplayChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    this.setState({
      editedInput: {
        ...this.state.editedInput,
        DisableInputEdit: e.currentTarget.checked,
      },
    });
  };

  private handleClose = (): void => this.props.onClose();

  private handleSave = (): void => {
    const { dispatch, validate, onClose } = this.props;
    const { editedInput, selectedBlocks, HasActiveNoteInput } = this.state;

    const validation = validate(editedInput, selectedBlocks);

    if (Object.keys(validation).length === 0) {
      const blockIds = selectedBlocks.map(
        (block: CalculationBlock) => block.id as number
      );
      const dimensionIds = editedInput.dimensionMapping.map(
        (mapping: InputDimensionMapping) => mapping.DimensionID
      );

      dispatch(
        saveInput(
          { ...editedInput, HasActiveNoteInput },
          blockIds,
          dimensionIds
        )
      )
        .then(() => onClose())
        .catch((error) => {
          console.error(error);
        });
    } else {
      const resetState = {
        errorMessageName: "",
        errorMessageCalculationBlocks: "",
        errorMessageCategoryId: "",
      };

      this.setState({
        ...resetState,
        ...validation,
      });
    }
  };

  private handleDelete = (): void => {
    const { dispatch, input, blockSourceInputCheck } = this.props;
    let confirmedDelete = true;
    if (!blockSourceInputCheck) {
      confirmedDelete = window.confirm(
        "You will no longer be able to transfer assumptions for this Input if deleted"
      );
    }
    if (confirmedDelete) {
      dispatch(deleteInput(input));
    }
  };

  private handleNameChange = (e: any): void =>
    this.setState({
      editedInput: {
        ...this.state.editedInput,
        Name: trim(e.currentTarget.value),
      },
    });

  private handleInputCategoryChange = (e: any): void =>
    this.setState({
      editedInput: {
        ...this.state.editedInput,
        CategoryID: e.currentTarget.value,
      },
    });

  private handleCrossTypeChange = (e: any): void =>
    this.setState({
      editedInput: {
        ...this.state.editedInput,
        Cross_Type: e.currentTarget.value,
      },
    });

  private renderCalculationBlockItem: ItemRenderer<CalculationBlock> = (
    block: CalculationBlock,
    { modifiers, handleClick, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const { selectedBlocks } = this.state;

    return (
      <MenuItem2
        key={block.id}
        active={modifiers.active}
        icon={
          includes(selectedBlocks, block) ? IconNames.TICK : IconNames.BLANK
        }
        onClick={handleClick}
        text={highlightText(block.Name as string, query)}
        shouldDismissPopover={false}
      />
    );
  };

  private renderCalculationBlockName = (block: CalculationBlock): string =>
    block.Name as string;

  private filterCalculationBlockItem = (
    query: string,
    block: CalculationBlock
  ): boolean => {
    if (!block.Name) {
      return false;
    }

    return block.Name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  private handleCalculationBlockSelect = (block: CalculationBlock) => {
    const { selectedBlocks } = this.state;

    if (includes(selectedBlocks, block)) {
      this.deselectCalculationBlock(block);
    } else {
      this.selectCalculationBlock(block);
    }
  };

  private handleCalculationBlockRemove = (
    tag: React.ReactNode,
    index: number
  ): void => this.deselectCalculationBlock(this.state.selectedBlocks[index]);

  private selectCalculationBlock = (block: CalculationBlock): void =>
    this.setState({
      selectedBlocks: [...this.state.selectedBlocks, block],
    });

  private deselectCalculationBlock = (block: CalculationBlock): void =>
    this.setState({
      selectedBlocks: this.state.selectedBlocks.filter(
        (b) => b.id !== block.id
      ),
    });
}

export default EditInputModal;
