import React, { FunctionComponent, useEffect, useReducer } from "react";
import { Button, Intent, Alignment, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box } from "@rebass/grid";
import { range, replace, some, split } from "lodash";
import { Select2 } from "@blueprintjs/select";
import { Classes, MenuItem2, Popover2 } from "@blueprintjs/popover2";

import { XRefDate } from "../../../../types/models";

interface IQuarterSelectorProps
  extends Pick<IQuarterPickerProps, "dates" | "minYear" | "maxYear"> {
  quarter?: number;
  year: number;
  onSelectYear: (quarter: number, year: number) => void;
}

interface IQuarterSelectorState {
  yearState: number;
  quarterState?: number;
}

const QuarterSelector: FunctionComponent<IQuarterSelectorProps> = ({
  quarter,
  year,
  onSelectYear,
  dates,
  minYear,
  maxYear,
}: IQuarterSelectorProps) => {
  const [state, setState] = useReducer(
    (
      state: IQuarterSelectorState,
      newState: Partial<IQuarterSelectorState>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      yearState: year,
      quarterState: quarter,
    }
  );

  useEffect(() => {}, [state]);

  const styles: { [key: string]: React.CSSProperties } = {
    yearNav: {
      display: "flex",
      alignItems: "center",
      margin: "0 1px 5px",
      justifyContent: "center",
    },
    yearButton: {
      flex: "1",
      textAlign: "center",
      margin: "0 2px",
    },
    yearButtonText: {
      verticalAlign: "middle",
      fontWeight: "bold",
    },
    quarterContainer: {
      display: "inline-block",
      padding: "1px",
      boxSizing: "border-box",
    },
    quarterButton: {
      lineHeight: "3em",
      textAlign: "center",
      width: "100%",
      minWidth: "4em",
    },
  };

  const yearsRange: number[] = range(minYear, maxYear + 1);

  const onPrevYear = (): void => {
    setState({ yearState: state.yearState - 1 });
  };

  const onNextYear = (): void => {
    setState({ yearState: state.yearState + 1 });
  };

  const onSelect = (quarter: number): void => {
    setState({ quarterState: quarter });
    onSelectYear(quarter, state.yearState);
  };

  const renderQuarter = (quarterItem: number): JSX.Element => {
    const selected: boolean =
      quarter === quarterItem && year === state.yearState;

    return (
      <Box
        className="quarter-container"
        style={styles.quarterContainer}
        key={quarterItem}
      >
        <Button
          intent={selected ? Intent.PRIMARY : Intent.NONE}
          style={styles.quarterButton}
          onClick={() => onSelect(quarterItem)}
          disabled={
            !some(dates, { Date: `${state.yearState} Q${quarterItem}` })
          }
        >
          {`Q${quarterItem}`}
        </Button>
      </Box>
    );
  };

  const quarters: JSX.Element[] = [];
  for (let i = 1; i <= 4; i++) {
    quarters.push(renderQuarter(i));
  }

  return (
    <Box className="py-3">
      <Box className="quarter-year-nav" style={styles.yearNav}>
        <Button
          small={true}
          onClick={onPrevYear}
          icon={IconNames.CHEVRON_LEFT}
          minimal={true}
          disabled={state.yearState <= minYear}
        />
        <Select2<number>
          activeItem={state.yearState}
          filterable={false}
          items={yearsRange}
          popoverProps={{
            minimal: true,
          }}
          itemRenderer={(item, { handleClick, modifiers }) => {
            return (
              <MenuItem2
                key={item}
                text={item}
                active={modifiers.active}
                disabled={modifiers.disabled}
                onClick={handleClick}
              />
            );
          }}
          onItemSelect={(year, e) => {
            (e as any).stopPropagation();
            setState({ yearState: year });
          }}
        >
          <Button
            rightIcon={
              <Icon icon={IconNames.DOUBLE_CARET_VERTICAL} size={14} />
            }
            style={styles.yearButton}
            fill={true}
          >
            <span style={styles.yearButtonText} className="block-header">
              {state.yearState}
            </span>
          </Button>
        </Select2>
        <Button
          small={true}
          onClick={onNextYear}
          icon={IconNames.CHEVRON_RIGHT}
          minimal={true}
          disabled={state.yearState >= maxYear}
        />
      </Box>
      <Box style={styles.quarters}>{quarters}</Box>
    </Box>
  );
};

interface IQuarterPickerProps {
  minYear: number;
  maxYear: number;
  onChange: (quarter: number | undefined, year: number) => void;
  dates: XRefDate[];
  large?: boolean;
  value?: string;
}

interface IQuarterPickerState {
  selectedQuarter?: number;
  selectedYear: number;
  isPopOverOpen: boolean;
}

const QuarterPicker: FunctionComponent<IQuarterPickerProps> = ({
  minYear,
  maxYear,
  onChange,
  dates,
  value,
  large = true,
}: IQuarterPickerProps) => {
  const [state, setState] = useReducer(
    (state: IQuarterPickerState, newState: Partial<IQuarterPickerState>) => ({
      ...state,
      ...newState,
    }),
    {
      selectedQuarter: value
        ? Number(replace(split(value, " ")[1], /[^0-9]/g, ""))
        : undefined,
      selectedYear: value ? Number(split(value, " ")[0]) : minYear,
      isPopOverOpen: false,
    }
  );

  useEffect(() => {
    if (value) {
      setState({
        selectedQuarter: Number(replace(split(value, " ")[1], /[^0-9]/g, "")),
        selectedYear: Number(split(value, " ")[0]),
      });
    }
  }, [value]);

  const onSelectQuarter = (quarter: number, year: number): void => {
    setState({
      selectedQuarter: quarter,
      selectedYear: year,
      isPopOverOpen: false,
    });
  };

  const handleClose = (): void => {
    onChange(state.selectedQuarter, state.selectedYear);
  };

  return (
    <Popover2
      hasBackdrop={true}
      placement="bottom-start"
      fill={true}
      onClose={handleClose}
      isOpen={state.isPopOverOpen}
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      content={
        <Box className="container-fluid">
          <QuarterSelector
            quarter={state.selectedQuarter}
            year={state.selectedYear}
            onSelectYear={onSelectQuarter}
            dates={dates}
            minYear={minYear}
            maxYear={maxYear}
          />
        </Box>
      }
    >
      <Button
        fill={true}
        alignText={Alignment.LEFT}
        text={
          value === undefined
            ? "Select a date"
            : `Q${state.selectedQuarter}-${state.selectedYear}`
        }
        rightIcon={IconNames.CALENDAR}
        large={large}
        onClick={() => setState({ isPopOverOpen: true })}
      />
    </Popover2>
  );
};

export default QuarterPicker;
