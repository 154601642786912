import * as React from "react";
import { Box } from "@rebass/grid";
import { Checkbox, Text } from "@blueprintjs/core";

import { Dimension, OutputDimensionMapping } from "../../../../types/models";

interface IProps {
  dimension: Dimension;
  dimensionMapping: OutputDimensionMapping;
  isLowestDimension: boolean;
  areDimensionsColumnsVisible: boolean;
  onChange(dimensionMapping: OutputDimensionMapping): void;
}

class DimensionRow extends React.Component<IProps> {
  public render() {
    const {
      dimension,
      dimensionMapping,
      isLowestDimension,
      areDimensionsColumnsVisible,
    } = this.props;

    return (
      <tr>
        <td
          style={
            isLowestDimension && areDimensionsColumnsVisible
              ? { borderBottom: "1px solid black" }
              : {}
          }
        >
          <Box>
            <Text>{dimension.Name}</Text>
          </Box>
        </td>
        <td
          style={
            isLowestDimension && areDimensionsColumnsVisible
              ? { borderBottom: "1px solid black" }
              : {}
          }
        >
          <Box>
            <Checkbox
              defaultChecked={dimensionMapping.Reporting_Flag === 1}
              onChange={this.handleReportingFlagChange}
            />
          </Box>
        </td>
        <td
          style={
            isLowestDimension && areDimensionsColumnsVisible
              ? { borderBottom: "1px solid black" }
              : {}
          }
        >
          <Box>
            <Checkbox
              defaultChecked={dimensionMapping.Aggregation_Flag === 1}
              onChange={this.handleAggregationFlagChange}
              disabled={isLowestDimension}
            />
          </Box>
        </td>
        {areDimensionsColumnsVisible && (
          <>
            <td
              style={
                isLowestDimension && areDimensionsColumnsVisible
                  ? { borderBottom: "1px solid black" }
                  : {}
              }
            >
              <Box>
                <Checkbox
                  defaultChecked={dimensionMapping.Dimension_Total_1 === 1}
                  onChange={this.handleDimensionTotalChangeOne}
                />
              </Box>
            </td>
            <td
              style={
                isLowestDimension && areDimensionsColumnsVisible
                  ? { borderBottom: "1px solid black" }
                  : {}
              }
            >
              <Box>
                <Checkbox
                  defaultChecked={dimensionMapping.Dimension_Total_2 === 1}
                  onChange={this.handleDimensionTotalChangeTwo}
                />
              </Box>
            </td>
            <td
              style={
                isLowestDimension && areDimensionsColumnsVisible
                  ? { borderBottom: "1px solid black" }
                  : {}
              }
            >
              <Box>
                <Checkbox
                  defaultChecked={dimensionMapping.Dimension_Total_3 === 1}
                  onChange={this.handleDimensionTotalChangeThree}
                />
              </Box>
            </td>
          </>
        )}
      </tr>
    );
  }

  private handleReportingFlagChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.onChange({
      ...this.props.dimensionMapping,
      Reporting_Flag: e.currentTarget.checked ? 1 : 0,
    });

  private handleAggregationFlagChange = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.onChange({
      ...this.props.dimensionMapping,
      Aggregation_Flag: e.currentTarget.checked ? 1 : 0,
    });

  private handleDimensionTotalChangeOne = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.onChange({
      ...this.props.dimensionMapping,
      Dimension_Total_1: e.currentTarget.checked ? 1 : 0,
    });

  private handleDimensionTotalChangeTwo = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.onChange({
      ...this.props.dimensionMapping,
      Dimension_Total_2: e.currentTarget.checked ? 1 : 0,
    });

  private handleDimensionTotalChangeThree = (
    e: React.FormEvent<HTMLInputElement>
  ): void =>
    this.props.onChange({
      ...this.props.dimensionMapping,
      Dimension_Total_3: e.currentTarget.checked ? 1 : 0,
    });
}

export default DimensionRow;
