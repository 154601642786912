import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { ModelInstance } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";
import { RootState } from "../reducer";

export type ModelInstanceState = ModelInstance[];

const initialState: ModelInstanceState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setModelInstances): {
      return action.payload;
    }
    case getType(actions.createModelInstance):
      return [...state, action.payload];
    case getType(actions.setModelInstance):
      if (state.length === 0) {
        return [action.payload];
      }
      if (
        state.find(
          (modelInstance) => action.payload.id === modelInstance.id
        ) === undefined
      ) {
        return [...state, action.payload];
      }
      return state.map((modelInstance) =>
        modelInstance.id === action.payload.id ? action.payload : modelInstance
      );
    case getType(actions.transferModelInstance):
      return state;
    default:
      return state;
  }
}

export const actions = {
  setModelInstances: createAction("@instances/setModelInstances")<
    ModelInstance[]
  >(),
  createModelInstance: createAction(
    "@instances/createModelInstance"
  )<ModelInstance>(),
  setModelInstance: createAction(
    "@instances/setModelInstance"
  )<ModelInstance>(),
  transferModelInstance: createAction(
    "@instances/transferModelInstance"
  )<ModelInstance>(),
};

export const getModelInstances =
  (client: number, model: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/clients/${client}/models/${model}/model_instances`)
      .then((modelInstances) =>
        dispatch(actions.setModelInstances(modelInstances))
      );
  };

export const selectors = {
  findById: (state: RootState, id: number): ModelInstance =>
    state.instances.find((instance) => instance.id === id) as ModelInstance,
};

export const getModelInstance =
  (id: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${id}`)
      .then((instance) => dispatch(actions.setModelInstance(instance)));
  };

export const finalizeModelInstance =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/instances/${id}/finalize`, {});
    dispatch(actions.setModelInstance(instance));
  };

export const publishModelInstance =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/instances/${id}/publish`, {});
    dispatch(actions.setModelInstance(instance));
  };

export const createModelInstance =
  (
    client: number,
    model: number,
    modelInstance: Pick<
      ModelInstance,
      | "Name"
      | "Timescale"
      | "Quarterly_Flag"
      | "Yearly_Flag"
      | "Start_DateID"
      | "End_DateID"
    >
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(
      `/clients/${client}/models/${model}/model_instances`,
      modelInstance
    );
    dispatch(actions.createModelInstance(instance));
  };

export const updateModelInstance =
  (
    instanceId: number,
    changes: Partial<ModelInstance>
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/instances/${instanceId}`, changes);
    dispatch(actions.setModelInstance(instance));
  };

export const cloneModelInstance =
  (
    instanceId: number,
    values: Partial<ModelInstance>
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/instances/${instanceId}/clone`, values);
    dispatch(actions.createModelInstance(instance));
  };

export const archiveModelInstance =
  (id: number, archiveFlag: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/instances/${id}/archive`, {
      Archive_Flag: archiveFlag,
    });
    dispatch(actions.setModelInstance(instance));
  };

export const transferModelInstance =
  (
    instanceId: number,
    name: string,
    destinationModelId: number
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/instances/${instanceId}/transfer`, {
      Destination_Model_Id: destinationModelId,
      Name: name,
    });
    dispatch(actions.transferModelInstance(instance));
  };
