import React, { FunctionComponent, useState, useMemo } from "react";
import { Button, Classes, Dialog, InputGroup, Intent } from "@blueprintjs/core";

interface Props {
  isOpen: boolean;
  description: string;
  onClose(): void;
  onSave(): void;
}

const confirmBoxCSS = {
  marginTop: "20px",
};

const DeleteAllDescendents: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onSave,
  description,
}) => {
  const [busy, setBusy] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>("");

  const disabled = useMemo(() => confirmText !== "YES", [confirmText]);

  const handleSave = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!disabled) {
      setBusy(true);
      try {
        await onSave();
        handleClose();
      } finally {
        setBusy(false);
      }
    }
  };

  const handleClose = () => {
    onClose();
    setConfirmText("");
  };

  return (
    <Dialog title="Confirm?" isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={handleSave}>
        <div className={Classes.DIALOG_BODY}>
          {description}
          <InputGroup
            style={confirmBoxCSS}
            id="confirm-text-input"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setConfirmText(e.target.value)
            }
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={handleClose} />
            <Button
              text="Okay"
              intent={Intent.PRIMARY}
              disabled={disabled}
              loading={busy}
              type="submit"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default DeleteAllDescendents;
