import * as React from "react";
import { ItemPredicate } from "@blueprintjs/select";

import { DimensionInstance } from "../../../types/models";
import { sortBy } from "lodash";

export const sortByNameCaseInsensitive = <T extends { Name: string }>(
  arr: T[]
) => {
  return sortBy(arr, [(i) => i.Name.toLowerCase()]);
};

export const filterNode: ItemPredicate<DimensionInstance> = (
  query,
  node,
  index
) => {
  return (
    `${index}. ${node.Name.toLowerCase()} ${node.id}`.indexOf(
      query.toLowerCase()
    ) >= 0
  );
};

export function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}
