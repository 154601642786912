import { createAction, getType } from "typesafe-actions";

import { FunctionParameter, SchemaFunction } from "../../types/models";
import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export interface FunctionsState {
  schema: SchemaFunction[];
  parameters: FunctionParameter[];
}

const FUNCTIONS_URL = `${process.env.REACT_APP_API_HOST}/functions_schema.json`;

const initialState: FunctionsState = { schema: [], parameters: [] };

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setSchema):
      return {
        ...state,
        schema: action.payload,
      };
    default:
      return state;
  }
}

export const actions = {
  setSchema: createAction("@functions/setSchema")<SchemaFunction[]>(),
};

export const getFunctionsSchema =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return fetch(FUNCTIONS_URL)
      .then((resp) => resp.json())
      .then((data) => dispatch(actions.setSchema(data.Functions)));
  };
