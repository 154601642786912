import * as React from "react";
import { createRoot } from "react-dom/client";
import { runWithAdal } from "react-adal";

import App from "./App";
import Auth from "./services/auth";
import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "./style.scss";

let container: HTMLElement | null = null;

document.addEventListener("DOMContentLoaded", () => {
  if (!container) {
    container = document.getElementById("root") as HTMLElement;
    const root = createRoot(container);
    runWithAdal(
      Auth,
      () => {
        return root.render(<App />);
      },
      false
    );
  }
});
