import { Position, Toaster } from "@blueprintjs/core";

const AppToaster = Toaster.create(
  {
    className: "toaster",
    position: Position.TOP_RIGHT,
  },
  document.getElementById("bp4-portal") as HTMLElement
);

export default AppToaster;
