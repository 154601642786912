/**
 * Client state
 */
import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { Client, Model } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export interface ClientMap {
  [id: number]: Client;
}

const initialState: ClientMap = {};

// Reducer
export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setClients):
      return action.payload;
    case getType(actions.addClient):
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}

// Actions
export const actions = {
  setClients: createAction("@client/setClients")<ClientMap>(),
  addClient: createAction("@client/addClient")<Client>(),
  addModel: createAction("@client/addModel")<Model>(),
};

// Action Creators
export const getClients = (): ThunkResult<Promise<any>> => (dispatch) => {
  return api.get("/clients").then((data) =>
    dispatch(
      actions.setClients(
        data.reduce((prev: ClientMap, curr: Client) => {
          prev[curr.id] = curr;
          return prev;
        }, {})
      )
    )
  );
};

export const getClient =
  (id: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api.get(`/clients/${id}`).then((client) => {
      dispatch(actions.addClient(client));
    });
  };

export const createClient =
  (Name: string): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post("/clients", { Name })
      .then((data) => dispatch(actions.addClient({ Name, ...data })));
  };

export const createModel =
  (ClientID: number, Name: string): ThunkResult<Promise<any>> =>
  (dispatch) => {
    const newModel = {
      Name,
      ClientID,
    };
    return api.post("/models", newModel).then((data) => {
      dispatch(actions.addModel({ ...newModel, ...data }));
      return data;
    });
  };
