/**
 * profile state
 */
import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { Profile } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type ProfileState = Profile;

const initialState: any = null;

// Reducer
export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setProfile):
      return action.payload;
    default:
      return state;
  }
}

// Actions
export const actions = {
  setProfile: createAction("@profile/setProfile")<Profile>(),
};

// Action Creators
export const getProfile = (): ThunkResult<void> => async (dispatch) => {
  const profile = await api.get("/profile");
  dispatch(actions.setProfile(profile));
};
