/**
 * currentUser state
 */
import { createAction, getType } from "typesafe-actions";

import auth from "../../services/auth";

import { ADUser } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type UserState = ADUser;

const initialState: any = null;

// Reducer
export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setCurrentUser):
      return action.payload;
    default:
      return state;
  }
}

// Actions
export const actions = {
  setCurrentUser: createAction("@user/setCurrentUser")<ADUser>(),
};

// Action Creators
export const getCurrentUser = (): ThunkResult<void> => (dispatch) => {
  return new Promise<void>((resolve, reject) => {
    auth.getUser((error, user) => {
      if (error || !user) {
        return reject(error);
      }

      dispatch(actions.setCurrentUser(user));
      resolve();
    });
  });
};
