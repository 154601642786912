import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ThunkDispatch } from "../../../types/redux";
import {
  Dimension,
  DimensionInstance as DimensionInstanceModel,
} from "../../../types/models";
import { Flex } from "@rebass/grid";
import {
  Button,
  ButtonGroup,
  InputGroup,
  Intent,
  Text,
  Classes,
} from "@blueprintjs/core";
import { trim } from "ramda";

import {
  deleteDimensionInstance,
  saveDimensionInstance,
} from "../../../store/modules/dimensions";
import { DEFAULT_DIMENSION_INSTANCE_NAME } from "./DimensionColumn";

interface IProps {
  dispatch: ThunkDispatch;
  dimension: Dimension;
  dimensionInstance: DimensionInstanceModel;
  validateName: (name: string, i: DimensionInstanceModel) => boolean;
  isHighlighted: boolean;
  dimensionInstanceSidebar?: (
    dimensionInstance: DimensionInstanceModel,
    dimension: Dimension
  ) => void;
  onDeleteInstanceMappings?: (dimensionInstanceId: number) => void;
}

export class DimensionInstance extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      errorMsg: "",
      instanceName: this.props.dimensionInstance.Name,
      isEditing:
        this.props.dimensionInstance.Name === DEFAULT_DIMENSION_INSTANCE_NAME,
      isDeleting: false,
      isSaving: false,
      value: this.props.dimensionInstance.Name,
    };
  }

  public componentDidUpdate() {
    // When this component is rendered in a list that is being re-ordered,
    // the props may update without changing the state. This can cause an
    // instance of DimenstionInstance to be re-rendered with the special
    // "Instance Name" name, but present as uneditable because the internal
    // state is never updated. Here we update the internal state.
    // Example:
    // 1. Create a dimension instance named "aaaa";
    // 2. Create two new dimension instances.
    // 3. Name the first created dimension instance (it's in the middle of
    // the list) "llll".
    // 4. The list is re-ordered, and the new middle instance should be
    // editable.
    if (
      this.props.dimensionInstance.Name === DEFAULT_DIMENSION_INSTANCE_NAME &&
      !this.state.isEditing
    ) {
      this.setState({ instanceName: "", isEditing: true });
    }
  }

  public render() {
    return (
      <StyledButton
        style={this.getInstanceStateStyles()}
        onDoubleClick={() => this.setState({ isEditing: true })}
        onClick={this.state.isEditing ? undefined : this.handleClick}
      >
        {this.state.isEditing ? (
          <this.InstanceEditing />
        ) : (
          this.props.dimensionInstance.Name
        )}
      </StyledButton>
    );
  }

  private getInstanceStateStyles = () => {
    let styles = { opacity: 1 };

    if (this.props.isHighlighted) {
      styles = Object.assign(styles, {
        opacity: 1,
        boxShadow:
          "0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 3px #48aff0",
      });
    }

    return styles;
  };

  private handleNameChange = (event: any) => {
    this.setState({ instanceName: trim(event.target.value) });
  };

  private handleEdit = (event: any) => {
    event.preventDefault();

    const { dimension, dimensionInstance } = this.props;

    if (
      this.props.validateName(
        this.state.instanceName,
        this.props.dimensionInstance
      )
    ) {
      const newDimInstance = {
        ...dimensionInstance,
        dimension,
        Name: this.state.instanceName,
      };

      this.setState({ isSaving: true });
      this.props
        .dispatch(saveDimensionInstance(newDimInstance, dimension))
        .then(() => this.setState({ isEditing: false, isSaving: false }))
        .catch(() => this.setState({ isSaving: false }));
    } else {
      this.setState({ errorMsg: "An Instance already exists with this name." });
    }
  };

  private handleClick = () => {
    const { dimension, dimensionInstance, dimensionInstanceSidebar } =
      this.props;
    if (dimensionInstanceSidebar) {
      dimensionInstanceSidebar(dimensionInstance, dimension);
    }
  };

  private handleInstanceDelete = () => {
    this.setState({ isDeleting: true });
    this.props
      .dispatch(
        deleteDimensionInstance(
          this.props.dimensionInstance,
          this.props.dimension
        )
      )
      .catch(() => this.setState({ isDeleting: false }));
    const { onDeleteInstanceMappings } = this.props;
    if (onDeleteInstanceMappings !== undefined) {
      onDeleteInstanceMappings(this.props.dimensionInstance.id as number);
    }
  };

  private InstanceEditing: React.FC = () => {
    return (
      <form onSubmit={this.handleEdit} style={{ width: "100%" }}>
        <Flex>
          <InputGroup
            type="text"
            onChange={this.handleNameChange}
            placeholder={"Instance Name"}
            required={true}
            autoFocus={true}
            fill
            defaultValue={`${
              this.props.dimensionInstance.Name !==
              DEFAULT_DIMENSION_INSTANCE_NAME
                ? this.props.dimensionInstance.Name
                : ""
            }`}
          />
          <ButtonGroup minimal={true}>
            <Button icon={"tick"} type="submit" loading={this.state.isSaving} />
            <Button
              icon={"trash"}
              loading={this.state.isDeleting}
              onClick={this.handleInstanceDelete}
              intent={Intent.DANGER}
            />
          </ButtonGroup>
        </Flex>
        <Text className={Classes.TEXT_SMALL}>{this.state.errorMsg}</Text>
      </form>
    );
  };
}

const StyledButton = styled.div`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  border-radius: 3px;
  color: #5c7080;
  display: flex;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1),
      0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 3px rgba(72, 175, 240, 0.5);
  }
`;

export default connect()(DimensionInstance);
