import React, { Fragment, FunctionComponent, FormEvent } from "react";

import { FormGroup, Intent, InputGroup } from "@blueprintjs/core";

import { ModelInstance } from "../../../types/models";

export type Fields = Pick<ModelInstance, "Name">;
export type Errors = { [P in keyof Fields]?: string };

export interface Form {
  fields: Fields;
  errors: Errors;
  disabled: boolean;
}

interface Props extends Form {
  instance: ModelInstance;
  onChange(changes: Partial<Fields>): void;
}

const CloneModelInstanceForm: FunctionComponent<Props> = ({
  instance,
  fields,
  errors,
  disabled,
  onChange,
}) => {
  return (
    <Fragment>
      <FormGroup disabled={disabled} label="Base Model Instance">
        <InputGroup disabled={true} value={instance.Name} />
      </FormGroup>

      <FormGroup
        disabled={disabled}
        intent={errors.Name ? Intent.DANGER : Intent.NONE}
        helperText={errors.Name}
        label="Clone Model Instance Name"
        labelInfo="(required)"
      >
        <InputGroup
          required={true}
          disabled={disabled}
          value={fields.Name}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            onChange({ Name: e.currentTarget.value })
          }
        />
      </FormGroup>
    </Fragment>
  );
};

export default CloneModelInstanceForm;
