import * as React from "react";
import "styled-components/macro";
import { Link } from "react-router-dom";
import { Box, Flex } from "@rebass/grid";
import { Classes, Button, Text, Tooltip, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import { get } from "lodash";
import { Dimension, CalculationBlock, Output } from "../../../../types/models";
import { ThunkDispatch } from "../../../../types/redux";
import { clearSelectCalculationRow } from "../../../../store/modules/selected_calculation_row";

const rowHeaderCSS = {
  borderBottom: "1px solid #E1E8ED",
};

const rowHeaderLabelClasses = classnames(
  Classes.TEXT_MUTED,
  Classes.TEXT_SMALL
);
const rowHeaderValueClasses = classnames(Classes.TEXT_LARGE);

interface IProps {
  backHref: string;
  dimension?: Dimension;
  calculationBlock: CalculationBlock;
  output?: Output;
  dispatch: ThunkDispatch;
}

const StatusBar: React.FC<IProps> = ({
  backHref,
  dimension,
  output,
  calculationBlock,
  dispatch,
}) => {
  const blockName = output === undefined ? calculationBlock.Name : output.Name;

  return (
    <Flex alignItems="center" py={3} css={rowHeaderCSS}>
      <Box mr={4}>
        <Link to={backHref}>
          <Button
            icon={IconNames.CHEVRON_LEFT}
            minimal={true}
            onClick={() => dispatch(clearSelectCalculationRow())}
          >
            Back
          </Button>
        </Link>
      </Box>

      <Flex flexDirection="column" mx={4}>
        <Text className={rowHeaderLabelClasses}>Dimension</Text>
        <Text className={rowHeaderValueClasses}>
          {get(dimension, "Name", "N/A")}
        </Text>
      </Flex>

      <Flex flexDirection="column" mx={4}>
        <Text className={rowHeaderLabelClasses}>Block Name</Text>
        <Tooltip content={calculationBlock.Type} position={Position.TOP}>
          <Text className={rowHeaderValueClasses}>{blockName}</Text>
        </Tooltip>
      </Flex>

      <Flex flexDirection="column" mx={4}>
        <Text className={rowHeaderLabelClasses}>Row Name</Text>
        <Text className={rowHeaderValueClasses}>Not Selected</Text>
      </Flex>
    </Flex>
  );
};

export default StatusBar;
