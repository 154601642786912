class RequestError extends Error {
  public message: string;
  public url: string;
  public body: object;
  public response: Response;
  public cause?: Error;

  constructor(
    message: string,
    url: string,
    body: object,
    response: Response,
    cause?: Error
  ) {
    super(message);
    if (cause) {
      this.message = message + "\n" + cause.message;
    } else {
      this.message = message;
    }
    this.response = response;
    this.url = url;
    this.body = body;
    this.cause = cause;
  }
}

export default RequestError;
