import * as React from "react";
import "styled-components/macro";
import classnames from "classnames";
import { Box } from "@rebass/grid";
import { Button, Classes } from "@blueprintjs/core";

class DimensionCreator extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      instances: [],
    };
  }

  public render() {
    const classes = classnames(Classes.MINIMAL, Classes.LARGE);
    return (
      <Box
        p={2}
        css={{
          backgroundColor: "#EBF1F5",
          height: "auto",
        }}
      >
        <Button
          text={"Add Dimension"}
          rightIcon="plus"
          className={classes}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        />
      </Box>
    );
  }
}

export default DimensionCreator;
