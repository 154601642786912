import * as React from "react";
import "styled-components/macro";

import classnames from "classnames";
import { Card, Classes, Colors, Icon, IconSize } from "@blueprintjs/core";

interface IProps {
  title: string;
  onClick?: () => void;
}

const NewEntryCard: React.FC<IProps> = ({ title, onClick }) => (
  <Card
    interactive={true}
    style={{ background: Colors.LIGHT_GRAY4, textAlign: "center" }}
    onClick={onClick}
    className={classnames(Classes.TEXT_MUTED, Classes.TEXT_LARGE)}
  >
    <Icon icon={"plus"} size={IconSize.LARGE} />
    &emsp;{title}
  </Card>
);

export default NewEntryCard;
