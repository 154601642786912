import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "../components/views/Home";
import Clients from "../components/views/Clients";
import Client from "../components/views/Clients/Client";
import Model from "../components/views/Model";
import ModelInstance from "../components/views/ModelInstance";

const AppRouter: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route exact={true} path="/" component={Home} />
      <Route
        path="/clients/:client/models/:model/:filter/instances/:instance"
        component={ModelInstance}
      />
      <Route path="/clients/:client/models/:model/:filter" component={Model} />
      <Route path="/clients/:client" component={Client} />
      <Route path="/clients" component={Clients} />
    </Switch>
  </Router>
);

export default AppRouter;
