import { createAction, getType } from "typesafe-actions";

import { CalculationBlockRow } from "../../types/models";
import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export type SelectedCalculationBlockRowsState = Array<number | undefined>;

const initialState: SelectedCalculationBlockRowsState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.selectedCalculationBlockRow): {
      return [action.payload].concat(state);
    }
    case getType(actions.clearSelectedCalculationBlockRow): {
      return [];
    }
    default:
      return state;
  }
}

export const actions = {
  selectedCalculationBlockRow: createAction(
    "@blockRows/selectedCalculationBlockRow"
  )<CalculationBlockRow["id"]>(),
  clearSelectedCalculationBlockRow: createAction(
    "@blockRows/clearSelectedCalculationBlockRow"
  )(),
};

export const selectCalculationBlockRow =
  (id: CalculationBlockRow["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.selectedCalculationBlockRow(id))
    );
  };

export const clearSelectCalculationBlockRow =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.clearSelectedCalculationBlockRow())
    );
  };
