import React, { FunctionComponent } from "react";

import { format, parseISO } from "date-fns";

interface Props {
  object: { updated_at: string };
}

const LastUpdated: FunctionComponent<Props> = ({ object }) => {
  const updatedAt = parseISO(object.updated_at);

  if (updatedAt) {
    return (
      <>Updated on {format(new Date(updatedAt), "MMM. d, yyyy 'at' hh:mma")}</>
    );
  }
  return null;
};

export default LastUpdated;
