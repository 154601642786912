import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { filter, find, includes, toLower } from "lodash";
import { Flex } from "@rebass/grid";

import { Dimension, Output, CalculationBlock } from "../../../../types/models";
import { ThunkDispatch } from "../../../../types/redux";
import { RootState } from "../../../../store/reducer";
import { getDimensions } from "../../../../store/modules/dimensions";
import {
  getOutputs,
  saveOutput,
  deleteOutput,
} from "../../../../store/modules/outputs";
import {
  CalculationBlockTypes,
  getCalculationBlocks,
  saveCalculationBlock,
} from "../../../../store/modules/calculation_blocks";

import AppViewTemplate from "../../../templates/AppView";
import Loading from "../../../molecules/Loading";
import InputOutputEditorTabs from "../../../organisms/InputOutputEditorTabs";
import TabBar from "../../../organisms/TabBar";
import OutputsTable from "../../../organisms/OutputsTable";
import EditOutputModal from "../../../organisms/EditOutputModal";
import { MaxBound } from "../../../atoms/Layout";
import { getFormatParameters } from "../../../../store/modules/format_parameter";
import FloatingButton from "../../../atoms/FloatingButton";
import { selectOutputRow } from "../../../../store/modules/selected_output_row";

interface IProps extends RouteComponentProps<any> {
  dimensions: Dimension[];
  dispatch: ThunkDispatch;
  outputs: Output[];
  calculationBlocks: CalculationBlock[];
}

interface IState {
  isLoading: boolean;
  isEditing: boolean;
  clientId: number;
  modelId: number;
  modelInstanceId: number;
  editedOutput?: Output;
  outputFilter: string;
  outputsList: Output[];
}

class OutputEditorView extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    isEditing: false,
    clientId: this.props.match.params.client,
    modelId: this.props.match.params.model,
    modelInstanceId: this.props.match.params.instance,
    outputFilter: "",
    outputsList: [],
  };

  public componentDidMount() {
    const { dispatch } = this.props;
    const { modelInstanceId } = this.state;

    Promise.all([
      dispatch(getFormatParameters(modelInstanceId)),
      dispatch(getDimensions(modelInstanceId)),
      dispatch(getOutputs(modelInstanceId)),
      dispatch(getCalculationBlocks(modelInstanceId)),
    ]).then(() =>
      this.setState({ isLoading: false, outputsList: this.props.outputs })
    );
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ) {
    if (prevState.outputFilter !== this.state.outputFilter) {
      this.setState({
        outputsList: filter(this.props.outputs, (output) =>
          includes(toLower(output.Name), toLower(this.state.outputFilter))
        ),
      });
    }
    if (prevProps.outputs !== this.props.outputs) {
      this.setState({
        outputsList: filter(this.props.outputs, (output) =>
          includes(toLower(output.Name), toLower(this.state.outputFilter))
        ),
      });
    }
  }

  public render() {
    const { dimensions, dispatch } = this.props;
    const {
      isLoading,
      isEditing,
      modelInstanceId,
      editedOutput,
      clientId,
      modelId,
      outputsList,
    } = this.state;

    return (
      <React.Fragment>
        <AppViewTemplate
          title="Inputs & Outputs"
          tabs={
            <InputOutputEditorTabs selectedTab="outputs" dispatch={dispatch} />
          }
        >
          <MaxBound mt={3} mb={6}>
            {isLoading ? (
              <Loading />
            ) : (
              <Flex flexDirection="column" width={1} pb={100}>
                <OutputsTable
                  dispatch={dispatch}
                  outputs={outputsList}
                  onOutputEdit={this.handleEdit}
                  clientId={clientId}
                  modelId={modelId}
                  modelInstanceId={modelInstanceId}
                  handleOutputFilterChange={this.handleOutputFilterChange}
                  outputFilter={this.state.outputFilter}
                />
                <FloatingButton
                  text="Define New Output"
                  onClick={this.addOutput}
                />
                {isEditing && (
                  <EditOutputModal
                    isOpen={true}
                    isValid={this.isValidOutput}
                    onCancel={this.handleEditCancel}
                    onSave={this.handleEditSave}
                    onDelete={this.handleDelete}
                    modelInstanceId={modelInstanceId}
                    dimensions={dimensions}
                    output={editedOutput}
                  />
                )}
              </Flex>
            )}
          </MaxBound>
        </AppViewTemplate>
        <TabBar />
      </React.Fragment>
    );
  }

  private addOutput = (): void =>
    this.setState({ editedOutput: undefined, isEditing: true });

  private handleOutputFilterChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({
      outputFilter: event.currentTarget.value,
    });
  };

  private handleEdit = (output: Output): void =>
    this.setState({ editedOutput: output, isEditing: true });

  private handleEditSave = (output: Output): void => {
    /* Automatically create a Dimmension Aggregation Calculation Block &
    a Timescale Aggregation Calculation Block and assign their respective
    ID's to the Output before saving it */
    const { dispatch } = this.props;

    if (output.id) {
      dispatch(saveOutput(output))
        .then(() => this.setState({ isEditing: false }))
        .catch(() => {});
    } else {
      const newDimensionAggregationBlock = {
        ModelInstanceID: this.state.modelInstanceId,
        Type: CalculationBlockTypes.DimensionAggregation,
      };

      const newTimeScaleAggregationBlock = {
        ModelInstanceID: this.state.modelInstanceId,
        Type: CalculationBlockTypes.TimescaleAggregation,
      };

      dispatch(saveCalculationBlock(newDimensionAggregationBlock))
        .then(
          (dimensionAggregation) =>
            (output.Dimension_Aggregation_BlockID = dimensionAggregation.id)
        )
        .then(() =>
          dispatch(saveCalculationBlock(newTimeScaleAggregationBlock))
        )
        .then((timescaleAggregation) => {
          output.Timescale_Aggregation_BlockID = timescaleAggregation.id;
          return dispatch(saveOutput(output));
        })
        .then((r) =>
          dispatch(selectOutputRow(r.payload.output.id)).then(() =>
            this.setState({ isEditing: false })
          )
        )
        .catch(() => {});
    }
  };

  private handleEditCancel = (): void => this.setState({ isEditing: false });

  private handleDelete = (output: Output): void => {
    this.props.dispatch(deleteOutput(output)).then(() => {
      this.setState({
        editedOutput: undefined,
        isEditing: false,
      });
    });
  };

  private isValidOutput = (output: Output): boolean => {
    const { outputs } = this.props;

    if ("" === output.Name) {
      return false;
    }

    return !find(
      outputs,
      (o) => o.id !== output.id && toLower(o.Name) === toLower(output.Name)
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  outputs: state.outputs,
  dimensions: state.dimensions,
  calculationBlocks: state.calculationBlocks,
});

export default connect(mapStateToProps)(withRouter(OutputEditorView));
