import { createAction, getType } from "typesafe-actions";
import { ModelInstanceFilter } from "../../types/models";
import { RootAction } from "../actions";

export type ModelInstanceFilterState = ModelInstanceFilter;

const initialState: ModelInstanceFilterState = "current";

export default function reducer(state = initialState, action: RootAction) {
  if (action.type === getType(actions.setModelInstanceFilter)) {
    return action.payload;
  } else {
    return state;
  }
}

export const actions = {
  setModelInstanceFilter: createAction(
    "@instances/setModelInstanceFilter"
  )<ModelInstanceFilter>(),
};
