import * as React from "react";
import "styled-components/macro";
import { Flex, Box } from "@rebass/grid";

import { Classes, Colors, Icon, H4 } from "@blueprintjs/core";

interface IProps {
  onClick?: () => void;
  text: string;
}

const wrapperCSS = {
  backgroundColor: "#EBF1F5",
  cursor: "pointer",
};

const NewRowButton: React.FunctionComponent<IProps> = ({ onClick, text }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    css={wrapperCSS}
    onClick={onClick}
  >
    <Box py={3} px={2}>
      <H4 className={Classes.TEXT_MUTED} style={{ margin: 0 }}>
        {text}
      </H4>
    </Box>
    <Icon color={Colors.GRAY1} icon="plus" size={20} />
  </Flex>
);

export default NewRowButton;
