import * as React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as R from "ramda";
import { Classes, Colors, Dialog, Divider, Text } from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";
import { Row } from "../../atoms/Layout";
import "styled-components/macro";

import {
  Dimension,
  DimensionInstance,
  StandardCalculationBlock,
  BlockInstanceMapping,
  FirstDimensionGroup,
} from "../../../types/models";
import { ThunkDispatch } from "../../../types/redux";
import { RootState } from "../../../store/reducer";

import DimensionColumn, { DEFAULT_DIMENSION_NAME } from "./DimensionColumn";
import DimensionTabName from "./DimensionTabName";
import Sidebar from "../../organisms/Sidebar";
import InstanceBlockMapping from "../../organisms/InstanceBlockMapping";
import FirstDimensionGroupAssignment from "./FirstDimensionGroupAssignment";
import Loading from "../../molecules/Loading";
import {
  getAllBlockInstanceMappings,
  deleteBlockInstanceMappings,
  BlockInstanceMappingState,
} from "../../../store/modules/block_instance_mapping";
import { saveDimensionInstance } from "../../../store/modules/dimensions";
import { sortBy } from "lodash";

const containerCSS = {
  overflow: "auto",
  height: `calc(100vh - 180px)`,
};

const rowCSS = {
  height: "100%",
  paddingTop: "36px",
};

interface IProps
  extends RouteComponentProps<{
    client: string;
    model: string;
    instance: string;
  }> {
  calculationBlocks: StandardCalculationBlock[];
  blockInstanceMappings: BlockInstanceMappingState;
  dimensions: Dimension[];
  firstDimensionGroups: FirstDimensionGroup[];
  getBlockInstanceMappings: (modelInstanceId: number) => Promise<any>;
  deleteInstanceMappings: (
    modelInstanceId: number,
    dimensionInstanceId: number
  ) => Promise<any>;
  addTabName: (
    selectedInstance: DimensionInstance,
    dimension: Dimension,
    name: string
  ) => Promise<void>;
  updateTabName: (
    selectedInstance: DimensionInstance,
    dimension: Dimension,
    newName: string
  ) => Promise<void>;
  deleteTabName: (
    selectedInstance: DimensionInstance,
    dimension: Dimension
  ) => Promise<void>;
}

interface IState {
  isLoading: boolean;
  isReordering: boolean;
  viewInstanceSidebar: boolean;
  selectedInstanceId?: number;
  sidebarInstanceName: string;
  instanceHasBlocks: boolean;
}

export class FinalizedDimensionsEditor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.isValidDimensionName = this.isValidDimensionName.bind(this);

    this.state = {
      isLoading: true,
      isReordering: false,
      viewInstanceSidebar: false,
      selectedInstanceId: undefined,
      sidebarInstanceName: "",
      instanceHasBlocks: this.props.calculationBlocks.length > 0,
    };
  }

  public componentDidMount() {
    const { getBlockInstanceMappings } = this.props;
    const { instance: modelInstanceId } = this.props.match.params;
    getBlockInstanceMappings(Number(modelInstanceId)).then(() => {
      this.setLoaded();
    });
  }

  public render() {
    const { viewInstanceSidebar, selectedInstanceId } = this.state;
    const {
      calculationBlocks,
      dimensions,
      firstDimensionGroups,
      addTabName,
      updateTabName,
      deleteTabName,
      match,
    } = this.props;
    const sortedDimensions = sortBy(dimensions, ["DimNumber"]);

    const sidebarDimension = dimensions.find((dimension) =>
      dimension.dimension_instances.some(
        (dimInstance) => dimInstance.id === selectedInstanceId
      )
    );

    const selectedInstance =
      sidebarDimension &&
      sidebarDimension.dimension_instances.find(
        (dimInstance) => dimInstance.id === selectedInstanceId
      );

    return (
      <React.Fragment>
        <Flex flex={3} css={containerCSS}>
          <React.Fragment>
            {this.state.isLoading ? (
              <Loading />
            ) : (
              <Row css={rowCSS}>
                {sortedDimensions.map((dimension) => (
                  <DimensionColumn
                    key={`${dimension.id}-${dimension.Name}`}
                    dimension={dimension}
                    validateName={this.isValidDimensionName}
                    isEditingView={true}
                    onViewInstanceSidebar={this.handleviewInstanceSidebar}
                    onDeleteInstanceMappings={this.handleDeleteInstanceMappings}
                    selectedInstance={selectedInstance}
                  />
                ))}
              </Row>
            )}
            <Dialog
              isOpen={this.state.isReordering}
              isCloseButtonShown={false}
              canOutsideClickClose={false}
              canEscapeKeyClose={false}
              className={Classes.OVERLAY_SCROLL_CONTAINER}
              title="Re-ordering Dimensions"
            >
              <div className={Classes.DIALOG_BODY}>Please wait...</div>
            </Dialog>
          </React.Fragment>
        </Flex>
        <Flex flex={1}>
          <Sidebar>
            {viewInstanceSidebar &&
              selectedInstance !== undefined &&
              selectedInstance.id !== undefined &&
              sidebarDimension !== undefined && (
                <React.Fragment key={selectedInstance.id}>
                  <Box
                    p={1}
                    mb={3}
                    bg={Colors.LIGHT_GRAY4}
                    css={{
                      borderRadius: "3px",
                      width: "fit-content",
                    }}
                  >
                    <Text>{sidebarDimension.Name}</Text>
                  </Box>

                  <Box mb={3}>
                    <Text
                      className={classnames(
                        Classes.TEXT_LARGE,
                        Classes.TEXT_MUTED
                      )}
                    >
                      {selectedInstance.Name}
                    </Text>
                  </Box>
                  <Box mb={3}>
                    <Divider />
                  </Box>
                  {sidebarDimension.DimNumber <= 1 && (
                    <DimensionTabName
                      tabName={selectedInstance.Tab_Name}
                      addTabName={(name) =>
                        addTabName(selectedInstance, sidebarDimension, name)
                      }
                      updateTabName={(newName) =>
                        updateTabName(
                          selectedInstance,
                          sidebarDimension,
                          newName
                        )
                      }
                      deleteTabName={() =>
                        deleteTabName(selectedInstance, sidebarDimension)
                      }
                    />
                  )}
                  {sidebarDimension.DimNumber === 0 && (
                    <FirstDimensionGroupAssignment
                      selectedInstance={selectedInstance}
                      dimension={sidebarDimension}
                      firstDimensionGroups={firstDimensionGroups}
                    />
                  )}
                  <InstanceBlockMapping
                    selectedCalculationBlocks={this.setCalculationBlocks(
                      selectedInstance.id
                    )}
                    selectedInstance={selectedInstance}
                    dimension={sidebarDimension}
                    allCalculationBlocks={calculationBlocks}
                    blockInstances={this.getBlockInstanceMappings(
                      selectedInstance.id
                    )}
                    clientId={Number(match.params.client)}
                    modelId={Number(match.params.model)}
                    modelInstanceId={Number(match.params.instance)}
                  />
                </React.Fragment>
              )}
          </Sidebar>
        </Flex>
      </React.Fragment>
    );
  }

  private setLoaded = () => {
    this.setState({ isLoading: false });
  };

  private handleviewInstanceSidebar = (
    dimensionInstance: DimensionInstance
  ) => {
    this.setState({
      selectedInstanceId: dimensionInstance.id,
      viewInstanceSidebar: true,
    });
  };

  private isValidDimensionName = (
    newName: string,
    dimNumber: number
  ): boolean => {
    return R.not(
      R.any(
        (dim) => {
          return (
            dim.DimNumber !== dimNumber &&
            R.toLower(dim.Name || DEFAULT_DIMENSION_NAME) === R.toLower(newName)
          );
        },
        [...this.props.dimensions]
      )
    );
  };

  private setCalculationBlocks = (id: number): StandardCalculationBlock[] => {
    return this.getBlockInstanceMappings(id).map((blockInstance) =>
      this.props.calculationBlocks.find(
        (block) => block.id === blockInstance.BlockID
      )
    ) as StandardCalculationBlock[];
  };

  private getBlockInstanceMappings = (
    instanceId: number
  ): BlockInstanceMapping[] => {
    return this.props.blockInstanceMappings[instanceId] || [];
  };

  private handleDeleteInstanceMappings = (dimensionInstanceId: number) => {
    const { deleteInstanceMappings } = this.props;
    const { instance: modelInstanceId } = this.props.match.params;

    deleteInstanceMappings(Number(modelInstanceId), dimensionInstanceId);

    this.setState({ viewInstanceSidebar: false });
  };
}

const updateTabNameOnInstance = (
  selectedInstance: DimensionInstance,
  dimension: Dimension,
  newTabName: string
) => ({
  ...selectedInstance,
  dimension,
  Tab_Name: newTabName,
});

const mapStateToProps = (state: RootState) => ({
  dimensions: state.dimensions,
  calculationBlocks: state.calculationBlocks,
  blockInstanceMappings: state.blockInstanceMappings,
  firstDimensionGroups: state.firstDimensionGroups,
});

type DispatchProps = Pick<
  IProps,
  | "getBlockInstanceMappings"
  | "deleteInstanceMappings"
  | "addTabName"
  | "updateTabName"
  | "deleteTabName"
>;

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  getBlockInstanceMappings: (modelInstanceId) =>
    dispatch(getAllBlockInstanceMappings(modelInstanceId)),
  deleteInstanceMappings: (modelInstanceId, dimensionInstanceId) =>
    dispatch(
      deleteBlockInstanceMappings(modelInstanceId, [dimensionInstanceId])
    ),
  addTabName: (selectedInstance, dimension, name) =>
    dispatch(
      saveDimensionInstance(
        updateTabNameOnInstance(selectedInstance, dimension, name),
        dimension
      )
    ),
  updateTabName: (selectedInstance, dimension, newName) =>
    dispatch(
      saveDimensionInstance(
        updateTabNameOnInstance(selectedInstance, dimension, newName),
        dimension
      )
    ),
  deleteTabName: (selectedInstance, dimension) =>
    dispatch(
      saveDimensionInstance(
        updateTabNameOnInstance(selectedInstance, dimension, ""),
        dimension
      )
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FinalizedDimensionsEditor)
);
