import { AdalConfig } from "react-adal";

const adalConfig: AdalConfig = {
  cacheLocation: "localStorage",
  clientId: process.env.REACT_APP_ADAL_CLIENT_ID!,
  tenant: process.env.REACT_APP_ADAL_TENANT_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/`,
};

export default adalConfig;
