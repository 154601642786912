import React, { FunctionComponent, useState, useMemo } from "react";
import {
  Button,
  Classes,
  Dialog,
  Intent,
  Menu,
  Alignment,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select2 } from "@blueprintjs/select";
import { map, repeat } from "lodash";

import { DimensionInstance } from "../../../types/models";
import { MenuItem2 } from "@blueprintjs/popover2";

interface Props {
  isOpen: boolean;
  dimensions: DimensionInstance[];
  onClose(): void;
  onSave(dimensionInstanceId: React.ReactText): void;
}

const emptyStringForDropdown = repeat("\xa0", 12);
const defaultValue = {
  label: emptyStringForDropdown,
  value: "",
};

const dimensionInstanceMenuCSS = {
  width: "100%",
  marginTop: "20px",
};

const CloneNodeAndAllDescendents: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onSave,
  dimensions,
}) => {
  const [busy, setBusy] = useState<boolean>(false);
  const [selectedDimensionInstance, setSelectedDimensionInstance] = useState<{
    label: string;
    value: React.ReactText;
  }>(defaultValue);

  const handleSave = async () => {
    setBusy(true);
    try {
      await onSave(selectedDimensionInstance.value);
      handleClose();
    } finally {
      setBusy(false);
    }
  };

  const handleClose = () => {
    setSelectedDimensionInstance(defaultValue);
    onClose();
  };

  const dimensionAggregateDropdownOptions = useMemo(() => {
    return map(dimensions, (item) => {
      return {
        label: item.Name,
        value: item.id || "",
      };
    });
  }, [dimensions]);

  return (
    <Dialog
      title="Clone Node and all Descendents"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        Select the Dimension Instance for the new parent node
        <div style={dimensionInstanceMenuCSS}>
          <Select2<{ label: string; value: string | number }>
            className="clone-node-select"
            filterable={false}
            items={dimensionAggregateDropdownOptions}
            noResults={<MenuItem2 disabled={true} text="No results." />}
            itemListRenderer={(itemListProps) => (
              <Menu ulRef={itemListProps.itemsParentRef}>
                {map(itemListProps.items, (item, index) =>
                  itemListProps.renderItem(item, index)
                )}
              </Menu>
            )}
            itemRenderer={(item, { modifiers, handleClick }) => (
              <MenuItem2
                active={modifiers.active}
                key={item.label}
                onClick={handleClick}
                text={item.label}
              />
            )}
            onItemSelect={(item) => setSelectedDimensionInstance(item)}
          >
            <Button
              fill={true}
              alignText={Alignment.LEFT}
              rightIcon={IconNames.CARET_DOWN}
              text={
                selectedDimensionInstance.label || "Please select node to clone"
              }
              title={selectedDimensionInstance.label}
            />
          </Select2>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={handleClose} />
          <Button
            text="Clone"
            intent={Intent.PRIMARY}
            loading={busy}
            onClick={handleSave}
            disabled={!selectedDimensionInstance.value}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CloneNodeAndAllDescendents;
