import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Icon, IconName, Classes, Position, IconSize } from "@blueprintjs/core";
import cx from "classnames";
import styled from "styled-components";
import store from "../../../store";
import { clearSelectCalculationRow } from "../../../store/modules/selected_calculation_row";
import { clearSelectOutputRow } from "../../../store/modules/selected_output_row";
import { clearSelectInputRow } from "../../../store/modules/selected_input_row";
import { clearSelectCalculationBlockRow } from "../../../store/modules/selected_calculation_block_rows";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";

interface IProps extends NavLinkProps {
  icon: IconName;
  className?: string;
  label: string;
}

const TabBarButton: React.FC<IProps> = ({
  icon,
  label,
  className,
  ...props
}: IProps) => (
  <Popover2>
    <Tooltip2 content={label} position={Position.TOP}>
      <NavLink
        {...props}
        onClick={() => {
          Promise.all([
            store.dispatch<any>(clearSelectCalculationRow()),
            store.dispatch<any>(clearSelectOutputRow()),
            store.dispatch<any>(clearSelectInputRow()),
            store.dispatch<any>(clearSelectCalculationBlockRow()),
          ]);
        }}
        activeClassName={cx("active", Classes.INTENT_PRIMARY)}
        className={cx(
          className,
          Classes.BUTTON,
          Classes.MINIMAL,
          Classes.LARGE
        )}
      >
        <Icon size={IconSize.LARGE} icon={icon} />
      </NavLink>
    </Tooltip2>
  </Popover2>
);

export default styled(TabBarButton)`
  width: 40px !important;
  height: 40px !important;
  &.active {
    background: none !important;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2),
      0 2px 6px rgba(16, 22, 26, 0.2) !important;
  }
`;
