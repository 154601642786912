import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Flex, Box } from "@rebass/grid";
import { Card, Dialog, Button, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ThunkDispatch } from "../../types/redux";
import { RootState } from "../../store/reducer";

import { InputCategory, Input } from "../../types/models";
import InputCategoriesTable from "../organisms/InputCategoriesTable";
import Loading from "../molecules/Loading";
import {
  actions,
  getInputCategories,
  saveInputCategory,
  deleteInputCategory,
} from "../../store/modules/input_categories";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  isOpen: boolean;
  onClose: () => void;
  inputCategories: InputCategory[];
  inputs: Input[];
}

interface IState {
  name: string;
  isLoading: boolean;
  isAdding: boolean;
  modelInstanceId: number;
}

class CreateInputCategoryModal extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      name: "",
      isLoading: true,
      isAdding: false,
      modelInstanceId: props.match.params.instance,
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const { modelInstanceId } = this.state;

    dispatch(getInputCategories(modelInstanceId)).then(() =>
      this.setState({ isLoading: false })
    );
  }

  public render() {
    const { inputCategories, inputs } = this.props;
    const { isAdding, isLoading } = this.state;
    return (
      <Dialog
        icon={IconNames.TAG}
        title="Input Categories"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <Flex flexDirection="column">
            {isLoading ? (
              <Loading />
            ) : (
              <Box
                style={{ textAlign: "center" }}
                p={0}
                width={1}
                height={300}
                color="#5c7080"
                bg="#EBF1F5"
                textAlign="center"
              >
                {inputCategories.length > 0 ? (
                  <Card
                    interactive={false}
                    style={{
                      padding: "20px 0px 175px 25px",
                      height: "320px",
                      width: "460px",
                      overflowY: "scroll",
                    }}
                  >
                    <InputCategoriesTable
                      inputCategories={inputCategories}
                      inputs={inputs}
                      onInputCategorySave={this.handleInputCategorySave}
                      onInputCategoryRemove={this.handleRemoveInputCategory}
                      onInputCategoryCancelAdd={this.handleAddCancel}
                    />
                  </Card>
                ) : (
                  <Card
                    interactive={false}
                    style={{
                      padding: "130px 65px 175px 65px",
                      height: "320px",
                      width: "460px",
                    }}
                  >
                    <p>
                      Start creating Input Categories by clicking the Add New
                      Category button below.
                    </p>
                  </Card>
                )}
              </Box>
            )}
            <Flex my={3}>
              <Button
                style={{
                  margin: "15px 0 15px auto",
                  width: "150px",
                }}
                onClick={this.addInputCategory}
                disabled={isAdding}
              >
                Add New Category
              </Button>
            </Flex>
          </Flex>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent="primary"
              onClick={this.handleOnClose}
              loading={this.state.isLoading}
              disabled={isAdding}
            >
              Done
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  private addInputCategory = () => {
    const newInputCategory = {
      Name: "",
      ModelInstanceID: this.state.modelInstanceId,
    };

    this.setState({ isAdding: true });
    this.props.dispatch(actions.addInputCategory(newInputCategory));
  };

  private handleInputCategorySave = (inputCategory: InputCategory) => {
    return this.props
      .dispatch(saveInputCategory(inputCategory))
      .then(() => this.setState({ isAdding: false }));
  };

  private handleRemoveInputCategory = (inputCategory: InputCategory) => {
    return this.props.dispatch(deleteInputCategory(inputCategory));
  };

  private handleOnClose = () => this.props.onClose();

  private handleAddCancel = (): void => {
    this.setState({ isAdding: false });
    this.props.dispatch(actions.cancelAddInputCategory());
  };
}

const mapStateToProps = (state: RootState) => ({
  inputCategories: state.inputCategories,
});

export default connect(mapStateToProps)(withRouter(CreateInputCategoryModal));
