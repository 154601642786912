import * as React from "react";
import {
  Dialog,
  HTMLSelect,
  Switch,
  Button,
  Classes,
  FormGroup,
  Intent,
} from "@blueprintjs/core";
import { every, reduce } from "lodash";

import { ThunkDispatch } from "../../types/redux";

import { createBlockSource } from "../../store/modules/block_sources";
import { Timescales } from "../../constants/timescales";

import {
  BlockSource,
  CalculationBlock,
  Dimension,
  Input,
  InputDimensionMapping,
  ModelInstance,
  XRefCurveSubType,
} from "../../types/models";

interface IProps {
  blockSources: BlockSource[];
  calculationBlock: CalculationBlock;
  dimensions: Dimension[];
  dispatch: ThunkDispatch;
  inputs: Input[];
  modelInstance: ModelInstance;
  xrefCurveSubTypes: XRefCurveSubType[];
  onClose: () => void;
  onSubmit: () => void;
}

interface IState {
  errorMessageCurveSubType: string;
  errorMessageInputId: string;
  inputId: string;
  isCurveInput: boolean;
  hasCurveSubType: boolean;
  curveSubTypeId: string;
}

class CreateInputSourceModal extends React.Component<IProps, IState> {
  public state = {
    errorMessageCurveSubType: "",
    errorMessageInputId: "",
    inputId: "",
    isCurveInput: false,
    hasCurveSubType: false,
    curveSubTypeId: "",
  };

  public render() {
    const {
      errorMessageCurveSubType,
      errorMessageInputId,
      inputId,
      isCurveInput,
      hasCurveSubType,
      curveSubTypeId,
    } = this.state;

    return (
      <Dialog isOpen={true} title="New Input Source" onClose={this.handleClose}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Input"
            labelInfo="(required)"
            helperText={errorMessageInputId}
            intent={errorMessageInputId ? Intent.DANGER : Intent.NONE}
          >
            <HTMLSelect
              options={this.inputOptions()}
              onChange={this.handleInputChange}
              value={inputId}
              fill={true}
            />
          </FormGroup>
          {isCurveInput && (
            <React.Fragment>
              <FormGroup>
                <Switch
                  label="Has Curve Sub-Type"
                  onChange={this.toggleSubTypeFlag}
                  checked={hasCurveSubType}
                  disabled={!isCurveInput}
                />
              </FormGroup>

              <FormGroup
                label="Curve Sub-Type"
                labelInfo="(required)"
                helperText={errorMessageCurveSubType}
                intent={errorMessageCurveSubType ? Intent.DANGER : Intent.NONE}
              >
                <HTMLSelect
                  options={this.curveSubTypeOptions(
                    this.findInput(Number(this.state.inputId)) as Input
                  )}
                  onChange={this.handleCurveSubTypeChange}
                  value={curveSubTypeId}
                  disabled={!hasCurveSubType}
                  fill={true}
                />
              </FormGroup>
            </React.Fragment>
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={this.handleClose} />
            <Button
              text="Create Input Source"
              onClick={this.handleSave}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  private inputOptions = () => {
    const { calculationBlock, dimensions, inputs } = this.props;

    const blockDimension = dimensions.find(
      (dimension: Dimension) => dimension.id === calculationBlock.DimensionID
    ) as Dimension;

    const filteredInputs = inputs.filter((input: Input) => {
      const dimensionIds = input.dimensionMapping.map(
        (mapping: InputDimensionMapping) => mapping.DimensionID
      );
      const inputDimensions = dimensionIds.map((id: number) =>
        dimensions.find((dimension: Dimension) => dimension.id === id)
      );
      const dimNumbers = inputDimensions.map(
        (dimension) => (dimension as Dimension).DimNumber
      );

      return every(
        dimNumbers,
        (dimNumber: number) => dimNumber <= blockDimension.DimNumber
      );
    });

    const defaultOption = {
      label: "Select an Input",
      value: "",
    };

    const inputOptions = filteredInputs.map((input) => ({
      label: input.Name,
      value: `${input.id}`,
    }));

    return [defaultOption, ...inputOptions];
  };

  private curveSubTypeOptions = (input: Input) => {
    const { xrefCurveSubTypes, modelInstance } = this.props;

    const defaultOption = {
      label: "Select a type",
      value: "",
    };

    const filteredSubTypes = () => {
      if (input.Curve_Type === "Uptake") {
        if (
          modelInstance.Timescale === "Weekly" ||
          modelInstance.Timescale === "Monthly"
        ) {
          return xrefCurveSubTypes.filter(
            (type) => type.Curve_Sub_TypeID >= 1 && type.Curve_Sub_TypeID <= 4
          );
        }
        return xrefCurveSubTypes.filter(
          (type) => type.Curve_Sub_TypeID >= 11 && type.Curve_Sub_TypeID <= 15
        );
      }
      if (
        modelInstance.Timescale === "Weekly" ||
        modelInstance.Timescale === "Monthly"
      ) {
        return xrefCurveSubTypes.filter(
          (type) => type.Curve_Sub_TypeID >= 1 && type.Curve_Sub_TypeID <= 5
        );
      }
      return xrefCurveSubTypes.filter(
        (type) => type.Curve_Sub_TypeID >= 11 && type.Curve_Sub_TypeID <= 16
      );
    };

    const curveSubTypeOptions = filteredSubTypes().map((type) => ({
      label: type.Curve_Sub_Type,
      value: type.Curve_Sub_TypeID,
    }));

    return [defaultOption, ...curveSubTypeOptions];
  };

  private handleInputChange = (e: any): void => {
    const inputId = e.currentTarget.value;
    const input = this.findInput(parseInt(inputId, 10));

    const newState = {
      inputId,
      isCurveInput: false,
      hasCurveSubType: false,
      curveSubTypeId: "",
    };

    if (input && input.Curve_Type) {
      newState.isCurveInput = true;
    }

    this.setState(newState);
  };

  private toggleSubTypeFlag = (): void =>
    this.setState({ hasCurveSubType: !this.state.hasCurveSubType });

  private handleCurveSubTypeChange = (e: any): void =>
    this.setState({ curveSubTypeId: e.currentTarget.value });

  private validate = (): object => {
    const { curveSubTypeId, inputId, hasCurveSubType } = this.state;

    const validation: Record<string, string> = {};

    if ("" === inputId) {
      validation["errorMessageInputId"] = "Please select an Input";
    }

    if (hasCurveSubType && curveSubTypeId === "") {
      validation["errorMessageCurveSubType"] =
        "Please select an Curve Sub Type";
    }

    return validation;
  };

  private handleSave = (): void => {
    const {
      blockSources,
      calculationBlock,
      modelInstance,
      dispatch,
      onSubmit,
    } = this.props;

    const { inputId, isCurveInput, curveSubTypeId } = this.state;

    const validation = this.validate();

    if (Object.keys(validation).length === 0) {
      const mdaCode = reduce(
        blockSources,
        (max: number, source: BlockSource) => {
          if (source.BlockRowID) {
            return max;
          }
          return max > source.MDA_Source_Code
            ? max
            : source.MDA_Source_Code + 1;
        },
        1
      );

      const input = this.findInput(parseInt(inputId, 10));

      let newInputType = "Input";

      if (input && input.Cross_Flag) {
        newInputType = "Cross Input";
      } else if (isCurveInput) {
        newInputType = curveSubTypeId ? "Curve Subtype Input" : "Curve Input";
      } else if (input && input.Timescale) {
        const modelInstanceTS = Timescales[modelInstance.Timescale];
        const inputTS = Timescales[input.Timescale];

        if (inputTS < modelInstanceTS) {
          newInputType = "Timescale Aggregation Input";
        } else if (inputTS > modelInstanceTS) {
          newInputType = "Timescale Mapping Input";
        }
      }

      const newInputSource: BlockSource = {
        ModelInstanceID: modelInstance.id as number,
        BlockID: calculationBlock.id as number,
        Type: newInputType,
        InputID: parseInt(inputId, 10),
        MDA_Source_Code: mdaCode,
        blockMapping: [],
        dimensionMapping: [],
        curveSubTypeMapping: [],
        Curve_Sub_TypeID: parseInt(curveSubTypeId, 10),
      };

      dispatch(createBlockSource(newInputSource))
        .then(() => onSubmit())
        .catch(() => {});
    } else {
      const resetState = {
        errorMessageInputId: "",
      };

      this.setState({
        ...resetState,
        ...validation,
      });
    }
  };

  private handleClose = (): void => this.props.onClose();

  private findInput = (inputId: number): Input | undefined =>
    this.props.inputs.find((i) => i.id === inputId);
}

export default CreateInputSourceModal;
