import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { orderBy, filter as filterList, isEmpty } from "lodash";
import { ThunkDispatch } from "../../../types/redux";
import { NonIdealState, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import AppViewTemplate from "../../templates/AppView";
import { AutoGrid, MaxBound } from "../../atoms/Layout";
import NewEntryCard from "../../organisms/NewEntryCard";
import EditModelInstanceDialog from "../../modals/EditModelInstanceDialog";
import Loading from "../../molecules/Loading";

import {
  getModelInstances,
  createModelInstance,
} from "../../../store/modules/model_instances";
import { getXRefDates } from "../../../store/modules/xref_dates";
import { isModelMember, isModelOwner, isAdmin } from "../../../models/profile";

import { RootState } from "../../../store/reducer";
import {
  ModelInstance,
  ModelInstanceFilter,
  Profile,
  XRefDate,
} from "../../../types/models";

import ModelInstanceCard from "./ModelInstanceCard";
import { actions } from "../../../store/modules/model_instances_filter";
import store from "../../../store";
import { getProfile } from "../../../store/modules/profile";

interface IProps
  extends RouteComponentProps<{
    client: string;
    model: string;
    filter: ModelInstanceFilter;
  }> {
  dispatch: ThunkDispatch;
  profile: Profile;
  instances: ModelInstance[];
  xrefDates: XRefDate[];
}

interface IState {
  isLoading: boolean;
  showCreateModalInstance: boolean;
  filter: ModelInstanceFilter;
}

class ModelView extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      showCreateModalInstance: false,
      filter: "current",
    };
  }

  get params() {
    const {
      client: clientIdString,
      model: modelIdString,
      filter: modelInstanceFilterString,
    } = this.props.match.params;
    return {
      clientId: parseInt(clientIdString, 10),
      modelId: parseInt(modelIdString, 10),
      modelInstanceFilter: modelInstanceFilterString,
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const { modelInstanceFilter } = this.params;

    this.setState({
      filter: modelInstanceFilter,
    });

    Promise.all([
      store.dispatch<any>(getProfile()),
      dispatch(getModelInstances(this.params.clientId, this.params.modelId)),
      dispatch(getXRefDates(this.params.clientId)),
    ]).then(() => this.setState({ isLoading: false }));
  }

  public render() {
    const { clientId, modelId } = this.params;
    const { dispatch, instances, profile, xrefDates } = this.props;
    const { filter } = this.state;

    const currentInstances: ModelInstance[] = orderBy(
      filterList(instances, ["Archive_Flag", 0]),
      ["updated_at"],
      ["desc"]
    );

    const archivedInstances: ModelInstance[] = orderBy(
      filterList(instances, ["Archive_Flag", 1]),
      ["updated_at"],
      ["desc"]
    );

    const instanceList: Array<{
      id: ModelInstanceFilter;
      title: string;
      data: ModelInstance[];
    }> = [
      {
        id: "current",
        title: "Current",
        data: currentInstances,
      },
      {
        id: "archive",
        title: "Archive",
        data: archivedInstances,
      },
    ];

    const isOwner = isModelOwner(profile, modelId);
    const isMember = isModelMember(profile, modelId);
    const isAdminUser = isAdmin(profile);

    return (
      <AppViewTemplate title="Model Instances">
        <MaxBound mt={3}>
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <>
              {!isEmpty(instances) ? (
                <Tabs
                  id="modelInstance-filter"
                  onChange={this.handleFilterChange}
                  selectedTabId={filter || "current"}
                >
                  {instanceList.map((item) => (
                    <Tab
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      panel={
                        <>
                          {!isEmpty(item.data) ? (
                            <AutoGrid columns={4}>
                              {item.data.map((instance) => (
                                <ModelInstanceCard
                                  key={instance.id}
                                  clientId={clientId}
                                  modelId={modelId}
                                  instance={instance}
                                  instances={currentInstances}
                                  xrefDates={xrefDates}
                                  isOwner={isOwner}
                                  isMember={isMember}
                                  isAdmin={isAdminUser}
                                  modelInstanceFilter={item.id}
                                />
                              ))}
                            </AutoGrid>
                          ) : (
                            <NonIdealState
                              title="No Archived Model Instances"
                              icon={IconNames.ISSUE}
                            />
                          )}
                        </>
                      }
                    />
                  ))}
                </Tabs>
              ) : (
                <>
                  {!isMember ? (
                    <NonIdealState
                      title="No Model Instances"
                      icon={IconNames.INFO_SIGN}
                      description="To create a new model instance you must be a model member"
                    />
                  ) : (
                    <>
                      {currentInstances.length === 0 && isMember && (
                        <AutoGrid columns={4}>
                          <NewEntryCard
                            title="Add New Model Instance"
                            onClick={this.toggleModal}
                          />
                        </AutoGrid>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          <EditModelInstanceDialog
            isOpen={this.state.showCreateModalInstance}
            instances={instances}
            xrefDates={xrefDates}
            onClose={this.toggleModal}
            onSave={(fields) =>
              dispatch(createModelInstance(clientId, modelId, fields))
            }
          />
        </MaxBound>
      </AppViewTemplate>
    );
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevState.filter !== this.state.filter) {
      const { clientId, modelId } = this.params;
      this.props.dispatch(
        actions.setModelInstanceFilter(this.state.filter || "current")
      );
      this.props.history.push(
        `/clients/${clientId}/models/${modelId}/${this.state.filter}`
      );
    }
  }

  private toggleModal = () => {
    this.setState({
      showCreateModalInstance: !this.state.showCreateModalInstance,
    });
  };

  private handleFilterChange = (filter: ModelInstanceFilter) => {
    this.setState({ filter });
  };
}

const mapStateToProps = (state: RootState) => ({
  profile: state.profile,
  instances: state.instances,
  xrefDates: state.xrefDates,
});

export default connect(mapStateToProps)(withRouter(ModelView));
