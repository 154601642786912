import * as React from "react";

import { Flex } from "@rebass/grid";
import { Classes, Text } from "@blueprintjs/core";
import EditableSelect from "../../molecules/EditableSelect";
import CRUDable from "../../molecules/CRUDable";

import {
  DimensionInstance,
  Dimension,
  FirstDimensionGroup,
} from "../../../types/models";
import { updateFirstDimensionGroup } from "../../../store/modules/dimensions";
import { ThunkDispatch } from "../../../types/redux";
import { connect } from "react-redux";

class CRUDableGroup extends CRUDable<FirstDimensionGroup | null> {}

interface Props {
  dispatch: ThunkDispatch;
  dimension: Dimension;
  selectedInstance: DimensionInstance;
  firstDimensionGroups: FirstDimensionGroup[];
}

const makeOptions = (groups: FirstDimensionGroup[]) =>
  groups.map((group) => ({ label: group.Name, value: group.id.toString() }));

const findGroup = (
  groups: FirstDimensionGroup[],
  id?: number
): FirstDimensionGroup | null => {
  if (id === undefined) {
    return null;
  }
  const group = groups.find((g) => g.id === id);
  if (group === undefined) {
    return null;
  }
  return group;
};

const FirstDimensionGroupAssignment: React.FC<Props> = ({
  selectedInstance,
  dimension,
  dispatch,
  firstDimensionGroups,
}) => (
  <React.Fragment>
    <div style={{ marginBottom: "20px" }}>
      <Text className={Classes.TEXT_LARGE}>Dimension Group:</Text>
    </div>
    <Flex style={{ marginBottom: "20px" }}>
      <CRUDableGroup
        value={findGroup(
          firstDimensionGroups,
          selectedInstance.FirstDimensionGroupID
        )}
        valueRender={(group) => (group as FirstDimensionGroup).Name}
        creationPrompt="Add Dimension Group"
        onCreate={(newGroup) =>
          dispatch(
            updateFirstDimensionGroup(selectedInstance, dimension, newGroup)
          )
        }
        onUpdate={(newGroup) =>
          dispatch(
            updateFirstDimensionGroup(selectedInstance, dimension, newGroup)
          )
        }
        onDelete={() =>
          dispatch(updateFirstDimensionGroup(selectedInstance, dimension, null))
        }
        editor={({ value, handleUpdate, handleCancel }) => (
          <EditableSelect
            value={value ? value.id.toString() : ""}
            options={makeOptions(firstDimensionGroups)}
            placeholder="No group"
            onChange={(newValue) =>
              handleUpdate(
                findGroup(firstDimensionGroups, parseInt(newValue, 10))
              )
            }
            onCancel={handleCancel}
            startEditing={true}
          />
        )}
      />
    </Flex>
  </React.Fragment>
);

export default connect()(FirstDimensionGroupAssignment);
