import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";
import { StandardCalculationBlock } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";
import { RootState } from "../reducer";
import { get } from "lodash";

export type CalculationBlocksState = StandardCalculationBlock[];

export enum CalculationBlockTypes {
  Standard = "Standard",
  DimensionAggregation = "Dimension Aggregation",
  TimescaleAggregation = "Timescale Aggregation",
}

const initialState: CalculationBlocksState = [];

const sortBlocks = (
  a: StandardCalculationBlock,
  b: StandardCalculationBlock
) => {
  if (CalculationBlockTypes.Standard !== a.Type) {
    return -1;
  }
  if (CalculationBlockTypes.Standard !== b.Type) {
    return 1;
  }

  const aName = (a as StandardCalculationBlock).Name.toLowerCase();
  const bName = (b as StandardCalculationBlock).Name.toLowerCase();
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setCalculationBlocks): {
      const calculationBlocks = action.payload.map((cb) => {
        cb.isEditing = false;
        return cb;
      });
      return calculationBlocks.sort(sortBlocks);
    }
    case getType(actions.addCalculationBlock): {
      const calculationBlocks = state.map((cb) => {
        cb.isEditing = false;
        return cb;
      });
      return [...calculationBlocks, action.payload].sort(sortBlocks);
    }
    case getType(actions.editCalculationBlock): {
      return state
        .map((cb) => {
          cb.isEditing = action.payload ? cb.id === action.payload : false;
          return cb;
        })
        .filter((cb) => cb.id);
    }
    case getType(actions.cancelAddCalculationBlock): {
      return state.filter((cb) => cb.id);
    }
    case getType(actions.createCalculationBlock):
      // Previously 'add'ed block is now persisted to the API so remove it and add
      // the newly saved instance
      return state
        .filter((cb) => cb.id)
        .concat([action.payload])
        .sort(sortBlocks);
    case getType(actions.updateCalculationBlock):
      return state
        .map((cb) => {
          return cb.id === action.payload.id ? action.payload : cb;
        })
        .sort(sortBlocks);
    case getType(actions.deleteCalculationBlock):
      return state.filter((cb) => action.payload.id !== cb.id);
    default:
      return state;
  }
}

export const actions = {
  setCalculationBlocks: createAction("@blocks/setCalculationBlocks")<
    StandardCalculationBlock[]
  >(),
  addCalculationBlock: createAction(
    "@blocks/addCalculationBlock"
  )<StandardCalculationBlock>(),
  editCalculationBlock: createAction("@blocks/editCalculationBlock")<
    StandardCalculationBlock["id"]
  >(),
  createCalculationBlock: createAction(
    "@blocks/createCalculationBlock"
  )<StandardCalculationBlock>(),
  updateCalculationBlock: createAction(
    "@blocks/updateCalculationBlock"
  )<StandardCalculationBlock>(),
  cancelAddCalculationBlock: createAction(
    "@blocks/cancelAddCalculationBlock"
  )(),
  deleteCalculationBlock: createAction(
    "@blocks/deleteCalculationBlock"
  )<StandardCalculationBlock>(),
};

export const selectors = {
  findById: (state: RootState, id: number): StandardCalculationBlock =>
    state.calculationBlocks.find(
      (block) => block.id === id
    ) as StandardCalculationBlock,
};

export const getCalculationBlocks =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/blocks`)
      .then((blocks) => dispatch(actions.setCalculationBlocks(blocks)));
  };

export const editCalculationBlock =
  (id: StandardCalculationBlock["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.editCalculationBlock(id as number))
    );
  };

export const cancelAddCalculationBlock =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.cancelAddCalculationBlock())
    );
  };

export const saveCalculationBlock =
  (
    block:
      | StandardCalculationBlock
      | { ModelInstanceID: number; Type: CalculationBlockTypes }
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    // Existing block - update
    if (get(block, "id")) {
      return api
        .post(
          `/instances/${block.ModelInstanceID}/blocks/${get(block, "id")}`,
          block
        )
        .then((updatedBlock) => {
          dispatch(actions.updateCalculationBlock(updatedBlock));
          return updatedBlock;
        });
    } else {
      // New block - create
      return api
        .post(`/instances/${block.ModelInstanceID}/blocks`, block)
        .then((createdBlock) => {
          dispatch(actions.createCalculationBlock(createdBlock));
          return createdBlock;
        });
    }
  };

export const deleteCalculationBlock =
  (block: StandardCalculationBlock): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (!block.id) {
      return Promise.resolve();
    }

    return api
      .delete(`/instances/${block.ModelInstanceID}/blocks/${block.id}`)
      .then(() => dispatch(actions.deleteCalculationBlock(block)));
  };

export const cloneCalculationBlock =
  (block: StandardCalculationBlock, Name: string): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (!block.id) {
      return Promise.resolve();
    }
    return api
      .post(`/instances/${block.ModelInstanceID}/blocks/${block.id}/clone`, {
        Name,
      })
      .then((block) => dispatch(actions.addCalculationBlock(block)));
  };
