import React, { FunctionComponent } from "react";
import { trim, omit, pick } from "lodash";

import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";

import ModelInstanceForm, {
  ModelInstanceErrors,
  ModelInstanceFields,
} from "../organisms/ModelInstanceForm";

import { ModelInstance, XRefDate } from "../../types/models";
import useSetState from "../../hooks/useSetState";

interface Props {
  instance?: ModelInstance;
  instances: ModelInstance[];
  xrefDates: XRefDate[];
  isOpen: boolean;
  onClose(): void;
  onSave(fields: ModelInstanceFields): Promise<void>;
}

function normalize(fields: ModelInstanceFields) {
  return {
    ...fields,
    Name: trim(fields.Name),
    Start_DateID: fields.Start_DateID,
    End_DateID: fields.End_DateID,
  };
}

function validate(
  fields: ModelInstanceFields,
  id: number | undefined,
  instances: ModelInstance[],
  xrefDates: XRefDate[]
) {
  const errors: ModelInstanceErrors = {};
  const dates = xrefDates.filter((date) => date.Timescale === fields.Timescale);

  if (!fields.Name) {
    errors.Name = "Please enter a name";
  }

  if (fields.Name.length > 60) {
    errors.Name = "Name cannot be longer than 60 characters";
  }

  if (
    instances.some(
      (instance) => instance.Name === fields.Name && instance.id !== id
    )
  ) {
    errors.Name = "That name is already taken";
  }

  if (
    !fields.Timescale ||
    !["Yearly", "Quarterly", "Monthly", "Weekly"].includes(fields.Timescale)
  ) {
    errors.Timescale = "Please choose a timescale";
  }

  if (
    typeof fields.Start_DateID !== "number" ||
    dates.every((date) => date.DateID !== fields.Start_DateID)
  ) {
    errors.Start_DateID = "Please choose a start date";
  }

  if (
    typeof fields.End_DateID !== "number" ||
    dates.every((date) => date.DateID !== fields.End_DateID)
  ) {
    errors.End_DateID = "Please choose an end date";
  }
  return errors;
}

interface State {
  fields: Pick<
    ModelInstance,
    | "Name"
    | "Timescale"
    | "Monthly_Flag"
    | "Quarterly_Flag"
    | "Yearly_Flag"
    | "Start_DateID"
    | "End_DateID"
  >;
  errors: ModelInstanceErrors;
  disabled: boolean;
}

const EditModelInstanceDialog: FunctionComponent<Props> = ({
  instance,
  instances,
  xrefDates,
  isOpen,
  onSave,
  onClose,
}) => {
  const INITIAL_STATE: State = {
    fields: instance
      ? pick(
          instance,
          "Name",
          "Timescale",
          "Monthly_Flag",
          "Quarterly_Flag",
          "Yearly_Flag",
          "Start_DateID",
          "End_DateID"
        )
      : {
          Name: "",
          Timescale: "Monthly",
          Monthly_Flag: 0,
          Quarterly_Flag: 0,
          Yearly_Flag: 0,
          Start_DateID: null,
          End_DateID: null,
        },
    errors: {},
    disabled: false,
  };

  const [state, setState] = useSetState<State>(INITIAL_STATE);

  const resetForm = () => setState(INITIAL_STATE);

  const handleSave = async () => {
    const values = normalize(state.fields);

    const errors = validate(
      values,
      instance && instance.id,
      instances,
      xrefDates
    );

    if (Object.keys(errors).length > 0) {
      setState({ errors });
      return;
    }

    try {
      try {
        setState({ disabled: true });
        await onSave(values);
      } finally {
        setState({ disabled: false });
      }

      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      title={
        instance && instance.id
          ? "Edit Model Instance Metadata"
          : "Add New Model Instance"
      }
      isOpen={isOpen}
      onOpening={resetForm}
      onClosed={resetForm}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <ModelInstanceForm
          {...state}
          isDraft={instance ? instance.Draft : true}
          isEditable={instance ? instance.Editable : true}
          xrefDates={xrefDates}
          onChange={(changes) => {
            setState({
              fields: { ...state.fields, ...changes },
              errors: omit(state.errors, Object.keys(changes)),
            });
          }}
          onChangeDateErrorClear={(extraFields) => {
            setState({
              errors: omit(state.errors, Object.keys(extraFields)),
            });
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={onClose} />
          <Button
            text="Save"
            intent={Intent.PRIMARY}
            disabled={state.disabled}
            loading={state.disabled}
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EditModelInstanceDialog;
