import * as React from "react";
import "styled-components/macro";
import api from "../../../../services/api";
import classnames from "classnames";
import { Box, Flex } from "@rebass/grid";
import { Button, Classes, Divider, Intent, Text } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { ThunkDispatch } from "../../../../types/redux";

import EditCrossOutputSource from "../../../modals/EditCrossOutputSource";
import BlockSourceDetails from "./BlockSourceDetails";
import { Alignment } from "../ui";

import {
  BlockSource,
  CalculationBlock,
  Dimension,
} from "../../../../types/models";
import { deleteBlockSource } from "../../../../store/modules/block_sources";

interface IProps {
  blockSource: BlockSource;
  calculationBlock: CalculationBlock;
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  dispatch: ThunkDispatch;
  disableEditMode: boolean;
  modelInstanceId: string;
  isEditingArr: Array<number | undefined>;
  addRemoveToEdit: any;
}

interface IState {
  isEditingInline: boolean;
  isEditingModal: boolean;
  showDetails: boolean;
}

const subtextClasses = classnames(Classes.TEXT_MUTED, "font-subtext");

class OutputSource extends React.Component<IProps, IState> {
  public state: IState = {
    isEditingInline: false,
    isEditingModal: false,
    showDetails: false,
  };

  public render() {
    const {
      blockSource,
      calculationBlock,
      calculationBlocks,
      dimensions,
      dispatch,
      modelInstanceId,
    } = this.props;
    const { isEditingModal, showDetails } = this.state;

    return (
      <React.Fragment>
        <Alignment
          pb={2}
          className={Classes.TEXT_SMALL}
          onClick={this.handleCancel}
        >
          <Box css={{ gridColumn: "start / name-col" }}>
            <Text>{blockSource.sourceCode}</Text>
          </Box>

          <Box css={{ gridColumn: "name-col / type-col" }}>
            <Flex flexDirection="column">
              <Text>{blockSource.sourceName}</Text>

              {this.hasSourceSubType() && (
                <Text className={subtextClasses}>
                  {this.blockSourceSubType()}
                </Text>
              )}
            </Flex>
          </Box>

          <Box css={{ gridColumn: "type-col / control-col" }}>
            <Text>{blockSource.Type}</Text>
          </Box>

          {this.controlsBox()}
        </Alignment>

        {showDetails && (
          <Alignment>
            <BlockSourceDetails
              blockMapping={blockSource.blockMapping}
              calculationBlocks={calculationBlocks}
              dimensions={dimensions}
              dimensionMapping={blockSource.dimensionMapping}
            />
          </Alignment>
        )}

        {isEditingModal && (
          <EditCrossOutputSource
            blockSource={blockSource}
            calculationBlock={calculationBlock}
            calculationBlocks={calculationBlocks}
            dispatch={dispatch}
            onClose={this.handleModalClose}
            modelInstanceId={modelInstanceId}
          />
        )}

        <Divider />
      </React.Fragment>
    );
  }

  private controlsBox = (): JSX.Element => {
    const { isEditingInline, showDetails } = this.state;
    const { disableEditMode, isEditingArr, blockSource } = this.props;

    if (disableEditMode) {
      return (
        <Box css={{ gridColumn: "control-col / end" }}>
          <Button
            icon={showDetails ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
            minimal={true}
            onClick={this.toggleDetails}
          />
        </Box>
      );
    }

    return (
      <Box css={{ gridColumn: "control-col / end" }}>
        {isEditingInline || isEditingArr.includes(blockSource.id) ? (
          <Button
            text="Remove"
            minimal={true}
            className={Classes.TEXT_SMALL}
            intent={Intent.DANGER}
            onClick={this.handleDelete}
          />
        ) : (
          <React.Fragment>
            <Button
              text="Edit"
              minimal={true}
              className={Classes.TEXT_SMALL}
              onClick={this.handleEdit}
            />
            <Button
              icon={showDetails ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
              minimal={true}
              onClick={this.toggleDetails}
            />
          </React.Fragment>
        )}
      </Box>
    );
  };

  private isCrossOutput = (): boolean =>
    Boolean(this.props.blockSource.Cross_Output_Type);

  private handleEdit = (): void => {
    if (this.isCrossOutput()) {
      this.setState({ isEditingModal: true });
    } else {
      this.setState({ isEditingInline: true });
    }
  };

  private handleCancel = (): void => {
    if (this.state.isEditingInline) {
      this.setState({ isEditingInline: false });
    }
  };

  private handleModalClose = (): void =>
    this.setState({ isEditingModal: false });

  private handleDelete = (): void => {
    const { dispatch, blockSource, modelInstanceId } = this.props;

    api
      .get(
        `/instances/${modelInstanceId}/block_source_function_parameters/${blockSource.id}`
      )
      .then((result) => {
        let confirmedDelete = true;
        if (result.length > 0) {
          confirmedDelete = window.confirm(
            "This Block Source is used in one or more Function Parameters. \nAre you sure you want to delete this Block Source?"
          );
        }
        if (confirmedDelete) {
          dispatch(deleteBlockSource(blockSource));
          this.setState({ isEditingInline: false, isEditingModal: false });
        }
      });
  };

  private toggleDetails = (): void =>
    this.setState({ showDetails: !this.state.showDetails });

  private hasSourceSubType = (): boolean =>
    Boolean(this.props.blockSource.Cross_Output_Type);

  private blockSourceSubType = (): string =>
    this.props.blockSource.Cross_Output_Type as string;
}

export default OutputSource;
