import * as React from "react";
import {
  FormGroup,
  Dialog,
  Button,
  Classes,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import { MultiSelect2, ItemRenderer } from "@blueprintjs/select";
import { reduce } from "lodash";

import { ThunkDispatch } from "../../types/redux";

import { highlightText } from "../organisms/ChildSelector/helpers";
import { createBlockSource } from "../../store/modules/block_sources";

import {
  BlockSource,
  CalculationBlock,
  Dimension,
  Output,
} from "../../types/models";
import { CrossTypes } from "../../constants/cross_types";
import { MenuItem2 } from "@blueprintjs/popover2";

const OutputSourceTypes = ["Output", "Cross Output"];

const StandardOutputType = OutputSourceTypes[0];
const CrossOutputType = OutputSourceTypes[1];

const CousinCrossType = CrossTypes[0];

interface IProps {
  blockSources: BlockSource[];
  calculationBlock: CalculationBlock;
  calculationBlocks: CalculationBlock[];
  dispatch: ThunkDispatch;
  dimensions: Dimension[];
  outputs: Output[];
  modelInstanceId: string;
  onCancel: () => void;
  onSubmit: () => void;
  isStandardCalculationBlock: boolean;
}

interface IState {
  dimensionId: string;
  errorMessageOutputId: string;
  errorMessageDimensionId: string;
  errorMessageCalculationBlocks: string;
  outputId: string;
  outputSourceType: string;
  outputCrossType: string;
  selectedBlocks: CalculationBlock[];
}

class CreateOutputSourceModal extends React.Component<IProps, IState> {
  public state: IState = {
    dimensionId: "",
    errorMessageOutputId: "",
    errorMessageDimensionId: "",
    errorMessageCalculationBlocks: "",
    outputId: "",
    outputSourceType: StandardOutputType,
    outputCrossType: "",
    selectedBlocks: [],
  };

  public render() {
    const {
      dimensionId,
      errorMessageOutputId,
      errorMessageDimensionId,
      errorMessageCalculationBlocks,
      outputId,
      outputSourceType,
      outputCrossType,
      selectedBlocks,
    } = this.state;

    const { calculationBlock, isStandardCalculationBlock } = this.props;

    return (
      <Dialog
        isOpen={true}
        title="New Output Source"
        onClose={this.handleCancel}
        className="p-input-creator"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Output Name"
            labelInfo="(required)"
            helperText={errorMessageOutputId}
            intent={errorMessageOutputId ? Intent.DANGER : Intent.NONE}
          >
            <HTMLSelect
              options={this.outputOptions()}
              value={outputId}
              onChange={this.handleOutputSelect}
              fill={true}
            />
          </FormGroup>

          {isStandardCalculationBlock && (
            <React.Fragment>
              <FormGroup label="Output Source Type" labelInfo="(required)">
                <HTMLSelect
                  options={OutputSourceTypes}
                  value={outputSourceType}
                  onChange={this.handleOutputSourceTypeSelect}
                  fill={true}
                />
              </FormGroup>
              <FormGroup label="Cross Output Type">
                <HTMLSelect
                  options={CrossTypes}
                  value={outputCrossType}
                  onChange={this.handleOutputCrossTypeSelect}
                  disabled={outputSourceType !== CrossOutputType}
                  fill={true}
                />
              </FormGroup>
            </React.Fragment>
          )}

          {outputSourceType !== CrossOutputType &&
            calculationBlock.Type !== "Dimension Aggregation" && (
              <FormGroup
                label="Source Dimension"
                labelInfo={
                  calculationBlock.Type === "Standard"
                    ? "(required)"
                    : "(optional)"
                }
                helperText={errorMessageDimensionId}
                intent={errorMessageDimensionId ? Intent.DANGER : Intent.NONE}
              >
                <HTMLSelect
                  options={this.dimensionOptions()}
                  value={dimensionId}
                  onChange={this.handleDimensionSelect}
                  fill={true}
                />
              </FormGroup>
            )}

          {outputSourceType === CrossOutputType && (
            <FormGroup
              label="Source Blocks"
              labelInfo="(required)"
              helperText={errorMessageCalculationBlocks}
              intent={
                errorMessageCalculationBlocks ? Intent.DANGER : Intent.NONE
              }
            >
              <MultiSelect2<CalculationBlock>
                items={this.filteredCalculationBlocks()}
                itemRenderer={this.renderBlock}
                noResults={<MenuItem2 text="No Results" disabled={true} />}
                onItemSelect={this.handleBlockSelect}
                popoverProps={{ minimal: true }}
                tagRenderer={this.renderBlockTag}
                tagInputProps={{
                  tagProps: { intent: Intent.NONE, minimal: true },
                  onRemove: this.handleBlockRemove,
                }}
                selectedItems={selectedBlocks}
                placeholder="Select Calculation Blocks…"
              />
            </FormGroup>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={this.handleCancel} />
            <Button
              text="Create Output Source"
              onClick={this.handleSave}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  private outputOptions = () => {
    const { outputs } = this.props;

    const defaultOption = {
      label: "Select output",
      value: "",
    };

    const options = outputs.map((output: Output) => ({
      label: output.Name,
      value: `${output.id}`,
    }));

    return [defaultOption, ...options];
  };

  private dimensionOptions = () => {
    const { calculationBlock, dimensions, isStandardCalculationBlock } =
      this.props;
    if (isStandardCalculationBlock) {
      const blockDimension = dimensions.find(
        (dimension) => dimension.id === calculationBlock.DimensionID
      ) as Dimension;

      const filteredDimensions = dimensions.filter(
        (dimension) => dimension.DimNumber <= blockDimension.DimNumber
      );

      const typeStandardDefaultOption = {
        label: "Select dimension",
        value: "",
      };

      const typeStandardOptions = filteredDimensions.map((dimension) => ({
        label: dimension.Name,
        value: `${dimension.id}`,
      }));

      return [typeStandardDefaultOption, ...typeStandardOptions];
    }

    const defaultOption = {
      label: "Select dimension",
      value: "",
    };

    const options = dimensions.map((dimension) => ({
      label: dimension.Name,
      value: `${dimension.id}`,
    }));

    return [defaultOption, ...options];
  };

  private filteredCalculationBlocks = (): CalculationBlock[] => {
    const { calculationBlock, calculationBlocks } = this.props;

    const filterDimensionId = calculationBlock.DimensionID as number;

    return calculationBlocks.filter(
      (block) => filterDimensionId === block.DimensionID
    );
  };

  private handleOutputSelect = (e: any): void =>
    this.setState({ outputId: e.currentTarget.value });

  private handleOutputSourceTypeSelect = (e: any): void => {
    const type = e.currentTarget.value;
    const newState = { outputSourceType: type };

    if (type !== CrossOutputType) {
      this.setState({
        ...newState,
        outputCrossType: "",
        selectedBlocks: [],
      });
    } else {
      this.setState({
        ...newState,
        outputCrossType: CousinCrossType,
        dimensionId: "",
      });
    }
  };

  private handleOutputCrossTypeSelect = (e: any): void =>
    this.setState({ outputCrossType: e.currentTarget.value });

  private handleDimensionSelect = (e: any): void =>
    this.setState({ dimensionId: e.currentTarget.value });

  private handleCancel = (): void => this.props.onCancel();

  private handleSave = (): void => {
    const {
      blockSources,
      calculationBlock,
      dispatch,
      onSubmit,
      modelInstanceId,
    } = this.props;
    const {
      dimensionId,
      outputId,
      outputSourceType,
      outputCrossType,
      selectedBlocks,
    } = this.state;

    const validation = this.validate();

    if (Object.keys(validation).length === 0) {
      const mdaCode = reduce(
        blockSources,
        (max: number, source: BlockSource) => {
          if (source.BlockRowID) {
            return max;
          }
          return max > source.MDA_Source_Code
            ? max
            : source.MDA_Source_Code + 1;
        },
        1
      );

      const newOutputSource: BlockSource = {
        ModelInstanceID: parseInt(modelInstanceId, 10),
        BlockID: calculationBlock.id as number,
        Type: outputSourceType,
        OutputID: parseInt(outputId, 10),
        MDA_Source_Code: mdaCode,
        blockMapping: [],
        dimensionMapping: [],
        curveSubTypeMapping: [],
      };

      if (outputSourceType !== CrossOutputType) {
        newOutputSource.DimensionID = parseInt(dimensionId, 10);
      } else {
        newOutputSource.Cross_Output_Type = outputCrossType;
        newOutputSource.blocks = selectedBlocks.map(
          (block: CalculationBlock) => block.id as number
        );
      }

      dispatch(createBlockSource(newOutputSource))
        .then(() => onSubmit())
        .catch(() => {});
    } else {
      const resetState = {
        errorMessageOutputId: "",
        errorMessageDimensionId: "",
        errorMessageCalculationBlocks: "",
      };

      this.setState({
        ...resetState,
        ...validation,
      });
    }
  };

  private validate = (): object => {
    const { dimensionId, outputId, outputSourceType, selectedBlocks } =
      this.state;
    const { isStandardCalculationBlock } = this.props;
    const validation: Record<string, string> = {};

    if ("" === outputId) {
      validation["errorMessageOutputId"] = "Please select an Output";
    }

    if (
      outputSourceType !== CrossOutputType &&
      dimensionId === "" &&
      isStandardCalculationBlock
    ) {
      validation["errorMessageDimensionId"] = "Please select a Dimension";
    }

    if (outputSourceType === CrossOutputType && selectedBlocks.length === 0) {
      validation["errorMessageCalculationBlocks"] =
        "Please select a Calculation Block";
    }

    return validation;
  };

  private renderBlock: ItemRenderer<CalculationBlock> = (
    block,
    { modifiers, handleClick, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = `${block.Name}`;
    return (
      <MenuItem2
        active={modifiers.active}
        icon={
          this.state.selectedBlocks.indexOf(block) !== -1 ? "tick" : "blank"
        }
        key={block.id}
        onClick={handleClick}
        text={highlightText(text, query)}
        shouldDismissPopover={false}
      />
    );
  };

  private renderBlockTag = (block: CalculationBlock) => block.Name;

  private handleBlockSelect = (block: CalculationBlock): void => {
    if (!this.isBlockSelected(block)) {
      this.selectBlock(block);
    } else {
      this.deselectBlock(this.getSelectedBlockIndex(block));
    }
  };

  private handleBlockRemove = (TAG: React.ReactNode, index: number): void =>
    this.deselectBlock(index);

  private onBlockSelect = (blocks: CalculationBlock[]): void =>
    this.setState({ selectedBlocks: blocks });

  private isBlockSelected = (block: CalculationBlock): boolean =>
    this.getSelectedBlockIndex(block) !== -1;

  private getSelectedBlockIndex = (block: CalculationBlock): number =>
    this.state.selectedBlocks.indexOf(block);

  private selectBlock = (block: CalculationBlock): void =>
    this.setState(
      { selectedBlocks: [...this.state.selectedBlocks, block] },
      () => this.onBlockSelect(this.state.selectedBlocks)
    );

  private deselectBlock = (index: number): void =>
    this.setState(
      {
        selectedBlocks: this.state.selectedBlocks.filter(
          (BLOCK, i) => i !== index
        ),
      },
      () => this.onBlockSelect(this.state.selectedBlocks)
    );
}

export default CreateOutputSourceModal;
