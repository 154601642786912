import * as React from "react";
import {
  Button,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  Intent,
  Card,
  ControlGroup,
} from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";
import DividedList from "../../../molecules/DividedList";
import DeleteButton from "../../../atoms/DeleteButton";
import { FirstDimensionGroup } from "../../../../types/models";
import EditableText from "../../../molecules/EditableText";

interface Props {
  groups: FirstDimensionGroup[];
  isOpen: boolean;
  onClose: () => void;
  onAddGroup: (name: string) => Promise<any>;
  onUpdateGroup: (group: FirstDimensionGroup, newName: string) => Promise<any>;
  onDeleteGroup: (group: FirstDimensionGroup) => Promise<any>;
}

type NewGroup = Partial<FirstDimensionGroup>;

interface State {
  newGroup: NewGroup | null;
}

const baseNewGroup: NewGroup = {
  Name: undefined,
};

export default class FirstDimensionGroupsModal extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newGroup: null,
    };
  }

  public render() {
    const {
      groups,
      isOpen,
      onClose,
      onAddGroup,
      onUpdateGroup,
      onDeleteGroup,
    } = this.props;
    const { newGroup } = this.state;

    return (
      <Dialog
        title="First Dimension Grouping"
        icon="inbox"
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Group Instances" labelInfo="(required)">
            <Card style={{ height: "320px", overflow: "auto" }}>
              <DividedList
                items={groups}
                getId={(group) => group.id}
                renderItem={(g) => (
                  <Box pl={3} my={2}>
                    <ControlGroup fill={true}>
                      <EditableText
                        value={g.Name || ""}
                        startEditing={false}
                        onChange={(s) => onUpdateGroup(g, s)}
                        placeholder="Group name"
                      />
                      <DeleteButton onDelete={() => onDeleteGroup(g)} />
                    </ControlGroup>
                  </Box>
                )}
              />
              {groups.length > 0 && newGroup !== null && <Divider />}
              {newGroup !== null && (
                <React.Fragment>
                  <Box pl={3} my={2}>
                    <ControlGroup fill={true}>
                      <EditableText
                        value={newGroup.Name || ""}
                        startEditing={true}
                        onChange={(s) =>
                          onAddGroup(s).then(() =>
                            this.setState({ newGroup: null })
                          )
                        }
                        onCancel={() => this.setState({ newGroup: null })}
                        placeholder="Group name"
                      />
                    </ControlGroup>
                  </Box>
                </React.Fragment>
              )}
            </Card>
            <Flex justifyContent="flex-end" mt={3}>
              <Button
                text="New Group"
                onClick={() => this.setState({ newGroup: baseNewGroup })}
                disabled={newGroup !== null}
              />
            </Flex>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent={Intent.PRIMARY} onClick={onClose}>
              Done
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
