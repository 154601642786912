import * as React from "react";
import "styled-components/macro";
import classnames from "classnames";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { Box } from "@rebass/grid";
import { Classes, Text } from "@blueprintjs/core";
import { trim } from "lodash";

import { ThunkDispatch } from "../../../../types/redux";
import {
  BlockSource,
  CalculationBlock,
  CalculationBlockRow,
  Output,
  XRefFunction,
} from "../../../../types/models";

import { reorderBlockRows } from "../../../../store/modules/calculation_block_rows";

import DraggableBlockRow from "./DraggableBlockRow";
import RowAlign from "../RowAlign";
import { clearCalculationBlockRowTree } from "../../../../store/modules/calculation_block_row_tree";
import { selectCalculationRow } from "../../../../store/modules/selected_calculation_row";

const rowHeaderLabelClasses = classnames(Classes.TEXT_MUTED);

interface IProps {
  calculationBlockRows: CalculationBlockRow[];
  blockSources: BlockSource[];
  dispatch: ThunkDispatch;
  outputs: Output[];
  modelInstanceId: string;
  xrefFunctions: XRefFunction[];
  refetchBlockRows: () => void;
  calculationBlock: CalculationBlock;
  isOutput: boolean;
  selectedOutput?: Output;
}

interface IState {
  loading: boolean;
}

class RowTable extends React.Component<IProps, IState> {
  public state = {
    loading: false,
  };
  public componentDidUpdate(prevProps: IProps) {
    if (this.props.blockSources.length < prevProps.blockSources.length) {
      // A BlockSource was deleted, so refetch the list of block rows in the case
      // that the deleted BlockSource was used in any of our RowTable's formulas
      // and requires a redraw
      this.props.refetchBlockRows();
    }
  }

  public componentDidMount() {
    this.props.dispatch(clearCalculationBlockRowTree());
  }

  public render() {
    const { calculationBlockRows, outputs, modelInstanceId, xrefFunctions } =
      this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <RowAlign py={3} ml="48px">
          <Box css={{ gridColumn: "col-1 / col-2" }}>
            <Text className={rowHeaderLabelClasses}>Output</Text>
          </Box>
          <Box css={{ gridColumn: "col-2 / col-3" }}>
            <Text className={rowHeaderLabelClasses}>Row Name</Text>
          </Box>
          <Box css={{ gridColumn: " col-3 / col-4 " }}>
            <Text className={rowHeaderLabelClasses}>Function</Text>
          </Box>
          <Box css={{ gridColumn: "col-4 / -1 " }}>
            <Text className={rowHeaderLabelClasses}>Calculation Summary</Text>
          </Box>
        </RowAlign>

        <Droppable droppableId="table">
          {(droppable) => (
            <div ref={droppable.innerRef} {...droppable.droppableProps}>
              {calculationBlockRows.map(
                (row: CalculationBlockRow, i: number) => (
                  <DraggableBlockRow
                    key={`row-${row.id}-${row.IsValid}-${i}`}
                    calculationBlockRows={calculationBlockRows}
                    row={row}
                    index={i}
                    modelInstanceId={modelInstanceId}
                    outputs={outputs}
                    onValidate={this.handleValidateBlockRow}
                    xrefFunctions={xrefFunctions}
                    handleLoading={this.handleLoading}
                    loading={this.state.loading}
                    refetchBlockRows={this.props.refetchBlockRows}
                    calculationBlock={this.props.calculationBlock}
                    isOutput={this.props.isOutput}
                    selectedOutput={this.props.selectedOutput as Output}
                  />
                )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  private handleLoading = (loading: boolean) => this.setState({ loading });

  private onDragEnd = (result: DropResult) => {
    // TODO: Add isReordering state to prevent duplicated drag/drop actions while hitting the API
    if (!result.destination) {
      return;
    }

    this.props
      .dispatch(
        reorderBlockRows(this.props.modelInstanceId, {
          from: result.source.index,
          to: result.destination.index,
        })
      )
      .then(() =>
        this.props.dispatch(
          selectCalculationRow(
            this.props.calculationBlockRows[result.destination!.index].id
          )
        )
      );
  };

  private handleValidateBlockRow = (row: CalculationBlockRow): boolean => {
    const isOutput = "Output" === row.Type;

    // If 'Output' type, make sure we have a unique OutputID
    if (isOutput) {
      const duplicateOutputRow = this.props.calculationBlockRows.find(
        (cbr) => row.OutputID === cbr.OutputID
      );
      return !(duplicateOutputRow && row.id !== duplicateOutputRow.id);
    }

    // If 'Intermediate' type, make sure we have a Name
    return "" !== trim(row.Name);
  };
}

export default RowTable;
