import { createAction, getType } from "typesafe-actions";

import { CalculationBlockRow } from "../../types/models";
import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export type SelectedCalculationRowState = Array<number | undefined>;

const initialState: SelectedCalculationRowState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.selectedCalculationRow): {
      return [action.payload].concat(state);
    }
    case getType(actions.clearSelectedCalculationRow): {
      return [];
    }
    default:
      return state;
  }
}

export const actions = {
  selectedCalculationRow: createAction(
    "@calculationRow/selectedCalculationRow"
  )<CalculationBlockRow["id"]>(),
  clearSelectedCalculationRow: createAction(
    "@calculationRow/clearSelectedCalculationRow"
  )(),
};

export const selectCalculationRow =
  (id: CalculationBlockRow["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.selectedCalculationRow(id))
    );
  };

export const clearSelectCalculationRow =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.clearSelectedCalculationRow())
    );
  };
