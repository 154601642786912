import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {
  Dialog,
  FormGroup,
  InputGroup,
  Button,
  Classes,
} from "@blueprintjs/core";

import { createModel, renameModelName } from "../../store/modules/model";
import { ThunkActionDispatch } from "../../types/redux";
import { Model } from "../../types/models";
import { size, trimStart } from "lodash";

interface IProps extends RouteComponentProps<any> {
  model?: Model;
  isOpen: boolean;
  onClose: () => void;
  createModel: ThunkActionDispatch<typeof createModel>;
  renameModelName: ThunkActionDispatch<typeof renameModelName>;
}

interface IState {
  name: string;
  isLoading: boolean;
  error: string;
}

class CreateModelModal extends React.Component<IProps, IState> {
  public state: IState = {
    name: "",
    isLoading: false,
    error: "",
  };

  public componentDidMount() {
    const { model } = this.props;

    if (model) {
      this.setState({ name: model.Name });
    }
  }

  public render() {
    const { model } = this.props;

    return (
      <Dialog
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={model && model.id ? "Edit Model Name" : "Add New Model Test"}
      >
        <form onSubmit={this.handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              helperText={
                this.state.error ||
                `${40 - size(this.state.name)} characters left`
              }
              intent={this.state.error ? "danger" : "none"}
              label={model && model.id ? "Name" : "New Model"}
              labelFor="model-name-input"
              labelInfo="(required)"
            >
              <InputGroup
                id="model-name-input"
                placeholder="Model Name"
                required={true}
                value={this.state.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({
                    name: trimStart(e.target.value).substring(0, 40),
                  })
                }
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={this.props.onClose}
                disabled={this.state.isLoading}
              >
                Cancel
              </Button>
              <Button
                intent="primary"
                type="submit"
                loading={this.state.isLoading}
              >
                {model && model.id ? "Save" : "Create Model"}
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  private isValid = (name: string): boolean => {
    if (typeof name !== "string" || name === "") {
      this.setState({ error: "Model name is missing" });
      return false;
    }

    if (name.trim() === "") {
      this.setState({ error: "Model name cannot be blank" });
      return false;
    }

    if (name.length > 40) {
      this.setState({ error: "Model name can not exceed 40 characters" });
      return false;
    }

    return true;
  };

  private handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.setState({ isLoading: true, error: "" });
    const clientId = this.props.match.params.client;
    const { model } = this.props;

    try {
      if (model && model.id) {
        if (this.isValid(this.state.name)) {
          await this.props.renameModelName(clientId, model.id, this.state.name);
          this.setState({ isLoading: false });
          this.props.onClose();
        } else {
          this.setState({ isLoading: false });
        }
      } else {
        const createdModel: any = await this.props.createModel(
          clientId,
          this.state.name
        );
        this.props.history.push(
          `/clients/${clientId}/models/${createdModel.id}/current`
        );
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };
}

export default withRouter(
  connect(null, {
    createModel,
    renameModelName,
  })(CreateModelModal)
);
