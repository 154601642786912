import * as React from "react";
import "styled-components/macro";
import Space from "styled-space";
import { Box, Flex } from "@rebass/grid";
import {
  Button,
  ButtonGroup,
  Intent,
  NonIdealState,
  Classes,
  Text,
  FormGroup,
  HTMLSelect,
  Tag,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import CalcBlockOrderCard from "../CalcBlockOrderCard";

import {
  StandardCalculationBlock,
  BlockNodeMapping,
  Node,
  Dimension,
  ModelInstanceFilter,
} from "../../../types/models";
import { saveBlockNodeMapping } from "../../../store/modules/block_node_mapping";
import { ThunkDispatch } from "../../../types/redux";
import { RouteComponentProps, withRouter, NavLink } from "react-router-dom";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  selectedCalculationBlocks: StandardCalculationBlock[];
  allCalculationBlocks: StandardCalculationBlock[];
  blockMappings: BlockNodeMapping[];
  selectedNode: Node;
  dimension: Dimension;
  modelInstancesFilter: ModelInstanceFilter;
}

interface IState {
  assignedBlocks: StandardCalculationBlock[];
  nodeHasBlocks: boolean;
  isAddingBlock: boolean;
  newNodeMapping: BlockNodeMapping;
  submitDisabled: boolean;
}

class NodeBlockMapping extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      nodeHasBlocks: this.props.allCalculationBlocks.length > 0,
      assignedBlocks: [],
      isAddingBlock: false,
      newNodeMapping: {
        NodeID: 0,
        BlockID: 0,
        Block_Order: 0,
        ModelInstanceID: 0,
      },
      submitDisabled: true,
    };
  }

  public render() {
    const { nodeHasBlocks, isAddingBlock } = this.state;
    const {
      client: clientId,
      model: modelId,
      instance: modelInstanceId,
    } = this.props.match.params;
    const { modelInstancesFilter } = this.props;
    return (
      <Flex flexDirection="column" mt={3}>
        {!nodeHasBlocks && (
          <NonIdealState
            title="No Calculation Blocks"
            description="Create calculation blocks to start assigning them to nodes"
            action={
              <NavLink
                to={`/clients/${clientId}/models/${modelId}/${modelInstancesFilter}/instances/${modelInstanceId}/calculations`}
              >
                <Button
                  text="Create Calculation Blocks"
                  minimal={true}
                  fill={true}
                  intent={Intent.PRIMARY}
                />
              </NavLink>
            }
          />
        )}
        {nodeHasBlocks && (
          <Flex flexDirection="column">
            <Space mb={2}>
              <div style={{ marginBottom: "20px" }}>
                <Text className={Classes.TEXT_LARGE}>
                  Calculation Block Order:
                </Text>
              </div>
              <Flex
                alignItems="inherit"
                justifyContent="space-between"
                flexDirection="column"
              >
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppable) => (
                      <div
                        ref={droppable.innerRef}
                        {...droppable.droppableProps}
                      >
                        {this.props.selectedCalculationBlocks.map(
                          (block, idx) => (
                            <Flex key={block.id}>
                              <Box style={{ margin: "auto 8px auto" }}>
                                <Tag minimal={true}>{idx + 1}</Tag>
                              </Box>
                              <Draggable
                                key={idx}
                                draggableId={`${idx}`}
                                index={idx}
                              >
                                {(draggable) => (
                                  <Box
                                    css={{ width: "100%" }}
                                    ref={draggable.innerRef}
                                    {...draggable.draggableProps}
                                    {...draggable.dragHandleProps}
                                  >
                                    <CalcBlockOrderCard
                                      calculationBlock={block}
                                      onRemove={
                                        this.handleRemoveBlockNodeMapping
                                      }
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            </Flex>
                          )
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Flex>

              {isAddingBlock && (
                <Flex>
                  <Flex flex={1}>
                    <FormGroup style={{ width: "100%", marginBottom: "0" }}>
                      <HTMLSelect
                        onChange={this.handleChange}
                        options={this.calculationBlockOptions()}
                        fill={true}
                        disabled={this.calculationBlockOptions().length <= 1}
                      />
                    </FormGroup>
                  </Flex>

                  <Flex ml={2}>
                    <ButtonGroup minimal={true}>
                      <Button
                        icon={IconNames.CROSS}
                        onClick={this.toggleAddBlock}
                        disabled={this.calculationBlockOptions().length <= 1}
                      />
                      <Button
                        icon={IconNames.TICK}
                        onClick={this.handleCreateBlockNodeMapping}
                        disabled={
                          this.calculationBlockOptions().length <= 1 ||
                          this.state.submitDisabled
                        }
                      />
                    </ButtonGroup>
                  </Flex>
                </Flex>
              )}
              {!isAddingBlock && this.calculationBlockOptions().length > 1 && (
                <Button
                  text="Add Block"
                  icon={IconNames.PLUS}
                  fill={true}
                  className={Classes.TEXT_SMALL}
                  onClick={this.toggleAddBlock}
                />
              )}
            </Space>
          </Flex>
        )}
      </Flex>
    );
  }

  private onDragEnd = (result: DropResult) => {
    const { dispatch, blockMappings } = this.props;

    if (!result.destination) {
      return;
    }

    const movedCardFromIndex = result.source.index;
    const movedCardToIndex = result.destination.index;

    blockMappings.splice(
      movedCardToIndex,
      0,
      blockMappings.splice(movedCardFromIndex, 1)[0]
    );

    dispatch(
      saveBlockNodeMapping(
        blockMappings.map((bm, index) => {
          return {
            ...bm,
            Block_Order: index + 1,
            id: undefined,
          };
        }),
        this.props.match.params.instance,
        this.props.selectedNode.id
      )
    );
  };

  private calculationBlockOptions = () => {
    const { selectedCalculationBlocks, allCalculationBlocks, dimension } =
      this.props;

    const filterForType = allCalculationBlocks.filter(
      (calculationBlock) =>
        calculationBlock.Type === "Standard" &&
        calculationBlock.DimensionID === dimension.id
    );

    const filteredArray = filterForType.filter(
      (allBlock) =>
        selectedCalculationBlocks.findIndex(
          (selectedBlock) => allBlock.id === selectedBlock.id
        ) === -1
    );
    const defaultOption = {
      label: "Calculation Blocks",
      value: 0,
    };

    const options = filteredArray.map((calcBlock) => ({
      label: calcBlock.Name,
      value: calcBlock.id as number,
    }));
    return [defaultOption, ...options];
  };

  private handleChange = (e: any): void => {
    if (e.currentTarget.value !== "0") {
      this.setState({
        newNodeMapping: {
          NodeID: this.props.selectedNode.id,
          BlockID: Number(e.currentTarget.value),
          Block_Order: this.props.blockMappings.length + 1,
          ModelInstanceID: this.props.match.params.instance,
        },
        submitDisabled: false,
      });
    } else {
      this.setState({ submitDisabled: true });
    }
  };

  private handleCreateBlockNodeMapping = () => {
    const mappings: BlockNodeMapping[] = this.props.blockMappings.map((bm) => {
      delete bm.id;
      return { ...bm };
    });

    mappings.push(this.state.newNodeMapping);

    this.props
      .dispatch(
        saveBlockNodeMapping(
          mappings,
          this.props.match.params.instance,
          this.props.selectedNode.id
        )
      )
      .catch(() => {
        this.setState({ submitDisabled: false });
      });
    this.setState({ submitDisabled: true });
  };

  private handleRemoveBlockNodeMapping = (
    calculationBlock: StandardCalculationBlock
  ) => {
    const { blockMappings } = this.props;

    const updatedMappings = blockMappings
      .filter((bm) => {
        return bm.BlockID !== calculationBlock.id;
      })
      .map((bm, idx) => {
        delete bm.id;
        return { ...bm, Block_Order: idx + 1 };
      });

    this.props.dispatch(
      saveBlockNodeMapping(
        updatedMappings,
        this.props.match.params.instance,
        this.props.selectedNode.id
      )
    );
  };

  private toggleAddBlock = (): void => {
    this.setState({
      isAddingBlock: !this.state.isAddingBlock,
      submitDisabled: true,
      newNodeMapping: {
        NodeID: 0,
        BlockID: 0,
        Block_Order: 0,
        ModelInstanceID: 0,
      },
    });
  };
}

export default withRouter(NodeBlockMapping);
