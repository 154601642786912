import * as React from "react";
import "styled-components/macro";
import { Classes, Colors } from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";

import ActionNav from "../ActionNav";
import UtilityNav from "../../molecules/UtilityNav";
import Breadcrumbs from "../../molecules/Breadcrumbs";

export interface INavigationBarProps {
  controls?: React.ReactNode;
  tabs?: React.ReactNode;
  title: string;
}

const NavigationBar: React.FC<INavigationBarProps> = (props) => {
  return (
    <Flex
      className={Classes.DARK}
      flexDirection="column"
      justifyContent="space-between"
      bg={Colors.BLUE2}
      css={{
        zIndex: "1",
      }}
    >
      <Box
        css={{
          borderBottom: `1px solid ${Colors.BLUE1}`,
        }}
        mb={2}
      >
        <UtilityNav />
      </Box>
      <Breadcrumbs />
      <ActionNav
        title={props.title}
        tabs={props.tabs}
        controls={props.controls}
      />
    </Flex>
  );
};

export default NavigationBar;
