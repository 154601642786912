import * as React from "react";
import "styled-components/macro";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Box, Flex } from "@rebass/grid";
import {
  H5,
  Text,
  Colors,
  Classes,
  Icon,
  Breadcrumb,
  Intent,
  PopoverInteractionKind,
  InputGroup,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import cx from "classnames";
import { isEmpty } from "lodash";
import ParameterRow, { StyledRepresentation } from "./ParameterRow";

import {
  Dimension,
  SchemaFunction,
  BlockSource,
  CalculationBlockRow,
  Input,
  Output,
  FunctionParameter,
  XRefFunction,
} from "../../../types/models";
import {
  CalculationBlockRowTree,
  changeCalculationBlockRowTree,
  clearCalculationBlockRowTree,
} from "../../../store/modules/calculation_block_row_tree";
import { ThunkDispatch } from "../../../types/redux";
import { SelectedFormulaRepresentationState } from "../../../store/modules/selected_formula_representation";
import { Breadcrumbs2, Popover2 } from "@blueprintjs/popover2";

interface IProps extends RouteComponentProps<any> {
  formula: SchemaFunction;
  blockSources: BlockSource[];
  blockRow: CalculationBlockRow;
  blockRows: CalculationBlockRow[];
  inputs: Input[];
  outputs: Output[];
  dispatch: ThunkDispatch;
  xrefFunctions: XRefFunction[];
  functionParameters: FunctionParameter[];
  calculationBlockRowTree: CalculationBlockRowTree[];
  selectedFormulaRepresentation: SelectedFormulaRepresentationState;
  onSaveFunctionParameter: (fp: FunctionParameter) => Promise<any>;
  onClearFunctionParameter: (fp: FunctionParameter) => Promise<any>;
  onAddRepeatedParameter: () => void;
}

interface IState {
  dimension?: Dimension;
  functionNestLevel: number;
  crumbs: CalculationBlockRowTree[];
}

class FormulaEditor extends React.Component<IProps, IState> {
  public state: IState = { functionNestLevel: 1, crumbs: [] };

  private createCrumbs = () => {
    this.setState({
      crumbs: this.props.calculationBlockRowTree.map((item, index, self) => {
        if (index === 0 || index === 1) {
          item.css = "greenCrumb";
        } else if (index === self.length - 1) {
          item.css = "blueCrumb";
        } else {
          item.css = "";
        }
        item.index = index;
        item.historyId = -(self.length - index - 1);
        return item;
      }),
    });
  };

  public componentDidMount() {
    this.createCrumbs();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.calculationBlockRowTree !== this.props.calculationBlockRowTree
    ) {
      this.createCrumbs();
    }
  }

  public render() {
    const {
      blockSources,
      blockRow,
      blockRows,
      formula,
      xrefFunctions,
      inputs,
      outputs,
      dispatch,
      selectedFormulaRepresentation,
      calculationBlockRowTree,
    } = this.props;

    if (!blockRow) {
      return (
        <React.Fragment>
          <span>Loading Block Row...</span>
        </React.Fragment>
      );
    }

    const popoverContent = selectedFormulaRepresentation ? (
      <StyledRepresentation>
        {selectedFormulaRepresentation}
      </StyledRepresentation>
    ) : undefined;

    return (
      <Flex flex={1} flexDirection="column">
        {!isEmpty(calculationBlockRowTree) && (
          <Flex
            py={3}
            css={{
              borderBottom: `1px solid ${Colors.LIGHT_GRAY3}`,
            }}
          >
            <Breadcrumbs2
              breadcrumbRenderer={this.breadcrumbRenderer}
              items={this.state.crumbs}
            />
          </Flex>
        )}
        <Flex
          py={3}
          css={{
            borderBottom: `1px solid ${Colors.LIGHT_GRAY3}`,
          }}
        >
          <Box width={1}>
            <H5>Formula Editor: {formula && formula.Name}</H5>
            {selectedFormulaRepresentation && (
              <Flex alignItems="center">
                <Box width={"20%"}>
                  <Text className={cx(Classes.TEXT_LARGE, Classes.TEXT_MUTED)}>
                    Formula Representation:{" "}
                  </Text>
                </Box>
                <Box width={"80%"} pr={2}>
                  <Popover2
                    content={popoverContent}
                    interactionKind={PopoverInteractionKind.HOVER}
                    targetTagName="div"
                    hoverOpenDelay={300}
                  >
                    <InputGroup
                      value={selectedFormulaRepresentation}
                      readOnly={true}
                    />
                  </Popover2>
                </Box>
              </Flex>
            )}
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          css={{
            height: "40px",
            borderBottom: `1px solid ${Colors.LIGHT_GRAY3}`,
          }}
        >
          <Flex flex={1}>
            <Text className={Classes.TEXT_MUTED}>Parameter</Text>
          </Flex>
          <Flex flex={1}>
            <Text className={Classes.TEXT_MUTED}>Type</Text>
          </Flex>
          <Flex flex={1}>
            <Text className={Classes.TEXT_MUTED}>Value</Text>
          </Flex>
          <Flex flex={3}>
            <Text className={Classes.TEXT_MUTED}>Parameter Representation</Text>
          </Flex>
          <Flex flex={1}>
            <Text className={Classes.TEXT_MUTED}>Actions</Text>
          </Flex>
          <Flex flex={1}>
            <Text className={Classes.TEXT_MUTED}>Status</Text>
          </Flex>
        </Flex>
        {this.getFunctionParameters().map((fp: FunctionParameter) => {
          return (
            <ParameterRow
              key={`${blockRow.id}-${formula.ID}-${fp.Parameter_Number}-${fp.id}`}
              functionSchemaParameter={
                this.getFormulaParameter(fp.Parameter_Number)!
              }
              functionParameter={fp}
              canBeDeleted={formula.Repeat && this.isParameterDeletable(fp)}
              blockSources={blockSources}
              blockRow={blockRow}
              blockRows={blockRows}
              inputs={inputs}
              outputs={outputs}
              xrefFunctions={xrefFunctions}
              onSaveFunctionParameter={this.props.onSaveFunctionParameter}
              onClearFunctionParameter={this.props.onClearFunctionParameter}
              endOfRepeatedParamGroup={this.isEndOfRepeatedParamGroup(
                fp,
                formula
              )}
              dispatch={dispatch}
              calculationBlockRowTree={calculationBlockRowTree}
            />
          );
        })}
        {this.canAddRepeatedParameters() && (
          <Flex
            justifyContent="center"
            alignItems="center"
            css={{
              backgroundColor: "#EBF1F5",
              cursor: "pointer",
            }}
            onClick={this.props.onAddRepeatedParameter}
          >
            <Box py={3} px={2}>
              <Text className={Classes.TEXT_MUTED}>Add Repeated Parameter</Text>
            </Box>
            <Icon color={Colors.GRAY1} icon={IconNames.PLUS} size={20} />
          </Flex>
        )}
      </Flex>
    );
  }

  private breadcrumbRenderer = ({ ...restProps }) => {
    const { text, historyId, index } = restProps;
    const newCrumbs = this.state.crumbs
      .slice(
        0,
        this.state.crumbs.findIndex((val) => val.historyId === historyId) + 1
      )
      .map((item) => {
        return {
          text: item.text,
          href: item.href,
          id: item.id,
        };
      });

    return (
      <Breadcrumb
        className={restProps.css}
        intent={Intent.PRIMARY}
        {...(historyId && {
          onClick: () => {
            if (index === 0) {
              this.props.dispatch(clearCalculationBlockRowTree());
            }
            this.props
              .dispatch(changeCalculationBlockRowTree(newCrumbs))
              .then(() => {
                this.props.history.go(historyId);
              });
          },
        })}
      >
        {text}
      </Breadcrumb>
    );
  };

  private isParameterDeletable = (fp: FunctionParameter) => {
    return !!fp.id && fp.Type !== "Nested Function";
  };

  private isEndOfRepeatedParamGroup = (
    fp: FunctionParameter,
    formula: SchemaFunction
  ): boolean => {
    if (!formula.Repeat) {
      return false;
    }

    return 0 === fp.Parameter_Number % formula.Parameters.length;
  };

  private canAddRepeatedParameters = (): boolean => {
    const { formula, functionParameters } = this.props;

    if (!formula.Repeat) {
      return false;
    }

    if (0 === functionParameters.length) {
      return false;
    }

    if (functionParameters.length % formula.Parameters.length > 0) {
      return false;
    }

    return functionParameters.every((fp) => {
      return fp.IsValidParameter && !!fp.id;
    });
  };

  private getFormulaParameter = (paramNumber: number) => {
    const { formula } = this.props;

    const paramsLength = formula.Parameters.length;

    if (formula.Repeat) {
      if (1 === paramsLength) {
        return formula.Parameters[0];
      }

      if (paramNumber > paramsLength) {
        const paramIndex = paramNumber % paramsLength;
        if (paramIndex) {
          return formula.Parameters[paramIndex - 1];
        }

        return formula.Parameters[paramsLength - 1];
      }
    }

    return formula.Parameters[paramNumber - 1];
  };

  private getFunctionParameters = (): FunctionParameter[] => {
    const { formula, functionParameters } = this.props;

    let paramsCountToDisplay = formula.Parameters.length;

    // If we have repeated params already, we need to "fill in any gaps"
    if (formula.Repeat && functionParameters.length > paramsCountToDisplay) {
      if (functionParameters.length % formula.Parameters.length > 0) {
        paramsCountToDisplay =
          (Math.floor(functionParameters.length / formula.Parameters.length) +
            1) *
          formula.Parameters.length;
      } else {
        paramsCountToDisplay = functionParameters.length;
      }
    }

    const params = [];
    for (let i = 0; i < paramsCountToDisplay; i++) {
      params.push(this.getFunctionParameter(i + 1));
    }

    return params;
  };

  // Use the functionParameters passed into to build out the UI for existing and non-existing rows
  private getFunctionParameter = (paramNumber: number): FunctionParameter => {
    const { blockRowId, instance } = this.props.match.params;

    const fp = this.props.functionParameters.find((p: FunctionParameter) => {
      return (
        paramNumber === p.Parameter_Number &&
        p.Function_BlockRowID === parseInt(blockRowId, 10)
      );
    });

    if (fp) {
      return fp;
    }

    return {
      ModelInstanceID: instance,
      Function_BlockRowID: blockRowId,
      FunctionID: this.props.formula.ID,
      IsValidParameter: false,
      Parameter_Number: paramNumber,
    };
  };
}

export default withRouter(FormulaEditor);
