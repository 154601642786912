import * as React from "react";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import DimensionEditor from "./DimensionEditor";
import NodeDimensionEditor from "./NodeDimensionEditor";
import CalculationEditor from "./CalculationEditor";
import RowEditor from "./RowEditor";
import InputsEditor from "./InputsEditor";
import OutputEditor from "./OutputEditor";
import FormulaEditorView from "./FormulaEditor";

const ModelInstanceView: React.FC<RouteComponentProps<any>> = ({ match }) => (
  <Switch>
    <Route exact={true} path={match.path} component={DimensionEditor} />
    <Route path={`${match.path}/nodes`} component={NodeDimensionEditor} />
    <Route
      path={`${match.path}/calculations/:blockId/edit`}
      component={RowEditor}
    />
    <Route
      path={`${match.path}/calculations/:blockId/row/:blockRowId/formula`}
      component={FormulaEditorView}
    />
    <Route path={`${match.path}/calculations`} component={CalculationEditor} />
    <Route path={`${match.path}/inputs`} component={InputsEditor} />
    <Route path={`${match.path}/outputs`} component={OutputEditor} />
  </Switch>
);

export default withRouter(ModelInstanceView);
