import { createAction, getType } from "typesafe-actions";

import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export type SelectedFormulaRepresentationState = string;

const initialState: SelectedFormulaRepresentationState = "";

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setFormulaRepresentation): {
      return action.payload;
    }
    default:
      return state;
  }
}

export const actions = {
  setFormulaRepresentation: createAction(
    "@calculationBlock/setFormulaRepresentation"
  )<string>(),
};

export const setFormulaRepresentation =
  (parameter: string | undefined): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.setFormulaRepresentation(parameter as string))
    );
  };
