import * as React from "react";
import {
  Classes,
  Text,
  Tag,
  Button,
  ButtonGroup,
  Intent,
  H3,
  FormGroup,
  Position,
  ProgressBar,
  Menu,
} from "@blueprintjs/core";
import { Flex } from "@rebass/grid";

import { Dimension, DimensionInstance, Node } from "../../../types/models";

import MultiSelector from "./MultiSelector";
import CloneNodeAndAllDescendents from "./CloneNodeAndAllDescendents";
import { MenuItem2, Popover2, Tooltip2 } from "@blueprintjs/popover2";

export const SELECT_ALL = "SELECT_ALL";
export const DESELECT_ALL = "DESELECT_ALL";

export type SELECT_CHILDREN = "SELECT_ALL" | "DESELECT_ALL" | "";

const DIALOG_VALUES = Object.freeze({
  none: "",
  cloneNodeAndAllDescendents: "clone-node-and-all-descendents",
});

interface IProps {
  currentDimension: Dimension;
  onInstanceSelect: (
    di: DimensionInstance[],
    deleteAllDescendents: boolean
  ) => void;
  instanceName: string;
  nextDimension: Dimension;
  descendantCount?: number;
  preSelectedNodes: Node[];
  selectedNode?: Node;
  isSaving: boolean;
  onDeleteAllDescendents: () => void;
  clonableDimensionInstance: DimensionInstance[];
  onCloneSubtree: (dimensionInstanceId: React.ReactText) => void;
}

interface IState {
  selectedInstances: DimensionInstance[];
  isChanged: boolean;
  selectAll: SELECT_CHILDREN;
  selectedInstanceIds: number[];
  showConfirmBox: string;
}

class ChildSelector extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      selectedInstances: [],
      isChanged: false,
      selectAll: "",
      selectedInstanceIds: [],
      showConfirmBox: "",
    };
  }

  public componentDidMount() {
    this.setState({
      selectedInstanceIds: this.props.preSelectedNodes.map(
        (n) => n.dimension_instance.id!
      ),
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    // Post-save, reset "change" status
    if (prevProps.isSaving && !this.props.isSaving) {
      this.setState({ isChanged: false });
    }
    if (prevProps.preSelectedNodes !== this.props.preSelectedNodes) {
      this.setState({
        selectedInstanceIds: this.props.preSelectedNodes.map(
          (n) => n.dimension_instance.id!
        ),
      });
    }
  }

  public render() {
    const {
      currentDimension,
      nextDimension,
      descendantCount,
      clonableDimensionInstance,
      onCloneSubtree,
    } = this.props;
    const { selectedInstanceIds } = this.state;
    return (
      <Flex flexDirection={"column"}>
        {this.displayDimensionName({
          name: currentDimension.Name,
        })}
        {this.displayInstanceName({
          name: this.props.instanceName,
          descendantCount,
        })}
        <FormGroup>
          <ButtonGroup fill={true}>
            <Button
              text="Set as Children"
              intent={Intent.PRIMARY}
              onClick={this.handleClick}
              disabled={!this.state.isChanged}
            />
            <Popover2
              position={Position.RIGHT}
              content={
                <Menu>
                  <MenuItem2
                    tagName="div"
                    text={"Select all"}
                    onClick={this.selectNodes}
                  />
                  <MenuItem2
                    tagName="div"
                    text={"Deselect all"}
                    onClick={this.deSelectNodes}
                  />
                  {!!descendantCount && (
                    <MenuItem2
                      tagName="div"
                      text={"Delete all descendents"}
                      onClick={() => {
                        this.props.onDeleteAllDescendents();
                        this.setState({ isChanged: true });
                      }}
                    />
                  )}
                  {!!clonableDimensionInstance.length && (
                    <MenuItem2
                      tagName="div"
                      text={"Clone node and descendants"}
                      onClick={() =>
                        this.setState({
                          showConfirmBox:
                            DIALOG_VALUES.cloneNodeAndAllDescendents,
                        })
                      }
                    />
                  )}
                </Menu>
              }
            >
              <Button intent={Intent.PRIMARY} icon="more" />
            </Popover2>
          </ButtonGroup>
          <MultiSelector
            key={nextDimension.DimNumber}
            instances={nextDimension.dimension_instances}
            preSelectedInstanceIds={selectedInstanceIds}
            onNodeSelect={this.handleNodeSelect}
            dimensionName={nextDimension.Name}
            parentNode={this.props.selectedNode}
            selectAll={this.state.selectAll}
            isChanged={this.state.isChanged}
          />
          <CloneNodeAndAllDescendents
            isOpen={
              this.state.showConfirmBox ===
              DIALOG_VALUES.cloneNodeAndAllDescendents
            }
            onClose={() =>
              this.setState({ showConfirmBox: DIALOG_VALUES.none })
            }
            onSave={(id) => {
              this.setState({ isChanged: true });
              onCloneSubtree(id);
            }}
            dimensions={clonableDimensionInstance}
          />
          {this.props.isSaving && <ProgressBar intent={Intent.PRIMARY} />}
        </FormGroup>
      </Flex>
    );
  }

  private selectNodes = () => {
    this.setState({
      selectAll: SELECT_ALL,
      isChanged: true,
    });
  };

  private deSelectNodes = () => {
    this.setState({
      selectAll: DESELECT_ALL,
      isChanged: true,
    });
  };

  private handleNodeSelect = (instances: DimensionInstance[]) => {
    this.setState({
      selectedInstances: instances,
      isChanged: true,
      selectAll: "",
    });
  };

  private handleClick = () => {
    this.props.onInstanceSelect(this.state.selectedInstances, false);
    this.setState({ isChanged: true });
  };

  private displayDimensionName = (props: any) => {
    return (
      <Flex className={Classes.TEXT_LARGE} alignItems={"center"} mb={3}>
        <Tag minimal={true}>
          <Text>{props.name}</Text>
        </Tag>
      </Flex>
    );
  };

  private displayInstanceName = (props: any) => {
    return (
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <H3>{props.name}</H3>
        {typeof props.descendantCount !== "undefined" && (
          <Tooltip2
            content="Total number of descendents"
            position={Position.TOP}
          >
            <Tag>
              <Text className={Classes.TEXT_SMALL}>
                {props.descendantCount}
              </Text>
            </Tag>
          </Tooltip2>
        )}
      </Flex>
    );
  };
}

export default ChildSelector;
