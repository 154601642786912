import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Flex } from "@rebass/grid";

import {
  CalculationBlock,
  Dimension,
  StandardCalculationBlock,
} from "../../../../types/models";
import { ThunkDispatch } from "../../../../types/redux";
import { RootState } from "../../../../store/reducer";
import { getDimensions } from "../../../../store/modules/dimensions";
import {
  actions,
  getCalculationBlocks,
  saveCalculationBlock,
  CalculationBlockTypes,
  deleteCalculationBlock,
} from "../../../../store/modules/calculation_blocks";
import { getXRefFunctions } from "../../../../store/modules/xref_functions";

import Toaster from "../../../molecules/Toaster";
import AppViewTemplate from "../../../templates/AppView";
import Loading from "../../../molecules/Loading";
import { MaxBound } from "../../../atoms/Layout";
import TabBar from "../../../organisms/TabBar";
import NewRowButton from "../../../molecules/NewRowButton";
import CalculationBlocksTable from "../../../organisms/CalculationBlocksTable";
import {
  clearSelectCalculationBlockRow,
  selectCalculationBlockRow,
} from "../../../../store/modules/selected_calculation_block_rows";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  dimensions: Dimension[];
  calculationBlocks: CalculationBlock[];
}

interface IState {
  isLoading: boolean;
  isAdding: boolean;
  modelInstanceId: number;
  isCalculationBlockLoading: boolean;
}

class CalculationEditorView extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      isAdding: false,
      modelInstanceId: props.match.params.instance,
      isCalculationBlockLoading: false,
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const { modelInstanceId } = this.state;

    Promise.all([
      dispatch(getDimensions(modelInstanceId)),
      dispatch(getCalculationBlocks(modelInstanceId)),
      dispatch(getXRefFunctions()),
    ]).then(() => this.setState({ isLoading: false }));
  }

  public render() {
    const { dimensions, calculationBlocks, dispatch } = this.props;
    const { isAdding, isLoading } = this.state;

    return (
      <React.Fragment>
        <AppViewTemplate title="Calculation Block Editor">
          <MaxBound mt={3}>
            {isLoading ? (
              <Loading />
            ) : (
              <Flex flexDirection="column" width={1} pb={100}>
                <CalculationBlocksTable
                  isAdding={isAdding}
                  handleIsAddingWhileEditing={this.handleIsAddingWhileEditing}
                  calculationBlocks={calculationBlocks}
                  dimensions={dimensions}
                  onBlockCancelAdd={this.handleAddCancel}
                  onBlockRemove={this.handleRemoveCalculationBlock}
                  onBlockSave={this.handleBlockSave}
                  isCalculationBlockLoading={
                    this.state.isCalculationBlockLoading
                  }
                  dispatch={dispatch}
                />
                {!isAdding && (
                  <NewRowButton
                    text="New Calculation Block"
                    onClick={this.addCalculationBlock}
                  />
                )}
              </Flex>
            )}
          </MaxBound>
        </AppViewTemplate>
        <TabBar />
      </React.Fragment>
    );
  }

  private handleIsAddingWhileEditing = () => this.setState({ isAdding: false });

  private addCalculationBlock = () => {
    const newBlock = {
      Name: "",
      ModelInstanceID: this.state.modelInstanceId,
      DimensionID: -1,
      Type: CalculationBlockTypes.Standard,
      isEditing: true,
    };

    this.setState({ isAdding: true });
    this.props
      .dispatch(clearSelectCalculationBlockRow())
      .then(() => this.props.dispatch(actions.addCalculationBlock(newBlock)));
  };

  private handleAddCancel = (): void => {
    this.setState({ isAdding: false });
    this.props.dispatch(actions.cancelAddCalculationBlock());
  };

  private handleBlockSave = (calcBlock: StandardCalculationBlock) => {
    Toaster.clear();
    this.setState({ isCalculationBlockLoading: true });
    this.props.dispatch(saveCalculationBlock(calcBlock)).then((r) => {
      this.props
        .dispatch(selectCalculationBlockRow(r.id))
        .then(() =>
          this.setState({ isAdding: false, isCalculationBlockLoading: false })
        );
    });
  };

  private handleRemoveCalculationBlock = (
    calcBlock: StandardCalculationBlock
  ): void => {
    Toaster.clear();
    this.props.dispatch(deleteCalculationBlock(calcBlock));
  };
}

const mapStateToProps = (state: RootState) => ({
  dimensions: state.dimensions,
  calculationBlocks: state.calculationBlocks,
});

export default connect(mapStateToProps)(withRouter(CalculationEditorView));
