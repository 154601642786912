/* eslint-disable  react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useReducer } from "react";
import { Button, Intent, Alignment, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box } from "@rebass/grid";
import { range, some } from "lodash";
import { Select2 } from "@blueprintjs/select";
import { Classes, MenuItem2, Popover2 } from "@blueprintjs/popover2";
import { format } from "date-fns";

import { XRefDate } from "../../../../types/models";

export const monthNames: string[] = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface IMonthSelectorProps
  extends Pick<IMonthPickerProps, "dates" | "minYear" | "maxYear"> {
  month?: number;
  year: number;
  onSelectYear: (month: number, year: number) => void;
}

interface IMonthSelectorState {
  yearState: number;
  monthState?: number;
}

const MonthSelector: FunctionComponent<IMonthSelectorProps> = ({
  month,
  year,
  onSelectYear,
  dates,
  minYear,
  maxYear,
}: IMonthSelectorProps) => {
  const [state, setState] = useReducer(
    (state: IMonthSelectorState, newState: Partial<IMonthSelectorState>) => ({
      ...state,
      ...newState,
    }),
    {
      yearState: year,
      monthState: month,
    }
  );

  const styles: { [key: string]: React.CSSProperties } = {
    yearNav: {
      display: "flex",
      alignItems: "center",
      margin: "0 1px 5px",
      justifyContent: "center",
    },
    yearButton: {
      flex: "1",
      textAlign: "center",
      margin: "0 2px",
    },
    yearButtonText: {
      verticalAlign: "middle",
      fontWeight: "bold",
    },
    monthContainer: {
      display: "inline-block",
      padding: "1px",
      boxSizing: "border-box",
      margin: 0,
      minWidth: 0,
    },
    monthButton: {
      lineHeight: "3em",
      textAlign: "center",
      width: "100%",
      minWidth: "4em",
    },
  };

  const yearsRange: number[] = range(minYear, maxYear + 1);

  const onPrevYear = (): void => {
    setState({ yearState: state.yearState - 1 });
  };

  const onNextYear = (): void => {
    setState({ yearState: state.yearState + 1 });
  };

  const onSelect = (month: number): void => {
    setState({ monthState: month });
    onSelectYear(month, state.yearState);
  };

  const renderMonth = (monthItem: number): JSX.Element => {
    const selected: boolean = month === monthItem && year === state.yearState;

    return (
      <Box
        className="month-container"
        style={styles.monthContainer}
        key={monthItem}
      >
        <Button
          intent={selected ? Intent.PRIMARY : Intent.NONE}
          style={styles.monthButton}
          onClick={() => onSelect(monthItem)}
          disabled={!some(dates, { Date: `${monthItem}/1/${state.yearState}` })}
          small
        >
          {monthNames[monthItem]}
        </Button>
      </Box>
    );
  };

  const months: JSX.Element[] = [];
  for (let i = 1; i <= 12; i++) {
    months.push(renderMonth(i));
  }

  return (
    <Box className="">
      <Box className="month-year-nav" style={styles.yearNav}>
        <Button
          small={true}
          onClick={onPrevYear}
          icon={IconNames.CHEVRON_LEFT}
          minimal={true}
          disabled={state.yearState <= minYear}
        />
        <Select2<number>
          activeItem={state.yearState}
          filterable={false}
          items={yearsRange}
          popoverProps={{
            minimal: true,
          }}
          itemRenderer={(item, { handleClick, modifiers }) => {
            return (
              <MenuItem2
                key={item}
                text={item}
                active={modifiers.active}
                disabled={modifiers.disabled}
                onClick={handleClick}
              />
            );
          }}
          onItemSelect={(year, e) => {
            (e as any).stopPropagation();
            setState({ yearState: year });
          }}
        >
          <Button
            rightIcon={
              <Icon icon={IconNames.DOUBLE_CARET_VERTICAL} size={14} />
            }
            style={styles.yearButton}
            fill={true}
          >
            <span style={styles.yearButtonText} className="block-header">
              {state.yearState}
            </span>
          </Button>
        </Select2>
        <Button
          small={true}
          onClick={onNextYear}
          icon={IconNames.CHEVRON_RIGHT}
          minimal={true}
          disabled={state.yearState >= maxYear}
        />
      </Box>
      <Box>{months}</Box>
    </Box>
  );
};

interface IMonthPickerProps {
  minYear: number;
  maxYear: number;
  onChange: (month: number | undefined, year: number) => void;
  dates: XRefDate[];
  large?: boolean;
  value?: string;
}

interface IMonthPickerState {
  selectedMonth?: number;
  selectedYear: number;
  isPopOverOpen: boolean;
}

const MonthPicker: FunctionComponent<IMonthPickerProps> = ({
  minYear,
  maxYear,
  onChange,
  dates,
  value,
  large = true,
}: IMonthPickerProps) => {
  const [state, setState] = useReducer(
    (state: IMonthPickerState, newState: Partial<IMonthPickerState>) => ({
      ...state,
      ...newState,
    }),
    {
      selectedMonth: value ? Number(format(new Date(value), "M")) : undefined,
      selectedYear: value ? Number(format(new Date(value), "yyyy")) : minYear,
      isPopOverOpen: false,
    }
  );

  useEffect(() => {
    if (value) {
      setState({
        selectedMonth: Number(format(new Date(value), "M")),
        selectedYear: Number(format(new Date(value), "yyyy")),
      });
    }
  }, [value]);

  const onSelectMonth = (month: number, year: number): void => {
    setState({
      selectedMonth: month,
      selectedYear: year,
      isPopOverOpen: false,
    });
  };

  const handleClose = (): void => {
    onChange(state.selectedMonth, state.selectedYear);
  };

  return (
    <Popover2
      hasBackdrop={true}
      placement="bottom-start"
      fill={true}
      isOpen={state.isPopOverOpen}
      onClose={handleClose}
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      content={
        <Box className="container-fluid">
          <MonthSelector
            month={state.selectedMonth}
            year={state.selectedYear}
            onSelectYear={onSelectMonth}
            dates={dates}
            minYear={minYear}
            maxYear={maxYear}
          />
        </Box>
      }
    >
      <Button
        fill={true}
        alignText={Alignment.LEFT}
        text={
          <>
            {value === undefined
              ? "Select a date"
              : `${monthNames[state.selectedMonth as number]}-${
                  state.selectedYear
                }`}
          </>
        }
        rightIcon={<Icon icon={IconNames.CALENDAR} size={14} />}
        large={large}
        onClick={() => setState({ isPopOverOpen: true })}
      />
    </Popover2>
  );
};

export default MonthPicker;
