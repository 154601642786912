import * as React from "react";
import { Provider } from "react-redux";

import AppRouter from "./router";

import store from "./store";
import { getCurrentUser } from "./store/modules/user";
import { getProfile } from "./store/modules/profile";
import { getClients } from "./store/modules/client";
import { getFunctionsSchema } from "./store/modules/functions";

import { UserRole } from "./types/models";

import Loading from "./components/molecules/Loading";
import Error500 from "./components/views/errors/500";

interface IState {
  loading: boolean;
  error?: string;
}

class App extends React.Component<{}, IState> {
  public state: IState = {
    loading: true,
  };

  public componentDidMount() {
    Promise.all([
      store.dispatch<any>(getProfile()),
      store.dispatch<any>(getCurrentUser()),
      store.dispatch<any>(getClients()),
      store.dispatch<any>(getFunctionsSchema()),
    ])
      .then(() => this.setState({ loading: false }))
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          error: "Error loading application data. Please try again.",
        });
      });
  }

  public render() {
    const { profile } = store.getState();

    if (this.state.loading) {
      return <Loading />;
    } else if (this.state.error) {
      return <Error500 error={this.state.error} />;
    } else if (
      profile &&
      [UserRole.Trinity_Admin_User, UserRole.Trinity_User].indexOf(
        profile.User.Role
      ) === -1
    ) {
      // Only trinity users are allowed to access the MDA
      return "You do not have permissions to access this app";
    } else {
      return (
        <Provider store={store}>
          <AppRouter />
        </Provider>
      );
    }
  }
}

export default App;
