import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { BlockInstanceMapping } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export interface BlockInstanceMappingState {
  [dimensionInstanceId: number]: BlockInstanceMapping[];
}

interface BlockInstanceMappingCollectionResponseObject {
  dimensionInstanceId: number;
  mappings: BlockInstanceMapping[];
}

type DimensionInstanceID = number;

const initialState: BlockInstanceMappingState = {};

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.resetBlockInstanceMappings):
      return action.payload.reduce(
        (
          acc: BlockInstanceMappingState,
          r: BlockInstanceMappingCollectionResponseObject
        ) => {
          acc[r.dimensionInstanceId] = r.mappings;
          return acc;
        },
        {}
      );
    case getType(actions.setBlockInstanceMappings):
      return {
        ...state,
        [action.payload.dimensionInstanceId]: action.payload.mappings,
      };
    case getType(actions.deleteBlockInstanceMappings):
      const newState = state;
      action.payload.forEach((instanceId) => {
        delete newState[instanceId];
      });

      return newState;
    default:
      return state;
  }
}

export const actions = {
  setBlockInstanceMappings: createAction(
    "@block_instances/setBlockInstanceMappings"
  )<{
    dimensionInstanceId: DimensionInstanceID;
    mappings: BlockInstanceMapping[];
  }>(),
  resetBlockInstanceMappings: createAction(
    "@block_instances/resetBlockInstanceMappings"
  )<BlockInstanceMappingCollectionResponseObject[]>(),
  deleteBlockInstanceMappings: createAction(
    "@block_instances/deleteBlockInstanceMappings"
  )<DimensionInstanceID[]>(),
};

export const getAllBlockInstanceMappings =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/block_instances`)
      .then((resp: BlockInstanceMappingCollectionResponseObject[]) =>
        dispatch(actions.resetBlockInstanceMappings(resp))
      );
  };

export const getBlockInstanceMappings =
  (
    modelInstanceId: number,
    dimensionInstanceId: number
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(
        `/instances/${modelInstanceId}/dimension_instances/${dimensionInstanceId}/block_instances`
      )
      .then((resp) =>
        dispatch(
          actions.setBlockInstanceMappings({
            dimensionInstanceId,
            mappings: resp,
          })
        )
      );
  };

export const saveBlockInstanceMapping =
  (
    blockInstanceMappings: BlockInstanceMapping[],
    modelInstanceId: number,
    dimensionInstanceId: DimensionInstanceID
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post(
        `/instances/${modelInstanceId}/dimension_instances/${dimensionInstanceId}/block_instances`,
        blockInstanceMappings
      )
      .then((resp) =>
        dispatch(
          actions.setBlockInstanceMappings({
            dimensionInstanceId,
            mappings: resp,
          })
        )
      );
  };

export const deleteBlockInstanceMappings =
  (
    modelInstanceId: number,
    deletedInstanceIds: DimensionInstanceID[]
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .delete(`/instances/${modelInstanceId}/block_instances`, {
        dimensionInstances: deletedInstanceIds,
      })
      .then(() =>
        dispatch(actions.deleteBlockInstanceMappings(deletedInstanceIds))
      );
  };
