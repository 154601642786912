import * as React from "react";
import classnames from "classnames";

import { Classes } from "@blueprintjs/core";
import { toLower } from "lodash";
import { InputCategory, Input } from "../../../types/models";

import InputCategoryRow from "./InputCategoryRow";

interface IProps {
  inputCategories: InputCategory[];
  inputs: Input[];
  onInputCategorySave(inputCategory: InputCategory): Promise<any>;
  onInputCategoryRemove(inputCategory: InputCategory): Promise<any>;
  onInputCategoryCancelAdd(): void;
}

const tableClasses = classnames(
  Classes.HTML_TABLE,
  "input-category-row-editor"
);

class InputCategoriesTable extends React.Component<IProps> {
  public render() {
    const {
      inputCategories,
      inputs,
      onInputCategorySave,
      onInputCategoryRemove,
      onInputCategoryCancelAdd,
    } = this.props;

    return (
      <table className={tableClasses}>
        <tbody>
          {inputCategories.map((inputCategory, idx) => (
            <InputCategoryRow
              key={`${inputCategory.id}-${idx}`}
              inputCategory={inputCategory}
              canDelete={this.canDelete(inputs, inputCategory)}
              onSave={onInputCategorySave}
              onDelete={onInputCategoryRemove}
              onCancel={onInputCategoryCancelAdd}
              isValid={this.isValidName}
            />
          ))}
        </tbody>
      </table>
    );
  }

  private isValidName = (name: string, id: number | undefined): string => {
    const result = this.props.inputCategories.find(
      (category) => toLower(category.Name) === toLower(name)
    );
    // Check that it is not empty
    if ("" === name) {
      return "Please enter a name";
    }
    // Check that is a new input and does not already exist
    if (id === undefined && result !== undefined) {
      return "That name is already taken";
    }
    // Check that an edited input is not the same as an already existing input
    if (result !== undefined && result.id !== id) {
      return "That name is already taken";
    }
    return "";
  };

  private canDelete = (
    inputs: Input[],
    inputCategory: InputCategory
  ): boolean => {
    return (
      inputs.findIndex((input) => input.CategoryID === inputCategory.id) < 0
    );
  };
}

export default InputCategoriesTable;
