import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { FormatParameter } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type FormatParameterState = FormatParameter[];

const initialState: FormatParameterState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setFormatParameters):
      return action.payload;
    case getType(actions.createFormatParameter):
      return state
        .filter((formatParameter: FormatParameter) => formatParameter.id)
        .concat([action.payload]);
    case getType(actions.updateFormatParameter):
      return state.map((updatedFormatParameter) =>
        updatedFormatParameter.id === action.payload.id
          ? action.payload
          : updatedFormatParameter
      );

    default:
      return state;
  }
}
export const actions = {
  setFormatParameters: createAction("@format_parameters/setFormatParameters")<
    FormatParameter[]
  >(),
  createFormatParameter: createAction(
    "@format_parameters/createFormatParameters"
  )<FormatParameter>(),
  updateFormatParameter: createAction(
    "@format_parameters/updateFormatParameters"
  )<FormatParameter>(),
};

export const getFormatParameters =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/format_parameters/`)
      .then((formatParameter) =>
        dispatch(actions.setFormatParameters(formatParameter))
      );
  };

export const saveFormatParameter =
  (
    modelInstanceId: number,
    formatParameter: FormatParameter
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (formatParameter.id) {
      // Existing  format parameters - update
      return api
        .post(
          `/instances/${modelInstanceId}/format_parameters/`,
          formatParameter
        )
        .then((updatedParameter) =>
          dispatch(actions.updateFormatParameter(updatedParameter))
        );
    } else {
      // New format parameters - create
      return api
        .post(
          `/instances/${modelInstanceId}/format_parameters/`,
          formatParameter
        )
        .then((createFormatParameter) =>
          dispatch(actions.createFormatParameter(createFormatParameter))
        );
    }
  };
