import * as React from "react";
import "styled-components/macro";
import { Flex } from "@rebass/grid";
import {
  Divider,
  Button,
  Text,
  Intent,
  Colors,
  Menu,
  MenuDivider,
  Alignment,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import FormatModal from "../../../modals/Format";
import {
  Output,
  FormatParameter,
  ModelInstanceFilter,
  XRefFunction,
} from "../../../../types/models";
import { Link } from "react-router-dom";
import { ThunkDispatch } from "../../../../types/redux";

import { RootState } from "../../../../store/reducer";
import { connect } from "react-redux";
import { selectOutputRow } from "../../../../store/modules/selected_output_row";
import {
  head,
  filter,
  map,
  repeat,
  find,
  isEmpty,
  isUndefined,
  sortBy,
} from "lodash";
import { Select2 } from "@blueprintjs/select";
import { Fragment } from "react";
import {
  getOutputs,
  updateOutputAggregationBlock,
} from "../../../../store/modules/outputs";
import { MenuItem2 } from "@blueprintjs/popover2";

interface IProps {
  formatParameters: FormatParameter[];
  dispatch: ThunkDispatch;
  clientId: number;
  modelId: number;
  modelInstanceId: number;
  output: Output;
  modelInstancesFilter: ModelInstanceFilter;
  selectedOutputRow: Array<Output["id"]>;
  xrefFunctions: XRefFunction[];
  isDimensionAggregationEditable?: Output["id"];
  isTimescaleAggregationEditable?: Output["id"];
  handleDimensionAggregateEditableState: (id?: Output["id"]) => void;
  handleTimescaleAggregateEditableState: (id?: Output["id"]) => void;
  onEdit(output: Output): void;
}

interface IState {
  isModalOpen: boolean;
  selectedDimensionAggregationValue: { label: string; value: React.ReactText };
  selectedTimescaleAggregationValue: { label: string; value: React.ReactText };
  loading: boolean;
}

const emptyStringForDropdown = repeat("\xa0", 12);

const bodyRowCSS = {
  borderBottom: "1px solid #E1E8ED",
  height: "70px",
  width: "100%",
};

const tableCellCSS = {
  height: "48px",
  width: "100%",
};

class OutputRow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedDimensionAggregationValue: {
        label: emptyStringForDropdown,
        value: "",
      },
      selectedTimescaleAggregationValue: {
        label: emptyStringForDropdown,
        value: "",
      },
      loading: false,
    };
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.xrefFunctions !== this.props.xrefFunctions) {
      if (!isEmpty(this.props.xrefFunctions)) {
        this.setState({
          selectedDimensionAggregationValue: {
            label: !isUndefined(
              find(this.props.xrefFunctions, {
                FunctionID: this.props.output
                  .Dimension_Aggregation_FunctionID as number,
              }) as XRefFunction
            )
              ? (
                  find(this.props.xrefFunctions, {
                    FunctionID: this.props.output
                      .Dimension_Aggregation_FunctionID as number,
                  }) as XRefFunction
                ).Function
              : emptyStringForDropdown,
            value: !isUndefined(
              find(this.props.xrefFunctions, {
                FunctionID: this.props.output
                  .Dimension_Aggregation_FunctionID as number,
              }) as XRefFunction
            )
              ? (
                  find(this.props.xrefFunctions, {
                    FunctionID: this.props.output
                      .Dimension_Aggregation_FunctionID as number,
                  }) as XRefFunction
                ).FunctionID
              : "",
          },
          selectedTimescaleAggregationValue: {
            label: !isUndefined(
              find(this.props.xrefFunctions, {
                FunctionID: this.props.output
                  .Timescale_Aggregation_FunctionID as number,
              }) as XRefFunction
            )
              ? (
                  find(this.props.xrefFunctions, {
                    FunctionID: this.props.output
                      .Timescale_Aggregation_FunctionID as number,
                  }) as XRefFunction
                ).Function
              : emptyStringForDropdown,
            value: !isUndefined(
              find(this.props.xrefFunctions, {
                FunctionID: this.props.output
                  .Timescale_Aggregation_FunctionID as number,
              }) as XRefFunction
            )
              ? (
                  find(this.props.xrefFunctions, {
                    FunctionID: this.props.output
                      .Timescale_Aggregation_FunctionID as number,
                  }) as XRefFunction
                ).FunctionID
              : "",
          },
        });
      }
    }
  }

  public render() {
    const {
      output,
      clientId,
      modelId,
      modelInstanceId,
      modelInstancesFilter,
      selectedOutputRow,
      handleTimescaleAggregateEditableState,
      handleDimensionAggregateEditableState,
      isDimensionAggregationEditable,
      isTimescaleAggregationEditable,
      xrefFunctions,
    } = this.props;
    const {
      isModalOpen,
      loading,
      selectedDimensionAggregationValue,
      selectedTimescaleAggregationValue,
    } = this.state;

    const CUSTOM_AGGREGATION = find(xrefFunctions, {
      FunctionCategory: "Aggregation Placeholder",
      Aggregation_Dropdown_Flag: true,
    }) as XRefFunction;

    const dimensionAggregateOptions = sortBy(
      filter(xrefFunctions, {
        FunctionCategory: "Dimension Aggregation",
        Aggregation_Dropdown_Flag: true,
      }),
      "Function"
    );

    if (!isEmpty(dimensionAggregateOptions)) {
      dimensionAggregateOptions.push(CUSTOM_AGGREGATION);
    }

    const timescaleAggregateOptions = sortBy(
      filter(xrefFunctions, {
        FunctionCategory: "Time Aggregation",
        Aggregation_Dropdown_Flag: true,
      }),
      "Function"
    );

    if (!isEmpty(timescaleAggregateOptions)) {
      timescaleAggregateOptions.push(CUSTOM_AGGREGATION);
    }

    const dimensionAggregateDropdownOptions = map(
      dimensionAggregateOptions,
      (item) => {
        return {
          label: item.Function,
          value: item.FunctionID || "",
        };
      }
    );

    dimensionAggregateDropdownOptions.unshift({
      label: emptyStringForDropdown,
      value: "",
    });

    const timescaleAggregateDropdownOptions = map(
      timescaleAggregateOptions,
      (item) => {
        return {
          label: item.Function,
          value: item.FunctionID || "",
        };
      }
    );

    timescaleAggregateDropdownOptions.unshift({
      label: emptyStringForDropdown,
      value: "",
    });

    return (
      <React.Fragment>
        <tr
          style={{
            ...bodyRowCSS,
            backgroundColor:
              head(selectedOutputRow) === output.id
                ? `rgba(19,124,189,.15)`
                : Colors.WHITE,
          }}
        >
          <td>
            <Flex alignItems="center" css={tableCellCSS}>
              <Text>{output.Name}</Text>
            </Flex>
          </td>
          <td>
            <Flex alignItems="center" css={tableCellCSS}>
              <Select2<{ label: string; value: string | number }>
                filterable={false}
                items={dimensionAggregateDropdownOptions}
                itemListRenderer={(itemListProps) => {
                  return (
                    <Menu ulRef={itemListProps.itemsParentRef}>
                      {map(itemListProps.items, (item, index) => {
                        if (item.label === "CUSTOM") {
                          return (
                            <Fragment key="customDimensionSelection">
                              <MenuDivider />
                              {itemListProps.renderItem(item, index)}
                            </Fragment>
                          );
                        }
                        return itemListProps.renderItem(item, index);
                      })}
                    </Menu>
                  );
                }}
                itemRenderer={(item, { modifiers, handleClick }) => {
                  if (!modifiers.matchesPredicate) {
                    return null;
                  }

                  return (
                    <MenuItem2
                      active={modifiers.active}
                      key={item.label}
                      onClick={handleClick}
                      text={item.label}
                    />
                  );
                }}
                disabled={isDimensionAggregationEditable !== output.id}
                onItemSelect={(item) =>
                  this.setState({
                    selectedDimensionAggregationValue: item,
                  })
                }
              >
                <Button
                  alignText={Alignment.LEFT}
                  style={{ width: "115px" }}
                  disabled={
                    isDimensionAggregationEditable !== output.id || loading
                  }
                  rightIcon={IconNames.CARET_DOWN}
                  text={selectedDimensionAggregationValue.label}
                  title={selectedDimensionAggregationValue.label}
                />
              </Select2>
              {isDimensionAggregationEditable !== output.id && (
                <Button
                  minimal={true}
                  icon={IconNames.EDIT}
                  title="Edit Dimension Aggregation"
                  onClick={() => {
                    this.handleSelectedOutputRow(output.id);
                    handleDimensionAggregateEditableState(output.id);
                    handleTimescaleAggregateEditableState(undefined);
                  }}
                />
              )}
              {isDimensionAggregationEditable === output.id && (
                <>
                  <Button
                    loading={loading}
                    minimal={true}
                    icon={IconNames.TICK}
                    title="Save Edit Dimension Aggregation"
                    onClick={() => this.updateOutputAggregationBlock(output, 0)}
                  />
                  <Button
                    minimal={true}
                    icon={IconNames.CROSS}
                    title="Close Edit Dimension Aggregation"
                    onClick={() => {
                      this.props.handleDimensionAggregateEditableState(
                        undefined
                      );
                      this.setState({
                        selectedDimensionAggregationValue: {
                          label: !isUndefined(
                            find(this.props.xrefFunctions, {
                              FunctionID: this.props.output
                                .Dimension_Aggregation_FunctionID as number,
                            }) as XRefFunction
                          )
                            ? (
                                find(this.props.xrefFunctions, {
                                  FunctionID: this.props.output
                                    .Dimension_Aggregation_FunctionID as number,
                                }) as XRefFunction
                              ).Function
                            : emptyStringForDropdown,
                          value: !isUndefined(
                            find(this.props.xrefFunctions, {
                              FunctionID: this.props.output
                                .Timescale_Aggregation_FunctionID as number,
                            }) as XRefFunction
                          )
                            ? (
                                find(this.props.xrefFunctions, {
                                  FunctionID: this.props.output
                                    .Dimension_Aggregation_FunctionID as number,
                                }) as XRefFunction
                              ).FunctionID
                            : "",
                        },
                      });
                    }}
                  />
                </>
              )}
              {selectedDimensionAggregationValue.label === "CUSTOM" &&
                !isDimensionAggregationEditable && (
                  <Link
                    to={{
                      pathname: `/clients/${clientId}/models/${modelId}/${modelInstancesFilter}/instances/${modelInstanceId}/calculations/${output.Dimension_Aggregation_BlockID}/edit`,
                      state: {
                        type: "output",
                        output,
                      },
                    }}
                    onClick={() => this.handleSelectedOutputRow(output.id)}
                  >
                    <Button
                      text="Edit Custom"
                      minimal={true}
                      intent={Intent.PRIMARY}
                    />
                  </Link>
                )}
            </Flex>
          </td>
          <td>
            <Flex alignItems="center" css={tableCellCSS}>
              <Select2<{ label: string; value: string | number }>
                filterable={false}
                items={timescaleAggregateDropdownOptions}
                itemListRenderer={(itemListProps) => {
                  return (
                    <Menu ulRef={itemListProps.itemsParentRef}>
                      {map(itemListProps.items, (item, index) => {
                        if (item.label === "CUSTOM") {
                          return (
                            <Fragment key="customTimescaleSelection">
                              <MenuDivider />
                              {itemListProps.renderItem(item, index)}
                            </Fragment>
                          );
                        }
                        return itemListProps.renderItem(item, index);
                      })}
                    </Menu>
                  );
                }}
                itemRenderer={(item, { modifiers, handleClick }) => {
                  if (!modifiers.matchesPredicate) {
                    return null;
                  }

                  return (
                    <MenuItem2
                      active={modifiers.active}
                      key={item.label}
                      onClick={handleClick}
                      text={item.label}
                      shouldDismissPopover={false}
                    />
                  );
                }}
                disabled={isTimescaleAggregationEditable !== output.id}
                onItemSelect={(item) =>
                  this.setState({
                    selectedTimescaleAggregationValue: item,
                  })
                }
              >
                <Button
                  alignText={Alignment.LEFT}
                  style={{ width: "115px" }}
                  disabled={
                    isTimescaleAggregationEditable !== output.id || loading
                  }
                  rightIcon={IconNames.CARET_DOWN}
                  text={selectedTimescaleAggregationValue.label}
                  title={selectedTimescaleAggregationValue.label}
                />
              </Select2>
              {isTimescaleAggregationEditable !== output.id && (
                <Button
                  minimal={true}
                  icon={IconNames.EDIT}
                  title="Edit Timescale Aggregation"
                  onClick={() => {
                    this.handleSelectedOutputRow(output.id);
                    handleTimescaleAggregateEditableState(output.id);
                    handleDimensionAggregateEditableState(undefined);
                  }}
                />
              )}
              {isTimescaleAggregationEditable === output.id && (
                <>
                  <Button
                    loading={loading}
                    minimal={true}
                    icon={IconNames.TICK}
                    title="Save Edit Timescale Aggregation"
                    onClick={() => this.updateOutputAggregationBlock(output, 1)}
                  />
                  <Button
                    minimal={true}
                    icon={IconNames.CROSS}
                    title="Close Edit Timescale Aggregation"
                    onClick={() => {
                      this.props.handleTimescaleAggregateEditableState(
                        undefined
                      );
                      this.setState({
                        selectedTimescaleAggregationValue: {
                          label: !isUndefined(
                            find(this.props.xrefFunctions, {
                              FunctionID: this.props.output
                                .Timescale_Aggregation_FunctionID as number,
                            }) as XRefFunction
                          )
                            ? (
                                find(this.props.xrefFunctions, {
                                  FunctionID: this.props.output
                                    .Timescale_Aggregation_FunctionID as number,
                                }) as XRefFunction
                              ).Function
                            : emptyStringForDropdown,
                          value: !isUndefined(
                            find(this.props.xrefFunctions, {
                              FunctionID: this.props.output
                                .Timescale_Aggregation_FunctionID as number,
                            }) as XRefFunction
                          )
                            ? (
                                find(this.props.xrefFunctions, {
                                  FunctionID: this.props.output
                                    .Timescale_Aggregation_FunctionID as number,
                                }) as XRefFunction
                              ).FunctionID
                            : "",
                        },
                      });
                    }}
                  />
                </>
              )}
              {selectedTimescaleAggregationValue.label === "CUSTOM" &&
                !isTimescaleAggregationEditable && (
                  <Link
                    to={`/clients/${clientId}/models/${modelId}/${modelInstancesFilter}/instances/${modelInstanceId}/calculations/${output.Timescale_Aggregation_BlockID}/edit`}
                    onClick={() => this.handleSelectedOutputRow(output.id)}
                  >
                    <Button
                      text="Edit Custom"
                      minimal={true}
                      intent={Intent.PRIMARY}
                    />
                  </Link>
                )}
            </Flex>
          </td>
          <td>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              css={tableCellCSS}
            >
              <Flex px={2}>
                <Button
                  text="Edit"
                  icon={IconNames.EDIT}
                  minimal={true}
                  onClick={() => this.handleEdit(output.id)}
                />
                <Divider />
                <Button
                  text="Format"
                  minimal={true}
                  icon={IconNames.TH}
                  onClick={() => this.toggleModal(output.id)}
                />
              </Flex>
            </Flex>
          </td>
        </tr>

        {isModalOpen && (
          <FormatModal
            onClose={() => this.toggleModal(output.id)}
            itemName={output.Name}
            output={output}
            formatParameter={this.findFormatParameter()}
          />
        )}
      </React.Fragment>
    );
  }

  private handleSelectedOutputRow = (id: Output["id"]) =>
    this.props.dispatch(selectOutputRow(id));

  private findFormatParameter = (): FormatParameter | undefined => {
    const { output, formatParameters } = this.props;
    if (output.FormatParameterID) {
      return formatParameters.find(
        (formatParameter) => formatParameter.id === output.FormatParameterID
      );
    }
    return undefined;
  };

  private toggleModal = (id: Output["id"]): void => {
    this.props.dispatch(selectOutputRow(id)).then(() =>
      this.setState({
        isModalOpen: !this.state.isModalOpen,
      })
    );
  };

  private handleEdit = (id: Output["id"]): void => {
    this.props
      .dispatch(selectOutputRow(id))
      .then(() => this.props.onEdit(this.props.output));
  };

  private updateOutputAggregationBlock = (
    output: Output,
    aggregationType: 0 | 1
  ) => {
    this.setState({
      loading: true,
    });
    this.props
      .dispatch(
        updateOutputAggregationBlock(
          output,
          aggregationType,
          aggregationType === 0
            ? this.state.selectedDimensionAggregationValue.value === ""
              ? null
              : this.state.selectedDimensionAggregationValue.value
            : this.state.selectedTimescaleAggregationValue.value === ""
            ? null
            : this.state.selectedTimescaleAggregationValue.value
        )
      )
      .then(() => {
        this.setState({
          loading: false,
        });
        this.props.dispatch(getOutputs(this.props.modelInstanceId));
        if (aggregationType === 0) {
          this.props.handleDimensionAggregateEditableState(undefined);
        } else {
          this.props.handleTimescaleAggregateEditableState(undefined);
        }
      });
  };
}

const mapStateToProps = (state: RootState) => ({
  formatParameters: state.formatParameters,
  modelInstancesFilter: state.instanceFilter,
  selectedOutputRow: state.selectedOutputRow,
  xrefFunctions: state.xrefFunctions,
});

export default connect(mapStateToProps)(OutputRow);
