import * as React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Tabs, Tab } from "@blueprintjs/core";
import { slice } from "lodash";
import { useCallback, useEffect } from "react";
import { ThunkDispatch } from "../../../types/redux";
import { clearSelectInputRow } from "../../../store/modules/selected_input_row";
import { clearSelectOutputRow } from "../../../store/modules/selected_output_row";

interface IProps extends RouteComponentProps<any> {
  selectedTab: string;
  dispatch: ThunkDispatch;
}

const InputOutputEditorTabs = ({ selectedTab, location, dispatch }: IProps) => {
  const pathParts = location.pathname.split("/");
  const basePathParts = slice(pathParts, 0, pathParts.length - 1);
  const inputsPath = [...basePathParts, "inputs"].join("/");
  const outputsPath = [...basePathParts, "outputs"].join("/");

  const handleClick = useCallback(() => {
    if (selectedTab === "inputs") {
      dispatch(clearSelectOutputRow());
    }
    if (selectedTab === "outputs") {
      dispatch(clearSelectInputRow());
    }
  }, [selectedTab, dispatch]);

  useEffect(() => {
    handleClick();
  }, [handleClick]);

  return (
    <Tabs
      id="action-nav-tabs"
      selectedTabId={selectedTab}
      animate={false}
      large={true}
    >
      <Tab id="inputs">
        <Link to={inputsPath}>Inputs</Link>
      </Tab>
      <Tab id="outputs">
        <Link to={outputsPath}>Outputs</Link>
      </Tab>
    </Tabs>
  );
};

export default withRouter(InputOutputEditorTabs);
