import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";
import { CalculationBlockRow } from "../../types/models";
import { trimEnd, trimStart } from "lodash";

interface Props {
  calculationBlockRow: CalculationBlockRow;
  onClose: () => void;
  handleSave: (comment: string | null) => void;
}

const Comment: FunctionComponent<Props> = (props) => {
  const [comment, setComment] = useState<string>(
    props.calculationBlockRow.Comment || ""
  );

  return (
    <Dialog
      title="Comment"
      isOpen={true}
      onClose={props.onClose}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <Box className={Classes.DIALOG_BODY}>
        <FormGroup helperText={255 - comment.length + " characters left"}>
          <TextArea
            fill={true}
            placeholder="Comment"
            value={comment}
            onChange={(e) =>
              setComment(trimStart(e.currentTarget.value.substring(0, 255)))
            }
            style={{ height: "300px" }}
            growVertically
          />
        </FormGroup>
      </Box>
      <Flex className={Classes.DIALOG_FOOTER} justifyContent="space-between">
        <Button
          text="Delete"
          intent={Intent.DANGER}
          onClick={() => props.handleSave(null)}
        />
        <Box className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" intent={Intent.NONE} onClick={props.onClose} />
          <Button
            text="Save"
            intent={Intent.PRIMARY}
            onClick={() => props.handleSave(trimEnd(comment))}
          />
        </Box>
      </Flex>
    </Dialog>
  );
};

export default Comment;
