import * as React from "react";
import "styled-components/macro";
import { connect } from "react-redux";
import { Box, Flex } from "@rebass/grid";
import { Button, ButtonGroup } from "@blueprintjs/core";

import { ThunkDispatch } from "../../../types/redux";
import { RootState } from "../../../store/reducer";

import { getOutputs } from "../../../store/modules/outputs";
import { getInputs } from "../../../store/modules/inputs";
import { getDimensions } from "../../../store/modules/dimensions";
import { getXRefFunctions } from "../../../store/modules/xref_functions";
import { getXRefCurveSubTypes } from "../../../store/modules/xref_curve_sub_types";
import { getBlockSources } from "../../../store/modules/block_sources";
import { getFormatParameters } from "../../../store/modules/format_parameter";
import {
  getModelInstance,
  selectors as modelInstanceSelectors,
} from "../../../store/modules/model_instances";
import {
  getCalculationBlocks,
  selectors as calculationBlockSelectors,
} from "../../../store/modules/calculation_blocks";
import {
  actions,
  getCalculationBlockRows,
  CalculationBlockRowTypes,
} from "../../../store/modules/calculation_block_rows";
import { CalculationBlockRowTreeState } from "../../../store/modules/calculation_block_row_tree";

import {
  BlockSource,
  CalculationBlockRow,
  CalculationBlock,
  Dimension,
  Input,
  Output,
  ModelInstance,
  XRefFunction,
  XRefCurveSubType,
  SchemaFunction,
  ModelInstanceFilter,
} from "../../../types/models";

import { Size, MaxBound } from "../../atoms/Layout";
import Loading from "../../molecules/Loading";
import Sidebar from "../../organisms/Sidebar";
import StatusBar from "./StatusBar";
import RowTable from "./RowTable";
import NewRow from "./NewRow";
import BlockSourcesTable from "../../organisms/BlockSourcesTable";
import CreateInputSourceModal from "../../modals/CreateInputSource";
import CreateOutputSourceModal from "../../modals/CreateOutputSource";
import { clearSelectCalculationRow } from "../../../store/modules/selected_calculation_row";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { filter, get, isEmpty, isUndefined } from "lodash";

interface IProps extends RouteComponentProps {
  schemaFunctions: SchemaFunction[];
  blockSources: BlockSource[];
  calculationBlock: CalculationBlock;
  calculationBlockId: string;
  calculationBlocks: CalculationBlock[];
  calculationBlockRows: CalculationBlockRow[];
  dispatch: ThunkDispatch;
  dimensions: Dimension[];
  inputs: Input[];
  outputs: Output[];
  modelInstance: ModelInstance;
  modelInstanceId: string;
  xrefFunctions: XRefFunction[];
  xrefCurveSubTypes: XRefCurveSubType[];
  clientId: string;
  modelId: string;
  modelInstancesFilter: ModelInstanceFilter;
  calculationBlockRowTree: CalculationBlockRowTreeState;
}

interface IState {
  dimension?: Dimension;
  isLoading: boolean;
  showInputSourceModal: boolean;
  showOutputSourceModal: boolean;
}

class CalculationRowEditor extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    showInputSourceModal: false,
    showOutputSourceModal: false,
  };

  public componentDidMount() {
    const { calculationBlockId, dispatch, modelInstanceId } = this.props;
    const calculationBlockIdInt = parseInt(calculationBlockId, 10);
    const modelInstanceIdInt = parseInt(modelInstanceId, 10);
    Promise.all([
      dispatch(getFormatParameters(Number(modelInstanceId))),
      dispatch(getXRefFunctions()),
      dispatch(getXRefCurveSubTypes()),
      dispatch(getModelInstance(modelInstanceIdInt)),
      dispatch(getCalculationBlocks(modelInstanceIdInt)),
      dispatch(
        getCalculationBlockRows(modelInstanceIdInt, calculationBlockIdInt)
      ),
      dispatch(getDimensions(modelInstanceIdInt)),
      dispatch(getOutputs(modelInstanceIdInt)),
      dispatch(getInputs(modelInstanceIdInt)),
      dispatch(getBlockSources(modelInstanceIdInt)),
    ]).then(() => {
      this.setDimension();
      this.setState({ isLoading: false });
      const nonNestedCalculationBlockRows = filter(
        this.props.calculationBlockRows,
        (br) => "Nested" !== br.Type
      );

      if (
        !isUndefined(this.props.location.state) &&
        !isEmpty(this.props.location.state) &&
        get(this.props.location.state, "type") === "output" &&
        isEmpty(nonNestedCalculationBlockRows)
      ) {
        this.handleAddNewRow();
      }
    });
  }

  public render() {
    const {
      calculationBlock,
      calculationBlockId,
      calculationBlocks,
      calculationBlockRows,
      dimensions,
      dispatch,
      inputs,
      outputs,
      modelInstance,
      modelInstanceId,
      clientId,
      modelId,
      xrefCurveSubTypes,
      modelInstancesFilter,
    } = this.props;
    const {
      isLoading,
      dimension,
      showInputSourceModal,
      showOutputSourceModal,
    } = this.state;

    const isAdding = calculationBlockRows.some((cbr) => !cbr.id);

    const isAggregationBlock = this.findOutput() !== undefined;

    const backNavRoute = isAggregationBlock ? `outputs` : `calculations`;
    const filteredBlockSources = this.filterBlockSources();

    const nonNestedCalculationBlockRows = filter(
      calculationBlockRows,
      (br) => "Nested" !== br.Type
    );

    return (
      <Flex flex={1}>
        <Flex flex={1}>
          {isLoading ? (
            <MaxBound mt={4}>
              <Loading />
            </MaxBound>
          ) : (
            <React.Fragment>
              <Flex
                flex={4}
                pl={`calc((100vw - ${Size.MAX_WIDTH}) / 2 + 32px )`}
              >
                <Box width={1}>
                  <StatusBar
                    backHref={`/clients/${clientId}/models/${modelId}/${modelInstancesFilter}/instances/${modelInstanceId}/${backNavRoute}`}
                    dimension={dimension}
                    calculationBlock={calculationBlock}
                    output={this.findOutput()}
                    dispatch={dispatch}
                  />
                  <Box mr={3} mb={80}>
                    <RowTable
                      calculationBlockRows={nonNestedCalculationBlockRows}
                      blockSources={filteredBlockSources}
                      dispatch={dispatch}
                      outputs={outputs}
                      modelInstanceId={modelInstanceId}
                      xrefFunctions={this.restrictFunctions()}
                      refetchBlockRows={() =>
                        dispatch(
                          getCalculationBlockRows(
                            Number(modelInstanceId),
                            Number(calculationBlockId)
                          )
                        )
                      }
                      calculationBlock={calculationBlock}
                      isOutput={
                        !isEmpty(nonNestedCalculationBlockRows)
                          ? !isEmpty(this.props.location.state)
                            ? (this.props.location.state as any).type ===
                              "output"
                            : false
                          : false
                      }
                      selectedOutput={
                        !isEmpty(nonNestedCalculationBlockRows)
                          ? get(this.props.location.state, "output")
                          : undefined
                      }
                    />
                    {!isAdding &&
                      (!isAggregationBlock ||
                        (isAggregationBlock &&
                          calculationBlockRows.length === 0)) && (
                        <NewRow onClick={this.handleAddNewRow} />
                      )}
                  </Box>
                </Box>
              </Flex>

              <Sidebar>
                <BlockSourcesTable
                  blockSources={filteredBlockSources}
                  calculationBlock={calculationBlock}
                  calculationBlocks={calculationBlocks}
                  dimensions={dimensions}
                  xrefCurveSubTypes={xrefCurveSubTypes}
                  modelInstanceId={modelInstanceId}
                />

                <Box py={3} css={{ zIndex: 0 }}>
                  <ButtonGroup fill={true}>
                    {!isAggregationBlock && (
                      <Button
                        text="New Input Source"
                        onClick={this.handleInputModalOpen}
                      />
                    )}

                    <Button
                      text="New Output Source"
                      onClick={this.handleOutputModalOpen}
                    />
                  </ButtonGroup>
                </Box>
              </Sidebar>
            </React.Fragment>
          )}
        </Flex>

        {showInputSourceModal && (
          <CreateInputSourceModal
            blockSources={this.filterBlockSources()}
            calculationBlock={calculationBlock}
            dimensions={dimensions}
            dispatch={dispatch}
            inputs={inputs}
            modelInstance={modelInstance}
            xrefCurveSubTypes={xrefCurveSubTypes}
            onClose={this.handleInputModalClose}
            onSubmit={this.handleInputSourceSubmit}
          />
        )}

        {showOutputSourceModal && (
          <CreateOutputSourceModal
            blockSources={this.filterBlockSources()}
            calculationBlock={calculationBlock}
            calculationBlocks={calculationBlocks}
            dimensions={dimensions}
            dispatch={dispatch}
            outputs={outputs}
            modelInstanceId={modelInstanceId}
            onCancel={this.handleOutputModalClose}
            onSubmit={this.handleOutputSourceSubmit}
            isStandardCalculationBlock={!isAggregationBlock}
          />
        )}
      </Flex>
    );
  }

  private handleInputModalOpen = (): void =>
    this.setState({ showInputSourceModal: true });

  private handleInputModalClose = (): void =>
    this.setState({ showInputSourceModal: false });

  private handleInputSourceSubmit = (): void =>
    this.setState({ showInputSourceModal: false });

  private handleOutputModalOpen = (): void =>
    this.setState({ showOutputSourceModal: true });

  private handleOutputModalClose = (): void =>
    this.setState({ showOutputSourceModal: false });

  private handleOutputSourceSubmit = (): void =>
    this.setState({ showOutputSourceModal: false });

  private findFunctionIndex = (): number => {
    const { calculationBlock, xrefFunctions } = this.props;
    if (calculationBlock.Type === "Dimension Aggregation") {
      return xrefFunctions.findIndex((xref) => xref.Dimension_Aggregation_Type);
    }
    if (calculationBlock.Type === "Timescale Aggregation") {
      return xrefFunctions.findIndex((xref) => xref.Timescale_Aggregation_Type);
    }
    return xrefFunctions.findIndex((xref) => xref.Standard_Type);
  };

  private handleAddNewRow = (): void => {
    const block = this.props.calculationBlock;

    const newBlockRow: CalculationBlockRow = {
      BlockID: block.id!,
      Type: CalculationBlockRowTypes.Intermediate,
      ModelInstanceID: block.ModelInstanceID,
      FunctionID: this.props.xrefFunctions[this.findFunctionIndex()].FunctionID,
      BlockRow_Number: this.props.calculationBlockRows.length,
      IsValid: false,
      isEditing: true,
      Comment: "",
    };

    this.props
      .dispatch(clearSelectCalculationRow())
      .then(() =>
        this.props.dispatch(actions.addCalculationBlockRow(newBlockRow))
      );
  };

  private setDimension = (): void => {
    const { calculationBlock, dimensions } = this.props;

    const dimension = dimensions.find(
      (dim: Dimension) => calculationBlock.DimensionID === dim.id
    );

    this.setState({ dimension });
  };

  private filterBlockSources = () => {
    const {
      calculationBlock,
      calculationBlockRows,
      blockSources,
      inputs,
      outputs,
    } = this.props;

    const filtered = blockSources.filter(
      (blockSource: BlockSource) => blockSource.BlockID === calculationBlock.id
    );

    filtered.forEach((data) => {
      if (data.id !== null) {
        if (data.OutputID) {
          const blockSourceOutput = outputs.find(
            (output) => output.id === data.OutputID
          );

          if (blockSourceOutput !== undefined) {
            data.sourceName = blockSourceOutput.Name;
            data.sourceCode = `S${data.MDA_Source_Code}`;
          }
        } else if (data.InputID) {
          const blockSourceInput = inputs.find(
            (input) => input.id === data.InputID
          );

          if (blockSourceInput !== undefined) {
            data.sourceName = blockSourceInput.Name;
            data.sourceCode = `S${data.MDA_Source_Code}`;
          }
        } else if (data.BlockRowID) {
          const blockRow = calculationBlockRows.find(
            (calculationBlockRow) => calculationBlockRow.id === data.BlockRowID
          );

          if (blockRow) {
            data.sourceCode = `R${data.MDA_Source_Code}`;

            if (blockRow.OutputID) {
              const blockRowOutput = outputs.find(
                (output) => output.id === blockRow.OutputID
              );
              if (blockRowOutput) {
                data.sourceName = blockRowOutput.Name;
              }
            } else {
              data.sourceName = blockRow.Name;
            }
          }
        }
      }
    });

    return filtered;
  };

  private findOutput = (): Output => {
    const { outputs, calculationBlock } = this.props;

    if (undefined === calculationBlock) {
      return calculationBlock;
    }

    if (calculationBlock.Type === "Dimension Aggregation") {
      return outputs.find(
        (output) => calculationBlock.id === output.Dimension_Aggregation_BlockID
      ) as Output;
    } else {
      return outputs.find(
        (output) => calculationBlock.id === output.Timescale_Aggregation_BlockID
      ) as Output;
    }
  };

  private restrictFunctions = (): XRefFunction[] => {
    const { calculationBlock, xrefFunctions, modelInstance, schemaFunctions } =
      this.props;

    const timeFilteredSchemaFunctions = schemaFunctions.filter(
      (schemaFuncion) =>
        schemaFuncion.Timescale.some(
          (timescale) => timescale === modelInstance.Timescale
        )
    );

    const filteredXrefFunctions = xrefFunctions.filter((xref) =>
      timeFilteredSchemaFunctions.some(
        (timeFiltered) =>
          xref.Function.toLowerCase() === timeFiltered.Name.toLowerCase()
      )
    );

    if (calculationBlock.Type === "Dimension Aggregation") {
      return filteredXrefFunctions.filter(
        (func) => func.Dimension_Aggregation_Type
      );
    }
    if (calculationBlock.Type === "Timescale Aggregation") {
      return filteredXrefFunctions.filter(
        (func) => func.Timescale_Aggregation_Type
      );
    }
    return filteredXrefFunctions.filter((func) => func.Standard_Type);
  };
}

const mapStateToProps = (state: RootState, ownProps: any) => {
  const calculationBlockIdInt = parseInt(ownProps.calculationBlockId, 10);
  const modelInstanceIdInt = parseInt(ownProps.modelInstanceId, 10);

  return {
    schemaFunctions: state.functions.schema,
    blockSources: state.blockSources,
    calculationBlock: calculationBlockSelectors.findById(
      state,
      calculationBlockIdInt
    ),
    calculationBlocks: state.calculationBlocks,
    calculationBlockRows: state.calculationBlockRows,
    dimensions: state.dimensions,
    inputs: state.inputs,
    outputs: state.outputs,
    modelInstance: modelInstanceSelectors.findById(state, modelInstanceIdInt),
    xrefFunctions: state.xrefFunctions,
    xrefCurveSubTypes: state.xrefCurveSubTypes,
    modelInstancesFilter: state.instanceFilter,
    calculationBlockRowTree: state.calculationBlockRowTree,
  };
};

export default connect(mapStateToProps)(withRouter(CalculationRowEditor));
