import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import {
  Input,
  InputBlockMapping,
  InputDimensionMapping,
} from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type InputsState = Input[];

const initialState: InputsState = [];

const sortInputResponseObjects = (
  a: InputResponseObject,
  b: InputResponseObject
) => {
  const aName = a.input.Name.toLowerCase();
  const bName = b.input.Name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

const sortInputs = (a: Input, b: Input) => {
  const aName = a.Name.toLowerCase();
  const bName = b.Name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

interface InputResponseObject {
  blockMapping: InputBlockMapping[];
  dimensionMapping: InputDimensionMapping[];
  input: Input;
}

const buildInputState = (payload: InputResponseObject[]): InputsState =>
  payload.map(buildInput);

const buildInput = (data: InputResponseObject) => {
  const { input, blockMapping, dimensionMapping } = data;
  input.blockMapping = blockMapping;
  input.dimensionMapping = dimensionMapping;
  return input;
};

export default function reducer(
  state = initialState,
  action: RootAction
): InputsState {
  switch (action.type) {
    case getType(actions.setInputs):
      return buildInputState(action.payload.sort(sortInputResponseObjects));
    case getType(actions.createInput):
      return state.concat([buildInput(action.payload)]).sort(sortInputs);
    case getType(actions.updateInput):
      const updatedInput = buildInput(action.payload);
      return state
        .map((input: Input) => {
          return input.id === updatedInput.id ? updatedInput : input;
        })
        .sort(sortInputs);
    case getType(actions.deleteInput):
      return state.filter((input: Input) => input.id !== action.payload.id);
    default:
      return state;
  }
}

export const actions = {
  setInputs: createAction("@inputs/setInputs")<InputResponseObject[]>(),
  createInput: createAction("@inputs/createInput")<InputResponseObject>(),
  updateInput: createAction("@inputs/updateInput")<InputResponseObject>(),
  deleteInput: createAction("@inputs/deleteInput")<Input>(),
};

export const getInputs =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/inputs`)
      .then((response) => dispatch(actions.setInputs(response)));
  };

export const saveInput =
  (
    input: Input,
    blocks: number[],
    dimensions: number[]
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    const payload = {
      input,
      blocks,
      dimensions,
    };

    if (input.id) {
      return api
        .post(`/instances/${input.ModelInstanceID}/inputs/${input.id}`, payload)
        .then((response) => dispatch(actions.updateInput(response)));
    } else {
      return api
        .post(`/instances/${input.ModelInstanceID}/inputs`, payload)
        .then((response) => dispatch(actions.createInput(response)));
    }
  };

export const deleteInput =
  (input: Input): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .delete(`/instances/${input.ModelInstanceID}/inputs/${input.id}`)
      .then(() => dispatch(actions.deleteInput(input)));
  };
