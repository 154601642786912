/**
 * Node State
 */
import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { Node, CloneSubtree } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type NodeState = Node[];

const initialState: NodeState = [];

// Reducer
export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.updateNode):
      return state.map((updatedNode) =>
        updatedNode.id === action.payload.id ? action.payload : updatedNode
      );
    case getType(actions.resetNodes):
      return initialState;
    case getType(actions.setNodes):
      return action.payload;
    case getType(actions.addNodes):
      const newState = state.concat(action.payload);

      // If new node is a child, add to parent's child_nodes array
      action.payload.forEach((node) => {
        if (node.parent_node) {
          const parentIndex = newState.findIndex(
            (n) => n.id === node.parent_node
          );
          newState[parentIndex].child_nodes.push(node.id);
        }
      });

      return newState;
    case getType(actions.updateNodes):
      // FIXME: Refactor this whole action-reducer handler
      const [addedNodes, deletedNodes] = action.payload;

      let updatedState = [...state, ...addedNodes];

      // If new node is a child, add to parent's child_nodes array
      addedNodes.forEach((node) => {
        if (node.parent_node) {
          const parentIndex = updatedState.findIndex(
            (n) => n.id === node.parent_node
          );
          updatedState[parentIndex].child_nodes.push(node.id);
        }
      });

      // Deleted nodes need to have their id's removed from their parent node `child_nodes` attr
      deletedNodes.forEach((node) => {
        if (node.parent_node) {
          const parentIndex = updatedState.findIndex(
            (n) => n.id === node.parent_node
          );
          updatedState[parentIndex].child_nodes = updatedState[
            parentIndex
          ].child_nodes.filter((childNodeId) => childNodeId !== node.id);
        }
      });

      // * Remove deleted nodes from the state list since they are now "deleted"
      updatedState = updatedState.filter((n) => {
        return -1 === deletedNodes.findIndex((dn) => dn.id === n.id);
      });

      return updatedState;
    default:
      return state;
  }
}

// Actions
export const actions = {
  setNodes: createAction("@nodes/setNodes")<Node[]>(),
  addNodes: createAction("@nodes/addNodes")<Node[]>(),
  updateNodes:
    createAction("@nodes/updateNodes")<[Node[], Array<Partial<Node>>]>(),
  resetNodes: createAction("@nodes/resetNodes")(),
  updateNode: createAction("@node/updateNode")<Node>(),
};

export const getNodes =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/nodes`)
      .then((nodes) => dispatch(actions.setNodes(nodes)));
  };

export const addNodes =
  (
    modelInstanceId: number,
    newNodes: Array<Partial<Node>>
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post(`/instances/${modelInstanceId}/nodes`, newNodes)
      .then((nodes: Node[]) => dispatch(actions.addNodes(nodes)))
      .catch((e) => {
        console.log("addNodes error:", e);
      });
  };

export const updateNodes =
  (
    modelInstanceId: number,
    newNodes: Array<Partial<Node>>,
    deletedNodes: Array<Partial<Node>>
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    const apiAdd = newNodes.length
      ? api.post(`/instances/${modelInstanceId}/nodes`, newNodes)
      : Promise.resolve(newNodes);
    const apiDelete = api.delete(
      `/instances/${modelInstanceId}/nodes`,
      deletedNodes
    );

    return Promise.all([apiAdd, apiDelete]).then(([addedNodes]) => {
      dispatch(actions.updateNodes([addedNodes, deletedNodes]));
    });
  };

export const updateNode =
  (modelInstanceId: number, node: Node): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post(`/instances/${modelInstanceId}/nodes/${node.id}`, node)
      .then((updatedNode) => {
        dispatch(actions.updateNode(updatedNode));
      });
  };

export const cloneSubtree =
  (
    modelInstanceId: number,
    dimensionInstanceId: number,
    node: CloneSubtree
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api.post(
      `/instances/${modelInstanceId}/node_replica/${dimensionInstanceId}`,
      {
        ...node,
      }
    );
  };
