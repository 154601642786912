import * as React from "react";
import "styled-components/macro";
import Space from "styled-space";
import { Box, Flex } from "@rebass/grid";
import {
  Button,
  ButtonGroup,
  Intent,
  NonIdealState,
  Classes,
  Text,
  FormGroup,
  HTMLSelect,
  Tag,
} from "@blueprintjs/core";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { IconNames } from "@blueprintjs/icons";

import CalcBlockOrderCard from "../CalcBlockOrderCard";

import {
  StandardCalculationBlock,
  BlockInstanceMapping,
  DimensionInstance,
  Dimension,
  ModelInstanceFilter,
} from "../../../types/models";
import { Link } from "react-router-dom";
import { saveBlockInstanceMapping } from "../../../store/modules/block_instance_mapping";
import { ThunkDispatch } from "../../../types/redux";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducer";

interface IProps {
  dispatch: ThunkDispatch;
  selectedCalculationBlocks: StandardCalculationBlock[];
  dimension: Dimension;
  selectedInstance: DimensionInstance;
  allCalculationBlocks: StandardCalculationBlock[];
  blockInstances: BlockInstanceMapping[];
  clientId: number;
  modelId: number;
  modelInstanceId: number;
  modelInstancesFilter: ModelInstanceFilter;
}

interface IState {
  instanceHasBlocks: boolean;
  isAddingBlockInstance: boolean;
  newBlockInstance: BlockInstanceMapping;
  submitDisabled: boolean;
}

class InstanceBlockMapping extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      instanceHasBlocks: this.props.allCalculationBlocks.length > 0,
      isAddingBlockInstance: false,
      newBlockInstance: {
        InstanceID: 0,
        BlockID: 0,
        Block_Order: 0,
        ModelInstanceID: 0,
      },
      submitDisabled: true,
    };
  }

  public render() {
    const { instanceHasBlocks, isAddingBlockInstance } = this.state;
    const {
      selectedInstance,
      selectedCalculationBlocks,
      clientId,
      modelId,
      modelInstanceId,
      modelInstancesFilter,
    } = this.props;
    return (
      <Flex flexDirection="column" mt={3}>
        {!instanceHasBlocks && selectedInstance.Name !== "" && (
          <NonIdealState
            title="No Calculation Blocks"
            description="Create calculation blocks to start assigning them to dimensions"
            action={
              <Link
                to={`/clients/${clientId}/models/${modelId}/${modelInstancesFilter}/instances/${modelInstanceId}/calculations`}
              >
                <Button
                  text="Create Calculation Blocks"
                  minimal={true}
                  fill={true}
                  intent={Intent.PRIMARY}
                />
              </Link>
            }
          />
        )}

        {instanceHasBlocks && selectedInstance.Name !== "" && (
          <Flex flexDirection="column">
            <Space mb={2}>
              {this.calculationBlockOptions().length <= 1 &&
              selectedCalculationBlocks.length === 0 ? (
                <React.Fragment>
                  <NonIdealState
                    title="No Calculation Blocks for this Dimension"
                    action={
                      <Link
                        to={`/clients/${clientId}/models/${modelId}/${modelInstancesFilter}/instances/${modelInstanceId}/calculations`}
                      >
                        <Button
                          text="Create Calculation Blocks"
                          minimal={true}
                          fill={true}
                          intent={Intent.PRIMARY}
                        />
                      </Link>
                    }
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={{ marginBottom: "20px" }}>
                    <Text className={Classes.TEXT_LARGE}>
                      Calculation Block Order:
                    </Text>
                  </div>
                  <Flex
                    alignItems="inherit"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(droppable) => (
                          <div
                            style={{ marginBottom: "10px" }}
                            ref={droppable.innerRef}
                            {...droppable.droppableProps}
                          >
                            {selectedCalculationBlocks.map((block, idx) => (
                              <Flex key={block.id}>
                                <Box style={{ margin: "auto 8px auto" }}>
                                  <Tag minimal={true}>{idx + 1}</Tag>
                                </Box>
                                <Draggable
                                  key={idx}
                                  draggableId={`${idx}`}
                                  index={idx}
                                >
                                  {(draggable) => (
                                    <Box
                                      css={{ width: "100%" }}
                                      ref={draggable.innerRef}
                                      {...draggable.draggableProps}
                                      {...draggable.dragHandleProps}
                                    >
                                      <CalcBlockOrderCard
                                        calculationBlock={block}
                                        onRemove={
                                          this.handleRemoveBlockInstanceMapping
                                        }
                                      />
                                    </Box>
                                  )}
                                </Draggable>
                              </Flex>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Flex>
                </React.Fragment>
              )}

              {isAddingBlockInstance && (
                <Flex>
                  <Flex flex={1}>
                    <FormGroup style={{ width: "100%", marginBottom: "0" }}>
                      <HTMLSelect
                        onChange={this.handleChange}
                        options={this.calculationBlockOptions()}
                        fill={true}
                        disabled={this.calculationBlockOptions().length <= 1}
                      />
                    </FormGroup>
                  </Flex>

                  <Flex ml={2}>
                    <ButtonGroup minimal={true}>
                      <Button
                        icon={IconNames.CROSS}
                        onClick={this.toggleAddBlockInstance}
                        disabled={this.calculationBlockOptions().length <= 1}
                      />
                      <Button
                        icon={IconNames.TICK}
                        onClick={this.handleCreateBlockInstance}
                        disabled={
                          this.calculationBlockOptions().length <= 1 ||
                          this.state.submitDisabled
                        }
                      />
                    </ButtonGroup>
                  </Flex>
                </Flex>
              )}
              {!isAddingBlockInstance &&
                this.calculationBlockOptions().length > 1 && (
                  <Button
                    text="Add Block"
                    icon={IconNames.PLUS}
                    fill={true}
                    className={Classes.TEXT_SMALL}
                    onClick={this.toggleAddBlockInstance}
                  />
                )}
            </Space>
          </Flex>
        )}
      </Flex>
    );
  }

  private onDragEnd = (result: DropResult) => {
    const { dispatch, blockInstances } = this.props;

    if (!result.destination) {
      return;
    }

    const movedCardFromIndex = result.source.index;
    const movedCardToIndex = result.destination.index;

    blockInstances.splice(
      movedCardToIndex,
      0,
      blockInstances.splice(movedCardFromIndex, 1)[0]
    );

    dispatch(
      saveBlockInstanceMapping(
        blockInstances.map((bi, idx) => {
          return {
            ...bi,
            Block_Order: idx + 1,
            id: undefined,
          };
        }),
        this.props.modelInstanceId,
        Number(this.props.selectedInstance.id)
      )
    );
  };

  private handleChange = (e: any): void => {
    if (e.currentTarget.value !== "0") {
      this.setState({
        newBlockInstance: {
          InstanceID: this.props.selectedInstance.id as number,
          BlockID: Number(e.currentTarget.value),
          Block_Order: this.props.blockInstances.length + 1,
          ModelInstanceID: this.props.modelInstanceId,
        },
        submitDisabled: false,
      });
    } else {
      this.setState({ submitDisabled: true });
    }
  };

  private toggleAddBlockInstance = (): void => {
    this.setState({
      isAddingBlockInstance: !this.state.isAddingBlockInstance,
      submitDisabled: true,
      newBlockInstance: {
        InstanceID: 0,
        BlockID: 0,
        Block_Order: 0,
        ModelInstanceID: 0,
      },
    });
  };

  private handleCreateBlockInstance = () => {
    const mappings: BlockInstanceMapping[] = this.props.blockInstances.map(
      (bi) => {
        delete bi.id;
        return { ...bi };
      }
    );

    mappings.push(this.state.newBlockInstance);

    this.props.dispatch(
      saveBlockInstanceMapping(
        mappings,
        this.props.modelInstanceId,
        this.props.selectedInstance.id!
      )
    );
    this.setState({ submitDisabled: true });
  };

  private handleRemoveBlockInstanceMapping = (
    calculationBlock: StandardCalculationBlock
  ) => {
    const { blockInstances } = this.props;

    const updatedMappings = blockInstances
      .filter((bi) => {
        return bi.BlockID !== calculationBlock.id;
      })
      .map((bi, idx) => {
        delete bi.id;
        return { ...bi, Block_Order: idx + 1 };
      });

    this.props.dispatch(
      saveBlockInstanceMapping(
        updatedMappings,
        this.props.modelInstanceId,
        this.props.selectedInstance.id!
      )
    );
  };

  private calculationBlockOptions = () => {
    const { selectedCalculationBlocks, allCalculationBlocks, dimension } =
      this.props;

    const filterForTypeAndDimension = allCalculationBlocks.filter(
      (calculationBlock) =>
        calculationBlock.Type === "Standard" &&
        calculationBlock.DimensionID === dimension.id
    );

    const finalFilteredArray = filterForTypeAndDimension.filter(
      (allBlock) =>
        selectedCalculationBlocks.findIndex(
          (selectedBlock) => allBlock.id === selectedBlock.id
        ) === -1
    );

    const defaultOption = {
      label: "Calculation Blocks",
      value: 0,
    };

    const options = finalFilteredArray.map((calcBlock) => ({
      label: calcBlock.Name,
      value: calcBlock.id as number,
    }));

    return [defaultOption, ...options];
  };
}

const mapStateToProps = (state: RootState) => ({
  modelInstancesFilter: state.instanceFilter,
});

export default connect(mapStateToProps)(InstanceBlockMapping);
