import "styled-components/macro";
import React, { Fragment, FunctionComponent, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Classes, Menu, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box, Flex } from "@rebass/grid";

import { Model, Profile } from "../../../../types/models";
import { isModelOwner } from "../../../../models/profile";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";

interface OwnProps {
  clientId: number;
  model: Model;
  toggleModal: (model: Model) => void;
  profile: Profile;
}

type Props = OwnProps;

const ModelCard: FunctionComponent<Props> = ({
  clientId,
  model,
  toggleModal,
  profile,
}) => (
  <Fragment>
    <Link
      to={`/clients/${clientId}/models/${model.id}/current`}
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <Card interactive={true}>
        <Flex>
          <Box flex={1} alignSelf="center" className={Classes.TEXT_LARGE}>
            {model.Name}
          </Box>
          <div onClick={(e: MouseEvent) => e.preventDefault()}>
            <Popover2
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <MenuItem2
                    icon={IconNames.EDIT}
                    text="Rename Model"
                    onClick={() => toggleModal(model)}
                    disabled={!isModelOwner(profile, model.id)}
                  />
                </Menu>
              }
            >
              <Button
                icon={IconNames.MORE}
                minimal={true}
                small={true}
                onClick={(e: MouseEvent) => e.preventDefault()}
              />
            </Popover2>
          </div>
        </Flex>
      </Card>
    </Link>
  </Fragment>
);

export default ModelCard;
