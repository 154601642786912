import { createAction, getType } from "typesafe-actions";
import { uniqBy } from "lodash";
import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export interface CalculationBlockRowTree {
  historyId?: number;
  css?: string;
  index?: number;
  id: number;
  text: string;
  href?: Location["pathname"];
}

export type CalculationBlockRowTreeState = CalculationBlockRowTree[];

const initialState: CalculationBlockRowTreeState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setCalculationBlockRowTree): {
      return uniqBy(state.concat(action.payload), "href");
    }
    case getType(actions.removeCalculationBlockRowTree): {
      return state.filter((s) => s.id !== action.payload);
    }
    case getType(actions.clearCalculationBlockRowTree): {
      return [];
    }
    case getType(actions.changeCalculationBlockRowTree): {
      return action.payload;
    }
    default:
      return state;
  }
}

export const actions = {
  setCalculationBlockRowTree: createAction(
    "@calculationBlock/setTree"
  )<CalculationBlockRowTree>(),
  removeCalculationBlockRowTree: createAction("@calculationBlock/removeTree")<
    CalculationBlockRowTree["id"]
  >(),
  clearCalculationBlockRowTree: createAction("@calculationBlock/clearTree")(),
  changeCalculationBlockRowTree: createAction("@calculationBlock/changeTree")<
    CalculationBlockRowTree[]
  >(),
};

export const setCalculationBlockRowTree =
  (treeVal: CalculationBlockRowTree): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.setCalculationBlockRowTree(treeVal))
    );
  };

export const removeCalculationBlockRowTree =
  (id: CalculationBlockRowTree["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.removeCalculationBlockRowTree(id))
    );
  };

export const clearCalculationBlockRowTree =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.clearCalculationBlockRowTree())
    );
  };

export const changeCalculationBlockRowTree =
  (treeVal: CalculationBlockRowTree[]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.changeCalculationBlockRowTree(treeVal))
    );
  };
