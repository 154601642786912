import * as React from "react";
import api from "../../../services/api";
import { Box, Flex } from "@rebass/grid";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, MultiSelect2 } from "@blueprintjs/select";
import { includes } from "lodash";

import { ThunkDispatch } from "../../../types/redux";

import { highlightText } from "../../organisms/ChildSelector/helpers";
import {
  updateBlockSource,
  deleteBlockSource,
} from "../../../store/modules/block_sources";
import { CrossTypes } from "../../../constants/cross_types";

import { BlockSource, CalculationBlock } from "../../../types/models";
import { MenuItem2 } from "@blueprintjs/popover2";

interface IProps {
  blockSource: BlockSource;
  calculationBlock: CalculationBlock;
  calculationBlocks: CalculationBlock[];
  dispatch: ThunkDispatch;
  modelInstanceId: string;
  onClose(): void;
}

interface IState {
  editedBlockSource: BlockSource;
  errorMessageCalculationBlocks: string;
  selectedBlocks: CalculationBlock[];
}

class EditCrossOutputModal extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      editedBlockSource: this.props.blockSource,
      errorMessageCalculationBlocks: "",
      selectedBlocks: this.setInitialSelectedBlocks(),
    };
  }

  public render() {
    const { editedBlockSource, errorMessageCalculationBlocks, selectedBlocks } =
      this.state;

    return (
      <Dialog
        isOpen={true}
        title="Edit Cross Output"
        onClose={this.handleClose}
        className="p-input-creator"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Cross Output Type">
            <HTMLSelect
              options={CrossTypes}
              value={editedBlockSource.Cross_Output_Type}
              onChange={this.handleCrossTypeSelect}
              fill={true}
            />
          </FormGroup>

          <FormGroup
            label="Source Blocks"
            labelInfo="(required)"
            helperText={errorMessageCalculationBlocks}
            intent={errorMessageCalculationBlocks ? Intent.DANGER : Intent.NONE}
          >
            <MultiSelect2<CalculationBlock>
              items={this.filteredCalculationBlocks()}
              itemRenderer={this.renderBlock}
              noResults={<MenuItem2 text="No Results" disabled={true} />}
              onItemSelect={this.handleBlockSelect}
              popoverProps={{ minimal: true }}
              tagRenderer={this.renderBlockTag}
              tagInputProps={{
                tagProps: { intent: Intent.NONE, minimal: true },
                onRemove: this.handleBlockRemove,
              }}
              selectedItems={selectedBlocks}
              placeholder="Select Calculation Blocks…"
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <Flex justifyContent="space-between">
            <Box>
              <Button intent={Intent.DANGER} onClick={this.handleDelete}>
                Delete
              </Button>
            </Box>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button intent={Intent.PRIMARY} onClick={this.handleSave}>
                Update Output
              </Button>
            </div>
          </Flex>
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => this.props.onClose();

  private handleSave = (): void => {
    const { dispatch, onClose } = this.props;
    const { editedBlockSource, selectedBlocks } = this.state;

    const isValid = selectedBlocks.length > 0;

    if (isValid) {
      const blockMapping = selectedBlocks.map((block: CalculationBlock) => ({
        ModelInstanceID: editedBlockSource.ModelInstanceID,
        BlockSourceID: editedBlockSource.id as number,
        BlockID: block.id as number,
      }));

      const updatedBlockSource = {
        ...editedBlockSource,
        blockMapping,
      };

      dispatch(updateBlockSource(updatedBlockSource)).then(() => onClose());
    } else {
      this.setState({
        errorMessageCalculationBlocks: "Please select a Calculation Block",
      });
    }
  };

  private handleDelete = () => {
    const { dispatch, blockSource, modelInstanceId } = this.props;

    api
      .get(
        `/instances/${modelInstanceId}/block_source_function_parameters/${blockSource.id}`
      )
      .then((result) => {
        let confirmedDelete = true;
        if (result.length > 0) {
          confirmedDelete = window.confirm(
            "This Block Source is used in one or more Function Parameters. \nAre you sure you want to delete this Block Source?"
          );
        }
        if (confirmedDelete) {
          dispatch(deleteBlockSource(blockSource));
        }
      });
  };
  private setInitialSelectedBlocks = (): CalculationBlock[] => {
    const { blockSource, calculationBlocks } = this.props;

    const blockIds = blockSource.blockMapping.map((mapping) => mapping.BlockID);

    return calculationBlocks.filter((block) => includes(blockIds, block.id));
  };

  private filteredCalculationBlocks = (): CalculationBlock[] => {
    const { calculationBlock, calculationBlocks } = this.props;

    const filterDimensionId = calculationBlock.DimensionID as number;

    return calculationBlocks.filter(
      (block) => filterDimensionId === block.DimensionID
    );
  };

  private handleCrossTypeSelect = (e: any): void =>
    this.setState({
      editedBlockSource: {
        ...this.state.editedBlockSource,
        Cross_Output_Type: e.currentTarget.value,
      },
    });

  private renderBlock: ItemRenderer<CalculationBlock> = (
    block,
    { modifiers, handleClick, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const { selectedBlocks } = this.state;
    const text = `${block.Name}`;
    return (
      <MenuItem2
        active={modifiers.active}
        icon={
          selectedBlocks.indexOf(block) !== -1
            ? IconNames.TICK
            : IconNames.BLANK
        }
        key={block.id}
        onClick={handleClick}
        text={highlightText(text, query)}
        shouldDismissPopover={false}
      />
    );
  };

  private renderBlockTag = (block: CalculationBlock) => block.Name;

  private getSelectedBlockIndex = (block: CalculationBlock): number =>
    this.state.selectedBlocks.indexOf(block);

  private isBlockSelected = (block: CalculationBlock): boolean =>
    this.getSelectedBlockIndex(block) !== -1;

  private onBlockSelect = (blocks: CalculationBlock[]): void =>
    this.setState({ selectedBlocks: blocks });

  private handleBlockSelect = (block: CalculationBlock): void => {
    if (!this.isBlockSelected(block)) {
      this.selectBlock(block);
    } else {
      this.deselectBlock(this.getSelectedBlockIndex(block));
    }
  };

  private handleBlockRemove = (TAG: React.ReactNode, index: number): void =>
    this.deselectBlock(index);

  private selectBlock = (block: CalculationBlock): void =>
    this.setState(
      { selectedBlocks: [...this.state.selectedBlocks, block] },
      () => this.onBlockSelect(this.state.selectedBlocks)
    );

  private deselectBlock = (index: number): void =>
    this.setState(
      {
        selectedBlocks: this.state.selectedBlocks.filter(
          (BLOCK, i) => i !== index
        ),
      },
      () => this.onBlockSelect(this.state.selectedBlocks)
    );
}

export default EditCrossOutputModal;
