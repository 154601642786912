import { createAction, getType } from "typesafe-actions";
import { map } from "lodash";

import api from "../../services/api";

import { OutputDimensionMapping, Output } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type OutputsState = Output[];

const initialState: OutputsState = [];

const sortOutputResponseObjects = (
  a: OutputResponseObject,
  b: OutputResponseObject
) => {
  const aName = a.output.Name.toLowerCase();
  const bName = b.output.Name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

const sortOutputs = (a: Output, b: Output) => {
  const aName = a.Name.toLowerCase();
  const bName = b.Name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

interface OutputResponseObject {
  dimensionMapping: OutputDimensionMapping[];
  output: Output;
}

const buildOutputState = (payload: OutputResponseObject[]): OutputsState =>
  map(payload, buildOutput);

const buildOutput = (data: OutputResponseObject): Output => {
  const { output, dimensionMapping } = data;
  output.dimensionMapping = dimensionMapping;
  return output;
};

export default function reducer(
  state = initialState,
  action: RootAction
): OutputsState {
  switch (action.type) {
    case getType(actions.setOutputs):
      return buildOutputState(action.payload.sort(sortOutputResponseObjects));
    case getType(actions.addOutput):
      return [...state, action.payload];
    case getType(actions.createOutput):
      return state.concat([buildOutput(action.payload)]).sort(sortOutputs);
    case getType(actions.updateOutput):
      const updatedOutput = buildOutput(action.payload);
      return state
        .map((output: Output) => {
          return output.id === updatedOutput.id ? updatedOutput : output;
        })
        .sort(sortOutputs);
    case getType(actions.deleteOutput):
      return state.filter((output: Output) => output.id !== action.payload.id);
    case getType(actions.updateOutputAggregationBlock): {
      return state.map((obj) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    }
    default:
      return state;
  }
}

export const actions = {
  setOutputs: createAction("@outputs/setOutputs")<OutputResponseObject[]>(),
  addOutput: createAction("outputs/addOutput")<Output>(),
  createOutput: createAction("@outputs/createOutput")<OutputResponseObject>(),
  updateOutput: createAction("@outputs/updateOutput")<OutputResponseObject>(),
  deleteOutput: createAction("@outputs/deleteOutput")<Output>(),
  updateOutputAggregationBlock: createAction(
    "@outputs/updateOutputAggregationBlock"
  )<any>(),
};

export const getOutputs =
  (modelInstanceId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/instances/${modelInstanceId}/outputs`)
      .then((response) => dispatch(actions.setOutputs(response)));
  };

export const saveOutput =
  (output: Output): ThunkResult<Promise<any>> =>
  (dispatch) => {
    if (output.id) {
      return api
        .post(
          `/instances/${output.ModelInstanceID}/outputs/${output.id}`,
          output
        )
        .then((response: OutputResponseObject) =>
          dispatch(actions.updateOutput(response))
        );
    } else {
      return api
        .post(`/instances/${output.ModelInstanceID}/outputs`, output)
        .then((response: OutputResponseObject) =>
          dispatch(actions.createOutput(response))
        );
    }
  };

export const deleteOutput =
  (output: Output): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .delete(`/instances/${output.ModelInstanceID}/outputs/${output.id}`)
      .then(() => dispatch(actions.deleteOutput(output)));
  };

export const updateOutputAggregationBlock =
  (
    output: Output,
    aggregationType: 0 | 1,
    functionID: null | string | number
  ): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .post(
        `/instances/${output.ModelInstanceID}/outputs/update/${output.id}`,
        {
          AggregationType: aggregationType,
          FunctionID: functionID,
        }
      )
      .then(() => dispatch(actions.updateOutputAggregationBlock(output)));
  };
