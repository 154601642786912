import * as React from "react";
import "styled-components/macro";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import * as R from "ramda";
import { Row, Column } from "../../../../atoms/Layout";
import NodeCard from "../../../../molecules/NodeCard";
import { Tag, Text, Classes, Divider, Switch, H3 } from "@blueprintjs/core";
import { Box, Flex } from "@rebass/grid";
import {
  Dimension,
  Node,
  DimensionInstance,
  StandardCalculationBlock,
  BlockNodeMapping,
  ModelInstanceFilter,
} from "../../../../../types/models";
import { RootState } from "../../../../../store/reducer";
import Sidebar from "../../../../organisms/Sidebar";
import ChildSelector from "../../../../organisms/ChildSelector";
import NodeBlockMapping from "../../../../organisms/NodeBlockMapping";
import {
  getAllBlockNodeMappings,
  deleteBlockNodeMappings,
  BlockNodeMappingState,
} from "../../../../../store/modules/block_node_mapping";
import {
  updateNodes,
  updateNode,
  getNodes,
  cloneSubtree,
} from "../../../../../store/modules/nodes";
import { ThunkDispatch } from "../../../../../types/redux";
import DeleteAllDescendents from "./DeleteAllDescendents";

interface IProps extends RouteComponentProps<any> {
  calculationBlocks: StandardCalculationBlock[];
  blockNodeMappings: BlockNodeMappingState;
  dispatch: ThunkDispatch;
  dimensions: Dimension[];
  nodes: Node[];
  modelInstancesFilter: ModelInstanceFilter;
}

interface IDeleteAllDescendentsModel {
  isOpen: boolean;
  description: string;
  selectedInstances: DimensionInstance[];
}

interface IState {
  isLoading: boolean;
  activeDimensionIndex: number;
  activeNodeName: string;
  activeNodePath: Array<Node | undefined>;
  selectedNode?: Node;
  viewNodeSidebar: boolean;
  isSaving: boolean;
  deleteAllDescendentsModel: IDeleteAllDescendentsModel;
}

const DeleteAllDescendentsModelDescriptions = {
  usingOptionMenu:
    "Are you sure you want to delete all descendents of this node? Please type YES to continue.",
};

const headerClasses = classnames(Classes.TEXT_LARGE, Classes.TEXT_MUTED);

const containerCSS = {
  overflow: "auto",
  height: `calc(100vh - 180px)`,
  paddingTop: "20px",
};

const rowCSS = {
  height: "100%",
  paddingTop: "36px",
  gridGap: "10px",
};

const columnCSS = {
  minWidth: "200px",
  "&:last-child": {
    paddingRight: "120px",
    paddingBottom: "20px",
  },
};

class NodeEditorView extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    viewNodeSidebar: false,
    activeDimensionIndex: 0,
    activeNodeName: "Start",
    activeNodePath: [],
    isSaving: false,
    deleteAllDescendentsModel: {
      isOpen: false,
      description: "",
      selectedInstances: [],
    },
  };

  public componentDidMount() {
    const { dispatch } = this.props;
    const { instance: modelInstanceId } = this.props.match.params;
    dispatch(getAllBlockNodeMappings(modelInstanceId)).then(() =>
      this.setState({ isLoading: false })
    );
  }

  public render() {
    const { dispatch, dimensions, calculationBlocks, modelInstancesFilter } =
      this.props;

    const {
      isSaving,
      selectedNode,
      activeNodeName,
      activeDimensionIndex,
      activeNodePath,
      viewNodeSidebar,
    } = this.state;

    const nextDimension =
      activeDimensionIndex !== -1 && dimensions[activeDimensionIndex];

    return (
      <React.Fragment>
        <Flex flex={3} css={containerCSS}>
          <Row css={rowCSS}>
            {dimensions.map((dim, dimIndex) => (
              <Column key={dim.Name} css={columnCSS}>
                <Flex
                  className={headerClasses}
                  alignItems={"center"}
                  mb={4}
                  css={{ minWidth: "200px" }}
                >
                  <Box mr={2}>
                    <Tag minimal={true}>{dimIndex + 1}</Tag>
                  </Box>
                  <Text>{dim.Name}</Text>
                </Flex>
                {this.sortByInstanceName(
                  this.nodesForDimensionAtIndex(dim, dimIndex),
                  dim
                ).map((node) => (
                  <NodeCard
                    key={node.id}
                    nodeName={this.dimensionInstanceName(
                      dim,
                      node.dimension_instance.id
                    )}
                    node={node}
                    isNextDimension={dimIndex === activeDimensionIndex}
                    isOnActivePath={activeNodePath.some((n) =>
                      n ? n.id === node.id : false
                    )}
                    isSelected={
                      selectedNode ? node.id === selectedNode.id : false
                    }
                    childrenCount={this.childCount(node)}
                    dimensionIndex={dimIndex}
                    onSelect={this.handleNodeSelect}
                    disabled={isSaving}
                  />
                ))}
                <div style={{ height: "20px" }} />
              </Column>
            ))}
          </Row>
        </Flex>
        <Flex flex={1}>
          <Sidebar>
            {nextDimension && (
              <ChildSelector
                isSaving={isSaving}
                currentDimension={
                  activeDimensionIndex === 0
                    ? nextDimension
                    : dimensions[activeDimensionIndex - 1]
                }
                nextDimension={nextDimension}
                onInstanceSelect={this.handleInstanceUpdate}
                instanceName={activeNodeName}
                descendantCount={
                  selectedNode
                    ? this.calculateDescendantsForNode(selectedNode)
                    : undefined
                }
                preSelectedNodes={
                  selectedNode
                    ? this.childNodes(selectedNode)
                    : this.rootNodes()
                }
                selectedNode={selectedNode}
                onDeleteAllDescendents={() =>
                  this.onDeleteAllDescendentsModelToggle(
                    true,
                    DeleteAllDescendentsModelDescriptions.usingOptionMenu
                  )
                }
                clonableDimensionInstance={this.getPreviousDimensionInstances()}
                onCloneSubtree={this.onCloneSubtree}
              />
            )}

            {viewNodeSidebar && (
              <React.Fragment>
                {!nextDimension && (
                  <React.Fragment>
                    <Flex
                      className={Classes.TEXT_LARGE}
                      alignItems="center"
                      mb={3}
                    >
                      <Tag minimal={true}>
                        <Text>{dimensions.slice(-1)[0].Name}</Text>
                      </Tag>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between">
                      <H3>{activeNodeName}</H3>
                    </Flex>
                  </React.Fragment>
                )}
                <Divider />
                {selectedNode && (
                  <React.Fragment>
                    <Flex pt={2}>
                      <Switch
                        checked={selectedNode.BlockNodeException_Flag === 1}
                        label="Node Exception"
                        onChange={this.handleNodeException}
                      />
                    </Flex>
                    <Divider />
                    <NodeBlockMapping
                      dispatch={dispatch}
                      selectedCalculationBlocks={this.setCalculationBlocks()}
                      allCalculationBlocks={calculationBlocks}
                      blockMappings={this.getBlockNodeMappings()}
                      selectedNode={selectedNode}
                      dimension={
                        activeDimensionIndex === -1
                          ? dimensions[dimensions.length - 1]
                          : dimensions[activeDimensionIndex - 1]
                      }
                      modelInstancesFilter={modelInstancesFilter}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Sidebar>
          <DeleteAllDescendents
            isOpen={this.state.deleteAllDescendentsModel.isOpen}
            description={this.state.deleteAllDescendentsModel.description}
            onClose={() => this.onDeleteAllDescendentsModelToggle(false, "")}
            onSave={() => this.handleInstanceUpdate([], true)}
          />
        </Flex>
      </React.Fragment>
    );
  }

  private onDeleteAllDescendentsModelToggle = (
    isOpen: boolean,
    description: string
  ) =>
    this.setState((prevState) => ({
      ...prevState,
      deleteAllDescendentsModel: {
        ...prevState.deleteAllDescendentsModel,
        isOpen,
        description,
      },
    }));

  private handleNodeException = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { instance: modelInstanceId } = this.props.match.params;
    const { dispatch } = this.props;

    if (this.state.selectedNode) {
      const updatedNode = {
        ...this.state.selectedNode,
        BlockNodeException_Flag: e.currentTarget.checked ? 1 : 0,
      };

      this.setState({
        selectedNode: updatedNode,
      });
      dispatch(updateNode(modelInstanceId, updatedNode));
    }
  };

  private setCalculationBlocks = (): StandardCalculationBlock[] => {
    return this.getBlockNodeMappings().map((nodeMapping) =>
      this.props.calculationBlocks.find(
        (block) => block.id === nodeMapping.BlockID
      )
    ) as StandardCalculationBlock[];
  };

  private getBlockNodeMappings = (): BlockNodeMapping[] => {
    const { selectedNode } = this.state;
    if (!selectedNode) {
      return [];
    }
    return this.props.blockNodeMappings[selectedNode.id] || [];
  };

  private handleInstanceUpdate = (
    instances: DimensionInstance[],
    deleteAllDescendents: boolean
  ) => {
    const {
      selectedNode,
      deleteAllDescendentsModel: { selectedInstances },
    } = this.state;
    const { instance: modelInstanceId } = this.props.match.params;
    const { dispatch, nodes } = this.props;
    const instancesExtened = deleteAllDescendents
      ? selectedInstances
      : instances;
    let newNodes: Array<Partial<Node>> = instancesExtened.map((di) => {
      return {
        dimension_instance: {
          id: di.id,
          ModelInstanceID: Number(modelInstanceId),
        },
        parent_node: selectedNode ? selectedNode.id : null,
        BlockNodeException_Flag: 0,
      };
    });
    // TODO: Refactor all these loops for performance reasons.  We can probably drop
    // this down to a single `reduce` loop, or perhaps 2 `reduce` loops.
    // Also, could/shoudl this logic be moved to the `updateNodes` action so it can
    // be performed async instead of blocking here?

    // First get all existing nodes for this parent node
    const existingNodesForParent = nodes.filter(
      (n) => n.parent_node === (selectedNode ? selectedNode.id : null)
    );

    // Next, determine which, if any, of the existing nodes for this parent are no longer in this list of nodes
    // Note, this step must run before the next filter pass where we filter out already existing nodes
    const deletedNodes = existingNodesForParent.filter((e) => {
      return (
        -1 ===
        newNodes.findIndex((n) => {
          return (
            e.parent_node === n.parent_node &&
            e.dimension_instance.id === n.dimension_instance!.id
          );
        })
      );
    });

    // Finally remove any of the existing nodes, since they are already persisted and not really "new" nodes
    newNodes = newNodes.filter((n) => {
      return (
        -1 ===
        existingNodesForParent.findIndex((e) => {
          return (
            e.parent_node === n.parent_node &&
            e.dimension_instance.id === n.dimension_instance!.id
          );
        })
      );
    });

    this.setState({ isSaving: true });
    // NOTE: If any of the deleted nodes has children, we need to alert the user that they wont be deleted and remove them from the deleted nodes list
    let deletedNodesWithChildren, updatedDeletedNodes: Node[];
    [deletedNodesWithChildren, updatedDeletedNodes] = R.partition(
      (n) => n.child_nodes.length > 0,
      deletedNodes
    );
    if (deletedNodesWithChildren.length > 0 && !deleteAllDescendents) {
      this.setState((prevState) => ({
        ...prevState,
        isSaving: false,
        deleteAllDescendentsModel: {
          ...prevState.deleteAllDescendentsModel,
          selectedInstances: instances,
        },
      }));
      this.onDeleteAllDescendentsModelToggle(
        true,
        DeleteAllDescendentsModelDescriptions.usingOptionMenu
      );
    } else {
      this.setState((prevState) => ({
        ...prevState,
        deleteAllDescendentsModel: {
          ...prevState.deleteAllDescendentsModel,
          selectedInstances: [],
        },
      }));
      dispatch(
        deleteBlockNodeMappings(
          this.props.match.params.instance,
          deletedNodes.map((dn) => dn.id)
        )
      )
        .then(() => {
          return dispatch(
            updateNodes(
              this.props.match.params.instance,
              newNodes,
              deleteAllDescendents ? deletedNodes : updatedDeletedNodes
            )
          );
        })
        .then(() => {
          this.setState({ isSaving: false });
        })
        .catch(() => this.setState({ isSaving: false }));
    }
  };

  private rootNodes = (): Node[] => {
    return this.props.nodes.filter((n) => !n.parent_node);
  };

  private calculateDescendantsForNode = (node: Node) => {
    const { nodes } = this.props;

    const children: number[] = [];

    function gatherChildren(currentNode: Node) {
      children.push(...currentNode.child_nodes);

      currentNode.child_nodes.forEach((child) => {
        const childNode = nodes.find((n) => n.id === child);

        if (childNode) {
          gatherChildren(childNode);
        }
      });
    }

    gatherChildren(node);

    return children.length;
  };

  private sortByInstanceName = (nodes: Node[], dim: Dimension) => {
    return R.sort((a: Node, b: Node) => {
      if (
        this.dimensionInstanceName(dim, a.dimension_instance.id) >
        this.dimensionInstanceName(dim, b.dimension_instance.id)
      ) {
        return 1;
      } else {
        return -1;
      }
    }, nodes);
  };

  private nodesForDimensionAtIndex = (
    dimension: Dimension,
    idx: number
  ): Node[] => {
    const { nodes } = this.props;

    if (0 === idx) {
      return nodes.filter((node) => {
        return dimension.id === node.dimension;
      });
    }

    const activeNodeForDimension = this.state.activeNodePath[idx - 1];
    if (activeNodeForDimension) {
      return nodes.filter((n) => activeNodeForDimension.id === n.parent_node);
    }

    return [];
  };

  private childCount = (parent: Node): number => {
    return this.childNodes(parent).length;
  };

  private childNodes = (parent?: Node): Node[] => {
    if (parent) {
      return this.props.nodes.filter((n) => parent.id === n.parent_node);
    }
    return [];
  };

  private getPreviousDimensionInstances = (): DimensionInstance[] => {
    const { dimensions, nodes } = this.props;
    const { selectedNode, activeDimensionIndex } = this.state;

    if (activeDimensionIndex > 0) {
      const clonableDimension =
        dimensions[activeDimensionIndex - 1].dimension_instances;
      const selectedIds = this.getSelectedDimensionInstanceIds(
        activeDimensionIndex - 1,
        nodes.find(
          (n) =>
            selectedNode &&
            (selectedNode.parent_node || selectedNode.parent_node) === n.id
        )
      );

      return clonableDimension.filter((n) => !selectedIds.includes(n.id));
    }

    return [];
  };

  private getSelectedDimensionInstanceIds = (
    index: number,
    parent?: Node
  ): Array<number | undefined> => {
    if (index) {
      return this.props.nodes
        .filter((n) => parent && parent.id === n.parent_node)
        .map((n) => n.dimension_instance.id);
    }

    return this.rootNodes().map((n) => n.dimension_instance.id);
  };

  private handleNodeSelect = (
    dimensionIndex: number,
    selectedNode: Node,
    nodeName: string
  ) => {
    const { activeNodePath, activeNodeName, activeDimensionIndex } = this.state;
    const { dimensions, nodes } = this.props;
    let nextIndex = dimensionIndex + 1;

    if (nextIndex >= dimensions.length) {
      nextIndex = -1;
    }
    activeNodePath[dimensionIndex] = selectedNode;
    const sameName = nodeName === activeNodeName;
    const sameDimension = dimensionIndex === activeDimensionIndex;

    if (!sameName || (sameName && sameDimension)) {
      this.setState({
        viewNodeSidebar: true,
        activeDimensionIndex: nextIndex,
        activeNodeName: nodeName,
        activeNodePath: activeNodePath.map((n, idx) => {
          return idx > dimensionIndex ? undefined : n;
        }),
        selectedNode,
      });
    } else if (selectedNode.parent_node) {
      const parentNode = nodes.find((n) => n.id === selectedNode.parent_node);

      this.setState({
        activeDimensionIndex: dimensionIndex,
        activeNodeName: this.dimensionInstanceName(
          dimensions[dimensionIndex - 1],
          parentNode!.dimension_instance.id
        ),
        activeNodePath: activeNodePath.map((n, idx) => {
          return idx > dimensionIndex - 1 ? undefined : n;
        }),
        selectedNode: parentNode,
      });
    } else {
      this.setState({
        activeDimensionIndex: 0,
        activeNodeName: "Start",
        activeNodePath: [],
        selectedNode: undefined,
      });
    }
  };

  private dimensionInstanceName = (dim: Dimension, id?: number): string => {
    const instance = dim.dimension_instances.find((di) => id === di.id);
    return (instance && instance.Name) || "N/A";
  };

  private onCloneSubtree = (dimensionInstanceId: React.ReactText) => {
    const { selectedNode } = this.state;
    const { dispatch } = this.props;
    const modelInstanceId = this.props.match.params.instance;

    if (selectedNode) {
      const dataToPost = {
        dimension_instance: {
          ...selectedNode.dimension_instance,
          id: Number(dimensionInstanceId),
        },
        parent_node: selectedNode.parent_node,
        BlockNodeException_Flag: selectedNode.BlockNodeException_Flag,
      };

      this.setState({ isSaving: true });

      dispatch(cloneSubtree(modelInstanceId, selectedNode.id, dataToPost))
        .then(() =>
          Promise.all([
            dispatch(getNodes(modelInstanceId)),
            dispatch(getAllBlockNodeMappings(modelInstanceId)),
          ])
        )
        .then(() => this.setState({ isSaving: false }))
        .catch(() => this.setState({ isSaving: false }));
    }
  };
}

const mapStateToProps = (state: RootState) => ({
  dimensions: state.dimensions,
  nodes: state.nodes,
  calculationBlocks: state.calculationBlocks,
  blockNodeMappings: state.blockNodeMappings,
  modelInstancesFilter: state.instanceFilter,
});

export default withRouter(connect(mapStateToProps)(NodeEditorView));
