/**
 * Models state
 */
import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { Model } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export interface ModelMap {
  [id: number]: Model;
}

const initialState: ModelMap = {};

// Reducer
export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setModels):
      return action.payload;
    case getType(actions.addModel):
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case getType(actions.renameModel):
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}

// Actions
export const actions = {
  setModels: createAction("@model/setModels")<ModelMap>(),
  addModel: createAction("@model/addModel")<Model>(),
  renameModel: createAction("@model/renameModel")<Model>(),
};

// Action Creators
export const getModels =
  (client: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api.get(`/clients/${client}/models`).then((data) => {
      dispatch(
        actions.setModels(
          data.reduce((prev: ModelMap, curr: Model) => {
            prev[curr.id] = curr;
            return prev;
          }, {})
        )
      );
    });
  };

export const getModel =
  (id: number, client: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/clients/${client}/models/${id}`)
      .then((model) => dispatch(actions.addModel(model)));
  };

export const createModel =
  (client: number, Name: string): ThunkResult<Promise<any>> =>
  (dispatch) => {
    const newModel = {
      Name,
    };
    return api.post(`/clients/${client}/models`, newModel).then((data) => {
      dispatch(actions.addModel({ ...newModel, ...data }));
      return data;
    });
  };

export const renameModelName =
  (
    clientId: number,
    modelId: number,
    Name: string
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const instance = await api.post(`/clients/${clientId}/models/${modelId}`, {
      name: Name,
    });
    dispatch(actions.renameModel(instance));
  };
