import * as React from "react";
import "styled-components/macro";
import { Box, Flex } from "@rebass/grid";
import { Classes, Divider, Text } from "@blueprintjs/core";

import { Alignment } from "../ui";

import { BlockSource } from "../../../../types/models";

interface IProps {
  blockSource: BlockSource;
}

class RowSource extends React.Component<IProps> {
  public render() {
    const { blockSource } = this.props;

    return (
      <React.Fragment>
        <Alignment pb={2} className={Classes.TEXT_SMALL}>
          <Box css={{ gridColumn: "start / name-col" }}>
            <Text>{blockSource.sourceCode}</Text>
          </Box>

          <Box css={{ gridColumn: "name-col / type-col" }}>
            <Flex flexDirection="column">
              <Text>{blockSource.sourceName}</Text>
            </Flex>
          </Box>

          <Box css={{ gridColumn: "type-col / control-col" }}>
            <Text>{blockSource.Type}</Text>
          </Box>

          <Box css={{ gridColumn: "control-col / end" }} />
        </Alignment>
        <Divider />
      </React.Fragment>
    );
  }
}

export default RowSource;
