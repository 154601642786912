import * as React from "react";
import "styled-components/macro";
import classnames from "classnames";
import { Box } from "@rebass/grid";
import { Classes, Text } from "@blueprintjs/core";

import {
  BlockSourceBlockMapping,
  BlockSourceDimensionMapping,
  CalculationBlock,
  Dimension,
} from "../../../../types/models";

interface IProps {
  blockMapping: BlockSourceBlockMapping[];
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  dimensionMapping: BlockSourceDimensionMapping[];
}

const headerStyles = classnames(Classes.TEXT_SMALL, Classes.TEXT_MUTED);
const listStyles = classnames(
  Classes.TEXT_SMALL,
  Classes.LIST,
  Classes.LIST_UNSTYLED
);

class BlockSourceDetails extends React.Component<IProps> {
  public render() {
    return (
      <React.Fragment>
        <Box
          css={{
            gridColumn: "name-col / type-col",
            alignSelf: "start",
          }}
        >
          <Text className={headerStyles}>Source Blocks:</Text>

          <ul className={listStyles}>
            {this.sourceBlocks().map((block) => (
              <li key={block.id}>{block.Name}</li>
            ))}
          </ul>
        </Box>

        <Box css={{ gridColumn: "type-col / end", alignSelf: "start" }}>
          <Text className={headerStyles}>Source Dimensions:</Text>

          <ul className={listStyles}>
            {this.sourceDimensions().map((dimension) => (
              <li key={dimension.id}>{dimension.Name}</li>
            ))}
          </ul>
        </Box>
      </React.Fragment>
    );
  }

  private sourceBlocks = (): CalculationBlock[] => {
    const { blockMapping, calculationBlocks } = this.props;

    const blockIds = blockMapping.map((mapping) => mapping.BlockID);

    return blockIds.map((id) => {
      return calculationBlocks.find(
        (block) => id === block.id
      ) as CalculationBlock;
    });
  };

  private sourceDimensions = () => {
    const { dimensionMapping, dimensions } = this.props;

    const dimensionIds = dimensionMapping.map((mapping) => mapping.DimensionID);

    return dimensionIds.map((id) => {
      return dimensions.find((dimension) => id === dimension.id) as Dimension;
    });
  };
}

export default BlockSourceDetails;
