import { createAction, getType } from "typesafe-actions";

import api from "../../services/api";

import { XRefDate } from "../../types/models";
import { ThunkResult } from "../../types/redux";
import { RootAction } from "../actions";

export type XRefDateState = XRefDate[];

const initialState: XRefDateState = [];
export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.setXRefDates):
      return action.payload;
    default:
      return state;
  }
}

export const actions = {
  setXRefDates: createAction("@xref/setDates")<XRefDate[]>(),
};

export const getXRefDates =
  (clientId: number): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return api
      .get(`/xref_date/${clientId}`)
      .then((dates) => dispatch(actions.setXRefDates(dates)));
  };
