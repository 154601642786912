import * as React from "react";
import "styled-components/macro";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Flex } from "@rebass/grid";
import Space from "styled-space";
import { Button, ButtonGroup, Intent } from "@blueprintjs/core";
import { sortBy, includes, last, isEmpty } from "lodash";

import { RootState } from "../../../../store/reducer";
import {
  SchemaFunction,
  SchemaFunctionParameter,
  BlockSource,
  ModelInstance,
  CalculationBlockRow,
  Input,
  Output,
  FunctionParameter,
  XRefFunction,
  Dimension,
  CalculationBlock,
  XRefCurveSubType,
} from "../../../../types/models";
import { getBlockSources } from "../../../../store/modules/block_sources";
import {
  getCalculationBlocks,
  CalculationBlockTypes,
  selectors as calculationBlockSelectors,
} from "../../../../store/modules/calculation_blocks";
import {
  getCalculationBlockRow,
  getCalculationBlockRows,
} from "../../../../store/modules/calculation_block_rows";
import { getXRefFunctions } from "../../../../store/modules/xref_functions";
import { getXRefCurveSubTypes } from "../../../../store/modules/xref_curve_sub_types";
import { getDimensions } from "../../../../store/modules/dimensions";
import { getInputs } from "../../../../store/modules/inputs";
import { getOutputs } from "../../../../store/modules/outputs";
import api from "../../../../services/api";
import { ThunkDispatch } from "../../../../types/redux";

import Loading from "../../../molecules/Loading";
import AppViewTemplate from "../../../templates/AppView";
import FormulaEditor from "../../../organisms/FormulaEditor";
import Sidebar from "../../../organisms/Sidebar";
import BlockSourcesTable from "../../../organisms/BlockSourcesTable";
import { Size } from "../../../atoms/Layout";
import CreateInputSourceModal from "../../../modals/CreateInputSource";
import CreateOutputSourceModal from "../../../modals/CreateOutputSource";
import {
  CalculationBlockRowTreeState,
  removeCalculationBlockRowTree,
} from "../../../../store/modules/calculation_block_row_tree";
import {
  SelectedFormulaRepresentationState,
  setFormulaRepresentation,
} from "../../../../store/modules/selected_formula_representation";

interface IProps extends RouteComponentProps<any> {
  schemaFunctions: SchemaFunction[];
  blockSources: BlockSource[];
  blockRows: CalculationBlockRow[];
  inputs: Input[];
  outputs: Output[];
  xrefFunctions: XRefFunction[];
  dispatch: ThunkDispatch;
  calculationBlock: CalculationBlock;
  calculationBlocks: CalculationBlock[];
  xrefCurveSubTypes: XRefCurveSubType[];
  dimensions: Dimension[];
  modelInstances: ModelInstance[];
  calculationBlockRowTree: CalculationBlockRowTreeState;
  selectedFormulaRepresentation: SelectedFormulaRepresentationState;
}

interface IState {
  functionParameters: FunctionParameter[];
  loadingCount: number;
  isSaving: boolean;
  showInputSourceModal: boolean;
  showOutputSourceModal: boolean;
}

class FormulaEditorView extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      functionParameters: [],
      loadingCount: props.blockSources.length === 0 ? 2 : 1,
      isSaving: false,
      showInputSourceModal: false,
      showOutputSourceModal: false,
    };
  }

  public componentDidUpdate = (prevProps: IProps) => {
    const { params: matchParams } = this.props.match;
    if (prevProps.match.params.blockRowId !== matchParams.blockRowId) {
      this.loadFunctionParameters(false);
      this.props.dispatch(
        getCalculationBlockRows(matchParams.instance, matchParams.blockId)
      );
    }
  };

  public componentDidMount() {
    const { params: matchParams } = this.props.match;

    // NOTE: Handle deep-link situation where we hit this component directly via URL
    // and the react-router had not set the state.  We should return to the `/edit` page
    // in this case and force the user to navigate back via the UI.
    const routerState = this.props.location.state;
    if (!routerState) {
      this.props.history.replace(
        this.props.location.pathname.replace(/row\/\d+\/formula$/, "/edit")
      );
      return;
    }

    // Ensure we have the block sources loaded for the given block row
    // because we might hit this URL page directly, and they are only passed
    // in as props from the previous screen.  Having an empty array is valid
    // since we truly might not have any BlockSource records for this model instance
    // so we just try to get them once if the prop is empty to confirm we have at
    // least attempted to load them.  Same for XRefFunctions
    const dispatchCalls = [
      this.props.dispatch(getCalculationBlocks(matchParams.instance)),
      this.props.dispatch(getXRefCurveSubTypes()),
      this.props.dispatch(getInputs(matchParams.instance)),
      this.props.dispatch(getOutputs(matchParams.instance)),
    ];

    if (!this.props.blockRows.find((br) => br.id === matchParams.blockRowId)) {
      dispatchCalls.push(
        this.props.dispatch(
          getCalculationBlockRows(matchParams.instance, matchParams.blockId)
        )
      );
    }

    if (0 === this.props.dimensions.length) {
      dispatchCalls.push(
        this.props.dispatch(getDimensions(matchParams.instance))
      );
    }

    if (0 === this.props.blockSources.length) {
      dispatchCalls.push(
        this.props.dispatch(getBlockSources(matchParams.instance))
      );
    }

    if (0 === this.props.xrefFunctions.length) {
      dispatchCalls.push(this.props.dispatch(getXRefFunctions()));
    }

    Promise.all([dispatchCalls]).then(() =>
      this.setState({ loadingCount: this.state.loadingCount - 1 })
    );

    this.loadFunctionParameters(true);
  }

  public render() {
    const {
      blockSources,
      blockRows,
      schemaFunctions,
      xrefFunctions,
      inputs,
      outputs,
      dimensions,
      calculationBlock,
      calculationBlocks,
      xrefCurveSubTypes,
      dispatch,
      calculationBlockRowTree,
      selectedFormulaRepresentation,
    } = this.props;

    const { showInputSourceModal, showOutputSourceModal } = this.state;

    const routerState = this.props.location.state as any;
    if (!routerState) {
      return <></>;
    }
    const { functionID } = routerState;
    const { params: matchParams } = this.props.match;
    const instance = matchParams.instance;
    const blockId = parseInt(matchParams.blockId, 10);
    const blockRowId = parseInt(matchParams.blockRowId, 10);
    const modelInstance = this.props.modelInstances.find(
      (mi) => mi.id === Number(instance)
    );

    let filteredXRefFunctions = xrefFunctions;
    if (modelInstance) {
      filteredXRefFunctions = xrefFunctions.filter((xrf) => {
        const schemaFunction = schemaFunctions.find(
          (sf) => sf.Name.toLowerCase() === xrf.Function.toLowerCase()
        );
        if (schemaFunction) {
          return includes(schemaFunction.Timescale, modelInstance.Timescale);
        }
        return true;
      });
    }

    let isAggregationBlock = false;
    if (calculationBlock) {
      if (
        CalculationBlockTypes.DimensionAggregation === calculationBlock.Type
      ) {
        isAggregationBlock = outputs.some(
          (o) => o.Dimension_Aggregation_BlockID === calculationBlock.id
        );
      }
      if (
        CalculationBlockTypes.TimescaleAggregation === calculationBlock.Type
      ) {
        isAggregationBlock = outputs.some(
          (o) => o.Timescale_Aggregation_BlockID === calculationBlock.id
        );
      }
    }

    const selectedFunction = schemaFunctions.find((f) => {
      return functionID === f.ID;
    });

    const controls = (
      <Space mr={2}>
        <Button
          text="Back"
          loading={this.state.isSaving}
          onClick={() => {
            this.props.history.goBack();
            if (!isEmpty(calculationBlockRowTree)) {
              this.props.dispatch(
                removeCalculationBlockRowTree(
                  (
                    last(calculationBlockRowTree) as {
                      text: string;
                      id: number;
                      href: Location["pathname"];
                    }
                  ).id
                )
              );
            }
          }}
        />
        {false && (
          <Button
            text="Save and Exit"
            loading={this.state.isSaving}
            intent={Intent.PRIMARY}
          />
        )}
      </Space>
    );

    return (
      <React.Fragment>
        <AppViewTemplate title="Calculation Block Editor" controls={controls}>
          {this.state.loadingCount > 0 ? (
            <Loading />
          ) : (
            <Flex flex={1}>
              {selectedFunction ? (
                <Flex
                  flex={4}
                  pl={`calc((100vw - ${Size.MAX_WIDTH}) / 2 + 32px)`}
                >
                  <FormulaEditor
                    formula={selectedFunction}
                    functionParameters={this.state.functionParameters}
                    blockSources={blockSources.filter(
                      (bs) => bs.BlockID === blockId
                    )}
                    blockRow={blockRows.find((br) => br.id === blockRowId)!}
                    blockRows={blockRows}
                    inputs={inputs}
                    outputs={outputs}
                    xrefFunctions={filteredXRefFunctions}
                    onSaveFunctionParameter={this.handleSaveFunctionParameter}
                    onClearFunctionParameter={this.handleClearFunctionParameter}
                    onAddRepeatedParameter={() => {
                      this.handleAddRepeatedParameter(selectedFunction);
                    }}
                    selectedFormulaRepresentation={
                      selectedFormulaRepresentation
                    }
                    dispatch={dispatch}
                    calculationBlockRowTree={calculationBlockRowTree}
                  />
                </Flex>
              ) : (
                <h3>Formula Not Found in Function Schema</h3>
              )}
              <Sidebar>
                <BlockSourcesTable
                  blockSources={this.filterBlockSources()}
                  calculationBlock={calculationBlock}
                  calculationBlocks={calculationBlocks}
                  dimensions={dimensions}
                  xrefCurveSubTypes={xrefCurveSubTypes}
                  disableEditMode={false}
                  modelInstanceId={instance}
                />
                <Box py={3} css={{ zIndex: 0 }}>
                  <ButtonGroup fill={true}>
                    {!isAggregationBlock && (
                      <Button
                        text="New Input Source"
                        onClick={this.handleInputModalOpen}
                      />
                    )}

                    <Button
                      text="New Output Source"
                      onClick={this.handleOutputModalOpen}
                    />
                  </ButtonGroup>
                </Box>
              </Sidebar>
              {showInputSourceModal && (
                <CreateInputSourceModal
                  blockSources={this.filterBlockSources()}
                  calculationBlock={calculationBlock}
                  dimensions={dimensions}
                  dispatch={dispatch}
                  inputs={inputs}
                  modelInstance={modelInstance!}
                  xrefCurveSubTypes={xrefCurveSubTypes}
                  onClose={this.handleInputModalClose}
                  onSubmit={this.handleInputModalClose}
                />
              )}

              {showOutputSourceModal && (
                <CreateOutputSourceModal
                  blockSources={this.filterBlockSources()}
                  calculationBlock={calculationBlock}
                  calculationBlocks={calculationBlocks}
                  dimensions={dimensions}
                  dispatch={dispatch}
                  outputs={outputs}
                  modelInstanceId={instance}
                  onCancel={this.handleOutputModalClose}
                  onSubmit={this.handleOutputModalClose}
                  isStandardCalculationBlock={!isAggregationBlock}
                />
              )}
            </Flex>
          )}
        </AppViewTemplate>
      </React.Fragment>
    );
  }

  private handleAddRepeatedParameter = (
    schemaFunction: SchemaFunction
  ): void => {
    const { instance, blockRowId } = this.props.match.params;
    const paramsCount = this.state.functionParameters.length;

    const newFunctionParameters: FunctionParameter[] =
      schemaFunction.Parameters.map((p: SchemaFunctionParameter) => {
        return {
          ModelInstanceID: instance,
          Function_BlockRowID: blockRowId,
          FunctionID: schemaFunction.ID,
          IsValidParameter: false,
          Parameter_Number: paramsCount + p.Number,
        };
      });

    this.setState({
      functionParameters: this.state.functionParameters.concat(
        newFunctionParameters
      ),
    });
  };

  private filterBlockSources = () => {
    const { calculationBlock, blockRows, blockSources, inputs, outputs } =
      this.props;

    const filteredSources = blockSources.filter(
      (blockSource: BlockSource) => blockSource.BlockID === calculationBlock.id
    );

    return filteredSources.reduce((acc: BlockSource[], bs: BlockSource) => {
      if (bs.id !== null) {
        if (bs.OutputID) {
          const blockSourceOutput = outputs.find(
            (output) => output.id === bs.OutputID
          );

          if (blockSourceOutput !== undefined) {
            return acc.concat({
              ...bs,
              sourceName: blockSourceOutput.Name,
              sourceCode: `S${bs.MDA_Source_Code}`,
            });
          }
        }

        if (bs.InputID) {
          const blockSourceInput = inputs.find(
            (input) => input.id === bs.InputID
          );

          if (blockSourceInput !== undefined) {
            return acc.concat({
              ...bs,
              sourceName: blockSourceInput.Name,
              sourceCode: `S${bs.MDA_Source_Code}`,
            });
          }
        }

        if (bs.BlockRowID) {
          const blockRow = blockRows.find(
            (calculationBlockRow) => calculationBlockRow.id === bs.BlockRowID
          );

          if (blockRow) {
            const sourceCode = `R${bs.MDA_Source_Code}`;
            let sourceName = blockRow.Name;

            if (blockRow.OutputID) {
              const blockRowOutput = outputs.find(
                (output) => output.id === blockRow.OutputID
              );
              if (blockRowOutput) {
                sourceName = blockRowOutput.Name;
              }
            }
            return acc.concat({
              ...bs,
              sourceName,
              sourceCode,
            });
          }
        }

        // Fallback
        return acc;
      }

      return acc;
    }, []);

    // return filtered;
  };

  private loadFunctionParameters = (updateLoadingCount: boolean) => {
    const { params: matchParams } = this.props.match;

    api
      .get(
        `/instances/${matchParams.instance}/blocks/${matchParams.blockId}/rows/${matchParams.blockRowId}/function_parameters`
      )
      .then((params: FunctionParameter[]) => {
        this.setState({
          functionParameters: params,
          loadingCount: updateLoadingCount
            ? this.state.loadingCount - 1
            : this.state.loadingCount,
        });
      });
  };

  private updateFunctionRepresentation = (
    instance: number,
    blockId: number
  ) => {
    this.props
      .dispatch(
        getCalculationBlockRow(
          instance,
          blockId,
          this.props.calculationBlockRowTree[0].id
        )
      )
      .then((action) => {
        const functionRepresentation = action.payload.Representation;
        this.props.dispatch(setFormulaRepresentation(functionRepresentation));
      });
  };

  private handleClearFunctionParameter = (
    fp: FunctionParameter
  ): Promise<void> => {
    const { params: matchParams } = this.props.match;
    const { instance, blockId } = matchParams;

    return api
      .delete(
        `/instances/${matchParams.instance}/blocks/${matchParams.blockId}/rows/${matchParams.blockRowId}/function_parameters/${fp.id}/clear`
      )
      .then((remainingFunctionParameters: FunctionParameter[]) => {
        this.updateFunctionRepresentation(instance, blockId);
        this.setState({ functionParameters: remainingFunctionParameters });
      });
  };

  private handleSaveFunctionParameter = (
    fp: FunctionParameter
  ): Promise<void> => {
    const { instance, blockId, blockRowId } = this.props.match.params;

    // CREATE new FunctionParameter record or UPDATE existing record
    if (fp.id) {
      return api
        .post(
          `/instances/${instance}/blocks/${blockId}/rows/${blockRowId}/function_parameters/${fp.id}`,
          fp
        )
        .then((updatedFP: FunctionParameter) => {
          this.updateFunctionRepresentation(instance, blockId);
          const updatedFunctionParameters = this.state.functionParameters.map(
            (currentFP) => {
              return updatedFP.id === currentFP.id ? updatedFP : currentFP;
            }
          );

          this.setState({ functionParameters: updatedFunctionParameters });
        });
    } else {
      return api
        .post(
          `/instances/${instance}/blocks/${blockId}/rows/${blockRowId}/function_parameters`,
          fp
        )
        .then((newFP: FunctionParameter) => {
          this.updateFunctionRepresentation(instance, blockId);
          /*
           * This may have been a repeated param that was added, or just a non-repeated param row that
           * had not been ever saved before.  If it was a repeated param, we need to remove the original
           * param "stub" from the list of FunctionParamters and replace it with the newly created/saved version
           */
          const updatedFunctionParameters = sortBy(
            this.state.functionParameters.reduce(
              (params: FunctionParameter[], funcParam: FunctionParameter) => {
                return funcParam.Parameter_Number === newFP.Parameter_Number &&
                  funcParam.id === undefined
                  ? params
                  : params.concat(funcParam);
              },
              [newFP]
            ),
            ["Parameter_Number"]
          );

          this.setState({ functionParameters: updatedFunctionParameters });
        });
    }
  };

  private handleInputModalOpen = (): void =>
    this.setState({ showInputSourceModal: true });

  private handleInputModalClose = (): void =>
    this.setState({ showInputSourceModal: false });

  private handleOutputModalOpen = (): void =>
    this.setState({ showOutputSourceModal: true });

  private handleOutputModalClose = (): void =>
    this.setState({ showOutputSourceModal: false });
}

const mapStateToProps = (state: RootState, ownProps: IProps) => {
  return {
    schemaFunctions: state.functions.schema,
    blockSources: state.blockSources,
    blockRows: state.calculationBlockRows,
    xrefFunctions: state.xrefFunctions,
    inputs: state.inputs,
    outputs: state.outputs,
    dimensions: state.dimensions,
    calculationBlocks: state.calculationBlocks,
    xrefCurveSubTypes: state.xrefCurveSubTypes,
    calculationBlock: calculationBlockSelectors.findById(
      state,
      parseInt(ownProps.match.params.blockId, 10)
    ),
    modelInstances: state.instances,
    calculationBlockRowTree: state.calculationBlockRowTree,
    selectedFormulaRepresentation: state.selectedFormulaRepresentation,
  };
};

export default withRouter(connect(mapStateToProps)(FormulaEditorView));
