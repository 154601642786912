import * as React from "react";
import { Flex, Box } from "@rebass/grid";

const Loading: React.FC = () => (
  <Flex>
    <Box>Loading...</Box>
  </Flex>
);

export default Loading;
