import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Flex, Box } from "@rebass/grid";
import { Button, Divider, Classes, Text, Colors } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { ThunkDispatch } from "../../../../types/redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import EditInputModal from "../../../modals/EditInput";
import FormatModal from "../../../modals/Format";

import {
  CalculationBlock,
  Dimension,
  Input,
  InputCategory,
  FormatParameter,
  BlockSource,
} from "../../../../types/models";
import { RootState } from "../../../../store/reducer";
import { selectInputRow } from "../../../../store/modules/selected_input_row";
import { head } from "lodash";

interface IProps extends RouteComponentProps<any> {
  blockSources: BlockSource[];
  formatParameters: FormatParameter[];
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  dispatch: ThunkDispatch;
  inputCategories: InputCategory[];
  input: Input;
  selectedInputRow: Array<Input["id"]>;
  validate(input: Input, blocks: CalculationBlock[]): object;
}

interface IState {
  isEditing: boolean;
  isFormatting: boolean;
}

const bodyRowCSS = {
  borderBottom: "1px solid #E1E8ED",
  width: "100%",
  height: "70px",
};

const tableCellCSS = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
};

const editableRowTextClasses = classnames(Classes.TEXT_LARGE);
const nonEditableRowTextClasses = classnames(
  Classes.TEXT_LARGE,
  Classes.TEXT_DISABLED
);

class InputsTableRow extends React.Component<IProps, IState> {
  public state: IState = {
    isEditing: false,
    isFormatting: false,
  };

  public render() {
    const {
      calculationBlocks,
      dimensions,
      dispatch,
      input,
      inputCategories,
      validate,
      selectedInputRow,
    } = this.props;
    const { isEditing, isFormatting } = this.state;

    return (
      <React.Fragment>
        <tr
          style={{
            ...bodyRowCSS,
            backgroundColor:
              head(selectedInputRow) === input.id
                ? `rgba(19,124,189,.15)`
                : Colors.WHITE,
          }}
        >
          <td>
            <Box style={tableCellCSS}>
              <Text className={editableRowTextClasses}>{input.Name}</Text>
            </Box>
          </td>
          <td>
            <Box style={tableCellCSS}>
              <Text className={editableRowTextClasses}>
                {this.inputCategoryName()}
              </Text>
            </Box>
          </td>
          <td style={{ width: "300px" }}>
            <Box style={tableCellCSS}>
              <Text className={nonEditableRowTextClasses}>
                {input.Tab_Name}
              </Text>
            </Box>
          </td>
          <td>
            <Box style={tableCellCSS}>
              <Text className={nonEditableRowTextClasses}>
                {input.Curve_Type ? input.Curve_Type : "None"}
              </Text>
            </Box>
          </td>
          <td>
            <Box style={tableCellCSS}>
              <Text className={editableRowTextClasses}>{input.Cross_Type}</Text>
            </Box>
          </td>
          <td>
            <Flex
              style={{ width: "100%", left: 0, ...tableCellCSS }}
              justifyContent="flex-end"
              pr={3}
            >
              <Button
                text="Edit"
                minimal={true}
                icon="edit"
                onClick={() => this.handleEditingOpen(input.id)}
              />
              <Divider />
              <Button
                text="Format"
                minimal={true}
                icon={IconNames.TH}
                onClick={() => this.handleFormattingOpen(input.id)}
              />
            </Flex>
          </td>
        </tr>

        {isEditing && (
          <EditInputModal
            calculationBlocks={calculationBlocks}
            dimensions={dimensions}
            dispatch={dispatch}
            input={input}
            inputCategories={inputCategories}
            onClose={this.handleEditingClose}
            validate={validate}
            blockSourceInputCheck={this.inputUsedInBlockSources()}
          />
        )}

        {isFormatting && (
          <FormatModal
            itemName={input.Name}
            onClose={this.handleFormattingClose}
            input={input}
            formatParameter={this.findFormatParameter()}
          />
        )}
      </React.Fragment>
    );
  }
  private findFormatParameter = (): FormatParameter | undefined => {
    const { input, formatParameters } = this.props;
    if (input.FormatParameterID) {
      return formatParameters.find(
        (formatParameter) => formatParameter.id === input.FormatParameterID
      );
    }
    return undefined;
  };

  private handleEditingOpen = (id: Input["id"]): void => {
    this.props
      .dispatch(selectInputRow(id))
      .then(() => this.setState({ isEditing: true }));
  };

  private handleEditingClose = (): void => this.setState({ isEditing: false });

  private handleFormattingOpen = (id: Input["id"]): void => {
    this.props
      .dispatch(selectInputRow(id))
      .then(() => this.setState({ isFormatting: true }));
  };

  private handleFormattingClose = (): void =>
    this.setState({ isFormatting: false });

  private inputCategoryName = (): string => {
    const { inputCategories, input } = this.props;

    const category = inputCategories.find((cat) => cat.id === input.CategoryID);

    return category ? category.Name : "";
  };

  private inputUsedInBlockSources = (): boolean => {
    const { blockSources, input } = this.props;

    return blockSources.some((blockSource) => blockSource.InputID === input.id);
  };
}

const mapStateToProps = (state: RootState) => ({
  formatParameters: state.formatParameters,
  selectedInputRow: state.selectedInputRow,
});

export default withRouter(connect(mapStateToProps)(InputsTableRow));
