import { createAction, getType } from "typesafe-actions";

import { Input } from "../../types/models";
import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export type SelectedInputRowState = Array<number | undefined>;

const initialState: SelectedInputRowState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.selectedInputRow): {
      return [action.payload].concat(state);
    }
    case getType(actions.clearSelectedInputRow): {
      return [];
    }
    default:
      return state;
  }
}

export const actions = {
  selectedInputRow: createAction("@inputs/selectedInputRow")<Input["id"]>(),
  clearSelectedInputRow: createAction("@inputs/clearSelectedInputRow")(),
};

export const selectInputRow =
  (id: Input["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.selectedInputRow(id))
    );
  };

export const clearSelectInputRow =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.clearSelectedInputRow())
    );
  };
