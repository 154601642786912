import * as React from "react";
import styled from "styled-components";
import { Box } from "@rebass/grid";
import { Position, Classes, Text, Tag, Colors } from "@blueprintjs/core";
import { Node } from "../../../types/models";
import { Tooltip2 } from "@blueprintjs/popover2";

interface IProps {
  nodeName: string;
  node: Node;
  disabled: boolean;
  onSelect: (idx: number, node: Node, name: string) => void;
  dimensionIndex: number;
  childrenCount: number;
  isSelected: boolean; // isSelected && 'blue-ring' + full opacity
  isOnActivePath: boolean; // isOnActivePath ? 'gray-ring + full opacity' : '40% opacity'
  isNextDimension: boolean; // isNextDimension && full opacity
}

class NodeCard extends React.Component<IProps> {
  public render() {
    const { nodeName, childrenCount, disabled } = this.props;
    return (
      <StyledButton
        onClick={(e) => (disabled ? e.preventDefault() : this.handleClick())}
        style={this.getNodeStateStyles()}
      >
        {nodeName}
        <Box ml="auto">
          <Tooltip2 position={Position.TOP} content="Direct Children">
            <Tag minimal={true}>
              <Text className={Classes.TEXT_MUTED}>{childrenCount}</Text>
            </Tag>
          </Tooltip2>
        </Box>
      </StyledButton>
    );
  }

  private handleClick = () => {
    this.props.onSelect(
      this.props.dimensionIndex,
      this.props.node,
      this.props.nodeName
    );
  };

  private getNodeStateStyles = () => {
    let styles = {
      opacity: 0.4,
      cursor: this.props.disabled ? "not-allowed" : "pointer",
    };

    if (this.props.isOnActivePath) {
      styles = Object.assign(styles, {
        opacity: 1,
        boxShadow:
          "0 0 0 1px rgba(16,22,26,0.1), 0 2px 4px 0 rgba(16,22,26,0.2), 0 0 0 3px #A7B6C2",
      });
    }

    if (this.props.isSelected) {
      styles = Object.assign(styles, {
        opacity: 1,
        boxShadow:
          "0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 3px #48aff0",
      });
    }

    if (this.props.isNextDimension) {
      styles = Object.assign(styles, {
        opacity: this.props.disabled ? 0.5 : 1,
      });
    }

    return styles;
  };
}

const StyledButton = styled.button`
  all: unset;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.disabled ? `rgba(${Colors.GRAY1}, 0.6)` : Colors.WHITE};
  border-radius: 3px;
  color: #5c7080;
  display: flex;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  min-width: 200px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: ${(props) =>
      props.disabled
        ? "none"
        : `0 0 0 1px rgba(16, 22, 26, 0.1),
      0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 3px rgba(72, 175, 240, 0.5)`};
  }
`;

export default NodeCard;
