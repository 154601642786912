import { combineReducers } from "redux";
import { RootAction } from "./actions";

import clients, { ClientMap } from "./modules/client";
import models, { ModelMap } from "./modules/model";
import instances, { ModelInstanceState } from "./modules/model_instances";
import instanceFilter, {
  ModelInstanceFilterState,
} from "./modules/model_instances_filter";
import dimensions, { DimensionsState } from "./modules/dimensions";
import currentUser, { UserState } from "./modules/user";
import profile, { ProfileState } from "./modules/profile";
import nodes, { NodeState } from "./modules/nodes";
import calculationBlocks, {
  CalculationBlocksState,
} from "./modules/calculation_blocks";
import calculationBlockRows, {
  CalculationBlockRowsState,
} from "./modules/calculation_block_rows";
import selectedCalculationBlockRow, {
  SelectedCalculationBlockRowsState,
} from "./modules/selected_calculation_block_rows";
import selectedCalculationRow, {
  SelectedCalculationRowState,
} from "./modules/selected_calculation_row";
import inputCategories, {
  InputCategoryState,
} from "./modules/input_categories";
import inputs, { InputsState } from "./modules/inputs";
import selectedInputRow, {
  SelectedInputRowState,
} from "./modules/selected_input_row";
import outputs, { OutputsState } from "./modules/outputs";
import selectedOutputRow, {
  SelectedOutputRowState,
} from "./modules/selected_output_row";
import blockSources, { BlockSourceState } from "./modules/block_sources";
import xrefFunctions, { XRefFunctionState } from "./modules/xref_functions";
import xrefDates, { XRefDateState } from "./modules/xref_dates";
import xrefCurveSubTypes, {
  XRefCurveSubTypeState,
} from "./modules/xref_curve_sub_types";
import blockInstanceMappings, {
  BlockInstanceMappingState,
} from "./modules/block_instance_mapping";
import blockNodeMappings, {
  BlockNodeMappingState,
} from "./modules/block_node_mapping";
import functions, { FunctionsState } from "./modules/functions";
import formatParameters, {
  FormatParameterState,
} from "./modules/format_parameter";
import firstDimensionGroups, {
  FirstDimensionGroupState,
} from "./modules/first_dimension_groups";
import calculationBlockRowTree, {
  CalculationBlockRowTreeState,
} from "./modules/calculation_block_row_tree";
import selectedFormulaRepresentation, {
  SelectedFormulaRepresentationState,
} from "./modules/selected_formula_representation";

export interface RootState {
  readonly currentUser: UserState;
  readonly profile: ProfileState;
  readonly clients: ClientMap;
  readonly models: ModelMap;
  readonly instances: ModelInstanceState;
  readonly instanceFilter: ModelInstanceFilterState;
  readonly dimensions: DimensionsState;
  readonly nodes: NodeState;
  readonly calculationBlocks: CalculationBlocksState;
  readonly calculationBlockRows: CalculationBlockRowsState;
  readonly selectedCalculationBlockRow: SelectedCalculationBlockRowsState;
  readonly selectedCalculationRow: SelectedCalculationRowState;
  readonly inputCategories: InputCategoryState;
  readonly inputs: InputsState;
  readonly selectedInputRow: SelectedInputRowState;
  readonly outputs: OutputsState;
  readonly selectedOutputRow: SelectedOutputRowState;
  readonly blockSources: BlockSourceState;
  readonly xrefFunctions: XRefFunctionState;
  readonly xrefDates: XRefDateState;
  readonly xrefCurveSubTypes: XRefCurveSubTypeState;
  readonly blockInstanceMappings: BlockInstanceMappingState;
  readonly blockNodeMappings: BlockNodeMappingState;
  readonly functions: FunctionsState;
  readonly formatParameters: FormatParameterState;
  readonly firstDimensionGroups: FirstDimensionGroupState;
  readonly calculationBlockRowTree: CalculationBlockRowTreeState;
  readonly selectedFormulaRepresentation: SelectedFormulaRepresentationState;
}

const reducers = {
  clients,
  models,
  instances,
  instanceFilter,
  currentUser,
  profile,
  dimensions,
  nodes,
  calculationBlocks,
  calculationBlockRows,
  selectedCalculationBlockRow,
  selectedCalculationRow,
  inputCategories,
  inputs,
  selectedInputRow,
  outputs,
  selectedOutputRow,
  blockSources,
  xrefFunctions,
  xrefDates,
  xrefCurveSubTypes,
  blockInstanceMappings,
  blockNodeMappings,
  functions,
  formatParameters,
  firstDimensionGroups,
  calculationBlockRowTree,
  selectedFormulaRepresentation,
};

export default combineReducers<RootState, RootAction>(reducers);
