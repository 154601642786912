import * as React from "react";
import "styled-components/macro";
import { Box } from "@rebass/grid";

const RowAlign = (props: any) => (
  <Box
    css={{
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      gridTemplateColumns: `[card-start] 16px 
                            [col-1] 64px 
                            [col-2] 148px 
                            [col-3] 120px 
                            [col-4] 1fr 
                            [col-5] 64px`,
    }}
    {...props}
  />
);

export default RowAlign;
