import * as React from "react";
import "styled-components/macro";
import { connect } from "react-redux";
import { Flex, Box } from "@rebass/grid";
import {
  Button,
  Icon,
  Classes,
  Text,
  Divider,
  Colors,
  Menu,
} from "@blueprintjs/core";
import cx from "classnames";
import Space from "styled-space";

import Auth from "../../../services/auth";
import { RootState } from "../../../store/reducer";
import { ADUser } from "../../../types/models";
import { MaxBound } from "../../atoms/Layout";
import AppSelect from "./AppSelect";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";

interface IProps {
  user?: ADUser;
}

const logout = () => Auth.logOut();

const UtilityNav: React.FC<IProps> = ({ user }) => {
  return (
    <MaxBound>
      <Flex justifyContent="stretch" py={1}>
        <Box mr={1} my="auto">
          <Text className={cx(Classes.TEXT_SMALL)}>
            {user && user.userName}
          </Text>
        </Box>
        <Divider />
        <AppSelect />

        <Box ml="auto">
          <Space ml={3}>
            <Button small={true} className={Classes.MINIMAL}>
              <Popover2
                inheritDarkTheme={false}
                content={
                  <Menu>
                    <MenuItem2
                      icon={<Icon icon="help" />}
                      text="Help"
                      onClick={() =>
                        window.open(
                          "https://forecasting-builder-help.trinitylifesciences.com/",
                          "_blank"
                        )
                      }
                    />
                  </Menu>
                }
              >
                <Icon icon="cog" color={Colors.WHITE} />
              </Popover2>
            </Button>
            <Button small={true} className={Classes.MINIMAL} onClick={logout}>
              <Icon icon="log-out" color={Colors.WHITE} />
            </Button>
          </Space>
        </Box>
      </Flex>
    </MaxBound>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.currentUser,
});

export default connect(mapStateToProps)(UtilityNav);
