import { adalFetch } from "react-adal";
import Toaster from "../components/molecules/Toaster";

import Auth from "./auth";
import RequestError from "./RequestError";

const API_HOST = process.env.REACT_APP_API_HOST;

// Format an error message based on our standard error response bodies
const getErrorMessage = (json: any): string => {
  if (json.message) {
    return json.message;
  } else if (json.errors) {
    const messages: string[] = [];
    for (const attr in json.errors) {
      if (json.errors.hasOwnProperty(attr)) {
        messages.push(`${attr}: ${json.errors[attr]}`);
      }
    }
    return messages.join("\n");
  } else {
    return "Error making request";
  }
};

// Errors need to be visible in a few different places
// - Toast, so the user has some feedback
// - console, so the developer has some feedback
// - thrown, so the code has some feedback
const toastAndThrow = (e: Error) => {
  Toaster.show({
    message: e.message,
    intent: "danger",
    timeout: 0,
  });
  console.log(e);
  throw e;
};

const responseHandler =
  (url: string, body: object) =>
  (response: Response): null | Promise<any> => {
    if (response.status === 204) {
      return null;
    }
    if (response.ok) {
      return (
        response
          .json()
          // Wrap errors that might occur when parsing the JSON body with a better
          // message
          .catch((e: Error) => {
            throw new RequestError(
              "Error making request",
              url,
              body,
              response,
              e
            );
          })
      );
    }
    // Response wasn't ok, so let's parse it for any errors
    return (
      response
        .json()
        // Wrap errors that might occur when parsing the JSON body with a better
        // message
        .catch((e: Error) => {
          throw new RequestError(
            "Error making request",
            url,
            body,
            response,
            e
          );
        })
        .then((json: any) => {
          const errorMessage = getErrorMessage(json);
          throw new RequestError(errorMessage, url, body, response);
        })
    );
  };

class ApiService {
  public get(url: string) {
    return adalFetch(Auth, Auth.config.clientId, fetch, `${API_HOST}${url}`, {})
      .then(responseHandler(url, {}))
      .catch(toastAndThrow);
  }

  public post(url: string, body = {}) {
    return adalFetch(Auth, Auth.config.clientId, fetch, `${API_HOST}${url}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(responseHandler(url, body))
      .catch(toastAndThrow);
  }

  public delete(url: string, body = {}) {
    return adalFetch(Auth, Auth.config.clientId, fetch, `${API_HOST}${url}`, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then(responseHandler(url, body))
      .catch(toastAndThrow);
  }
}

const apiService = new ApiService();
export default apiService;
