import React, { FormEvent, Fragment } from "react";
import { Box, Flex } from "@rebass/grid";
import {
  Checkbox,
  FormGroup,
  HTMLSelect,
  Intent,
  InputGroup,
} from "@blueprintjs/core";

import { ModelInstance, Timescale, XRefDate } from "../../../types/models";
import SelectTimescaleValue from "./TimescaleValuePickers/SelectTimescaleValue";

export type ModelInstanceFields = Pick<
  ModelInstance,
  | "Name"
  | "Timescale"
  | "Monthly_Flag"
  | "Quarterly_Flag"
  | "Yearly_Flag"
  | "Start_DateID"
  | "End_DateID"
>;

export type ModelInstanceErrors = { [P in keyof ModelInstanceFields]?: string };

interface Props {
  xrefDates: XRefDate[];

  isDraft: boolean;
  isEditable: boolean;

  disabled: boolean;
  fields: ModelInstanceFields;
  errors: ModelInstanceErrors;

  onChange(changes: Partial<ModelInstanceFields>): void;
  onChangeDateErrorClear(extraFields: Partial<ModelInstanceFields>): void;
}

const ModalInstanceForm: React.FunctionComponent<Props> = ({
  xrefDates,
  isDraft,
  isEditable,
  disabled,
  fields,
  errors,
  onChange,
  onChangeDateErrorClear,
}) => {
  const dates = [
    ...xrefDates.filter((date) => date.Timescale === fields.Timescale),
  ];
  const TIMESCALE_OPTIONS: string[] = [
    "Weekly",
    "Monthly",
    "Quarterly",
    "Yearly",
  ];

  const handleDateChange = (value: number | null, key: string): void => {
    if (key === "Start_DateID") {
      if (value) {
        onChange({
          Start_DateID: value,
        });
        onChangeDateErrorClear({
          Start_DateID: null,
          End_DateID: null,
        });
      }
    }
    if (key === "End_DateID") {
      if (value) {
        onChange({
          End_DateID: value,
        });
        onChangeDateErrorClear({
          Start_DateID: null,
          End_DateID: null,
        });
      }
    }
  };

  return (
    <Fragment>
      <FormGroup
        disabled={disabled}
        helperText={errors.Name}
        intent={errors.Name ? Intent.DANGER : Intent.NONE}
        label="Name"
        labelInfo="(required)"
      >
        <InputGroup
          disabled={disabled}
          required={true}
          value={fields.Name}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            onChange({ Name: e.currentTarget.value })
          }
        />
      </FormGroup>
      <FormGroup
        disabled={disabled}
        intent={errors.Timescale ? Intent.DANGER : Intent.NONE}
        label="Timescale"
        labelInfo="(required)"
        helperText={
          errors.Timescale ||
          "Timescale cannot be edited after dimensions are finalized"
        }
      >
        <HTMLSelect
          disabled={disabled || !isDraft || !isEditable}
          options={TIMESCALE_OPTIONS}
          fill={true}
          required={true}
          value={fields.Timescale}
          onChange={(e: FormEvent<HTMLSelectElement>) =>
            onChange({
              Timescale: e.currentTarget.value,
              Monthly_Flag: 0,
              Quarterly_Flag: 0,
              Yearly_Flag: 0,
              Start_DateID: 0,
              End_DateID: 0,
            })
          }
        />
      </FormGroup>
      <FormGroup
        disabled={disabled}
        labelFor="model-aggregation"
        label="Aggregation"
      >
        <Flex>
          <Checkbox
            label="Monthly"
            onClick={(e: FormEvent<HTMLInputElement>) =>
              onChange({ Monthly_Flag: e.currentTarget.checked ? 1 : 0 })
            }
            checked={fields.Monthly_Flag === 1}
            disabled={disabled || !isEditable || fields.Timescale !== "Weekly"}
          />
          <Checkbox
            style={{ marginLeft: "30px" }}
            label="Quarterly"
            onClick={(e: FormEvent<HTMLInputElement>) =>
              onChange({ Quarterly_Flag: e.currentTarget.checked ? 1 : 0 })
            }
            checked={fields.Quarterly_Flag === 1}
            disabled={
              disabled ||
              !isEditable ||
              (fields.Timescale !== "Weekly" && fields.Timescale !== "Monthly")
            }
          />
          <Checkbox
            style={{ marginLeft: "30px" }}
            label="Yearly"
            onClick={(e: FormEvent<HTMLInputElement>) =>
              onChange({ Yearly_Flag: e.currentTarget.checked ? 1 : 0 })
            }
            checked={fields.Yearly_Flag === 1}
            disabled={disabled || !isEditable || fields.Timescale === "Yearly"}
          />
        </Flex>
      </FormGroup>
      <Flex>
        <Box width={1 / 2} pr={2}>
          <FormGroup
            disabled={disabled}
            helperText={errors.Start_DateID}
            intent={errors.Start_DateID ? Intent.DANGER : Intent.NONE}
            labelFor="model-start-date"
            label="Start Date"
            labelInfo="(required)"
          >
            <SelectTimescaleValue
              onChange={(timescaleValue: XRefDate["DateID"]) => {
                handleDateChange(timescaleValue, "Start_DateID");
              }}
              timescale={fields.Timescale as Timescale}
              large={false}
              value={fields.Start_DateID ? fields.Start_DateID : undefined}
              dates={dates}
            />
          </FormGroup>
        </Box>
        <Box width={1 / 2} pl={2}>
          <FormGroup
            disabled={disabled}
            helperText={errors.End_DateID}
            intent={errors.End_DateID ? Intent.DANGER : Intent.NONE}
            labelFor="model-end-date"
            label="End Date"
            labelInfo="(required)"
          >
            <SelectTimescaleValue
              onChange={(timescaleValue: XRefDate["DateID"]) => {
                handleDateChange(timescaleValue, "End_DateID");
              }}
              timescale={fields.Timescale as Timescale}
              large={false}
              value={fields.End_DateID ? fields.End_DateID : undefined}
              dates={dates}
            />
          </FormGroup>
        </Box>
      </Flex>
    </Fragment>
  );
};

export default ModalInstanceForm;
