import * as React from "react";
import { Button, Menu, Position, Icon, Colors } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";

const AppSelect: React.FunctionComponent = () => (
  <Popover2
    position={Position.BOTTOM_LEFT}
    inheritDarkTheme={false}
    minimal={true}
    content={
      <Menu>
        <MenuItem2
          text="Forecast Manager"
          icon={IconNames.GIT_PUSH}
          href={process.env.REACT_APP_FM_URL}
        />
        <MenuItem2
          text="Model Design Application"
          icon={IconNames.COMPARISON}
          href={process.env.REACT_APP_MDA_URL}
        />
      </Menu>
    }
  >
    <Button
      text="Model Design Application"
      minimal={true}
      small={true}
      rightIcon={<Icon icon={IconNames.CHEVRON_DOWN} color={Colors.WHITE} />}
      icon={<Icon icon={IconNames.APPLICATIONS} color={Colors.WHITE} />}
    />
  </Popover2>
);

export default AppSelect;
