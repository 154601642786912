import * as React from "react";
import "styled-components/macro";
import { Flex } from "@rebass/grid";

import { tabBarHeight } from "../TabBar";
import { PropsWithChildren } from "react";

const DimensionColumns: React.FC<PropsWithChildren> = (props) => (
  <Flex
    mt={4}
    css={{
      height: "100%",
      paddingBottom: `${tabBarHeight}`,
      width: "100%",
    }}
  >
    {props.children}
  </Flex>
);

export default DimensionColumns;
