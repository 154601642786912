import * as React from "react";
import "styled-components/macro";
import { Flex, Box } from "@rebass/grid";
import { Colors } from "@blueprintjs/core";

import { Size } from "../../atoms/Layout";
import { PropsWithChildren } from "react";

const Sidebar: React.FC<PropsWithChildren> = (props) => (
  <Flex flex={1} bg={Colors.WHITE} pr={`calc((100vw - ${Size.MAX_WIDTH}) / 2)`}>
    <Box px={4} pt={4} mb={5} css={{ minWidth: "350px" }} {...props} />
  </Flex>
);

export default Sidebar;
