import { createAction, getType } from "typesafe-actions";

import { Output } from "../../types/models";
import { RootAction } from "../actions";
import { ThunkResult } from "../../types/redux";

export type SelectedOutputRowState = Array<number | undefined>;

const initialState: SelectedOutputRowState = [];

export default function reducer(state = initialState, action: RootAction) {
  switch (action.type) {
    case getType(actions.selectedOutputRow): {
      return [action.payload].concat(state);
    }
    case getType(actions.clearSelectedOutputRow): {
      return [];
    }
    default:
      return state;
  }
}

export const actions = {
  selectedOutputRow: createAction("@outputs/selectedOutputRow")<Output["id"]>(),
  clearSelectedOutputRow: createAction("@outputs/clearSelectedOutputRow")(),
};

export const selectOutputRow =
  (id: Output["id"]): ThunkResult<Promise<any>> =>
  (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.selectedOutputRow(id))
    );
  };

export const clearSelectOutputRow =
  (): ThunkResult<Promise<any>> => (dispatch) => {
    return Promise.resolve([]).then(() =>
      dispatch(actions.clearSelectedOutputRow())
    );
  };
