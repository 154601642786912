import * as React from "react";
import "styled-components/macro";
import { connect } from "react-redux";
import classnames from "classnames";
import Space from "styled-space";
import { Flex } from "@rebass/grid";
import {
  Button,
  Classes,
  Icon,
  InputGroup,
  ButtonGroup,
  Intent,
  Text,
} from "@blueprintjs/core";
import * as R from "ramda";

import { ThunkDispatch } from "../../../types/redux";
import {
  Dimension,
  DimensionInstance as DimensionInstanceModel,
} from "../../../types/models";

import {
  actions,
  saveDimension,
  deleteDimension,
} from "../../../store/modules/dimensions";
import DimensionInstance from "./DimensionInstance";
import { orderBy } from "lodash";

export const DEFAULT_DIMENSION_NAME = "!__DEFAULT_DIMENSION_NAME__";
export const DEFAULT_DIMENSION_INSTANCE_NAME = "!__DEFAULT_INSTANCE_NAME__";

const columnCSS = {
  borderRadius: "3px",
  flexDirection: "column",
  minWidth: "200px",
  "&:last-child": {
    paddingBottom: "20px",
  },
};

interface IProps {
  dispatch: ThunkDispatch;
  dimension: Dimension;
  validateName: (name: string, dimNumber: number) => boolean;
  isEditingView: boolean;
  selectedInstance?: DimensionInstanceModel;
  onViewInstanceSidebar?: (
    dimensionInstance: DimensionInstanceModel,
    dimension: Dimension
  ) => void;
  onDeleteInstanceMappings?: (dimensionInstanceId: number) => void;
}

export class DimensionColumn extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);

    this.isValidDimensionInstanceName =
      this.isValidDimensionInstanceName.bind(this);

    this.state = {
      dimensionName: "",
      errorMsg: "",
      isEditingDimension: this.props.dimension.Name === DEFAULT_DIMENSION_NAME,
      isSaving: false,
      isDeleting: false,
      value: this.props.dimension.Name,
    };
  }

  public render() {
    const nameSorter = (i: { Name: string }) => i.Name.toLowerCase();

    const sortByNameCaseInsensitive = (arr: any[]) =>
      orderBy(arr, [nameSorter], ["asc"]);

    const btnClasses = classnames(Classes.LARGE, Classes.MINIMAL);
    const {
      dimension,
      isEditingView,
      onViewInstanceSidebar,
      selectedInstance,
      onDeleteInstanceMappings,
    } = this.props;
    const { isEditingDimension } = this.state;
    const isAddingDimensionInstance = dimension.dimension_instances.some(
      (di: DimensionInstanceModel) =>
        DEFAULT_DIMENSION_INSTANCE_NAME === di.Name
    );

    return (
      <Flex px={3} pt={3} css={columnCSS}>
        <Space mb={3}>
          <div
            style={{
              boxShadow:
                "rgba(16, 22, 26, 0.1) 0px 0px 0px 1px, rgba(16, 22, 26, 0) 0px 0px 0px, rgba(16, 22, 26, 0.2) 0px 1px 1px",
              borderRadius: "3px",
            }}
          >
            {!isEditingView && (
              <Flex justifyContent="center">
                <Icon icon="drag-handle-horizontal" />
              </Flex>
            )}
            <Flex justifyContent={"center"}>
              {isEditingDimension ? (
                <this.DimensionEditing />
              ) : (
                <Flex
                  justifyContent={"space-between"}
                  width={1}
                  css={{ padding: "5px 16px", alignItems: "center" }}
                >
                  <Text className="bp4-text-muted bp4-text-large">
                    {dimension.Name}
                  </Text>
                  <Button
                    minimal={true}
                    icon="edit"
                    onClick={() => this.setState({ isEditingDimension: true })}
                  />
                </Flex>
              )}
            </Flex>
            <Button
              text={"Add Instance"}
              rightIcon="plus"
              className={btnClasses}
              onClick={this.createInstance}
              disabled={isAddingDimensionInstance || isEditingDimension}
              fill
            />
          </div>
          {sortByNameCaseInsensitive(dimension.dimension_instances).map(
            (instance, i) => (
              <DimensionInstance
                key={`${dimension.DimNumber}-${instance.id}-${instance.Name}-${i}`}
                dimensionInstance={instance}
                dimension={dimension}
                validateName={this.isValidDimensionInstanceName}
                dimensionInstanceSidebar={onViewInstanceSidebar}
                onDeleteInstanceMappings={onDeleteInstanceMappings}
                isHighlighted={
                  selectedInstance !== undefined &&
                  instance.id === selectedInstance.id
                }
              />
            )
          )}
          <div style={{ height: "20px", padding: "20px" }} />
        </Space>
      </Flex>
    );
  }

  private DimensionEditing: React.FC = () => {
    return (
      <form onSubmit={this.handleDimensionEdit}>
        <Flex>
          <InputGroup
            type="text"
            onChange={this.handleDimensionChange}
            placeholder="Dimension Name"
            required={true}
            autoFocus={true}
            defaultValue={
              this.props.dimension.Name !== DEFAULT_DIMENSION_NAME
                ? this.props.dimension.Name
                : ""
            }
          />
          <ButtonGroup minimal={true}>
            {!this.props.isEditingView && (
              <Button
                icon="trash"
                loading={this.state.isDeleting}
                onClick={this.handleDimensionDelete}
                intent={Intent.DANGER}
              />
            )}
            <Button icon="tick" type="submit" loading={this.state.isSaving} />
          </ButtonGroup>
        </Flex>
        <Text className={Classes.TEXT_SMALL}>{this.state.errorMsg}</Text>
      </form>
    );
  };

  private handleDimensionEdit = (event: any) => {
    event.preventDefault(); // disable default form submission

    const editedDim = this.props.dimension;
    if (
      this.props.validateName(this.state.dimensionName, editedDim.DimNumber)
    ) {
      this.setState({ isSaving: true });
      this.props
        .dispatch(
          saveDimension({
            ...editedDim,
            Name: this.state.dimensionName || this.state.value,
          })
        )
        .then(() =>
          this.setState({ isEditingDimension: false, isSaving: false })
        )
        .catch(() => this.setState({ isSaving: false }));
    } else {
      this.setState({ errorMsg: "A Dimension already exists with this name." });
    }
  };

  private handleDimensionChange = (event: any) => {
    this.setState({ dimensionName: R.trim(event.target.value) });
  };

  private handleDimensionDelete = () => {
    this.setState({ isDeleting: true });

    this.props
      .dispatch(deleteDimension(this.props.dimension))
      .catch(() => this.setState({ isDeleting: false }));
  };

  private isValidDimensionInstanceName = (
    newName: string,
    instance: DimensionInstanceModel
  ): boolean => {
    const instances = this.props.dimension.dimension_instances;
    const lowerName = R.toLower(newName);
    const duplicateIndex = R.findIndex(
      R.propSatisfies((n: string) => R.toLower(n) === lowerName, "Name"),
      [...(instances || [])]
    );
    return (
      duplicateIndex === -1 || instances[duplicateIndex].id === instance.id
    );
  };

  private createInstance = () => {
    this.props.dispatch(
      actions.addDimensionInstance({
        dimensionInstance: {
          Name: DEFAULT_DIMENSION_INSTANCE_NAME,
          dimension: this.props.dimension,
        },
        dimension: this.props.dimension,
      })
    );
  };
}

export default connect()(DimensionColumn);
