import React from "react";
import {
  Button,
  Classes,
  Dialog,
  Intent,
  HTMLSelect,
  Checkbox,
  TextArea,
  FormGroup,
  Slider,
} from "@blueprintjs/core";

import {
  FormatNumberType,
  FormatParameter,
  Output,
  Input,
  CalculationBlockRow,
  InputBlockMapping,
  InputDimensionMapping,
} from "../../types/models";

import { saveFormatParameter } from "../../store/modules/format_parameter";
import { saveInput } from "../../store/modules/inputs";
import { saveOutput } from "../../store/modules/outputs";
import { ThunkDispatch } from "../../types/redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { saveCalculationBlockRow } from "../../store/modules/calculation_block_rows";
import { trimStart, trimEnd } from "lodash";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  onClose: () => void;
  itemName: string;
  output?: Output;
  calculationBlockRow?: CalculationBlockRow;
  input?: Input;
  formatParameter?: FormatParameter;
}

interface IState {
  numberType: FormatNumberType;
  magnitudeTruncation: number;
  decimalPlaces: number;
  isBold: boolean;
  isItalicized: boolean;
  isUnderlined: boolean;
  dataValidation: string;
}

const formatNumberType = [
  { label: "General", value: "General" },
  { label: "Percent", value: "Percent" },
  { label: "ShortDate", value: "ShortDate" },
  { label: "INTLDate", value: "INTLDate" },
  { label: "MonthDate", value: "MonthDate" },
  { label: "$", value: "$" },
];

class FormatModal extends React.Component<IProps, IState> {
  public state: IState = {
    numberType: this.props.formatParameter
      ? this.props.formatParameter.Number_Type
      : "General",
    magnitudeTruncation: this.props.formatParameter
      ? this.props.formatParameter.Magnitude_Truncation
      : 0,
    decimalPlaces: this.props.formatParameter
      ? this.props.formatParameter.Number_Decimals
      : 0,
    isBold: this.props.formatParameter
      ? Boolean(this.props.formatParameter.Bold_Flag)
      : false,
    isItalicized: this.props.formatParameter
      ? Boolean(this.props.formatParameter.Italic_Flag)
      : false,
    isUnderlined: this.props.formatParameter
      ? Boolean(this.props.formatParameter.Underline_Flag)
      : false,
    dataValidation: this.props.formatParameter
      ? this.props.formatParameter.Data_Validation
        ? this.props.formatParameter.Data_Validation
        : ""
      : "",
  };

  public render() {
    const { itemName } = this.props;
    const {
      numberType,
      magnitudeTruncation,
      decimalPlaces,
      isBold,
      isItalicized,
      isUnderlined,
      dataValidation,
    } = this.state;
    return (
      <Dialog
        title={`${itemName} Formatting`}
        isOpen={true}
        onClose={this.handleClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Number Type">
            <HTMLSelect
              defaultValue={numberType}
              fill={true}
              options={formatNumberType}
              onChange={this.handleNumberType}
            />
          </FormGroup>
          <FormGroup label="Magnitude Truncation:">
            <Slider
              min={0}
              max={2}
              labelStepSize={1}
              value={magnitudeTruncation}
              onChange={this.handleMagnitudeChange}
            />
          </FormGroup>
          <FormGroup label="Decimal Places:">
            <Slider
              min={0}
              max={2}
              labelStepSize={1}
              value={decimalPlaces}
              onChange={this.handleDecimalChange}
            />
          </FormGroup>
          <FormGroup label="Style:">
            <Checkbox
              label="Bold"
              defaultChecked={isBold}
              onChange={this.toggleBold}
            />
            <Checkbox
              label="Italicized"
              defaultChecked={isItalicized}
              onChange={this.toggleItalics}
            />
            <Checkbox
              label="Underlined"
              defaultChecked={isUnderlined}
              onChange={this.toggleUnderline}
            />
          </FormGroup>
          <FormGroup
            label="Data Validation"
            helperText={`${255 - dataValidation.length} characters left`}
          >
            <TextArea
              fill={true}
              placeholder="Data validation"
              value={dataValidation}
              onChange={this.handleValidationChange}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Cancel"
              intent={Intent.NONE}
              onClick={this.handleClose}
            />
            <Button
              text="Format"
              intent={Intent.PRIMARY}
              onClick={this.handleSave}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  private handleClose = () => this.props.onClose();

  private handleSave = (): void => {
    const {
      calculationBlockRow,
      input,
      output,
      dispatch,
      onClose,
      formatParameter,
    } = this.props;
    const {
      numberType,
      magnitudeTruncation,
      decimalPlaces,
      isBold,
      isItalicized,
      isUnderlined,
      dataValidation,
    } = this.state;
    const ModelInstanceId = this.props.match.params.instance;
    const formatParameterModal = {
      Type: this.handleType(),
      ModelInstanceID: ModelInstanceId,
      Number_Type: numberType,
      Magnitude_Truncation: magnitudeTruncation,
      Number_Decimals: decimalPlaces,
      Bold_Flag: Number(isBold),
      Italic_Flag: Number(isItalicized),
      Underline_Flag: Number(isUnderlined),
      Data_Validation: dataValidation !== "" ? trimEnd(dataValidation) : null,
    };

    if (formatParameter) {
      const updatedFormat = {
        ...formatParameterModal,
        id: formatParameter.id,
      };
      dispatch(saveFormatParameter(ModelInstanceId, updatedFormat));
    } else {
      if (output) {
        dispatch(
          saveFormatParameter(ModelInstanceId, formatParameterModal)
        ).then((format) => {
          output.FormatParameterID = format.payload.id;
          dispatch(saveOutput(output));
        });
      }
      if (calculationBlockRow) {
        dispatch(
          saveFormatParameter(ModelInstanceId, formatParameterModal)
        ).then((format) => {
          calculationBlockRow.FormatParameterID = format.payload.id;
          dispatch(saveCalculationBlockRow(calculationBlockRow));
        });
      }

      if (input) {
        dispatch(
          saveFormatParameter(ModelInstanceId, formatParameterModal)
        ).then((format) => {
          input.FormatParameterID = format.payload.id;
          const blockIds = input.blockMapping.map(
            (block: InputBlockMapping) => block.BlockID as number
          );
          const dimensionIds = input.dimensionMapping.map(
            (mapping: InputDimensionMapping) => mapping.DimensionID
          );
          dispatch(saveInput(input, blockIds, dimensionIds));
        });
      }
    }
    onClose();
  };

  private handleType = (): string => {
    const { input, output, calculationBlockRow } = this.props;
    const type = input || output || calculationBlockRow;

    switch (type) {
      case input:
        return "Input";
      case output:
        return "Output";
      case calculationBlockRow:
        return "Intermediate";
      default:
        return "";
    }
  };

  private handleValidationChange = (event: any): void =>
    this.setState({
      dataValidation: trimStart(event.currentTarget.value.substring(0, 255)),
    });

  private handleMagnitudeChange = (value: number): void =>
    this.setState({ magnitudeTruncation: value });

  private handleDecimalChange = (value: number): void =>
    this.setState({ decimalPlaces: value });

  private handleNumberType = ({ target }: any): void =>
    this.setState({ numberType: target.value });

  private toggleBold = (event: React.FormEvent<HTMLInputElement>): void =>
    this.setState({ isBold: event.currentTarget.checked });
  private toggleItalics = (event: React.FormEvent<HTMLInputElement>): void =>
    this.setState({ isItalicized: event.currentTarget.checked });
  private toggleUnderline = (event: React.FormEvent<HTMLInputElement>): void =>
    this.setState({ isUnderlined: event.currentTarget.checked });
}

export default withRouter(connect()(FormatModal));
