export enum UserRole {
  Trinity_Admin_User = 0,
  Trinity_User = 1,
  Client_Read_Write_User = 2,
  Client_Read_Only_User = 3,
}

export interface User {
  id: number;
  Display_Name: string;
  User_Name: string;
  Role: UserRole;
  ClientID: number | null;
}

export enum ModelMembershipRole {
  Contributor = 0,
  Owner = 1,
}

export interface ModelMembership {
  id: number;
  ClientID: number;
  ModelID: number;
  UserID: number;
  User: User;
  Role: ModelMembershipRole;
}

export interface Profile {
  User: User;
  ModelMemberships: Array<
    Pick<ModelMembership, "ClientID" | "ModelID" | "Role">
  >;
}

export interface ADUser {
  userName: string;
}

export interface Client {
  id: number;
  Name: string;
  models?: Model[];
}

export interface Model {
  id: number;
  Name: string;
  Client: number;
}

export enum ModelInstanceState {
  Draft = 0,
  Dirty = 1,
  Synced = 2,
  Published = 3,
}

export interface ModelInstance {
  id?: number;
  Name: string;
  ModelID: number;
  Timescale: string;
  Start_DateID: number | null;
  End_DateID: number | null;
  Monthly_Flag: number;
  Quarterly_Flag: number;
  Yearly_Flag: number;
  State: ModelInstanceState;
  Draft: boolean;
  Editable: boolean;
  Published: boolean;
  updated_at: string;
  Archive_Flag: 0 | 1;
  Tab_Transfer_Download_BlobURL: string;
  Tab_Transfer_Download_FileName: string;
}

export type ModelInstanceFilter = "current" | "archive";

export enum GeneratedFileStatus {
  Queued = 0,
  Started = 1,
  Finished = 2,
  Failed = -1,
  Failed_With_Return_File = -2,
  Failed_WebCalculation_FileGeneration = -3,
}

export interface GeneratedFile {
  id: number;
  FileName: string;
  BlobName: string;
  BlobURL: string;
  Status: GeneratedFileStatus;
  Status_Message?: string;
  created_at: string;
}

export interface ModelInstanceRevision {
  id: number;
  ModelInstanceID: number;
  GeneratedFile: GeneratedFile;
}

export interface Dimension {
  id?: number;
  Name: string;
  DimNumber: number;
  ModelInstance: number;
  dimension_instances: DimensionInstance[];
}

export interface DimensionInstance {
  id?: number;
  Name: string;
  dimension: Dimension;
  Tab_Name?: string;
  FirstDimensionGroupID?: number;
}

interface NestedDimensionInstance {
  id?: number;
  ModelInstanceID: number;
}

export interface FirstDimensionGroup {
  id: number;
  Name: string;
  dimension_instances: DimensionInstance[];
}

export interface Node {
  id: number;
  dimension: number;
  dimension_instance: NestedDimensionInstance;
  parent_node: number | null;
  child_nodes: number[];
  BlockNodeException_Flag?: number;
}

export type CalculationBlockType =
  | "Standard"
  | "Dimension Aggregation"
  | "Timescale Aggregation";

export interface CalculationBlock {
  id?: number;
  Name?: string;
  DimensionID?: number;
  ModelInstanceID: number;
  Type: CalculationBlockType;
  isEditing?: boolean;
}

export interface StandardCalculationBlock extends CalculationBlock {
  Name: string;
  DimensionID: number;
}

export interface AggregationCalculationBlock extends CalculationBlock {}

export type CalculationBlockRowType = "Intermediate" | "Nested" | "Output";

export interface CalculationBlockRow {
  isEditing?: boolean;
  id?: number;
  Name?: string;
  Type: CalculationBlockRowType;
  BlockRow_Number?: number;
  BlockID: number;
  FunctionID: number;
  OutputID?: number;
  ModelInstanceID: number;
  FormatParameterID?: number;
  IsValid: boolean;
  Representation?: string;
  Root_BlockRowID?: number;
  Comment: string | null;
}

export interface InputCategory {
  id?: number;
  ModelInstanceID: number;
  Name: string;
}

export interface Output {
  id?: number;
  Name: string;
  ModelInstanceID: number;
  Timescale_Aggregation_BlockID?: number;
  Dimension_Aggregation_BlockID?: number;
  dimensionMapping: OutputDimensionMapping[];
  FormatParameterID?: number;
  Dimension_Aggregation_FunctionID: number | null;
  Timescale_Aggregation_FunctionID: number | null;
  SuppressReportingDimensionTotal1: boolean;
  SuppressReportingDimensionTotal2: boolean;
  SuppressReportingDimensionTotal3: boolean;
  DisableOutputAggregation: boolean;
}

export interface OutputDimensionMapping {
  id?: number;
  DimensionID: number;
  Reporting_Flag: number;
  Aggregation_Flag: number;
  Dimension_Total_1: number;
  Dimension_Total_2: number;
  Dimension_Total_3: number;
}

export type InputTimescaleType = "Weekly" | "Monthly" | "Quarterly" | "Yearly";

export type FormatNumberType =
  | "General"
  | "Percent"
  | "ShortDate"
  | "INTLDate"
  | "MonthDate"
  | "$";

export type TabNameType =
  | "Single Value Assumptions"
  | "Uptake & Event Assumptions"
  | "Other Assumptions"
  | "Weekly Assumptions"
  | "Monthly Assumptions"
  | "Quarterly Assumptions"
  | "Yearly Assumptions"
  | "Weekly Data"
  | "Monthly Data"
  | "Quarterly Data"
  | "Yearly Data";

export type InputCurveType = "Event" | "Uptake";

export type InputCrossType =
  | "Cousin"
  | "Sibling"
  | "Sibling, Cousin"
  | "Self, Sibling"
  | "Self, Sibling, Cousin";

export interface Input {
  id?: number;
  CategoryID: number;
  Cross_Flag: number;
  Cross_Type?: InputCrossType;
  Curve_Type?: InputCurveType;
  ModelInstanceID: number;
  Name: string;
  Tab_Name: TabNameType;
  Timescale?: InputTimescaleType;
  blockMapping: InputBlockMapping[];
  dimensionMapping: InputDimensionMapping[];
  FormatParameterID?: number;
  HasActiveNoteInput: boolean;
  DisableInputEdit: boolean;
}

export interface InputBlockMapping {
  id?: number;
  BlockID: number;
  ModelInstanceID: number;
  InputID: number;
}

export interface InputDimensionMapping {
  id?: number;
  DimensionID: number;
  ModelInstanceID: number;
  InputID: number;
}

export interface BlockSourceBlockMapping {
  id?: number;
  ModelInstanceID: number;
  BlockSourceID: number;
  BlockID: number;
}

export interface BlockSourceDimensionMapping {
  id: number;
  ModelInstanceID: number;
  BlockSourceID: number;
  DimensionID: number;
}

export interface BlockSourceCurveSubTypeMapping {
  id: number;
  ModelInstanceID: number;
  BlockSourceID: number;
  Curve_Sub_TypeID: number;
}

export interface BlockSource {
  id?: number;
  sourceCode?: string;
  sourceName?: string;
  ModelInstanceID: number;
  BlockID: number;
  Type: string;
  MDA_Source_Code: number;
  BlockRowID?: number;
  InputID?: number;
  OutputID?: number;
  Curve_Sub_TypeID?: number;
  Cross_Output_Type?: string;
  DimensionID?: number;
  blocks?: number[];
  blockMapping: BlockSourceBlockMapping[];
  dimensionMapping: BlockSourceDimensionMapping[];
  curveSubTypeMapping: BlockSourceCurveSubTypeMapping[];
}

export interface XRefFunction {
  Dimension_Aggregation_Type: boolean;
  Timescale_Aggregation_Type: boolean;
  Aggregation_Dropdown_Flag: boolean;
  Standard_Type: boolean;
  FunctionID: number;
  Function: string;
  FunctionCategory: string;
}

export type DateTimsecaleType = "Weekly" | "Monthly" | "Quarterly" | "Yearly";

export interface XRefDate {
  DateID: number;
  Date: string;
  Timescale: DateTimsecaleType;
}

export interface XRefCurveSubType {
  Curve_Sub_TypeID: number;
  Curve_Sub_Type: string;
}

export interface BlockInstanceMapping {
  id?: number;
  InstanceID: number;
  BlockID: number;
  Block_Order: number;
  ModelInstanceID: number;
}

export interface BlockNodeMapping {
  id?: number;
  NodeID: number;
  BlockID: number;
  Block_Order: number;
  ModelInstanceID: number;
}

export interface FunctionParameter {
  id?: number;
  ModelInstanceID: number;
  Function_BlockRowID: number;
  FunctionID: number;
  BlockSourceID?: number;
  Nested_BlockRowID?: number;
  Nested_FunctionID?: number;
  Scalar?: string;
  Parameter_Number: number;
  Type?: string;
  IsValidParameter: boolean;
  Representation?: string;
}

export interface SchemaFunctionParameterValidation {
  Type: string;
  Validation: any;
}

export interface SchemaFunctionParameter {
  Number: number;
  Name: string;
  "Parameter Type Validation": SchemaFunctionParameterValidation[];
  Optional: boolean;
}

export interface SchemaFunction {
  ID: number;
  Name: string;
  Category: string;
  Repeat: boolean;
  "Show Name": boolean;
  Timescale: string[];
  Parameters: SchemaFunctionParameter[];
}

export interface FormatParameter {
  id?: number;
  Type: string;
  InputID?: number;
  OutputID?: number;
  BlockRowID?: number;
  Number_Type: FormatNumberType;
  Magnitude_Truncation: number;
  Number_Decimals: number;
  Bold_Flag: number;
  Italic_Flag: number;
  Underline_Flag: number;
  Data_Validation: string | null;
}

export interface CloneSubtree {
  dimension_instance: NestedDimensionInstance;
  parent_node: number | null;
  BlockNodeException_Flag?: number;
}

export enum Timescale {
  Yearly = "Yearly",
  Quarterly = "Quarterly",
  Monthly = "Monthly",
  Weekly = "Weekly",
}
