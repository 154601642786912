import * as React from "react";
import { Link } from "react-router-dom";

import AppViewTemplate from "../../templates/AppView";
import { MaxBound } from "../../atoms/Layout";

const HomeView: React.FC = () => (
  <AppViewTemplate title="Dashboard">
    <MaxBound mt={3}>
      <Link to="/clients">My Clients</Link>
    </MaxBound>
  </AppViewTemplate>
);

export default HomeView;
