import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Alert, Intent, FormGroup } from "@blueprintjs/core";

import { finalizeModelInstance } from "../../store/modules/model_instances";
import { ThunkDispatch } from "../../types/redux";

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  isOpen: boolean;
  onClose: () => void;
  modelInstanceId: number;
}

interface IState {
  isLoading: boolean;
  error: string;
}

class FinalizeDimensionsModal extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: false,
    error: "",
  };

  public render() {
    return (
      <Alert
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        onConfirm={this.handleSubmit}
        intent={Intent.PRIMARY}
        icon="warning-sign"
        cancelButtonText="Go back"
        confirmButtonText="Yes, finalize structure"
      >
        <FormGroup
          helperText={this.state.error}
          intent={this.state.error ? "danger" : "none"}
        >
          <p>
            Are you sure you are done editing this model structure? Once
            finalized you cannot reorder dimension nodes.
          </p>
        </FormGroup>
      </Alert>
    );
  }

  private handleSubmit = async () => {
    this.setState({ isLoading: true });

    this.props
      .dispatch(finalizeModelInstance(this.props.modelInstanceId))
      .then(() => {
        this.props.history.push(`${this.props.location.pathname}/nodes`);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: "Error finalizing model instance. Please try again.",
        });
        console.log(error);
      });
  };
}

export default withRouter(connect()(FinalizeDimensionsModal));
