import * as React from "react";
import { connect } from "react-redux";

import { ThunkDispatch } from "../../../../types/redux";

import RowSource from "./RowSource";
import InputSource from "./InputSource";
import OutputSource from "./OutputSource";

import {
  BlockSource,
  CalculationBlock,
  Dimension,
  XRefCurveSubType,
} from "../../../../types/models";

interface IProps {
  blockSource: BlockSource;
  calculationBlock: CalculationBlock;
  calculationBlocks: CalculationBlock[];
  dimensions: Dimension[];
  dispatch: ThunkDispatch;
  xrefCurveSubTypes: XRefCurveSubType[];
  disableEditMode: boolean;
  modelInstanceId: string;
  addRemoveToEdit: any;
  isEditingArr: Array<number | undefined>;
}

class BlockSourceRow extends React.Component<IProps> {
  public render() {
    const {
      blockSource,
      calculationBlock,
      calculationBlocks,
      dimensions,
      dispatch,
      xrefCurveSubTypes,
      disableEditMode,
      modelInstanceId,
      addRemoveToEdit,
      isEditingArr,
    } = this.props;

    if (this.isIntermediateSource()) {
      return <RowSource blockSource={blockSource} />;
    } else if (this.isInputSource()) {
      return (
        <InputSource
          blockSource={blockSource}
          dispatch={dispatch}
          xrefCurveSubTypes={xrefCurveSubTypes}
          disableEditMode={disableEditMode}
          modelInstanceId={modelInstanceId}
          addRemoveToEdit={addRemoveToEdit}
          isEditingArr={isEditingArr}
        />
      );
    } else {
      return (
        <OutputSource
          blockSource={blockSource}
          calculationBlock={calculationBlock}
          calculationBlocks={calculationBlocks}
          dimensions={dimensions}
          dispatch={dispatch}
          disableEditMode={disableEditMode}
          modelInstanceId={modelInstanceId}
          addRemoveToEdit={addRemoveToEdit}
          isEditingArr={isEditingArr}
        />
      );
    }
  }

  private isInputSource = (): boolean =>
    Boolean(this.props.blockSource.InputID);

  private isIntermediateSource = (): boolean =>
    Boolean(this.props.blockSource.BlockRowID);
}

export default connect()(BlockSourceRow);
