import * as React from "react";
import "styled-components/macro";
import styled from "styled-components";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { Box, Flex } from "@rebass/grid";
import { IconNames } from "@blueprintjs/icons";
import {
  Colors,
  PopoverInteractionKind,
  Button,
  FormGroup,
  Icon,
  InputGroup,
  HTMLSelect,
  Switch,
  Tag,
  Text,
  Divider,
  Menu,
  Dialog,
  Classes,
  Intent,
} from "@blueprintjs/core";

import {
  actions,
  saveCalculationBlockRow,
  deleteCalculationBlockRow,
  editCalculationBlockRow,
  cloneCalculationBlockRow,
} from "../../../../store/modules/calculation_block_rows";
import { getBlockSources } from "../../../../store/modules/block_sources";
import { ThunkDispatch } from "../../../../types/redux";
import {
  CalculationBlockRow,
  Output,
  XRefFunction,
  FormatParameter,
  CalculationBlock,
} from "../../../../types/models";
import RowAlign from "../RowAlign";
import FormatModal from "../../../modals/Format";
import CommentModal from "../../../modals/Comment";

import { RootState } from "../../../../store/reducer";
import { connect } from "react-redux";
import { setCalculationBlockRowTree } from "../../../../store/modules/calculation_block_row_tree";
import { setFormulaRepresentation } from "../../../../store/modules/selected_formula_representation";
import { head, trimStart } from "lodash";
import { selectCalculationRow } from "../../../../store/modules/selected_calculation_row";
import { FormEvent } from "react";
import { getFormatParameters } from "../../../../store/modules/format_parameter";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";

const cardStyle = {
  width: "100%",
  borderRadius: "3px",
  backgroundColor: "white",
  padding: "10px 20px",
  boxShadow:
    "0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0)",
};

interface IProps extends RouteComponentProps<any> {
  formatParameters: FormatParameter[];
  row: CalculationBlockRow;
  calculationBlockRows: CalculationBlockRow[];
  index: number;
  xrefFunctions: XRefFunction[];
  outputs: Output[];
  modelInstanceId: string;
  dispatch: ThunkDispatch;
  onValidate: (br: CalculationBlockRow) => boolean;
  selectedCalculationRow: Array<CalculationBlockRow["id"]>;
  handleLoading: (loading: boolean) => void;
  loading: boolean;
  refetchBlockRows: () => void;
  calculationBlock: CalculationBlock;
  isOutput: boolean;
  selectedOutput: Output;
}

interface IState {
  editedRow: CalculationBlockRow;
  isFormatModalOpen: boolean;
  isLoading: boolean;
  error: string;
  functionError: string;
  isCloneModalOpen: boolean;
  cloneCalculationBlockRowName: string;
  isCommentModalOpen: boolean;
}

class DraggableBlockRow extends React.Component<IProps, IState> {
  private cloneCalculationBlockRowInputRef: HTMLInputElement | undefined;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      editedRow: { ...props.row },
      isFormatModalOpen: false,
      isLoading: false,
      error: "",
      functionError: "",
      isCloneModalOpen: false,
      cloneCalculationBlockRowName: "",
      isCommentModalOpen: false,
    };
  }

  public componentDidMount() {
    if (this.props.isOutput) {
      this.setState({
        editedRow: {
          ...this.props.row,
          Type: "Output",
          OutputID: this.props.selectedOutput.id,
        },
      });
    }
  }

  public render() {
    const {
      editedRow,
      isLoading,
      isFormatModalOpen,
      isCloneModalOpen,
      cloneCalculationBlockRowName,
      isCommentModalOpen,
    } = this.state;
    const {
      row,
      outputs,
      selectedCalculationRow,
      loading,
      history,
      calculationBlock,
    } = this.props;
    const i = this.props.index;
    const isOutputType = "Output" === editedRow.Type;
    const formulaPath = this.props.location.pathname.replace(
      /(\d+)\/edit/,
      `$1/row/${editedRow.id}/formula`
    );

    const popoverContent = row.Representation && (
      <StyledRepresentation>{row.Representation}</StyledRepresentation>
    );

    const getItemStyle = (draggableStyle: any, isDragging: boolean): {} => ({
      userSelect: "none",
      backgroundColor:
        isDragging || head(selectedCalculationRow) === row.id || !row.BlockID
          ? `rgba(19,124,189,.15)`
          : Colors.WHITE,
      boxShadow:
        isDragging || head(selectedCalculationRow) === row.id || !row.BlockID
          ? `${Colors.BLUE3} 0px 0px 0px 1px, ${Colors.BLUE3} 0px 0px 0px, ${Colors.BLUE3} 0px 0px 0px`
          : `rgba(16, 22, 26, 0.15) 0px 0px 0px 1px, rgba(16, 22, 26, 0) 0px 0px 0px, rgba(16, 22, 26, 0) 0px 0px 0px`,
      ...draggableStyle,
    });

    return (
      <React.Fragment>
        <Flex alignItems="center">
          <Box css={{ gridColumn: "row-start / card-start" }} mr={2}>
            <Tag minimal={true}>{i + 1}</Tag>
          </Box>
          <Draggable
            key={`block-${row.BlockID}-row-${row.id}-${i}`}
            index={i}
            isDragDisabled={row.isEditing}
            draggableId={`${row.BlockID}-${row.id}`}
          >
            {(draggable, snapshot) => (
              <Box
                id={`blockRow-${row.id}`}
                css={{
                  ...cardStyle,
                  backgroundColor:
                    head(selectedCalculationRow) === row.id || !row.BlockID
                      ? `rgba(19,124,189,.15)`
                      : Colors.WHITE,
                  boxShadow:
                    head(selectedCalculationRow) === row.id || !row.BlockID
                      ? `${Colors.BLUE3} 0px 0px 0px 1px, ${Colors.BLUE3} 0px 0px 0px, ${Colors.BLUE3} 0px 0px 0px`
                      : `rgba(16, 22, 26, 0.15) 0px 0px 0px 1px, rgba(16, 22, 26, 0) 0px 0px 0px, rgba(16, 22, 26, 0) 0px 0px 0px`,
                  position: "relative",
                }}
                mb={2}
                ref={draggable.innerRef}
                {...draggable.draggableProps}
                style={getItemStyle(
                  draggable.draggableProps.style,
                  snapshot.isDragging
                )}
              >
                <RowAlign width="100%">
                  <Box
                    css={{ gridColumn: "card-start / col-1" }}
                    {...draggable.dragHandleProps}
                  >
                    <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} />
                  </Box>
                  <Flex
                    css={{ gridColumn: "col-1 / col-2" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Switch
                      className="draggableBlockRowSwitch"
                      disabled={!row.isEditing || this.props.isOutput}
                      checked={editedRow.Type === "Output"}
                      onChange={this.handleTypeChange}
                      style={{ marginBottom: "0" }}
                    />
                  </Flex>
                  <Box css={{ gridColumn: "col-2 / col-3" }}>
                    {row.isEditing ? (
                      <FormGroup
                        className="margin-zero"
                        helperText={this.state.error}
                        intent={this.state.error ? "danger" : "none"}
                      >
                        <NameOrOutputControl
                          isOutput={isOutputType}
                          row={editedRow}
                          onNameChange={this.handleNameChange}
                          onOutputChange={this.handleOutputChange}
                          outputs={outputs}
                          disabled={this.props.isOutput}
                        />
                      </FormGroup>
                    ) : (
                      <Text>
                        {isOutputType
                          ? this.outputName(row.OutputID)
                          : row.Name}
                      </Text>
                    )}
                  </Box>
                  <Box css={{ gridColumn: "col-3 / col-4" }}>
                    {row.isEditing ? (
                      <FormGroup
                        className="margin-zero"
                        helperText={this.state.functionError}
                        intent={this.state.functionError ? "danger" : "none"}
                      >
                        <StyledSelect
                          disabled={loading}
                          options={[
                            { label: "", value: "" },
                            ...this.functionTypeOptions(),
                          ]}
                          value={editedRow.FunctionID}
                          onChange={this.handleFunctionChange}
                        />
                      </FormGroup>
                    ) : (
                      <Text>{this.xrefFunctionName(editedRow.FunctionID)}</Text>
                    )}
                  </Box>
                  {this.showEditFormulaLink() ? (
                    <Box css={{ gridColumn: "col-4 / col-5" }}>
                      <Link
                        to={{
                          pathname: formulaPath,
                          state: { functionID: editedRow.FunctionID },
                        }}
                        onClick={() => {
                          /**
                           * Add Block Row name to breadcrumb
                           */
                          this.props
                            .dispatch(
                              setCalculationBlockRowTree({
                                id: row.id as number,
                                text: isOutputType
                                  ? this.outputName(row.OutputID)
                                  : (row.Name as string),
                                href: history.location.pathname,
                              })
                            )
                            .then(() => {
                              /**
                               * Add Block Row Function Name to breadcrumb
                               */
                              this.handleSetCalculationBlockRowTree(
                                this.xrefFunctionName(
                                  this.state.editedRow.FunctionID
                                ),
                                this.state.editedRow.FunctionID
                              );
                              this.props.dispatch(selectCalculationRow(row.id));
                              this.handleSetParameterRepresentation(
                                row.Representation as string
                              );
                            });
                        }}
                      >
                        Edit Formula
                      </Link>
                    </Box>
                  ) : (
                    <Box css={{ gridColumn: "col-4 / col-5" }}>
                      <Popover2
                        content={
                          !row.IsValid ? (
                            <StyledRepresentation
                              style={{
                                border: "1px solid rgba(255, 0, 0, 0.4)",
                              }}
                            >
                              Incomplete Formula
                            </StyledRepresentation>
                          ) : (
                            popoverContent
                          )
                        }
                        interactionKind={PopoverInteractionKind.HOVER}
                        targetTagName="div"
                      >
                        <InputGroup
                          style={
                            !row.IsValid
                              ? {
                                  border: "1px solid rgba(255, 0, 0, 0.4)",
                                }
                              : {}
                          }
                          defaultValue={row.Representation}
                          readOnly={true}
                        />
                      </Popover2>
                    </Box>
                  )}
                  <Flex ml="auto" css={{ gridColumn: " col-5 / card-end" }}>
                    {row.isEditing ? (
                      <React.Fragment>
                        <Button
                          minimal={true}
                          disabled={loading}
                          icon={!row.id ? IconNames.PLUS : IconNames.TICK}
                          loading={isLoading}
                          onClick={this.handleSaveRow}
                        />
                        {row.id ? (
                          <Button
                            minimal={true}
                            icon={IconNames.TRASH}
                            disabled={loading}
                            intent="danger"
                            loading={isLoading}
                            onClick={this.handleDeleteRow}
                          />
                        ) : (
                          <Button
                            minimal={true}
                            onClick={this.handleCancelAddRow}
                          >
                            Cancel
                          </Button>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          minimal={true}
                          disabled={loading}
                          icon={IconNames.EDIT}
                          onClick={() => this.handleEditBlockRow(row.id)}
                        />
                        <Divider />
                        <Popover2
                          disabled={loading}
                          content={
                            <Menu>
                              {calculationBlock.Type === "Standard" && (
                                <MenuItem2
                                  disabled={loading || !this.props.row.IsValid}
                                  icon={IconNames.DUPLICATE}
                                  text="Clone"
                                  onClick={() =>
                                    this.handleCloneModalOpen(row.id)
                                  }
                                  title={
                                    !this.props.row.IsValid
                                      ? "You cannot clone an incomplete block row"
                                      : ""
                                  }
                                />
                              )}
                              <MenuItem2
                                disabled={loading}
                                icon={IconNames.TH}
                                onClick={() =>
                                  this.handleFormatModalOpen(row.id)
                                }
                                text="Format"
                              />
                              <MenuItem2
                                disabled={loading}
                                icon={IconNames.COMMENT}
                                onClick={() =>
                                  this.handleEditCommentModalOpen(row.id)
                                }
                                text="Add/Edit Comment"
                              />
                            </Menu>
                          }
                        >
                          <Button
                            icon={IconNames.MORE}
                            minimal={true}
                            disabled={loading}
                          />
                        </Popover2>
                      </React.Fragment>
                    )}
                  </Flex>
                </RowAlign>
                {row.Comment && (
                  <Popover2
                    content={
                      <StyledRepresentation style={{ wordWrap: "break-word" }}>
                        {row.Comment}
                      </StyledRepresentation>
                    }
                    interactionKind={PopoverInteractionKind.HOVER}
                    targetTagName="div"
                    className="comment-popover"
                    popoverClassName="comment-popover-cn"
                  >
                    <Box
                      style={{
                        content: "",
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "0 15px 15px 0",
                        borderColor:
                          "transparent #608A32 transparent transparent",
                        right: 0,
                        top: 0,
                        position: "absolute",
                      }}
                    />
                  </Popover2>
                )}
              </Box>
            )}
          </Draggable>
        </Flex>
        {isFormatModalOpen && (
          <FormatModal
            itemName={row.Name ? row.Name : ""}
            onClose={this.handleModalClose}
            calculationBlockRow={row}
            formatParameter={this.findFormatParameter()}
          />
        )}
        {isCommentModalOpen && (
          <CommentModal
            calculationBlockRow={row}
            onClose={this.handleCommentModalClose}
            handleSave={this.handleCommentModalSave}
          />
        )}
        <Dialog
          onOpening={() =>
            this.cloneCalculationBlockRowInputRef &&
            this.cloneCalculationBlockRowInputRef.focus()
          }
          title="Clone Calculation Block Row"
          isOpen={isCloneModalOpen}
          onClose={() => {
            this.cloneCalculationBlockRowInputRef &&
              this.cloneCalculationBlockRowInputRef.blur();
            this.setState({ isCloneModalOpen: false });
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Calculation Block Row Name"
              labelFor="cloneCalculationBlockRowInput"
              helperText={`${
                256 - cloneCalculationBlockRowName.length
              } characters left`}
            >
              <InputGroup
                id="cloneCalculationBlockRowInput"
                value={cloneCalculationBlockRowName}
                onChange={(e: FormEvent<HTMLInputElement>) =>
                  this.setState({
                    cloneCalculationBlockRowName: trimStart(
                      e.currentTarget.value.substring(0, 256)
                    ),
                  })
                }
                inputRef={(ref) =>
                  (this.cloneCalculationBlockRowInputRef =
                    ref as HTMLInputElement)
                }
              />
            </FormGroup>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  text="Close"
                  disabled={isLoading}
                  minimal={true}
                  onClick={() =>
                    this.setState({
                      isCloneModalOpen: false,
                      cloneCalculationBlockRowName: "",
                    })
                  }
                />
                <Button
                  text="Clone"
                  loading={isLoading}
                  intent={Intent.PRIMARY}
                  disabled={!cloneCalculationBlockRowName || isLoading}
                  onClick={() => {
                    this.setState({ isLoading: true });
                    this.props
                      .dispatch(
                        cloneCalculationBlockRow(
                          row,
                          this.props.modelInstanceId,
                          row.BlockID,
                          cloneCalculationBlockRowName
                        )
                      )
                      .then((blockRow) => {
                        this.props
                          .dispatch(selectCalculationRow(blockRow.payload.id))
                          .then(() =>
                            this.props
                              .dispatch(
                                getFormatParameters(
                                  Number(this.props.modelInstanceId)
                                )
                              )
                              .then(() => {
                                this.props
                                  .dispatch(
                                    getBlockSources(
                                      Number(this.props.modelInstanceId)
                                    )
                                  )
                                  .then(() => {
                                    this.props.refetchBlockRows();
                                    window.scrollTo(
                                      0,
                                      document.body.scrollHeight
                                    );
                                    const calcBlockRow =
                                      document.getElementById(
                                        `blockRow-${blockRow.payload.id}`
                                      );
                                    calcBlockRow &&
                                      calcBlockRow.scrollIntoView();
                                    this.props.handleLoading(false);
                                    this.setState({
                                      isCloneModalOpen: false,
                                      cloneCalculationBlockRowName: "",
                                      isLoading: false,
                                    });
                                  });
                              })
                          );
                      });
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.selectedCalculationRow !== this.props.selectedCalculationRow
    ) {
      const isOutputType = "Output" === this.state.editedRow.Type;
      this.setState({
        cloneCalculationBlockRowName: `${
          isOutputType
            ? this.outputName(this.props.row.OutputID)
            : this.props.row.Name
        } Clone`,
      });
    }
  }

  private handleSetCalculationBlockRowTree = (
    name: CalculationBlockRow["Name"],
    id: XRefFunction["FunctionID"]
  ) => {
    if (name && id) {
      this.props.dispatch(
        setCalculationBlockRowTree({
          text: name,
          id,
          href: this.props.history.location.pathname,
        })
      );
    }
  };

  private handleSetParameterRepresentation = (formula: string) => {
    this.props.dispatch(setFormulaRepresentation(formula));
  };

  private handleEditBlockRow = (id: CalculationBlockRow["id"]) => {
    this.props
      .dispatch(selectCalculationRow(id))
      .then(() => this.props.dispatch(editCalculationBlockRow(id)));
  };

  private showEditFormulaLink = (): boolean => {
    const { editedRow } = this.state;
    const { row } = this.props;

    if (row.isEditing) {
      return !!(editedRow.id && editedRow.FunctionID === row.FunctionID);
    }

    return false;
  };

  private findFormatParameter = (): FormatParameter | undefined => {
    const { formatParameters, row } = this.props;

    if (row.FormatParameterID) {
      return formatParameters.find(
        (formatParameter) => formatParameter.id === row.FormatParameterID
      );
    }
    return undefined;
  };

  private handleModalClose = (): void =>
    this.setState({ isFormatModalOpen: false });

  private handleFormatModalOpen = (id: CalculationBlockRow["id"]): void => {
    this.props
      .dispatch(selectCalculationRow(id))
      .then(() => this.setState({ isFormatModalOpen: true }));
  };

  private handleCommentModalClose = (): void =>
    this.setState({ isCommentModalOpen: false });

  private handleCommentModalSave = (comment: string | null): void => {
    this.setState(
      {
        editedRow: {
          ...this.state.editedRow,
          Comment: comment,
        },
      },
      () => {
        this.handleSaveRow();
        this.handleCommentModalClose();
      }
    );
  };

  private handleEditCommentModalOpen = (
    id: CalculationBlockRow["id"]
  ): void => {
    this.props
      .dispatch(selectCalculationRow(id))
      .then(() => this.setState({ isCommentModalOpen: true }));
  };

  private handleCloneModalOpen = (id: CalculationBlockRow["id"]): void => {
    this.props
      .dispatch(selectCalculationRow(id))
      .then(() => this.setState({ isCloneModalOpen: true }));
  };

  private xrefFunctionName = (functionID: number) => {
    const func = this.props.xrefFunctions.find((xrf) => {
      return functionID === xrf.FunctionID;
    });

    return func ? func.Function : "N/A";
  };

  private functionTypeOptions = () => {
    return this.props.xrefFunctions.map((xrf) => {
      return { label: xrf.Function, value: xrf.FunctionID };
    });
  };

  private outputName = (outputID?: number): string => {
    if (!outputID) {
      return "N/A";
    }
    const output = this.props.outputs.find((o) => outputID === o.id);
    return output ? output.Name : "N/A";
  };

  private handleTypeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const checked = evt.currentTarget.checked;
    const { outputs } = this.props;

    if (checked) {
      if (outputs.length) {
        this.setState({
          editedRow: {
            ...this.state.editedRow,
            Type: "Output",
            OutputID: this.props.outputs[0].id,
            Name: undefined,
          },
          error: "",
        });
      } else {
        this.setState({ error: "No Outputs have been defined yet!" });
      }
    } else {
      this.setState({
        editedRow: {
          ...this.state.editedRow,
          Type: "Intermediate",
          OutputID: undefined,
          Name: "",
        },
        error: "",
      });
    }
  };

  private handleNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editedRow: { ...this.state.editedRow, Name: evt.target.value },
    });
  };

  private handleOutputChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      editedRow: {
        ...this.state.editedRow,
        OutputID: parseInt(evt.currentTarget.value, 10),
      },
    });
  };

  private handleFunctionChange = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({
      editedRow: {
        ...this.state.editedRow,
        FunctionID: parseInt(evt.currentTarget.value, 10),
      },
      functionError: "",
    });
  };

  private handleCancelAddRow = () => {
    this.props.dispatch(actions.cancelAddCalculationBlockRow());
  };

  private handleDeleteRow = () => {
    const { dispatch, modelInstanceId, row } = this.props;

    if (window.confirm("Are you sure you want to delete this BlockRow?")) {
      this.props.handleLoading(true);
      this.setState({ isLoading: true });
      return dispatch(deleteCalculationBlockRow(row, modelInstanceId))
        .then(() => {
          dispatch(getBlockSources(modelInstanceId));
          this.props.handleLoading(false);
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.props.handleLoading(false);
          this.setState({ isLoading: false });
        });
    }

    return;
  };

  private handleSaveRow = () => {
    const { dispatch, modelInstanceId } = this.props;
    const { editedRow } = this.state;
    const isOutput = "Output" === editedRow.Type;
    if (editedRow?.FunctionID === 66 || isNaN(editedRow?.FunctionID)) {
      return this.setState({ functionError: "Function cannot be empty" });
    }
    // If Output type, verify we have a unique OutputID
    if (!this.props.onValidate(editedRow)) {
      const error = isOutput
        ? "Please select an unused Output for this BlockRow."
        : "Please provide a valid name.";

      return this.setState({ error });
    }

    this.props.handleLoading(true);
    this.setState({ isLoading: true });
    return dispatch(saveCalculationBlockRow(editedRow))
      .then((r) => {
        dispatch(selectCalculationRow(r.payload.id)).then(() =>
          dispatch(getBlockSources(modelInstanceId)).then(() => {
            this.props.handleLoading(false);
            this.setState({ isLoading: false });
          })
        );
      })
      .catch(() => {
        this.props.handleLoading(false);
        this.setState({ isLoading: false });
      });
  };
}

interface INameOrOutputControlProps {
  row: CalculationBlockRow;
  isOutput: boolean;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOutputChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  outputs: Output[];
  disabled: boolean;
}

const NameOrOutputControl: React.FunctionComponent<
  INameOrOutputControlProps
> = ({ isOutput, row, onNameChange, onOutputChange, outputs, disabled }) => {
  if (isOutput) {
    return (
      <StyledSelect
        onChange={onOutputChange}
        value={row.OutputID}
        options={outputs.map((o) => {
          return { label: o.Name, value: o.id! };
        })}
        disabled={disabled}
      />
    );
  }

  return (
    <InputGroup required={true} value={row.Name} onChange={onNameChange} />
  );
};

const StyledSelect = styled(HTMLSelect)`
  width: 100%;
`;

const mapStateToProps = (state: RootState) => ({
  formatParameters: state.formatParameters,
  selectedCalculationRow: state.selectedCalculationRow,
});

export default withRouter(connect(mapStateToProps)(DraggableBlockRow));

const StyledRepresentation = styled.div`
  padding: 20px;
`;
