import React, { FunctionComponent } from "react";
import { Button, Colors, IButtonProps, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "styled-components";

const ScrollButton = styled(Button)`
  opacity: 1;
  position: fixed;
  bottom: 70px;
  right: 25px;
  height: 50px;
  border-radius: 6px !important;
`;

type IFloatingButtonProps = IButtonProps;

const FloatingButton: FunctionComponent<IFloatingButtonProps> = (props) => {
  return (
    <ScrollButton
      {...props}
      intent={Intent.PRIMARY}
      icon={<Icon icon={IconNames.ADD} color={Colors.WHITE} />}
    />
  );
};

export default FloatingButton;
