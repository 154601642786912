import * as React from "react";
import { connect } from "react-redux";
import { Flex } from "@rebass/grid";
import { Classes } from "@blueprintjs/core";
import { ThunkDispatch } from "../../../types/redux";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { compact } from "lodash";

import { RootState } from "../../../store/reducer";
import { ModelInstance, ModelInstanceFilter } from "../../../types/models";
import { MaxBound } from "../../atoms/Layout";
import { ClientMap, getClient } from "../../../store/modules/client";
import { getModel, ModelMap } from "../../../store/modules/model";
import { getModelInstance } from "../../../store/modules/model_instances";
import { clearCalculationBlockRowTree } from "../../../store/modules/calculation_block_row_tree";
import store from "../../../store";
import { clearSelectCalculationRow } from "../../../store/modules/selected_calculation_row";
import { clearSelectOutputRow } from "../../../store/modules/selected_output_row";
import { clearSelectInputRow } from "../../../store/modules/selected_input_row";
import { clearSelectCalculationBlockRow } from "../../../store/modules/selected_calculation_block_rows";

interface BreadcrumbMap {
  text?: string;
  href: string;
  id: number;
}

interface IProps extends RouteComponentProps<any> {
  dispatch: ThunkDispatch;
  clients: ClientMap;
  models: ModelMap;
  modelInstances: ModelInstance[];
  modelInstancesFilter: ModelInstanceFilter;
}

class Breadcrumbs extends React.Component<IProps> {
  public componentDidMount() {
    const { dispatch } = this.props;
    const { client, model, instance } = this.props.match.params;
    if (client !== undefined) {
      dispatch(getClient(client));
      if (model !== undefined) {
        dispatch(getModel(model, client));
      }
    }
    if (instance !== undefined) {
      dispatch(getModelInstance(instance));
    }
  }
  public render() {
    const { match, clients, modelInstances, models, modelInstancesFilter } =
      this.props;
    const crumbs = compact([
      "Clients",
      match.params.client,
      match.params.model,
      match.params.instance,
    ]);

    const breadcrumbs: BreadcrumbMap[] = crumbs.map((crumb, idx) => {
      switch (idx) {
        case 0:
          return {
            id: idx,
            text: crumb,
            href: "/clients",
          };
        case 1:
          return {
            id: idx,
            text: clients[crumb] ? clients[crumb].Name : crumb,
            href: `/clients/${crumb}`,
          };
        case 2:
          const modelUrl = models[crumb] ? models[crumb].id : "";
          return {
            id: idx,
            text: models[crumb] ? models[crumb].Name : crumb,
            href: `/clients/${crumbs[1]}/models/${modelUrl}/${modelInstancesFilter}`,
          };
        case 3:
          const selectedModelInstance = modelInstances.find(
            (modelInstance) => Number(crumb) === modelInstance.id
          );
          const modelInstanceUrl = selectedModelInstance
            ? selectedModelInstance.id
            : "";
          const addOn =
            selectedModelInstance !== undefined && selectedModelInstance.Draft
              ? ""
              : "/nodes";
          return {
            id: idx,
            text: selectedModelInstance ? selectedModelInstance.Name : crumb,
            href: `/clients/${crumbs[1]}/models/${crumbs[2]}/${modelInstancesFilter}/instances/${modelInstanceUrl}${addOn}`,
          };
        default:
          return {
            id: idx,
            href: "/",
          };
      }
    });
    return (
      <Flex flex={1} className={Classes.DARK}>
        <MaxBound>
          <ul className={Classes.BREADCRUMBS}>
            {breadcrumbs.map((crumb, i) => (
              <li key={crumb.id}>
                <Link
                  onClick={() => {
                    Promise.all([
                      store.dispatch<any>(clearSelectCalculationRow()),
                      store.dispatch<any>(clearSelectOutputRow()),
                      store.dispatch<any>(clearSelectInputRow()),
                      store.dispatch<any>(clearSelectCalculationBlockRow()),
                      store.dispatch<any>(clearCalculationBlockRowTree()),
                    ]);
                  }}
                  style={{ fontSize: "14px" }}
                  to={crumb.href}
                  className={
                    i === breadcrumbs.length - 1
                      ? Classes.BREADCRUMB_CURRENT
                      : Classes.BREADCRUMB
                  }
                >
                  {crumb.text}
                </Link>
              </li>
            ))}
          </ul>
        </MaxBound>
      </Flex>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clients: state.clients,
  modelInstances: state.instances,
  models: state.models,
  modelInstancesFilter: state.instanceFilter,
});

export default connect(mapStateToProps)(withRouter(Breadcrumbs));
