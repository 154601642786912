import * as React from "react";

import { Flex } from "@rebass/grid";
import { Classes, ControlGroup, Text } from "@blueprintjs/core";
import EditableText from "../../molecules/EditableText";
import { CRUDableString } from "../../molecules/CRUDable";

interface IProps {
  tabName?: string;
  addTabName: (name: string) => Promise<void>;
  updateTabName: (newName: string) => Promise<void>;
  deleteTabName: () => Promise<void>;
}

const DimensionTabName: React.FC<IProps> = ({
  tabName,
  addTabName,
  updateTabName,
  deleteTabName,
}) => (
  <React.Fragment>
    <div style={{ marginBottom: "20px" }}>
      <Text className={Classes.TEXT_LARGE}>Tab Name:</Text>
    </div>
    <Flex style={{ marginBottom: "20px" }}>
      <CRUDableString
        value={tabName}
        creationPrompt="Add Tab Name"
        onCreate={addTabName}
        onUpdate={updateTabName}
        onDelete={deleteTabName}
        editor={({ value, handleUpdate, handleCancel }) => (
          <ControlGroup fill={true}>
            <EditableText
              value={value || ""}
              placeholder=""
              onChange={handleUpdate}
              onCancel={handleCancel}
              startEditing={true}
            />
          </ControlGroup>
        )}
      />
    </Flex>
  </React.Fragment>
);

export default DimensionTabName;
